import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const ChartCms = ({ series }) => {
    const [graphData, setGraphData] = useState({});

    useEffect(() => {
        // Process the data to group it by moc
        const categories = Object.keys(series);
        const mocData = Object.keys(series[categories[0]]);
        const chartData = mocData.map((moc) => ({
            name: moc,
            data: categories.map((category) => series[category][moc] || 0),
        }));

        setGraphData({
            options: {
                chart: {
                    type: "bar",
                    height: 350,
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        stacked: true,
                    },
                },
                xaxis: {
                    categories: categories,
                },
                yaxis: {
                    title: {
                        text: "Number of CMS",
                        style: {
                            fontSize: "13px",
                            fontWeight: "500",
                        },
                    },
                },
                colors: ["#F6AE2D", "#0A78A1", "#00A7A4", "#ED5958"],
                title: {
                    text: "CMS Overview",
                    align: "left",
                    style: {
                        fontSize: "14px",
                        fontWeight: "550",
                    },
                },
            },
            series: chartData,
        });
    }, [series]);

    return (
        <div>
            {graphData.options && graphData.series ? (
                <Chart
                    options={graphData.options}
                    series={graphData.series}
                    type="bar"
                    height={graphData.options.chart.height}
                />
            ) : (
                <p>Loading graph...</p>
            )}
        </div>
    );
};
export default ChartCms;