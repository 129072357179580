import React, { useState, useEffect } from "react";
import { Typography, InputLabel, Divider, TextField, MenuItem, Button, CircularProgress, DialogTitle, DialogContent, Box } from "@mui/material";
import Header from "../app/TopAppBar";
import { useRecoilState } from "recoil";
import { changeRequestData, editCMSData } from "../recoil/atoms";
import "../index.css";
import axios from "axios";
import API from "../Services/API";
import { useNavigate } from "react-router-dom";
import configParam from "../config";

const descChangeRequired = configParam.DES_CHANGE_REQUIRED;

const ChangeRequest = ({ dataProps }) => {
    const [changeRequest, setChangeRequest] = useRecoilState(changeRequestData);
    const [newDialogRequestsOpen, setNewDialogRequestsOpen] = useState('true');
    const [isEditCms, setIsEditCms] = useRecoilState(editCMSData);
    const [errors, setErrors] = useState(false);
    const [stepError, setStepError] = useState(false);
    const [save, setSave] = useState(false);
    const [saveMessage, setSaveMessage] = useState(false);
    const [initialLoad, setInitialLoad] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [showUpdatePopup, setShowUpdatePopup] = useState(false);
    const [, setCmsData] = useState([]);
    const [departmentData, setDepartmentData] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [modelData, setModelData] = useState([]);
    const [glassReferenceData, setGlassReferenceData] = useState([]);
    const [plantData, setPlantData] = useState([]);
    const [changeCategoryData, setChangeCategoryData] = useState([]);
    const [changeCategoryTypeData, setChangeCategoryTypeData] = useState([]);
    const [designChangeData, setDesignChangeData] = useState([]);
    // const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const navigate = useNavigate();
    //console.log(isEditCms, "isEditCms35")
    const access_token = JSON.parse(localStorage.getItem("accesstoken"));

    useEffect(() => {
        const handlePopup = (setter, delay) => {
            setter(true);
            const timer = setTimeout(() => {
                setter(false);
            }, delay);
            return () => clearTimeout(timer);
        };
        const handlePopups = (showPopup, setShowPopup) => {
            if (showPopup) {
                setInitialLoad(true);
                setTimeout(() => {
                    setInitialLoad(false);
                    handlePopup(setShowPopup, 1000);
                }, 2000);
            }
        };
        handlePopups(showSuccessPopup, setShowSuccessPopup);
        handlePopups(showUpdatePopup, setShowUpdatePopup);
    }, [showSuccessPopup, showUpdatePopup]);

    useEffect(() => {
        const today = new Date().toISOString().split("T")[0];
        let updatedForm = { ...changeRequest };
        if (!updatedForm.date) {
            updatedForm["date"] = today;
            setChangeRequest(updatedForm);
        }
    }, [changeRequest, setChangeRequest]);

    const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
    const userFName = storedUserData.FName + " " + storedUserData.LName;

    const fetchMasterData = async (retryCount = 3) => {
        setInitialLoad(true);
        let isMounted = true; // Track if component is mounted
        try {
            const response = await axios.get(`${API.GET_CMS_MASTER_DATA}`, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
            const department = response.data
                .filter(item => item.tag === "department")
                .map(({ id, master_data, tag, master_data_no }) => ({ id, master_data, tag, master_data_no }))
                .sort((a, b) => a.master_data_no - b.master_data_no);
            const customer = response.data
                .filter(item => item.tag === "customer")
                .map(({ id, master_data, tag, master_data_no }) => ({ id, master_data, tag, master_data_no }))
                .sort((a, b) => a.master_data_no - b.master_data_no);
            const model = response.data
                .filter(item => item.tag === "model")
                .map(({ id, master_data, tag, master_data_no }) => ({ id, master_data, tag, master_data_no }))
                .sort((a, b) => a.master_data_no - b.master_data_no);
            const glassReference = response.data
                .filter(item => item.tag === "glass_reference")
                .map(({ id, master_data, tag, master_data_no }) => ({ id, master_data, tag, master_data_no }))
                .sort((a, b) => a.master_data_no - b.master_data_no);
            const plant = response.data
                .filter(item => item.tag === "plant")
                .map(({ id, master_data, tag, master_data_no }) => ({ id, master_data, tag, master_data_no }))
                .sort((a, b) => a.master_data_no - b.master_data_no);
            const changeCategory = response.data
                .filter(item => item.tag === "change_category")
                .map(({ id, master_data, tag, master_data_no }) => ({ id, master_data, tag, master_data_no }))
                .sort((a, b) => a.master_data_no - b.master_data_no);
            const changeCategoryType = response.data
                .filter(item => item.tag === "change_category_type")
                .map(({ id, master_data, tag, master_data_no }) => ({ id, master_data, tag, master_data_no }))
                .sort((a, b) => a.master_data_no - b.master_data_no);
            const designChange = response.data
                .filter(item => item.tag === "design_change")
                .map(({ id, master_data, tag, master_data_no }) => ({ id, master_data, tag, master_data_no }))
                .sort((a, b) => a.master_data_no - b.master_data_no);
            if (isMounted) {
                setDepartmentData(department);
                setCustomerData(customer);
                setModelData(model);
                setGlassReferenceData(glassReference);
                setPlantData(plant);
                setChangeCategoryData(changeCategory);
                setChangeCategoryTypeData(changeCategoryType);
                setDesignChangeData(designChange);
            }
        } catch (error) {
            if (error.response && error.response.status === 429 && retryCount > 0) {
                console.warn("Rate limit exceeded, retrying...");
                await new Promise(resolve => setTimeout(resolve, 1000)); // wait for 1 second
                fetchMasterData(retryCount - 1);
            } else {
                console.error("Error fetching master data:", error);
            }
        }
        if (isMounted) setInitialLoad(false);
        return () => { isMounted = false; }; // Cleanup function
    };

    useEffect(() => {
        fetchMasterData();
    }, []);

    const handleDateChange = (event) => {
        const inputDate = event.target.value;
        const selectedDate = new Date(inputDate);
        const today = new Date().toISOString().split("T")[0];
        let updatedForm = { ...changeRequest };
        if (!isNaN(selectedDate.getTime()) && inputDate <= today) {
            updatedForm["date"] = inputDate;
            setChangeRequest(updatedForm);
        } else {
            setChangeRequest(updatedForm);
        }
    };

    const handleReceivingDateChange = (event) => {
        const inputDate = event.target.value;
        const updatedForm = { ...changeRequest };
        if (inputDate === "") {
            updatedForm["receiveing_customer_date"] = "";
        } else {
            updatedForm["receiveing_customer_date"] = inputDate; // Accept any valid date
        }
        setChangeRequest(updatedForm);
    };

    const handleFeasCustomerDateChange = (event) => {
        const inputDate = event.target.value;
        const updatedForm = { ...changeRequest };
        if (inputDate === "") {
            updatedForm["feas_con_customer_date"] = "";
        } else {
            updatedForm["feas_con_customer_date"] = inputDate; // Accept any valid date
        }
        setChangeRequest(updatedForm);
    };

    const handleCustomerImpleDateChange = (event) => {
        const inputDate = event.target.value;
        let updatedForm = { ...changeRequest };
        if (inputDate === "") {
            updatedForm["customer_req_imple_date"] = "";
        } else {
            updatedForm["customer_req_imple_date"] = inputDate; // Accept any valid date
        }
        setChangeRequest(updatedForm);
    };

    const onHandleChange = (event, field) => {
        let updatedForm = { ...changeRequest };
        const value = event.target.value;
        updatedForm[field] = value;
        setChangeRequest(updatedForm);
        setErrors(false);
        // setHasUnsavedChanges(true);
    };

    const requestData = {
        change_request: [],
    };

    requestData.change_request.push({
        date: changeRequest.date,
        request_initiator: userFName,
        department: changeRequest.department,
        type_design_change: changeRequest.type_design_change,
        plant: changeRequest.plant,
        change_category: changeRequest.change_category,
        design_change: changeRequest.design_change,
        process_change: changeRequest.process_change,
        design_int_customer: changeRequest.design_int_customer,
        design_int_model: changeRequest.design_int_model,
        design_int_glass_reference: changeRequest.design_int_glass_reference,
        design_int_part_number_rev_no: changeRequest.design_int_part_number_rev_no,
        design_int_part_number: changeRequest.design_int_part_number,
        design_int_part_name: changeRequest.design_int_part_name,
        design_int_drawing_no_rev_no: changeRequest.design_int_drawing_no_rev_no,
        design_int_drawing_number: changeRequest.design_int_drawing_number,
        design_int_process_reference: changeRequest.design_int_process_reference,
        design_ext_customer: changeRequest.design_ext_customer,
        design_ext_model: changeRequest.design_ext_model,
        design_ext_glass_reference: changeRequest.design_ext_glass_reference,
        design_ext_part_number_rev_no: changeRequest.design_ext_part_number_rev_no,
        design_ext_part_number: changeRequest.design_ext_part_number,
        design_ext_part_name: changeRequest.design_ext_part_name,
        design_ext_drawing_no_rev_no: changeRequest.design_ext_drawing_no_rev_no,
        design_ext_drawing_number: changeRequest.design_ext_drawing_number,
        design_ext_process_reference: changeRequest.design_ext_process_reference,
        process_int_customer: changeRequest.process_int_customer,
        process_int_model: changeRequest.process_int_model,
        process_int_part_number: changeRequest.process_int_part_number,
        process_int_part_number_rev_no: changeRequest.process_int_part_number_rev_no,
        process_int_part_name: changeRequest.process_int_part_name,
        process_int_drawing_no_rev_no: changeRequest.process_int_drawing_no_rev_no,
        process_int_drawing_number: changeRequest.process_int_drawing_number,
        process_int_process_reference: changeRequest.process_int_process_reference,
        process_ext_customer: changeRequest.process_ext_customer,
        process_ext_model: changeRequest.process_ext_model,
        process_ext_glass_reference: changeRequest.process_ext_glass_reference,
        process_ext_part_number_rev_no: changeRequest.process_ext_part_number_rev_no,
        process_ext_part_number: changeRequest.process_ext_part_number,
        process_ext_part_name: changeRequest.process_ext_part_name,
        process_ext_drawing_no_rev_no: changeRequest.process_ext_drawing_no_rev_no,
        process_ext_drawing_number: changeRequest.process_ext_drawing_number,
        process_ext_process_reference: changeRequest.process_ext_process_reference,
        des_change_required: changeRequest.des_change_required,
        other_comments: changeRequest.other_comments,
        receiveing_customer_date: changeRequest.receiveing_customer_date,
        customer_req_imple_date: changeRequest.customer_req_imple_date,
        feas_con_customer_date: changeRequest.feas_con_customer_date,
        remarks: changeRequest.remarks,
    });

    const handleRequestSave = async () => {
        try {
            if (
                !changeRequest.department ||
                !changeRequest.type_design_change ||
                !changeRequest.plant ||
                !changeRequest.change_category ||
                (changeRequest.change_category === "Design Change" && !changeRequest.design_change) ||
                (changeRequest.change_category === "Design Change" && changeRequest.design_change === "External" &&
                    (!changeRequest.design_ext_customer ||
                        !changeRequest.design_ext_model ||
                        !changeRequest.design_ext_glass_reference ||
                        !changeRequest.design_ext_part_number_rev_no ||
                        !changeRequest.design_ext_part_number ||
                        !changeRequest.design_ext_part_name ||
                        !changeRequest.design_ext_drawing_no_rev_no ||
                        !changeRequest.design_ext_drawing_number ||
                        !changeRequest.design_ext_process_reference)
                ) ||
                (changeRequest.change_category === "Process Change" && !changeRequest.process_change) ||
                (changeRequest.change_category === "Process Change" && changeRequest.process_change === "External" &&
                    (!changeRequest.process_ext_customer ||
                        !changeRequest.process_ext_model ||
                        !changeRequest.process_ext_glass_reference ||
                        !changeRequest.process_ext_part_number_rev_no ||
                        !changeRequest.process_ext_part_number ||
                        !changeRequest.process_ext_part_name ||
                        !changeRequest.process_ext_drawing_no_rev_no ||
                        !changeRequest.process_ext_drawing_number ||
                        !changeRequest.process_ext_process_reference)
                ) ||
                !changeRequest.des_change_required ||
                !changeRequest.receiveing_customer_date ||
                !changeRequest.customer_req_imple_date ||
                !changeRequest.feas_con_customer_date
            ) {
                setErrors(true);
                setStepError(true);
                setSaveMessage(false);
                return;
            } else {
                setErrors(false);
                setStepError(false);
                setSaveMessage(false);
            }
            //console.log(requestData, "requestData232");
            const response = await axios.post(API.INSERT_CHANGE_REQUEST, requestData, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
            if (response.status === 200) {
                //console.log("response", response);
                const requestID = response.data.identifiers[0].id;
                localStorage.setItem("requestID", requestID);
                localStorage.setItem("isChangeRequestSave", true);
                await cmsForm(requestID);
                //console.log(requestID, "requestID");
                setSave(true);
                setShowSuccessPopup(true);
            }
        } catch (error) {
            console.error("An error occurred in update trials:", error);
        }
    };

    const cmsForm = async (changeRequestId) => {
        const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
        //console.log(storedUserData, "storedUserData94");
        // const cmsID = localStorage.getItem("cmsID");
        // const userSGID = cmsID ? storedUserData.SGID : "";
        const userSGID = storedUserData.SGID;
        const userName = storedUserData.FName + " " + storedUserData.LName;
        //console.log(userName, "username96");
        const userMail = storedUserData.MailId;
        try {
            //console.log("check98");
            const cmsData = {
                user_id: userSGID,
                updated_at: new Date(),
                created_by: userName,
                doc_ref: "F/CFT/SGSI/01",
                // rev_no: "03",
                // rev_date: new Date(),
                change_request_id: changeRequestId,
                submission_status: changeRequestId ? "pending" : "",
                user_mail: userMail
            };
            const mocResponse = await axios.post(API.INSERT_CMS_FORM, cmsData,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                });
            //console.log("mocData110", cmsData);
            if (mocResponse.status === 200) {
                //console.log("Inserted into moc table:", mocResponse);
                const cmsID = mocResponse.data["identifiers"][0].id;
                //console.log("Inserted cmsID", cmsID);
                localStorage.setItem("cmsID", cmsID);
                localStorage.setItem("cmsData", JSON.stringify(cmsData));
                //console.log("cmsData297", cmsData);
                await insertCmsApprovals(cmsID);
            }
        } catch (error) {
            console.error("An error occurred in insertIntoMocTable:", error);
        }
    };

    const insertCmsApprovals = async () => {
        const updateMocDet = localStorage.getItem("cmsID");
        const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
        //console.log(storedUserData, "storedUserData79999");
        //console.log(updateMocDet, "updateMocDet");
        const approverSGID = storedUserData.N1SGID;
        const userSGID = updateMocDet ? storedUserData.SGID : "";
        const userAppName = updateMocDet ? storedUserData.FName + " " + storedUserData.LName : " ";
        try {
            const cmsData = {
                cms_id: updateMocDet,
                user_sgid: userSGID,
                user_approval_name: userAppName,
                status: "pending",
            };
            const mocResponse = await axios.post(API.INSERT_CMS_APPROVALS, cmsData,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                });
            if (mocResponse.status === 200) {
                //console.log("Inserted into moc table:", mocResponse);
                const cmsApprovalID = mocResponse.data["identifiers"][0].id;
                //console.log("Inserted cmsApprovalID", cmsApprovalID);
                localStorage.setItem("cmsApprovalID", cmsApprovalID);
            }
        } catch (error) {
            console.error("An error occurred in insertIntoMocTable:", error);
        }
    };

    const handleRequestUpdate = async () => {
        const storedCmsDataString = localStorage.getItem("cmsDataByID");
        const storedCmsData = JSON.parse(storedCmsDataString);
        const changeRequestID = storedCmsData?.cmsForm?.change_request_id;

        const updateData = [{
            date: changeRequest.date,
            request_initiator: userFName,
            department: changeRequest.department,
            type_design_change: changeRequest.type_design_change,
            plant: changeRequest.plant,
            change_category: changeRequest.change_category,
            design_change: changeRequest.design_change,
            process_change: changeRequest.process_change,
            design_int_customer: changeRequest.design_int_customer,
            design_int_model: changeRequest.design_int_model,
            design_int_glass_reference: changeRequest.design_int_glass_reference,
            design_int_part_number_rev_no: changeRequest.design_int_part_number_rev_no,
            design_int_part_number: changeRequest.design_int_part_number,
            design_int_part_name: changeRequest.design_int_part_name,
            design_int_drawing_no_rev_no: changeRequest.design_int_drawing_no_rev_no,
            design_int_drawing_number: changeRequest.design_int_drawing_number,
            design_int_process_reference: changeRequest.design_int_process_reference,
            design_ext_customer: changeRequest.design_ext_customer,
            design_ext_model: changeRequest.design_ext_model,
            design_ext_glass_reference: changeRequest.design_ext_glass_reference,
            design_ext_part_number_rev_no: changeRequest.design_ext_part_number_rev_no,
            design_ext_part_number: changeRequest.design_ext_part_number,
            design_ext_part_name: changeRequest.design_ext_part_name,
            design_ext_drawing_no_rev_no: changeRequest.design_ext_drawing_no_rev_no,
            design_ext_drawing_number: changeRequest.design_ext_drawing_number,
            design_ext_process_reference: changeRequest.design_ext_process_reference,
            process_int_customer: changeRequest.process_int_customer,
            process_int_model: changeRequest.process_int_model,
            process_int_glass_reference: changeRequest.process_int_glass_reference,
            process_int_part_number: changeRequest.process_int_part_number,
            process_int_part_number_rev_no: changeRequest.process_int_part_number_rev_no,
            process_int_part_name: changeRequest.process_int_part_name,
            process_int_drawing_no_rev_no: changeRequest.process_int_drawing_no_rev_no,
            process_int_drawing_number: changeRequest.process_int_drawing_number,
            process_int_process_reference: changeRequest.process_int_process_reference,
            process_ext_customer: changeRequest.process_ext_customer,
            process_ext_model: changeRequest.process_ext_model,
            process_ext_glass_reference: changeRequest.process_ext_glass_reference,
            process_ext_part_number_rev_no: changeRequest.process_ext_part_number_rev_no,
            process_ext_part_number: changeRequest.process_ext_part_number,
            process_ext_part_name: changeRequest.process_ext_part_name,
            process_ext_drawing_no_rev_no: changeRequest.process_ext_drawing_no_rev_no,
            process_ext_drawing_number: changeRequest.process_ext_drawing_number,
            process_ext_process_reference: changeRequest.process_ext_process_reference,
            des_change_required: changeRequest.des_change_required,
            other_comments: changeRequest.other_comments,
            receiveing_customer_date: changeRequest.receiveing_customer_date,
            customer_req_imple_date: changeRequest.customer_req_imple_date,
            feas_con_customer_date: changeRequest.feas_con_customer_date,
            remarks: changeRequest.remarks,
        }];
        try {
            if (
                !changeRequest.department ||
                !changeRequest.type_design_change ||
                !changeRequest.plant ||
                !changeRequest.change_category ||
                (changeRequest.change_category === "Design Change" && !changeRequest.design_change) ||
                (changeRequest.change_category === "Design Change" && changeRequest.design_change === "External" &&
                    (!changeRequest.design_ext_customer ||
                        !changeRequest.design_ext_model ||
                        !changeRequest.design_ext_glass_reference ||
                        !changeRequest.design_ext_part_number_rev_no ||
                        !changeRequest.design_ext_part_number ||
                        !changeRequest.design_ext_part_name ||
                        !changeRequest.design_ext_drawing_no_rev_no ||
                        !changeRequest.design_ext_drawing_number ||
                        !changeRequest.design_ext_process_reference)
                ) ||
                (changeRequest.change_category === "Process Change" && !changeRequest.process_change) ||
                (changeRequest.change_category === "Process Change" && changeRequest.process_change === "External" &&
                    (!changeRequest.process_ext_customer ||
                        !changeRequest.process_ext_model ||
                        !changeRequest.process_ext_glass_reference ||
                        !changeRequest.process_ext_part_number_rev_no ||
                        !changeRequest.process_ext_part_number ||
                        !changeRequest.process_ext_part_name ||
                        !changeRequest.process_ext_drawing_no_rev_no ||
                        !changeRequest.process_ext_drawing_number ||
                        !changeRequest.process_ext_process_reference)
                ) ||
                !changeRequest.des_change_required ||
                !changeRequest.receiveing_customer_date ||
                !changeRequest.customer_req_imple_date ||
                !changeRequest.feas_con_customer_date
            ) {
                setErrors(true);
                setStepError(true);
                setSaveMessage(false);
                return;
            } else {
                setErrors(false);
                setStepError(false);
                setSaveMessage(false);
            }
            const response = await axios.put(
                `${API.UPDATE_CHANGE_REQUEST}?id=${changeRequestID}`,
                updateData,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                }
            );
            if (response.status === 200) {
                //console.log("update response", response);
                setSave(true);
                setShowUpdatePopup(true);
                // setHasUnsavedChanges(false);
            }
        } catch (error) {
            console.error("An error occurred in update trials:", error);
        }
    };

    const handleRequestNext = () => {
        // if (isEditCms && hasUnsavedChanges) {
        //     setSaveMessage("Please save before going to the next step.");
        //     return;
        // }
        try {
            const isChangeRequestSave = JSON.parse(localStorage.getItem("isChangeRequestSave"));
            if (
                !changeRequest.department ||
                !changeRequest.type_design_change ||
                !changeRequest.plant ||
                !changeRequest.change_category ||
                !changeRequest.des_change_required ||
                !changeRequest.receiveing_customer_date ||
                !changeRequest.customer_req_imple_date ||
                !changeRequest.feas_con_customer_date
            ) {
                setErrors(true);
                setStepError(true);
                setSaveMessage(false);
                return;
            } else {
                setErrors(false);
                setStepError(false);
                setSaveMessage(false);
            }
            if (save || isChangeRequestSave || isEditCms) {
                // navigate("/ChangeRequired");
                dataProps(newDialogRequestsOpen);
            } else {
                setSaveMessage("Failed to save. Please try again.");
            }
        } catch (error) {
            console.error("An error occurred in update trials:", error);
        }
    };

    useEffect(() => {
        if (isEditCms) {
            const getRequestForm = async () => {
                const storedCmsDataString = localStorage.getItem("cmsDataByID");
                const storedCmsData = JSON.parse(storedCmsDataString);
                //console.log(storedCmsData, "storedMocData319")
                const changeRequestID = storedCmsData?.cmsForm?.change_request_id;
                //console.log(changeRequestID, "requestFormID322")
                try {
                    const response = await axios.get(
                        `${API.GET_CMS_REQUEST_FORM}?id=${changeRequestID}`,
                        {
                            headers: {
                                Authorization: `Bearer ${access_token}`
                            }
                        }
                    );
                    const requestFormDetails = await response.data.change_request[0];
                    setChangeRequest(requestFormDetails);
                    //console.log(response, "response329")
                    //console.log(requestFormDetails, "requestFormDetails330")
                } catch (error) {
                    console.error("Error fetching MOC details:", error);
                }
            };
            getRequestForm();
        }
    }, [setChangeRequest, isEditCms]);

    const loadCmsVisits = async () => {
        try {
            const takenCmsID = localStorage.getItem("takeCmsID");
            const response = await axios.get(`${API.GET_ALL_CMS_FOR_APPROVALS}?id=${takenCmsID}`,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                });
            if (response.status === 200) {
                if (response.data && response.data.length > 0) {
                    //console.log(response.data, "response.dataresponse.data");
                    setCmsData(response.data);
                    const loadCmsData = response.data;
                    localStorage.setItem("loadCmsData", JSON.stringify(loadCmsData));
                }
            }
        } catch (error) {
            //console.log("ERROR", error);
        }
    };

    useEffect(() => {
        loadCmsVisits();
    }, []);

    const cmsDetData = JSON.parse(localStorage.getItem("cmsDataByID"));
    //console.log(cmsDetData, "cmsDetData315")
    const takeCmsID = cmsDetData?.cms_id;
    //console.log(takeCmsID, "takeCmsID359");
    localStorage.setItem("takeCmsID", takeCmsID);
    const userRequisition = cmsDetData?.cmsForm?.created_by;
    const userSGID = storedUserData.SGID;
    let disableCondition;
    if (isEditCms) {
        disableCondition = userSGID !== cmsDetData?.cmsForm?.user_id;
    }

    return (
        <>
            <DialogTitle>Change Request Form</DialogTitle>
            <DialogContent>
                {/* <Header pageTitle="Change Request Form" isNewUser={false} /> */}
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Typography
                        style={{
                            fontWeight: 600,
                            fontSize: 16,
                            marginTop: "10px",
                            fontStyle: "normal",
                            fontFamily: "Roboto, sans-serif",
                            color: "#B51849",
                            gap: "16px",
                        }}
                    >
                        DETAILS
                    </Typography>
                    <div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography
                                style={{
                                    fontWeight: 600,
                                    fontStyle: "normal",
                                    fontFamily: "Roboto, sans-serif",
                                    color: "#000",
                                    fontSize: 14,
                                    gap: 8,
                                }}
                            >
                                Doc Reference:
                            </Typography>
                            <Typography
                                style={{
                                    fontWeight: 500,
                                    fontStyle: "normal",
                                    fontFamily: "Roboto, sans-serif",
                                    color: "#7F7F7F",
                                }}
                            >
                                F/CFT/SGSI/01
                            </Typography>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography
                                style={{
                                    fontWeight: 600,
                                    fontStyle: "normal",
                                    fontFamily: "Roboto, sans-serif",
                                    color: "#000",
                                    fontSize: 14,
                                    gap: "8px",
                                }}
                            >
                                Revision Number:
                            </Typography>
                            <Typography
                                style={{
                                    fontWeight: 500,
                                    fontStyle: "normal",
                                    fontFamily: "Roboto, sans-serif",
                                    color: "#7F7F7F",
                                }}
                            >
                                03
                            </Typography>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography
                                style={{
                                    fontWeight: 600,
                                    fontStyle: "normal",
                                    fontFamily: "Roboto, sans-serif",
                                    color: "#000",
                                    fontSize: 14,
                                    gap: "8px",
                                }}
                            >
                                Revision Date:
                            </Typography>
                            <Typography
                                style={{
                                    fontWeight: 500,
                                    fontStyle: "normal",
                                    fontFamily: "Roboto, sans-serif",
                                    color: "#7F7F7F",
                                }}
                            >
                                23-03-2024
                            </Typography>
                        </div>
                        {isEditCms ? (

                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    style={{
                                        fontWeight: 600,
                                        fontStyle: "normal",
                                        fontFamily: "Roboto, sans-serif",
                                        color: "#000",
                                        fontSize: 14,
                                        gap: "8px",
                                    }}
                                >
                                    CMS No:
                                </Typography>
                                <Typography
                                    style={{
                                        fontWeight: 500,
                                        fontStyle: "normal",
                                        fontFamily: "Roboto, sans-serif",
                                        color: "#7F7F7F",
                                    }}
                                >
                                    {cmsDetData?.cmsForm?.cms_no}
                                </Typography>
                            </div>
                        ) : ("")}
                    </div>
                    <Divider />
                </div>
                <div style={{ marginTop: "10px" }}>
                    <InputLabel
                        style={{
                            fontWeight: 600,
                            fontStyle: "normal",
                            fontFamily: "Roboto, sans-serif",
                            color: "#000",
                            fontSize: 14,
                            alignSelf: "stretch",
                            lineHeight: 2,
                        }}
                    >
                        Date *
                    </InputLabel>
                    <TextField
                        style={{ width: "100%", background: "#FFFFFF", borderRadius: 4 }}
                        id="date"
                        type="date"
                        value={new Date(changeRequest.date).toLocaleDateString("en-CA")}
                        onChange={(e) => handleDateChange(e, "date")}
                        variant="outlined"
                        InputLabelProps={{ style: { fontWeight: 500, fontStyle: "normal" } }}
                        inputProps={{ max: new Date().toISOString().split("T")[0] }}
                        disabled={true}
                    />
                </div>
                <div style={{ marginTop: "10px" }}>
                    <InputLabel
                        style={{
                            fontWeight: 600,
                            fontStyle: "normal",
                            fontFamily: "Roboto, sans-serif",
                            color: "#000",
                            fontSize: 14,
                            marginTop: 4,
                        }}
                    >
                        Request Initiator *
                    </InputLabel>
                    <TextField
                        style={{
                            width: "100%",
                            marginTop: 10,
                            background: "#FFFFFF",
                            borderRadius: 8,
                        }}
                        id="name"
                        variant="outlined"
                        value={isEditCms ? userRequisition : userFName}
                        disabled={true}
                    />
                </div>
                <div style={{ marginTop: "10px" }}>
                    <InputLabel
                        style={{
                            fontWeight: 600,
                            fontStyle: "normal",
                            fontFamily: "Roboto, sans-serif",
                            color: "#000",
                            fontSize: 14,
                        }}
                    >
                        Department *
                    </InputLabel>
                    <TextField
                        style={{ width: "100%", marginTop: 10, textAlign: "left" }}
                        value={changeRequest.department}
                        onChange={(e) => onHandleChange(e, "department")}
                        id="outlined-select-currency"
                        select
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={stepError && !changeRequest.department ? true : false}
                        disabled={disableCondition}
                    >
                        {departmentData.map((type) => {
                            return (
                                <MenuItem key={type.id} value={type.master_data}>
                                    {type.master_data}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                </div>
                <div style={{ marginTop: "10px" }}>
                    <InputLabel
                        style={{
                            fontWeight: 600,
                            fontStyle: "normal",
                            fontFamily: "Roboto, sans-serif",
                            color: "#000",
                            fontSize: 14,
                        }}
                    >
                        Type of Design Change *
                    </InputLabel>
                    <TextField
                        style={{ width: "100%", marginTop: 10, textAlign: "left" }}
                        value={changeRequest.type_design_change}
                        onChange={(e) => onHandleChange(e, "type_design_change")}
                        id="outlined-select-currency"
                        select
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={stepError && !changeRequest.type_design_change ? true : false}
                        disabled={disableCondition}
                    >
                        {designChangeData.map((type) => {
                            return (
                                <MenuItem key={type.id} value={type.master_data}>
                                    {type.master_data}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                </div>

                <div style={{ marginTop: "10px" }}>
                    <InputLabel
                        style={{
                            fontWeight: 600,
                            fontStyle: "normal",
                            fontFamily: "Roboto, sans-serif",
                            color: "#000",
                            fontSize: 14,
                        }}
                    >
                        Plant *
                    </InputLabel>
                    <TextField
                        style={{ width: "100%", marginTop: 10, textAlign: "left" }}
                        value={changeRequest.plant}
                        onChange={(e) => onHandleChange(e, "plant")}
                        id="outlined-select-currency"
                        select
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={stepError && !changeRequest.plant ? true : false}
                        disabled={disableCondition}
                    >
                        {plantData.map((type) => {
                            return (
                                <MenuItem key={type.id} value={type.master_data}>
                                    {type.master_data}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                </div>

                <div style={{ marginTop: "10px" }}>
                    <InputLabel
                        style={{
                            fontWeight: 600,
                            fontStyle: "normal",
                            fontFamily: "Roboto, sans-serif",
                            color: "#000",
                            fontSize: 14,
                        }}
                    >
                        Change Category *
                    </InputLabel>
                    <TextField
                        style={{ width: "100%", marginTop: 10, textAlign: "left" }}
                        value={changeRequest.change_category}
                        onChange={(e) => onHandleChange(e, "change_category")}
                        id="outlined-select-currency"
                        select
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={stepError && !changeRequest.change_category ? true : false}
                        disabled={disableCondition}
                    >
                        {changeCategoryData.map((type) => {
                            return (
                                <MenuItem key={type.id} value={type.master_data}>
                                    {type.master_data}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                </div>

                {changeRequest.change_category === "Design Change" ? (
                    <div style={{ marginTop: "10px" }}>
                        <InputLabel
                            style={{
                                fontWeight: 600,
                                fontStyle: "normal",
                                fontFamily: "Roboto, sans-serif",
                                color: "#000",
                                fontSize: 14,
                            }}
                        >
                            Design Change *
                        </InputLabel>
                        <TextField
                            style={{ width: "100%", marginTop: 10, textAlign: "left" }}
                            value={changeRequest.design_change}
                            onChange={(e) => onHandleChange(e, "design_change")}
                            id="outlined-select-currency"
                            select
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={stepError && !changeRequest.design_change ? true : false}
                            disabled={disableCondition}
                        >
                            {changeCategoryTypeData.map((type) => (
                                <MenuItem key={type.id} value={type.master_data}>
                                    {type.master_data}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                ) : null}

                {changeRequest.change_category === "Process Change" ? (
                    <div style={{ marginTop: "10px" }}>
                        <InputLabel
                            style={{
                                fontWeight: 600,
                                fontStyle: "normal",
                                fontFamily: "Roboto, sans-serif",
                                color: "#000",
                                fontSize: 14,
                            }}
                        >
                            Process Change *
                        </InputLabel>
                        <TextField
                            style={{ width: "100%", marginTop: 10, textAlign: "left" }}
                            value={changeRequest.process_change}
                            onChange={(e) => onHandleChange(e, "process_change")}
                            id="outlined-select-currency"
                            select
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={stepError && !changeRequest.process_change ? true : false}
                            disabled={disableCondition}
                        >
                            {changeCategoryTypeData.map((type) => (
                                <MenuItem key={type.id} value={type.master_data}>
                                    {type.master_data}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                ) : null}

                {changeRequest.change_category === "Design Change" && changeRequest.design_change === "Internal" ? (
                    <>
                        <div style={{ marginTop: "10px" }}>
                            <InputLabel
                                style={{
                                    fontWeight: 600,
                                    fontStyle: "normal",
                                    fontFamily: "Roboto, sans-serif",
                                    color: "#000",
                                    fontSize: 14,
                                }}
                            >
                                Customer
                            </InputLabel>
                            <TextField
                                style={{
                                    width: "100%",
                                    marginTop: 10,
                                    background: "#FFFFFF",
                                    borderRadius: 4,
                                }}
                                id="outlined-multiline-static"
                                value={changeRequest.design_int_customer}
                                onChange={(e) => onHandleChange(e, "design_int_customer")}
                                disabled={disableCondition}
                                autoComplete="off"
                            />
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <InputLabel
                                style={{
                                    fontWeight: 600,
                                    fontStyle: "normal",
                                    fontFamily: "Roboto, sans-serif",
                                    color: "#000",
                                    fontSize: 14,
                                }}
                            >
                                Model
                            </InputLabel>
                            <TextField
                                style={{
                                    width: "100%",
                                    marginTop: 10,
                                    background: "#FFFFFF",
                                    borderRadius: 4,
                                }}
                                id="outlined-multiline-static"
                                value={changeRequest.design_int_model}
                                onChange={(e) => onHandleChange(e, "design_int_model")}
                                disabled={disableCondition}
                                autoComplete="off"
                            />
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <InputLabel
                                style={{
                                    fontWeight: 600,
                                    fontStyle: "normal",
                                    fontFamily: "Roboto, sans-serif",
                                    color: "#000",
                                    fontSize: 14,
                                }}
                            >
                                Glass Reference
                            </InputLabel>
                            <TextField
                                style={{
                                    width: "100%",
                                    marginTop: 10,
                                    background: "#FFFFFF",
                                    borderRadius: 4,
                                }}
                                id="outlined-multiline-static"
                                value={changeRequest.design_int_glass_reference}
                                onChange={(e) => onHandleChange(e, "design_int_glass_reference")}
                                disabled={disableCondition}
                                autoComplete="off"
                            >
                            </TextField>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "10px",
                            }}
                        >
                            <div style={{ flex: "0 0 48%" }}>
                                <InputLabel
                                    style={{
                                        fontWeight: 600,
                                        fontStyle: "normal",
                                        fontFamily: "Roboto, sans-serif",
                                        color: "#000",
                                        fontSize: 14,
                                    }}
                                >
                                    Part Number
                                </InputLabel>
                                <TextField
                                    style={{
                                        width: "100%",
                                        marginTop: 10,
                                        background: "#FFFFFF",
                                        borderRadius: 4,
                                    }}
                                    id="outlined-multiline-static"
                                    value={changeRequest.design_int_part_number}
                                    onChange={(e) => onHandleChange(e, "design_int_part_number")}
                                    disabled={disableCondition}
                                    autoComplete="off"
                                />
                            </div>
                            <div style={{ flex: "0 0 48%" }}>
                                <InputLabel
                                    style={{
                                        fontWeight: 600,
                                        fontStyle: "normal",
                                        fontFamily: "Roboto, sans-serif",
                                        color: "#000",
                                        fontSize: 14,
                                    }}
                                >
                                    Rev No
                                </InputLabel>
                                <TextField
                                    style={{
                                        width: "100%",
                                        marginTop: 10,
                                        background: "#FFFFFF",
                                        borderRadius: 4,
                                    }}
                                    value={changeRequest.design_int_part_number_rev_no}
                                    onChange={(e) => onHandleChange(e, "design_int_part_number_rev_no")}
                                    id="outlined-select-currency"
                                    disabled={disableCondition}
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <InputLabel
                                style={{
                                    fontWeight: 600,
                                    fontStyle: "normal",
                                    fontFamily: "Roboto, sans-serif",
                                    color: "#000",
                                    fontSize: 14,
                                }}
                            >
                                Part Name
                            </InputLabel>
                            <TextField
                                style={{
                                    width: "100%",
                                    marginTop: 10,
                                    background: "#FFFFFF",
                                    borderRadius: 4,
                                }}
                                id="outlined-multiline-static"
                                value={changeRequest.design_int_part_name}
                                onChange={(e) => onHandleChange(e, "design_int_part_name")}
                                disabled={disableCondition}
                                autoComplete="off"
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "10px",
                            }}
                        >
                            <div style={{ flex: "0 0 48%" }}>
                                <InputLabel
                                    style={{
                                        fontWeight: 600,
                                        fontStyle: "normal",
                                        fontFamily: "Roboto, sans-serif",
                                        color: "#000",
                                        fontSize: 14,
                                    }}
                                >
                                    Drawing Number
                                </InputLabel>
                                <TextField
                                    style={{
                                        width: "100%",
                                        marginTop: 10,
                                        background: "#FFFFFF",
                                        borderRadius: 4,
                                    }}
                                    id="outlined-multiline-static"
                                    value={changeRequest.design_int_drawing_number}
                                    onChange={(e) => onHandleChange(e, "design_int_drawing_number")}
                                    disabled={disableCondition}
                                    autoComplete="off"
                                />
                            </div>
                            <div style={{ flex: "0 0 48%" }}>
                                <InputLabel
                                    style={{
                                        fontWeight: 600,
                                        fontStyle: "normal",
                                        fontFamily: "Roboto, sans-serif",
                                        color: "#000",
                                        fontSize: 14,
                                    }}
                                >
                                    Rev No
                                </InputLabel>
                                <TextField
                                    style={{
                                        width: "100%",
                                        marginTop: 10,
                                        background: "#FFFFFF",
                                        borderRadius: 4,
                                    }}
                                    value={changeRequest.design_int_drawing_no_rev_no}
                                    onChange={(e) => onHandleChange(e, "design_int_drawing_no_rev_no")}
                                    id="outlined-select-currency"
                                    disabled={disableCondition}
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <InputLabel
                                style={{
                                    fontWeight: 600,
                                    fontStyle: "normal",
                                    fontFamily: "Roboto, sans-serif",
                                    color: "#000",
                                    fontSize: 14,
                                }}
                            >
                                Process Reference
                            </InputLabel>
                            <TextField
                                style={{
                                    width: "100%",
                                    marginTop: 10,
                                    background: "#FFFFFF",
                                    borderRadius: 4,
                                }}
                                id="outlined-multiline-static"
                                value={changeRequest.design_int_process_reference}
                                onChange={(e) => onHandleChange(e, "design_int_process_reference")}
                                disabled={disableCondition}
                                autoComplete="off"
                            />
                        </div>
                    </>) : null
                }

                {changeRequest.change_category === "Design Change" && changeRequest.design_change === "External" ? (
                    <>
                        <div style={{ marginTop: "10px" }}>
                            <InputLabel
                                style={{
                                    fontWeight: 600,
                                    fontStyle: "normal",
                                    fontFamily: "Roboto, sans-serif",
                                    color: "#000",
                                    fontSize: 14,
                                }}
                            >
                                Customer *
                            </InputLabel>
                            <TextField
                                style={{ width: "100%", marginTop: 10, textAlign: "left" }}
                                value={changeRequest.design_ext_customer}
                                onChange={(e) => onHandleChange(e, "design_ext_customer")}
                                id="outlined-select-currency"
                                select
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={stepError && !changeRequest.design_ext_customer ? true : false}
                                disabled={disableCondition}
                            >
                                {/* {externalCustomer.map((type) => (
                                    <MenuItem key={type.value} value={type.value}>
                                        {type.label}
                                    </MenuItem>
                                ))} */}
                                {customerData.map((type) => {
                                    //console.log('type12', type);
                                    return (
                                        <MenuItem key={type.id} value={type.master_data}>
                                            {type.master_data}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <InputLabel
                                style={{
                                    fontWeight: 600,
                                    fontStyle: "normal",
                                    fontFamily: "Roboto, sans-serif",
                                    color: "#000",
                                    fontSize: 14,
                                }}
                            >
                                Model *
                            </InputLabel>
                            <TextField
                                style={{ width: "100%", marginTop: 10, textAlign: "left" }}
                                value={changeRequest.design_ext_model}
                                onChange={(e) => onHandleChange(e, "design_ext_model")}
                                id="outlined-select-currency"
                                select
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={stepError && !changeRequest.design_ext_model ? true : false}
                                disabled={disableCondition}
                            >
                                {modelData.map((type) => {
                                    return (
                                        <MenuItem key={type.id} value={type.master_data}>
                                            {type.master_data}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <InputLabel
                                style={{
                                    fontWeight: 600,
                                    fontStyle: "normal",
                                    fontFamily: "Roboto, sans-serif",
                                    color: "#000",
                                    fontSize: 14,
                                }}
                            >
                                Glass Reference *
                            </InputLabel>
                            <TextField
                                style={{ width: "100%", marginTop: 10, textAlign: "left" }}
                                value={changeRequest.design_ext_glass_reference}
                                onChange={(e) => onHandleChange(e, "design_ext_glass_reference")}
                                id="outlined-select-currency"
                                select
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={stepError && !changeRequest.design_ext_glass_reference ? true : false}
                                disabled={disableCondition}
                            >
                                {glassReferenceData.map((type) => {
                                    return (
                                        <MenuItem key={type.id} value={type.master_data}>
                                            {type.master_data}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "10px",
                            }}
                        >
                            <div style={{ flex: "0 0 48%" }}>
                                <InputLabel
                                    style={{
                                        fontWeight: 600,
                                        fontStyle: "normal",
                                        fontFamily: "Roboto, sans-serif",
                                        color: "#000",
                                        fontSize: 14,
                                    }}
                                >
                                    Part Number *
                                </InputLabel>
                                <TextField
                                    style={{
                                        width: "100%",
                                        marginTop: 10,
                                        background: "#FFFFFF",
                                        borderRadius: 4,
                                    }}
                                    id="outlined-multiline-static"
                                    value={changeRequest.design_ext_part_number}
                                    onChange={(e) => onHandleChange(e, "design_ext_part_number")}
                                    error={stepError && !changeRequest.design_ext_part_number ? true : false}
                                    disabled={disableCondition}
                                    autoComplete="off"
                                />
                            </div>
                            <div style={{ flex: "0 0 48%" }}>
                                <InputLabel
                                    style={{
                                        fontWeight: 600,
                                        fontStyle: "normal",
                                        fontFamily: "Roboto, sans-serif",
                                        color: "#000",
                                        fontSize: 14,
                                    }}
                                >
                                    Rev No *
                                </InputLabel>
                                <TextField
                                    style={{
                                        width: "100%",
                                        marginTop: 10,
                                        background: "#FFFFFF",
                                        borderRadius: 4,
                                    }}
                                    value={changeRequest.design_ext_part_number_rev_no}
                                    onChange={(e) => onHandleChange(e, "design_ext_part_number_rev_no")}
                                    id="outlined-select-currency"
                                    error={stepError && !changeRequest.design_ext_part_number_rev_no ? true : false}
                                    disabled={disableCondition}
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <InputLabel
                                style={{
                                    fontWeight: 600,
                                    fontStyle: "normal",
                                    fontFamily: "Roboto, sans-serif",
                                    color: "#000",
                                    fontSize: 14,
                                }}
                            >
                                Part Name *
                            </InputLabel>
                            <TextField
                                style={{
                                    width: "100%",
                                    marginTop: 10,
                                    background: "#FFFFFF",
                                    borderRadius: 4,
                                }}
                                id="outlined-multiline-static"
                                value={changeRequest.design_ext_part_name}
                                onChange={(e) => onHandleChange(e, "design_ext_part_name")}
                                error={stepError && !changeRequest.design_ext_part_name ? true : false}
                                disabled={disableCondition}
                                autoComplete="off"
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "10px",
                            }}
                        >
                            <div style={{ flex: "0 0 48%" }}>
                                <InputLabel
                                    style={{
                                        fontWeight: 600,
                                        fontStyle: "normal",
                                        fontFamily: "Roboto, sans-serif",
                                        color: "#000",
                                        fontSize: 14,
                                    }}
                                >
                                    Drawing Number *
                                </InputLabel>
                                <TextField
                                    style={{
                                        width: "100%",
                                        marginTop: 10,
                                        background: "#FFFFFF",
                                        borderRadius: 4,
                                    }}
                                    id="outlined-multiline-static"
                                    value={changeRequest.design_ext_drawing_number}
                                    onChange={(e) => onHandleChange(e, "design_ext_drawing_number")}
                                    error={stepError && !changeRequest.design_ext_drawing_number ? true : false}
                                    disabled={disableCondition}
                                    autoComplete="off"
                                />
                            </div>
                            <div style={{ flex: "0 0 48%" }}>
                                <InputLabel
                                    style={{
                                        fontWeight: 600,
                                        fontStyle: "normal",
                                        fontFamily: "Roboto, sans-serif",
                                        color: "#000",
                                        fontSize: 14,
                                    }}
                                >
                                    Rev No *
                                </InputLabel>
                                <TextField
                                    style={{
                                        width: "100%",
                                        marginTop: 10,
                                        background: "#FFFFFF",
                                        borderRadius: 4,
                                    }}
                                    value={changeRequest.design_ext_drawing_no_rev_no}
                                    onChange={(e) => onHandleChange(e, "design_ext_drawing_no_rev_no")}
                                    id="outlined-select-currency"
                                    error={stepError && !changeRequest.design_ext_drawing_no_rev_no ? true : false}
                                    disabled={disableCondition}
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <InputLabel
                                style={{
                                    fontWeight: 600,
                                    fontStyle: "normal",
                                    fontFamily: "Roboto, sans-serif",
                                    color: "#000",
                                    fontSize: 14,
                                }}
                            >
                                Process Reference *
                            </InputLabel>
                            <TextField
                                style={{
                                    width: "100%",
                                    marginTop: 10,
                                    background: "#FFFFFF",
                                    borderRadius: 4,
                                }}
                                id="outlined-multiline-static"
                                value={changeRequest.design_ext_process_reference}
                                onChange={(e) => onHandleChange(e, "design_ext_process_reference")}
                                error={stepError && !changeRequest.design_ext_process_reference ? true : false}
                                disabled={disableCondition}
                                autoComplete="off"
                            />
                        </div>
                    </>) : null
                }

                {changeRequest.change_category === "Process Change" && changeRequest.process_change === "Internal" ? (
                    <>
                        <div style={{ marginTop: "10px" }}>
                            <InputLabel
                                style={{
                                    fontWeight: 600,
                                    fontStyle: "normal",
                                    fontFamily: "Roboto, sans-serif",
                                    color: "#000",
                                    fontSize: 14,
                                }}
                            >
                                Customer
                            </InputLabel>
                            <TextField
                                style={{
                                    width: "100%",
                                    marginTop: 10,
                                    background: "#FFFFFF",
                                    borderRadius: 4,
                                }}
                                id="outlined-multiline-static"
                                value={changeRequest.process_int_customer}
                                onChange={(e) => onHandleChange(e, "process_int_customer")}
                                disabled={disableCondition}
                                autoComplete="off"
                            />
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <InputLabel
                                style={{
                                    fontWeight: 600,
                                    fontStyle: "normal",
                                    fontFamily: "Roboto, sans-serif",
                                    color: "#000",
                                    fontSize: 14,
                                }}
                            >
                                Model
                            </InputLabel>
                            <TextField
                                style={{
                                    width: "100%",
                                    marginTop: 10,
                                    background: "#FFFFFF",
                                    borderRadius: 4,
                                }}
                                id="outlined-multiline-static"
                                value={changeRequest.process_int_model}
                                onChange={(e) => onHandleChange(e, "process_int_model")}
                                disabled={disableCondition}
                                autoComplete="off"
                            />
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <InputLabel
                                style={{
                                    fontWeight: 600,
                                    fontStyle: "normal",
                                    fontFamily: "Roboto, sans-serif",
                                    color: "#000",
                                    fontSize: 14,
                                }}
                            >
                                Glass Reference
                            </InputLabel>
                            <TextField
                                style={{
                                    width: "100%",
                                    marginTop: 10,
                                    background: "#FFFFFF",
                                    borderRadius: 4,
                                }}
                                id="outlined-multiline-static"
                                value={changeRequest.process_int_glass_reference}
                                onChange={(e) => onHandleChange(e, "process_int_glass_reference")}
                                disabled={disableCondition}
                                autoComplete="off"
                            >
                            </TextField>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "10px",
                            }}
                        >
                            <div style={{ flex: "0 0 48%" }}>
                                <InputLabel
                                    style={{
                                        fontWeight: 600,
                                        fontStyle: "normal",
                                        fontFamily: "Roboto, sans-serif",
                                        color: "#000",
                                        fontSize: 14,
                                    }}
                                >
                                    Part Number
                                </InputLabel>
                                <TextField
                                    style={{
                                        width: "100%",
                                        marginTop: 10,
                                        background: "#FFFFFF",
                                        borderRadius: 4,
                                    }}
                                    id="outlined-multiline-static"
                                    value={changeRequest.process_int_part_number}
                                    onChange={(e) => onHandleChange(e, "process_int_part_number")}
                                    disabled={disableCondition}
                                    autoComplete="off"
                                />
                            </div>
                            <div style={{ flex: "0 0 48%" }}>
                                <InputLabel
                                    style={{
                                        fontWeight: 600,
                                        fontStyle: "normal",
                                        fontFamily: "Roboto, sans-serif",
                                        color: "#000",
                                        fontSize: 14,
                                    }}
                                >
                                    Rev No
                                </InputLabel>
                                <TextField
                                    style={{
                                        width: "100%",
                                        marginTop: 10,
                                        background: "#FFFFFF",
                                        borderRadius: 4,
                                    }}
                                    value={changeRequest.process_int_part_number_rev_no}
                                    onChange={(e) => onHandleChange(e, "process_int_part_number_rev_no")}
                                    id="outlined-select-currency"
                                    disabled={disableCondition}
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <InputLabel
                                style={{
                                    fontWeight: 600,
                                    fontStyle: "normal",
                                    fontFamily: "Roboto, sans-serif",
                                    color: "#000",
                                    fontSize: 14,
                                }}
                            >
                                Part Name
                            </InputLabel>
                            <TextField
                                style={{
                                    width: "100%",
                                    marginTop: 10,
                                    background: "#FFFFFF",
                                    borderRadius: 4,
                                }}
                                id="outlined-multiline-static"
                                value={changeRequest.process_int_part_name}
                                onChange={(e) => onHandleChange(e, "process_int_part_name")}
                                disabled={disableCondition}
                                autoComplete="off"
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "10px",
                            }}
                        >
                            <div style={{ flex: "0 0 48%" }}>
                                <InputLabel
                                    style={{
                                        fontWeight: 600,
                                        fontStyle: "normal",
                                        fontFamily: "Roboto, sans-serif",
                                        color: "#000",
                                        fontSize: 14,
                                    }}
                                >
                                    Drawing Number
                                </InputLabel>
                                <TextField
                                    style={{
                                        width: "100%",
                                        marginTop: 10,
                                        background: "#FFFFFF",
                                        borderRadius: 4,
                                    }}
                                    id="outlined-multiline-static"
                                    value={changeRequest.process_int_drawing_number}
                                    onChange={(e) => onHandleChange(e, "process_int_drawing_number")}
                                    disabled={disableCondition}
                                    autoComplete="off"
                                />
                            </div>
                            <div style={{ flex: "0 0 48%" }}>
                                <InputLabel
                                    style={{
                                        fontWeight: 600,
                                        fontStyle: "normal",
                                        fontFamily: "Roboto, sans-serif",
                                        color: "#000",
                                        fontSize: 14,
                                    }}
                                >
                                    Rev No
                                </InputLabel>
                                <TextField
                                    style={{
                                        width: "100%",
                                        marginTop: 10,
                                        background: "#FFFFFF",
                                        borderRadius: 4,
                                    }}
                                    value={changeRequest.process_int_drawing_no_rev_no}
                                    onChange={(e) => onHandleChange(e, "process_int_drawing_no_rev_no")}
                                    id="outlined-select-currency"
                                    disabled={disableCondition}
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <InputLabel
                                style={{
                                    fontWeight: 600,
                                    fontStyle: "normal",
                                    fontFamily: "Roboto, sans-serif",
                                    color: "#000",
                                    fontSize: 14,
                                }}
                            >
                                Process Reference
                            </InputLabel>
                            <TextField
                                style={{
                                    width: "100%",
                                    marginTop: 10,
                                    background: "#FFFFFF",
                                    borderRadius: 4,
                                }}
                                id="outlined-multiline-static"
                                value={changeRequest.process_int_process_reference}
                                onChange={(e) => onHandleChange(e, "process_int_process_reference")}
                                disabled={disableCondition}
                                autoComplete="off"
                            />
                        </div>
                    </>) : null
                }

                {changeRequest.change_category === "Process Change" && changeRequest.process_change === "External" ? (
                    <>
                        <div style={{ marginTop: "10px" }}>
                            <InputLabel
                                style={{
                                    fontWeight: 600,
                                    fontStyle: "normal",
                                    fontFamily: "Roboto, sans-serif",
                                    color: "#000",
                                    fontSize: 14,
                                }}
                            >
                                Customer *
                            </InputLabel>
                            <TextField
                                style={{ width: "100%", marginTop: 10, textAlign: "left" }}
                                value={changeRequest.process_ext_customer}
                                onChange={(e) => onHandleChange(e, "process_ext_customer")}
                                id="outlined-select-currency"
                                select
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={stepError && !changeRequest.process_ext_customer ? true : false}
                                disabled={disableCondition}
                            >
                                {customerData.map((type) => {
                                    return (
                                        <MenuItem key={type.id} value={type.master_data}>
                                            {type.master_data}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <InputLabel
                                style={{
                                    fontWeight: 600,
                                    fontStyle: "normal",
                                    fontFamily: "Roboto, sans-serif",
                                    color: "#000",
                                    fontSize: 14,
                                }}
                            >
                                Model *
                            </InputLabel>
                            <TextField
                                style={{ width: "100%", marginTop: 10, textAlign: "left" }}
                                value={changeRequest.process_ext_model}
                                onChange={(e) => onHandleChange(e, "process_ext_model")}
                                id="outlined-select-currency"
                                select
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={stepError && !changeRequest.process_ext_model ? true : false}
                                disabled={disableCondition}
                            >
                                {modelData.map((type) => {
                                    return (
                                        <MenuItem key={type.id} value={type.master_data}>
                                            {type.master_data}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <InputLabel
                                style={{
                                    fontWeight: 600,
                                    fontStyle: "normal",
                                    fontFamily: "Roboto, sans-serif",
                                    color: "#000",
                                    fontSize: 14,
                                }}
                            >
                                Glass Reference *
                            </InputLabel>
                            <TextField
                                style={{ width: "100%", marginTop: 10, textAlign: "left" }}
                                value={changeRequest.process_ext_glass_reference}
                                onChange={(e) => onHandleChange(e, "process_ext_glass_reference")}
                                id="outlined-select-currency"
                                select
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={stepError && !changeRequest.process_ext_glass_reference ? true : false}
                                disabled={disableCondition}
                            >
                                {glassReferenceData.map((type) => {
                                    return (
                                        <MenuItem key={type.id} value={type.master_data}>
                                            {type.master_data}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "10px",
                            }}
                        >
                            <div style={{ flex: "0 0 48%" }}>
                                <InputLabel
                                    style={{
                                        fontWeight: 600,
                                        fontStyle: "normal",
                                        fontFamily: "Roboto, sans-serif",
                                        color: "#000",
                                        fontSize: 14,
                                    }}
                                >
                                    Part Number *
                                </InputLabel>
                                <TextField
                                    style={{
                                        width: "100%",
                                        marginTop: 10,
                                        background: "#FFFFFF",
                                        borderRadius: 4,
                                    }}
                                    id="outlined-multiline-static"
                                    value={changeRequest.process_ext_part_number}
                                    onChange={(e) => onHandleChange(e, "process_ext_part_number")}
                                    error={stepError && !changeRequest.process_ext_part_number ? true : false}
                                    disabled={disableCondition}
                                    autoComplete="off"
                                />
                            </div>
                            <div style={{ flex: "0 0 48%" }}>
                                <InputLabel
                                    style={{
                                        fontWeight: 600,
                                        fontStyle: "normal",
                                        fontFamily: "Roboto, sans-serif",
                                        color: "#000",
                                        fontSize: 14,
                                    }}
                                >
                                    Rev No *
                                </InputLabel>
                                <TextField
                                    style={{
                                        width: "100%",
                                        marginTop: 10,
                                        background: "#FFFFFF",
                                        borderRadius: 4,
                                    }}
                                    value={changeRequest.process_ext_part_number_rev_no}
                                    onChange={(e) => onHandleChange(e, "process_ext_part_number_rev_no")}
                                    id="outlined-select-currency"
                                    error={stepError && !changeRequest.process_ext_part_number_rev_no ? true : false}
                                    disabled={disableCondition}
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <InputLabel
                                style={{
                                    fontWeight: 600,
                                    fontStyle: "normal",
                                    fontFamily: "Roboto, sans-serif",
                                    color: "#000",
                                    fontSize: 14,
                                }}
                            >
                                Part Name *
                            </InputLabel>
                            <TextField
                                style={{
                                    width: "100%",
                                    marginTop: 10,
                                    background: "#FFFFFF",
                                    borderRadius: 4,
                                }}
                                id="outlined-multiline-static"
                                value={changeRequest.process_ext_part_name}
                                onChange={(e) => onHandleChange(e, "process_ext_part_name")}
                                error={stepError && !changeRequest.process_ext_part_name ? true : false}
                                disabled={disableCondition}
                                autoComplete="off"
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "10px",
                            }}
                        >
                            <div style={{ flex: "0 0 48%" }}>
                                <InputLabel
                                    style={{
                                        fontWeight: 600,
                                        fontStyle: "normal",
                                        fontFamily: "Roboto, sans-serif",
                                        color: "#000",
                                        fontSize: 14,
                                    }}
                                >
                                    Drawing Number *
                                </InputLabel>
                                <TextField
                                    style={{
                                        width: "100%",
                                        marginTop: 10,
                                        background: "#FFFFFF",
                                        borderRadius: 4,
                                    }}
                                    id="outlined-multiline-static"
                                    value={changeRequest.process_ext_drawing_number}
                                    onChange={(e) => onHandleChange(e, "process_ext_drawing_number")}
                                    error={stepError && !changeRequest.process_ext_drawing_number ? true : false}
                                    disabled={disableCondition}
                                    autoComplete="off"
                                />
                            </div>
                            <div style={{ flex: "0 0 48%" }}>
                                <InputLabel
                                    style={{
                                        fontWeight: 600,
                                        fontStyle: "normal",
                                        fontFamily: "Roboto, sans-serif",
                                        color: "#000",
                                        fontSize: 14,
                                    }}
                                >
                                    Rev No *
                                </InputLabel>
                                <TextField
                                    style={{
                                        width: "100%",
                                        marginTop: 10,
                                        background: "#FFFFFF",
                                        borderRadius: 4,
                                    }}
                                    value={changeRequest.process_ext_drawing_no_rev_no}
                                    onChange={(e) => onHandleChange(e, "process_ext_drawing_no_rev_no")}
                                    id="outlined-select-currency"
                                    error={stepError && !changeRequest.process_ext_drawing_no_rev_no ? true : false}
                                    disabled={disableCondition}
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <InputLabel
                                style={{
                                    fontWeight: 600,
                                    fontStyle: "normal",
                                    fontFamily: "Roboto, sans-serif",
                                    color: "#000",
                                    fontSize: 14,
                                }}
                            >
                                Process Reference *
                            </InputLabel>
                            <TextField
                                style={{
                                    width: "100%",
                                    marginTop: 10,
                                    background: "#FFFFFF",
                                    borderRadius: 4,
                                }}
                                id="outlined-multiline-static"
                                value={changeRequest.process_ext_process_reference}
                                onChange={(e) => onHandleChange(e, "process_ext_process_reference")}
                                error={stepError && !changeRequest.process_ext_process_reference ? true : false}
                                disabled={disableCondition}
                                autoComplete="off"
                            />
                        </div>
                    </>) : null
                }

                <div style={{ marginTop: "10px" }}>
                    <InputLabel
                        style={{
                            fontWeight: 600,
                            fontStyle: "normal",
                            fontFamily: "Roboto, sans-serif",
                            color: "#000",
                            fontSize: 14,
                        }}
                    >
                        Description Of Change Required *
                    </InputLabel>
                    <TextField
                        style={{ width: "100%", marginTop: 10, textAlign: "left" }}
                        value={changeRequest.des_change_required}
                        onChange={(e) => onHandleChange(e, "des_change_required")}
                        id="outlined-select-currency"
                        select
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={stepError && !changeRequest.des_change_required ? true : false}
                        disabled={disableCondition}
                    >
                        {descChangeRequired.map((type) => (
                            <MenuItem key={type.value} value={type.value}>
                                {type.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>

                <div style={{ marginTop: "10px" }}>
                    <InputLabel
                        style={{
                            fontWeight: 600,
                            fontStyle: "normal",
                            fontFamily: "Roboto, sans-serif",
                            color: "#000",
                            fontSize: 14,
                        }}
                    >
                        Others
                    </InputLabel>
                    <TextField
                        style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
                        value={changeRequest.other_comments}
                        onChange={(e) => onHandleChange(e, "other_comments")}
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        disabled={disableCondition}
                        autoComplete="off"
                    />
                </div>

                <div style={{ marginTop: "10px" }}>
                    <InputLabel
                        style={{
                            fontWeight: 600,
                            fontStyle: "normal",
                            fontFamily: "Roboto, sans-serif",
                            color: "#000",
                            fontSize: 14,
                            alignSelf: "stretch",
                            lineHeight: 2,
                        }}
                    >
                        Date of Receiving Customer Requirement *
                    </InputLabel>
                    <TextField
                        style={{ width: "100%", background: "#FFFFFF", borderRadius: 4 }}
                        id="date"
                        type="date"
                        value={changeRequest.receiveing_customer_date || ""} // Ensures it can be empty
                        onChange={(e) => handleReceivingDateChange(e, "receiveing_customer_date")}
                        variant="outlined"
                        InputLabelProps={{ style: { fontWeight: 500, fontStyle: "normal" } }}
                        error={stepError && !changeRequest.receiveing_customer_date}
                        disabled={disableCondition}
                    />
                </div>

                <div style={{ marginTop: "10px" }}>
                    <InputLabel
                        style={{
                            fontWeight: 600,
                            fontStyle: "normal",
                            fontFamily: "Roboto, sans-serif",
                            color: "#000",
                            fontSize: 14,
                            alignSelf: "stretch",
                            lineHeight: 2,
                        }}
                    >
                        Date of Customer Requirement For Implement *
                    </InputLabel>
                    <TextField
                        style={{ width: "100%", background: "#FFFFFF", borderRadius: 4 }}
                        id="date"
                        type="date"
                        value={changeRequest.customer_req_imple_date || ""} // Ensures it can be empty
                        onChange={(e) => handleCustomerImpleDateChange(e, "customer_req_imple_date")}
                        variant="outlined"
                        InputLabelProps={{ style: { fontWeight: 500, fontStyle: "normal" } }}
                        error={stepError && !changeRequest.customer_req_imple_date}
                        disabled={disableCondition}
                    />
                </div>

                <div style={{ marginTop: "10px" }}>
                    <InputLabel
                        style={{
                            fontWeight: 600,
                            fontStyle: "normal",
                            fontFamily: "Roboto, sans-serif",
                            color: "#000",
                            fontSize: 14,
                            alignSelf: "stretch",
                            lineHeight: 2,
                        }}
                    >
                        Feasibility Confirmation To Customer *
                    </InputLabel>
                    <TextField
                        style={{ width: "100%", background: "#FFFFFF", borderRadius: 4 }}
                        id="date"
                        type="date"
                        value={changeRequest.feas_con_customer_date || ""}
                        onChange={(e) => handleFeasCustomerDateChange(e, "feas_con_customer_date")}
                        variant="outlined"
                        InputLabelProps={{ style: { fontWeight: 500, fontStyle: "normal" } }}
                        error={stepError && !changeRequest.feas_con_customer_date}
                        disabled={disableCondition}
                    />
                </div>

                <div style={{ marginTop: "10px" }}>
                    <InputLabel
                        style={{
                            fontWeight: 600,
                            fontStyle: "normal",
                            fontFamily: "Roboto, sans-serif",
                            color: "#000",
                            fontSize: 14,
                        }}
                    >
                        Remarks
                    </InputLabel>
                    <TextField
                        style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
                        value={changeRequest.remarks}
                        onChange={(e) => onHandleChange(e, "remarks")}
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        disabled={disableCondition}
                        autoComplete="off"
                    />
                </div>
                {initialLoad ? (
                    <div className="popup-overlay">
                        <div className="popup">
                            <CircularProgress />
                        </div>
                    </div>
                ) : showSuccessPopup ? (
                    <div className="popup-overlay">
                        <div className="popup">
                            <p>Data saved successfully!</p>
                        </div>
                    </div>
                ) : null}
                {initialLoad ? (
                    <div className="popup-overlay">
                        <div className="popup">
                            <CircularProgress />
                        </div>
                    </div>
                ) : showUpdatePopup ? (
                    <div className="popup-overlay">
                        <div className="popup">
                            <p>Data updated successfully!</p>
                        </div>
                    </div>
                ) : null}
                {errors ? (
                    <Typography variant="subtitle2" color="red">
                        {""}
                        Please fill all the fields
                    </Typography>
                ) : null}
                {saveMessage ? (
                    <Typography variant="subtitle2" color="red">
                        {""}
                        Please Save before going to Next
                    </Typography>
                ) : null}
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row", // Keep it as row to align buttons horizontally
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "20px",
                    }}
                >
                    {(isEditCms && !(disableCondition) && (JSON.parse(localStorage.getItem("cmsDataByID")))?.cmsForm?.change_request_id !== null) ?
                        <Button
                            variant="contained"
                            style={{
                                backgroundColor: "#fff",
                                fontSize: 20,
                                color: "#B1174B",
                                border: "1px solid #B1174B",
                                textTransform: "none",
                                width: "75%",
                                marginTop: 20,
                                marginRight: 5,
                                marginBottom: 10,
                                boxShadow: "none"
                            }}
                            onClick={handleRequestUpdate}
                        >
                            Update
                        </Button>
                        :
                        ((!isEditCms || (JSON.parse(localStorage.getItem("cmsDataByID")))?.cmsForm?.change_request_id === null) &&
                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor: "#fff",
                                    fontSize: 20,
                                    color: "#B1174B",
                                    border: "1px solid #B1174B",
                                    textTransform: "none",
                                    width: "75%",
                                    marginTop: 20,
                                    marginRight: 5,
                                    marginBottom: 10,
                                    boxShadow: "none"
                                }}
                                onClick={handleRequestSave}
                            >
                                Save
                            </Button>
                        )}
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: "#B1174B",
                            fontSize: 20,
                            color: "white",
                            textTransform: "none",
                            width: "75%",
                            marginTop: 20,
                            marginRight: 5,
                            marginBottom: 10
                        }}
                        onClick={handleRequestNext}
                    >
                        Next
                    </Button>
                </div>
            </DialogContent>
        </>
    );
};
export default ChangeRequest;