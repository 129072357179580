import React, { useState, useEffect, useRef } from "react";
import { Typography, FormControl, Radio, RadioGroup, FormControlLabel, Divider, Checkbox, InputLabel, Button, CircularProgress, Grid, Box, Dialog, DialogTitle, DialogContent, useTheme, useMediaQuery } from "@mui/material";
import { TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Header from "../app/TopAppBar";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { alternationData, editCMSData } from "../recoil/atoms";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUpload from "../Asset/Icons/file_upload_icon.svg";
import axios from "axios";
import API from "../Services/API";
import configParam from "../config";
import { handleSend } from "../4m_Pages/EmailPopupCms";

const appStatus = configParam.APPROVAL_STATUS;

const Alternation = ({ isOpen, onClose }) => {
    const [alterData, setAlterData] = useRecoilState(alternationData);
    const [newDialogAlterOpen, setNewDialogAlterOpen] = useState('true');
    const [isEditCms, setIsEditCms] = useRecoilState(editCMSData);
    const [save, setSave] = useState(false);
    const [errors, setErrors] = useState(false);
    const [approvalErrors, setApprovalErrors] = useState(false);
    const [saveMessage, setSaveMessage] = useState(false);
    const [stepError, setStepError] = useState(false);
    const [initialLoad, setInitialLoad] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [showUpdatePopup, setShowUpdatePopup] = useState(false);
    const [showResubmitPopup, setShowResubmitPopup] = useState(false);
    // const [uploadDocument, setUploadDocument] = useState("");
    const [uploadDocuments, setUploadDocuments] = useState(Array.from({ length: 12 }, () => ""));
    const [selectedValue, setSelectedValue] = useState(null);
    const [questionAssess, setQuestionAssess] = useState([]);
    const fileInputRef = useRef(null);
    const navigate = useNavigate();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const access_token = JSON.parse(localStorage.getItem("accesstoken"));
    //console.log(isEditCms, "isEditCms40")
    useEffect(() => {
        const handlePopup = (setter, delay) => {
            setter(true);
            const timer = setTimeout(() => {
                setter(false);
            }, delay);
            return () => clearTimeout(timer);
        };

        const handlePopups = (showPopup, setShowPopup) => {
            if (showPopup) {
                setInitialLoad(true);
                setTimeout(() => {
                    setInitialLoad(false);
                    handlePopups(setShowPopup, 3000); // Increased to 3000 milliseconds (3 seconds)
                }, 5000); // Increased to 5000 milliseconds (5 seconds) before showing the popup again
            }
        };


        handlePopups(showSuccessPopup, setShowSuccessPopup);
        handlePopups(showUpdatePopup, setShowUpdatePopup);
    }, [showSuccessPopup, showUpdatePopup]);

    useEffect(() => {
        if (showSuccessPopup || showUpdatePopup) {
            onClose();
        }
    }, [showSuccessPopup, showUpdatePopup]);

    const CPQuestionsStyle = {
        fontWeight: 550,
        fontSize: 16,
        fontStyle: "normal",
        fontFamily: "Inter, sans-serif",
        color: "black",
        lineHeight: 1.5,
        textTransform: "capitalize",
        marginTop: "10px",
    };

    useEffect(() => {
        const fetchAssessQuestion = async () => {
            try {
                const response = await axios.get(`${API.GET_ALTERNATION_CMS_QUESTIONS}`, {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                });
                const data = response.data
                    .filter((item) => item.tag === "alternation")
                    .map(({ id, questions, tag, question_no }) => ({ id, questions, tag, question_no }))
                    .sort((a, b) => a.question_no - b.question_no); // Ensure sorting is correct if needed

                setQuestionAssess(data);
            } catch (error) {
                console.error("Error fetching questions:", error);
            }
            setInitialLoad(false);
        };
        fetchAssessQuestion();
    }, []);

    const handleRadioChange = (index, value) => {
        const updatedAnswers = [...alterData.affects];
        //console.log(updatedAnswers, "updated108")
        updatedAnswers[index] = value;
        setAlterData((prev) => ({
            ...prev,
            affects: updatedAnswers,
        }));
        setSelectedValue(value);
        // setErrors(false);
    }

    const onHandleChange = (event, field, index) => {
        let updatedForm = { ...alterData, [field]: [...alterData[field]] };
        updatedForm[field][index] = event.target.value;
        setAlterData(updatedForm);
        setErrors(false);
        // setApprovalErrors(false);
    };

    if (!alterData.date) {
        const initialDates = new Array(questionAssess.length).fill("");
        setAlterData((prev) => ({
            ...prev,
            date: initialDates,
        }));
    }

    const handleDateChange = (event, index) => {
        const inputDate = event.target.value;
        let updatedDates = [...alterData.date];
        if (inputDate === "") {
            updatedDates[index] = "";
        } else {
            updatedDates[index] = inputDate;
        }
        setAlterData((prev) => ({
            ...prev,
            date: updatedDates,
        }));
    };

    const handleRemarksChange = (index, event) => {
        const updatedRemarks = [...alterData.remarks];
        updatedRemarks[index] = event.target.value;
        setAlterData({
            ...alterData,
            remarks: updatedRemarks,
        });
    };

    const handleAlterDateChange = (event) => {
        const inputDate = event.target.value;
        const updatedForm = { ...alterData };
        if (inputDate === "") {
            updatedForm["alternation_date"] = "";
        } else {
            updatedForm["alternation_date"] = inputDate; // Accept any valid date
        }
        setAlterData(updatedForm);
    };

    const handleImplementDateChange = (event) => {
        const inputDate = event.target.value;
        const updatedForm = { ...alterData };
        if (inputDate === "") {
            updatedForm["implementation_date"] = "";
        } else {
            updatedForm["implementation_date"] = inputDate; // Accept any valid date
        }
        setAlterData(updatedForm);
    };

    const onHandleChanges = (event, field) => {
        let updatedForm = { ...alterData };
        const value = event.target.value;
        updatedForm[field] = value;
        setAlterData(updatedForm);
        setErrors(false);
        // setApprovalErrors(false);
    };

    // const alterChanges = {
    //     alter_change: [],
    // };

    // questionAssess.forEach((alterchanges, index) => {
    //     alterChanges.alter_change.push({
    //         alterchanges,
    //         affects: alterData.affects[index],
    //         from: alterData.from[index],
    //         to: alterData.to[index],
    //         date: alterData.date[index],
    //         stock_disposal: alterData.stock_disposal[index],
    //         remarks: alterData.remarks[index],
    //         document: alterData.document[index],
    //     });
    // });

    const alterChanges = {
        alter_note: {
            // Include the required fields
            alter_change: questionAssess.map((alterchanges, index) => ({
                alterchanges,
                affects: alterData.affects[index],
                from: alterData.from[index],
                to: alterData.to[index],
                date: alterData.date[index],
                stock_disposal: alterData.stock_disposal[index],
                remarks: alterData.remarks[index],
                document: alterData.document[index],
            })),
        },
        alternation_date: alterData.alternation_date,
        implementation_date: alterData.implementation_date,
        implementation_status: alterData.implementation_status, // Update with actual status
        final_comments: alterData.final_comments,
        updated_at: new Date().toISOString(), // Ensure this is included
    };
    //console.log(isEditCms, "200")
    const handleAlterSave = async () => {
        const storedCmsDataString = localStorage.getItem("cmsDataByID");
        const storedCmsData = JSON.parse(storedCmsDataString);
        try {
            if (!Array.isArray(alterData.affects) || // Check if affects is an array
                alterData.affects.includes(undefined)
                || alterData.affects.length !== questionAssess.length
                //  || // Check if it includes undefined
                // alterData.affects.length !== 13 // Check if length is exactly 28) 
            ) {
                setErrors(true);
                setStepError(true);
                setSaveMessage(false);
                return;
            }
            // Check for mandatory alternation_date
            if (!alterData.alternation_date || !alterData.implementation_date) {
                setErrors(true);
                setStepError(true);
                setSaveMessage(false);
                return;
            }
            let hasError = false;
            alterData.affects.forEach((affect, index) => {
                //console.log(affect, "affect")
                if (affect === "Yes") {
                    if (!alterData.from[index] || !alterData.to[index] || !alterData.date[index] || !alterData.stock_disposal[index] || !alterData.remarks[index] ||
                        !(alterData.document[index] &&
                            Array.isArray(alterData.document[index]) &&
                            alterData.document[index].length > 0)
                    ) {
                        hasError = true;
                    }
                }
            });
            if (hasError) {
                setErrors(true);
                setStepError(true);
                setSaveMessage(false);
                return;
            } else {
                setErrors(false);
                setStepError(false);
                setSaveMessage(false);
            }
            const response = await axios.post(API.INSERT_ALTERNATION, alterChanges, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
            if (response.status === 200) {
                //console.log("response176", response);
                const alterID = response.data.identifiers[0].id;
                localStorage.setItem("alterID", alterID);
                localStorage.setItem("isAlternationSave", true);
                await cmsForm(alterID);
                //console.log(alterID, "alterID");
                setSave(true);
                setShowSuccessPopup(true);
                const storedUserByData = JSON.parse(localStorage.getItem("userDatas"));
                let from_email = [storedUserByData?.MailId];
                let to_email = [
                    ...storedCmsData?.cmsForm?.notificationHeader?.cmsForms?.map(cmsForm => cmsForm?.functional_mail_id).filter(Boolean),
                    ...storedCmsData?.cmsForm?.notificationHeader?.cmsForms?.map(cmsForm => cmsForm?.approver_mail_id).filter(Boolean)
                ].filter(email => email);
                handleSend(from_email, to_email);
            }
        } catch (error) {
            console.error("An error occurred in update trials:", error);
        }
    };

    const cmsForm = async () => {
        const updateCmsDet = localStorage.getItem("cmsID");
        //console.log(updateCmsDet, "updateMocDet228");
        const storedCmsDataString = localStorage.getItem("cmsDataByID");
        const storedCmsData = JSON.parse(storedCmsDataString);
        //console.log(storedCmsData, "storedCmsData191");
        const updateTakeCmsDet = storedCmsData?.cmsForm?.id;
        //console.log(updateTakeCmsDet, "updateTakeCmsDet184");
        const requestID = storedCmsData?.cmsForm?.change_request_id;
        const changeRequireID = storedCmsData?.cmsForm?.change_required_id;
        const localImpactId = localStorage.getItem("impactID");
        const impactID = isEditCms ? storedCmsData?.cmsForm?.impact_change_id : localImpactId;
        const localNotificationId = localStorage.getItem("notificationID");
        const notificationID = isEditCms ? storedCmsData?.cmsForm?.notification_id : localNotificationId;
        const localAlterID = localStorage.getItem("alterID");
        //console.log(localAlterID, "localAlterID264")
        const alterID = isEditCms ? storedCmsData?.cmsForm?.alternation_note_id : localAlterID;
        //console.log(alterID, "alterID225")
        const userID = storedCmsData?.cmsForm?.user_id;
        const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
        const userSGID = storedUserData.SGID;
        const userName = storedUserData.FName + " " + storedUserData.LName;
        const userMail = storedUserData.MailId;
        try {
            const cmsData = {
                id: updateTakeCmsDet,
                user_id: isEditCms ? storedCmsData?.cmsForm?.user_id : userSGID,
                updated_at: new Date(),
                created_by: userName,
                doc_ref: "F/CFT/SGSI/01",
                // rev_no: "03",
                // rev_date: new Date(),
                change_request_id: requestID,
                change_required_id: changeRequireID,
                impact_change_id: impactID,
                notification_id: notificationID,
                alternation_note_id: alterID,
                submission_status: "approved",
                user_mail: userMail
            };
            const cmsResponse = await axios.put(
                `${API.UPDATE_CMS_FORM}?id=${updateTakeCmsDet}`,
                cmsData,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                }
            );
            //console.log("updateTakeCmsDet279", updateTakeCmsDet);
            //console.log("cmsData217", cmsData);
            if (cmsResponse.status === 200) {
                //console.log("update conclusion", cmsResponse);
                await updateCmsApprovals(updateTakeCmsDet);
                setSave(true);
                // setShowSuccessPopup(true);
            }
        } catch (error) {
            console.error("An error occurred in insertIntoMocTable:", error);
        }
    };

    const updateCmsApprovals = async () => {
        const updateMocDet = localStorage.getItem("cmsID");
        const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
        //console.log(storedUserData, "storedUserData79999");
        //console.log(updateMocDet, "updateMocDet");
        const userSGID = updateMocDet ? storedUserData.SGID : "";
        const userAppName = updateMocDet ? storedUserData.FName + " " + storedUserData.LName : " ";
        const cmsApprovalID = localStorage.getItem("cmsApprovalID");
        try {
            const cmsData = {
                cms_id: updateMocDet,
                user_sgid: userSGID,
                user_approval_name: userAppName,
                // status: "pending",
            };
            const cmsResponse = await axios.put(`${API.UPDATE_CMS_APPROVALS}?id=${cmsApprovalID}`, cmsData, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
            if (cmsResponse.status === 200) {
                //console.log("Inserted into moc table:", cmsResponse);
                const cmsApprovalID = cmsResponse.data["identifiers"][0].id;
                //console.log("Inserted cmsApprovalID", cmsApprovalID);
                localStorage.setItem("cmsApprovalID", cmsApprovalID);
            }
        } catch (error) {
            console.error("An error occurred in insertIntoMocTable:", error);
        }
    };

    const handleAlterCancel = () => {
        onClose();
        // navigate("/managecms");
    }

    const handleCaptureGallery = (index) => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
            fileInputRef.current.onchange = (e) => {
                const selectedFiles = e.target.files;
                const currentFiles = alterData.document[index] || [];
                // Check if there are already 5 files for this question
                if (currentFiles.length + selectedFiles.length > 5) {
                    alert("You can upload a maximum of 5 files per question.");
                    return; // Do not proceed if file count exceeds 5
                }
                // Proceed with uploading the files if it's within the limit
                setUploadedFile({ target: { files: selectedFiles } }, index);
            };
        }
    };

    const displayImage = (files) => {
        const imageBeforeArray = [];
        Array.from(files).forEach(file => {
            const reader = new FileReader();
            reader.onload = (e) => {
                // Set the selected image URLs
                imageBeforeArray.push(e.target.result);
                if (imageBeforeArray.length === files.length) {
                    // setBeforeImage((prevBeforeImage) => [...prevBeforeImage, ...imageBeforeArray])
                }
            };
            reader.readAsDataURL(file);
        });
    };

    const setUploadedFile = async (event, questionIndex) => {
        const files = event.target.files;
        displayImage(files);

        if (files.length > 0) {
            const form = new FormData();
            Array.from(files).forEach(file => {
                form.append('file', file, file.name);
            });

            try {
                const response = await axios.post(API.UPLOAD_DOCUMENTS_ALTER, form, {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                });

                // Get the file paths from the response
                const result = response.data.filePaths;

                // Update the document array for the specific question
                const updatedDocument = [...alterData.document];
                if (!updatedDocument[questionIndex]) {
                    updatedDocument[questionIndex] = [];
                }

                // Add the new files to the existing documents for this question
                updatedDocument[questionIndex] = [...updatedDocument[questionIndex], ...result];

                setAlterData({
                    ...alterData,
                    document: updatedDocument,
                });
            } catch (error) {
                console.error('Failed to upload images:', error);
            }
        }
    };

    const handleDelete = (questionIndex, fileIndex) => {
        const updatedDocument = [...alterData.document];
        // Delete the file by filtering out the file at the given index
        updatedDocument[questionIndex] = updatedDocument[questionIndex].filter((_, i) => i !== fileIndex);
        setAlterData(prevData => ({
            ...prevData,
            document: updatedDocument,
        }));
    };

    const handleDownloadDocument = async (file) => {
        const parts = file.split(/[\\/]/);
        try {
            const response = await axios.get(
                API.DOWNLOAD_DOCUMENTS + `/${parts[parts.length - 1]}`,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                    responseType: 'blob', // Ensure the response type is set to 'blob'
                }
            );

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', parts[parts.length - 1]); // Set the correct filename
            document.body.appendChild(link);
            link.click();
            link.remove();

            // Revoke the object URL after the file is downloaded to free up resources
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading the file", error);
        }
    };

    useEffect(() => {
        if (isEditCms) {
            const getAlterNote = async () => {
                const storedCmsDataString = localStorage.getItem("cmsDataByID");
                const storedCmsData = JSON.parse(storedCmsDataString);
                //console.log(storedCmsData, "storedMocData319")
                const alterNoteID = storedCmsData?.cmsForm?.alternation_note_id;
                //console.log(alterNoteID, "alterNoteID434");
                //console.log(isEditCms, "isEditCms547");
                // if (!alterNoteID && isEditCms) {
                //     setIsEditCms(false);
                //     //console.log(isEditCms, "isEditCms567")
                //     return; // Exit early if no ID is found
                // }
                try {
                    const response = await axios.get(
                        `${API.GET_CMS_ALTERNATION}?id=${alterNoteID}`,
                        {
                            headers: {
                                Authorization: `Bearer ${access_token}`
                            }
                        }
                    );

                    const alternationDetails = response.data; // Updated to directly access the response
                    //console.log(response, "response329")
                    //console.log(alternationDetails, "requestFormDetails330")

                    let getAffects = [];
                    let getFrom = [];
                    let getTo = [];
                    let getDate = [];
                    let getStockDisposal = [];
                    let getRemarks = [];

                    // Assuming alter_note contains alter_change
                    alternationDetails.alter_note.alter_change.map((data) => {
                        //console.log(data.affects, "data.affects507");
                        getAffects.push(data.affects);
                        getFrom.push(data.from);
                        getTo.push(data.to);
                        getDate.push(data.date);
                        getStockDisposal.push(data.stock_disposal);
                        getRemarks.push(data.remarks);
                        return null;
                    });

                    // Set the additional fields in your state
                    setAlterData((prev) => {
                        //console.log(prev, "pre state");
                        return {
                            ...prev,
                            affects: getAffects,
                            from: getFrom,
                            to: getTo,
                            date: getDate,
                            stock_disposal: getStockDisposal,
                            remarks: getRemarks,
                            alternation_date: alternationDetails.alternation_date,
                            implementation_date: alternationDetails.implementation_date,
                            implementation_status: alternationDetails.implementation_status,
                            final_comments: alternationDetails.final_comments,
                        };
                    });
                } catch (error) {
                    console.error("Error fetching MOC details:", error);
                }
            };
            getAlterNote();
        }
    }, [setAlterData, isEditCms]);

    // const cmsNumberData = localStorage.getItem("cmsNumber");

    const handleUploadClick = (questionIndex) => {
        if (!uploadDocuments[questionIndex]) {
            handleCaptureGallery(questionIndex);
        }
    };

    const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
    const userSGID = storedUserData.SGID;
    const cmsDetData = JSON.parse(localStorage.getItem("cmsDataByID"));
    const cmsNo = cmsDetData?.cmsForm?.cms_no === null;
    //console.log(cmsNo, "cmsNo576")
    //console.log(cmsDetData, "cmsDetData577")
    const userID = cmsDetData?.cmsForm?.user_id;
    let disableCondition;
    let disableUserCondition;
    if (isEditCms) {
        disableCondition = userSGID !== cmsDetData?.cmsForm?.user_id;
        disableUserCondition = userSGID === cmsDetData?.cmsForm?.user_id;
    }

    return (
        <>
            <Dialog
                open={isOpen}
                onClose={onClose}
                fullScreen={fullScreen}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>Alternation Note</DialogTitle>
                <DialogContent>
                    {/* <Header pageTitle="The Impact of Change" /> */}
                    <Typography style={{ fontSize: 18, color: "#B51849", fontWeight: 600 }}>
                        Alternation Details
                    </Typography>
                    {initialLoad ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "45px"
                            }}
                        >
                            <CircularProgress />
                        </div>
                    ) : (
                        <React.Fragment>
                            {questionAssess.map((question, index) => (
                                <div
                                    key={index}
                                    style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        marginBottom: "5px",
                                        backgroundColor: "#F9F9F9",
                                    }}
                                >
                                    <span
                                        style={{
                                            marginRight: "5px",
                                            flexShrink: 0,
                                            fontWeight: 550,
                                            backgroundColor: "#F9F9F9",
                                        }}
                                    >
                                        {index + 1}
                                    </span>
                                    <Grid style={{ backgroundColor: "#F9F9F9", width: "100%" }}>
                                        <Typography style={{ ...CPQuestionsStyle, margin: 0 }}>
                                            {question.questions}
                                        </Typography>
                                        <FormControl component="fieldset">
                                            <RadioGroup
                                                value={
                                                    alterData.affects
                                                    && alterData.affects[index] || null
                                                }
                                                onChange={(event) =>
                                                    handleRadioChange(index, event.target.value)
                                                }
                                                row
                                            >
                                                <FormControlLabel
                                                    value="Yes"
                                                    control={<Radio sx={{ color: "#B51849" }} />}
                                                    label="Yes"
                                                    disabled={disableCondition}
                                                />
                                                <FormControlLabel
                                                    value="No"
                                                    control={<Radio sx={{ color: "#B51849" }} />}
                                                    label="No"
                                                    disabled={disableCondition}
                                                />
                                            </RadioGroup>
                                        </FormControl>

                                        {alterData.affects[index] === "No" || alterData.affects[index] === undefined ? null :
                                            <Grid container spacing={2} sx={{ flexWrap: 'wrap' }}>
                                                <Grid item xs={12} sm={6} md={3}>
                                                    <InputLabel
                                                        sx={{
                                                            fontWeight: 600,
                                                            fontSize: 14,
                                                        }}
                                                    >
                                                        From *
                                                    </InputLabel>
                                                    <TextField
                                                        sx={{ width: '100%', mt: 1, background: '#FFFFFF', borderRadius: 1 }}
                                                        id="outlined-multiline-static"
                                                        value={alterData.from[index] || ""}
                                                        onChange={(e) => onHandleChange(e, "from", index)}
                                                        error={stepError && !alterData.from[index]}
                                                        disabled={disableCondition}
                                                        autoComplete="off"
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={6} md={3}>
                                                    <InputLabel
                                                        sx={{
                                                            fontWeight: 600,
                                                            fontSize: 14,
                                                        }}
                                                    >
                                                        To *
                                                    </InputLabel>
                                                    <TextField
                                                        sx={{ width: '100%', mt: 1, background: '#FFFFFF', borderRadius: 1 }}
                                                        id="outlined-multiline-static"
                                                        value={alterData.to[index] || ""}
                                                        onChange={(e) => onHandleChange(e, "to", index)}
                                                        error={stepError && !alterData.to[index]}
                                                        disabled={disableCondition}
                                                        autoComplete="off"
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={6} md={3}>
                                                    <InputLabel
                                                        sx={{
                                                            fontWeight: 600,
                                                            fontSize: 14,
                                                        }}
                                                    >
                                                        Date *
                                                    </InputLabel>
                                                    <TextField
                                                        sx={{ width: '100%', mt: 1, background: '#FFFFFF', borderRadius: 1 }}
                                                        id={`date-${index}`}
                                                        type="date"
                                                        value={alterData.date[index] || ''}  // Ensure it's controlled properly
                                                        onChange={(e) => handleDateChange(e, index)}
                                                        error={stepError && !alterData.date[index]}
                                                        variant="outlined"
                                                        InputLabelProps={{ sx: { fontWeight: 500, fontSize: 14 } }}
                                                        inputProps={{}}  // No restrictions on max or min dates
                                                        disabled={disableCondition}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={6} md={3}>
                                                    <InputLabel
                                                        sx={{
                                                            fontWeight: 600,
                                                            fontSize: 14,
                                                        }}
                                                    >
                                                        Stock Disposal *
                                                    </InputLabel>
                                                    <TextField
                                                        sx={{ width: '100%', mt: 1, background: '#FFFFFF', borderRadius: 1 }}
                                                        id="outlined-multiline-static"
                                                        value={alterData.stock_disposal[index] || ""}
                                                        onChange={(e) => onHandleChange(e, "stock_disposal", index)}
                                                        error={stepError && !alterData.stock_disposal[index]}
                                                        disabled={disableCondition}
                                                        autoComplete="off"
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={6} md={3}>
                                                    <InputLabel
                                                        sx={{
                                                            fontWeight: 600,
                                                            fontSize: 14,
                                                        }}
                                                    >
                                                        Remarks *
                                                    </InputLabel>
                                                    <TextField
                                                        sx={{ width: '100%', background: '#FFFFFF', borderRadius: 1 }}
                                                        id="outlined-multiline-static"
                                                        value={alterData.remarks && alterData.remarks[index] || ""}
                                                        onChange={(event) => handleRemarksChange(index, event)}
                                                        error={stepError && !alterData.remarks[index]}
                                                        disabled={disableCondition}
                                                        autoComplete="off"
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={6} md={3}>
                                                    <InputLabel
                                                        sx={{
                                                            fontWeight: 600,
                                                            fontSize: 14,
                                                        }}
                                                    >
                                                        Document *
                                                    </InputLabel>
                                                    <Box display="flex" alignItems="center">
                                                        <input
                                                            id="upload-image"
                                                            multiple
                                                            type="file"
                                                            style={{ display: "none" }}
                                                            ref={fileInputRef}
                                                            disabled={disableCondition}
                                                        />
                                                        <img
                                                            src={CloudUpload}
                                                            alt="Upload File"
                                                            style={{ width: "35px", height: "35px", cursor: "pointer" }}
                                                            onClick={() => handleCaptureGallery(index)}
                                                            disabled={alterData.document && alterData.document[index] && alterData.document[index].length >= 5}
                                                        />
                                                        {alterData && alterData.document && Array.isArray(alterData.document) && alterData.document[index] && (
                                                            <div style={{ marginTop: '10px' }}>
                                                                {alterData.document[index].map((file, fileIndex) => (
                                                                    <div key={fileIndex} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                                        <CloudDownloadIcon
                                                                            style={{ width: '35px', height: 'auto', marginRight: '10px', cursor: "pointer" }}
                                                                            onClick={() => handleDownloadDocument(file)}
                                                                        />
                                                                        <span>{file}</span>
                                                                        <button
                                                                            onClick={() => handleDelete(index, fileIndex)} // Pass the document and file index
                                                                            style={{
                                                                                marginLeft: '10px',
                                                                                background: 'none',
                                                                                border: 'none',
                                                                                color: 'red',
                                                                                fontSize: '20px',
                                                                                cursor: 'pointer'
                                                                            }}
                                                                        >
                                                                            &times;
                                                                        </button>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}

                                                    </Box>
                                                    {!disableCondition && alterData.document && alterData.document[index] && alterData.document[index].length >= 5 && (
                                                        <Typography variant="subtitle2" color="error">
                                                            Allows maximum 5 documents to upload
                                                        </Typography>
                                                    )}
                                                </Grid>

                                            </Grid>
                                        }

                                    </Grid>

                                </div>
                            ))}

                            <div style={{ marginTop: "10px" }}>
                                <InputLabel
                                    style={{
                                        fontWeight: 600,
                                        fontStyle: "normal",
                                        fontFamily: "Roboto, sans-serif",
                                        color: "#000",
                                        fontSize: 14,
                                        alignSelf: "stretch",
                                        lineHeight: 2,
                                    }}
                                >
                                    This alternation to take effect from : *
                                </InputLabel>
                                <TextField
                                    style={{ width: "100%", background: "#FFFFFF", borderRadius: 4 }}
                                    id="date"
                                    type="date"
                                    value={alterData.alternation_date || ""}
                                    onChange={(e) => handleAlterDateChange(e, "alternation_date")}
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontWeight: 500, fontStyle: "normal" } }}
                                    error={stepError && !alterData.alternation_date}
                                    disabled={disableUserCondition}
                                />
                            </div>

                            <div style={{ marginTop: "10px" }}>
                                <InputLabel
                                    style={{
                                        fontWeight: 600,
                                        fontStyle: "normal",
                                        fontFamily: "Roboto, sans-serif",
                                        color: "#000",
                                        fontSize: 14,
                                        alignSelf: "stretch",
                                        lineHeight: 2,
                                    }}
                                >
                                    Date of implementation of change : *
                                </InputLabel>
                                <TextField
                                    style={{ width: "100%", background: "#FFFFFF", borderRadius: 4 }}
                                    id="date"
                                    type="date"
                                    value={alterData.implementation_date || ""}
                                    onChange={(e) => handleImplementDateChange(e, "implementation_date")}
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontWeight: 500, fontStyle: "normal" } }}
                                    error={stepError && !alterData.implementation_date}
                                    disabled={disableUserCondition}
                                />
                            </div>
                            <div style={{ marginTop: "10px" }}>
                                <InputLabel
                                    style={{
                                        fontWeight: 500,
                                        fontStyle: "normal",
                                        fontFamily: "Inter, sans-serif",
                                        color: "#000000",
                                    }}
                                >
                                    Implementation status :
                                </InputLabel>
                                <TextField
                                    style={{ width: "100%", marginTop: 10, textAlign: "left" }}
                                    value={alterData.implementation_status}
                                    onChange={(e) => onHandleChanges(e, "implementation_status")}
                                    id="outlined-multiline-static"
                                    // error={stepError && !alterData.implementation_status ? true : false}
                                    disabled
                                >
                                </TextField>
                            </div>
                            <div style={{ marginTop: "10px" }}>
                                <InputLabel
                                    style={{
                                        fontWeight: 500,
                                        fontStyle: "normal",
                                        fontFamily: "Inter, sans-serif",
                                        color: "#000000",
                                    }}
                                >
                                    Final comments if any :
                                </InputLabel>
                                <TextField
                                    style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
                                    value={alterData.final_comments}
                                    onChange={(e) => onHandleChanges(e, "final_comments")}
                                    id="outlined-multiline-static"
                                    multiline
                                    rows={4}
                                    disabled={disableUserCondition}
                                />
                            </div>
                        </React.Fragment>
                    )}
                    {errors ? (
                        <Typography variant="subtitle2" color="red">
                            {""}
                            Please fill all the fields
                        </Typography>
                    ) : null}
                    {saveMessage ? (
                        <Typography variant="subtitle2" color="red">
                            {""}
                            Please Save before going to Next
                        </Typography>
                    ) : null}
                    {/* {approvalErrors ? (
                        <Typography variant="subtitle2" color="red">
                            {""}
                            Please fill the approval status
                        </Typography>
                    ) : null} */}
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "10px"
                        }}
                    >
                        {initialLoad ? (
                            <div className="popup-overlay">
                                <div className="popup">
                                    <CircularProgress />
                                </div>
                            </div>
                        ) : showSuccessPopup ? (
                            <div className="popup-overlay">
                                <div className="popup">
                                    <p>Data saved successfully!</p>
                                </div>
                            </div>
                        ) : null}
                        {initialLoad ? (
                            <div className="popup-overlay">
                                <div className="popup">
                                    <CircularProgress />
                                </div>
                            </div>
                        ) : showUpdatePopup ? (
                            <div className="popup-overlay">
                                <div className="popup">
                                    <p>Data updated successfully!</p>
                                </div>
                            </div>
                        ) : null}
                    </div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row", // Keep it as row to align buttons horizontally
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "20px",
                        }}
                    >

                        {cmsDetData?.cmsForm?.submission_status === "pending" && disableUserCondition && isEditCms &&
                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor: "#B1174B",
                                    fontSize: 20,
                                    color: "white",
                                    textTransform: "none",
                                    width: "75%",
                                    marginTop: 20,
                                    marginRight: 5,
                                }}
                                onClick={handleAlterCancel}
                            >
                                {"Cancel"}
                            </Button>
                        }
                        {userSGID === userID ?
                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor: "#fff",
                                    fontSize: 20,
                                    color: "#B1174B",
                                    border: "1px solid #B1174B",
                                    textTransform: "none",
                                    width: "100%",
                                    marginTop: 20,
                                    marginRight: 5,
                                    marginBottom: 10,
                                    boxShadow: "none"
                                }}
                                onClick={handleAlterSave}
                            >
                                Save
                            </Button>
                            :
                            <></>
                        }

                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
};

export default Alternation;