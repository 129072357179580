import React from 'react';
import Teams from '../Asset/Icons/Teams.svg';
import Mail from '../Asset/Icons/Mail.svg';
import Header from '../app/TopAppBar';

const HelpSupport = () => {
    return (
        <div style={{ justifyContent: 'center', alignItems: 'center', display: "flex", flexDirection: "column", marginTop: "80px" }}>
            {/* <Header pageTitle='Help & Support' /> */}
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: "center",
                alignItems: "center",
            }}>
                <img src={Teams} alt="" style={{ width: "300px", height: "40vh" }}></img>
            </div>

            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: "center",
                alignItems: "center",
                marginTop: 10
            }}>
                <img src={Mail} alt="" style={{ width: "300px", height: "40vh" }}></img>
            </div>

        </div>
    )
}
export default HelpSupport;