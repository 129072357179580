import React, { useState, useEffect } from "react";
import { Stepper, Step, StepButton, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, Box, useTheme, useMediaQuery } from "@mui/material";
import ChangeRequest from "./ChangeRequest";
import ChangeRequired from "./ChangeRequired";
import ImpactChange from "./ImpactChange";
import Notification from "./Notification";

const NewChangeRequest = ({ isOpen, onClose }) => {
    const [dataSend, setDataSend] = useState(false);
    const [dataRequire, setDataRequire] = useState(false);
    const [dataImpact, setDataImpact] = useState(false);
    const [dataNotify, setDataNotify] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    // Reset states when the dialog opens
    useEffect(() => {
        if (isOpen) {
            setDataSend(false);
            setDataRequire(false);
            setDataImpact(false);
            setDataNotify(false);
        }
    }, [isOpen]);

    // Callback function to update state
    const handleRequest = (data) => {
        setDataSend(data);
        //console.log(data, 'data')
    };

    const handleRequired = (data) => {
        setDataRequire(data);
        //console.log(data, 'data')
    };

    const handleImpact = (data) => {
        setDataImpact(data);
        //console.log(data, 'data')
    };

    const handleNotify = (data) => {
        setDataNotify(data);
        //console.log(data, 'data')
    };

    const handleBackClick = () => {
        if (dataNotify) {
            setDataNotify(false); // Go back to Notification step
        } else if (dataImpact) {
            setDataImpact(false); // Go back to Impact Change step
        } else if (dataRequire) {
            setDataRequire(false); // Go back to Change Required step
        } else {
            setDataSend(false); // Go back to Change Request step
        }
    };

    //console.log("dataSend", dataSend)
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            fullScreen={fullScreen}
            fullWidth
            maxWidth="sm"
        >
            {!dataSend && <ChangeRequest dataProps={handleRequest} />}

            {dataSend && !dataRequire && <ChangeRequired dataRequired={handleRequired} onBack={handleBackClick} />}

            {dataSend && dataRequire && !dataImpact && <ImpactChange dataImpacted={handleImpact} onBack={handleBackClick} />}

            {dataSend && dataRequire && dataImpact && !dataNotify && <Notification dataNotified={handleNotify} onClose={onClose} onBack={handleBackClick} />}

        </Dialog>
    );
}
export default NewChangeRequest;