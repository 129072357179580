import React, { useState, useEffect } from 'react';
import API from "../Services/API";
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import { Button, Paper, Popover, TextField, Typography } from '@mui/material';
import SuccessDialog from '../Inputs/SuccessDialog';
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { trialsApprovalData, editMocData } from "../recoil/atoms";

const EmailPopup = () => {
    const [emailStatus, setEmailStatus] = useState(null);
    const [error, setError] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('')
    const [anchorEl, setAnchorEl] = useState(null);
    const [trialsApp] = useRecoilState(trialsApprovalData);
    const [isEditMoc] = useRecoilState(editMocData);
    const navigate = useNavigate();

    const access_token = JSON.parse(localStorage.getItem("accesstoken"));
    const mocDetData = JSON.parse(localStorage.getItem("mocDataByID"));
    const userData = JSON.parse(localStorage.getItem("userDatas"));
    const { name } = userData;
    const mocNumberData = localStorage.getItem("mocNumber");

    useEffect(() => {
        if (userData) {
            setMessage(
                `Dear Mr/Miss.${name},
                Greetings,
                MOC request no.${mocNumberData} needs your kind attention.
                Kindly review and update the MOC details with all evidences.
                MOC created by: ${mocDetData?.mocForm?.created_by}`
            );
        }
    }, [userData]);

    const storedAllUserData = JSON.parse(localStorage.getItem("allUsersData"));
    function getEmailsOfQualityHeads(allUsersData) {
        const qualityHeadEmails = [];

        allUsersData.forEach(user => {
            if (user.role === "Quality Head") {
                qualityHeadEmails.push(user.email);
            }
        });

        return qualityHeadEmails;
    }

    // Retrieve quality head emails from stored all users data
    const qualityHeadEmails = getEmailsOfQualityHeads(storedAllUserData);

    function getEmailsOfSafetyHeads(allUsersData) {
        const safetyHeadEmails = [];

        allUsersData.forEach(user => {
            if (user.role === "Safety Head") {
                safetyHeadEmails.push(user.email);
            }
        });

        return safetyHeadEmails;
    }

    // Retrieve quality head emails from stored all users data
    const safetyHeadEmails = getEmailsOfSafetyHeads(storedAllUserData);

    function getEmailsOfPlantHeads(allUsersData) {
        const plantHeadEmails = [];

        allUsersData.forEach(user => {
            if (user.role === "Plant Head") {
                plantHeadEmails.push(user.email);
            }
        });

        return plantHeadEmails;
    }

    // Retrieve quality head emails from stored all users data
    const plantHeadEmails = getEmailsOfPlantHeads(storedAllUserData);

    let from_email = [];
    let to_email = [];
    if (userData) {
        from_email = [userData.email]
        to_email = [];
        if (mocDetData.mocForm.conclusion === "Quality Head") {
            if (!isEditMoc) {
                to_email.push(userData.admin_email);
            }

            else if (isEditMoc && (mocDetData.mocForm.submission_status === "pending" || mocDetData.mocForm.submission_status === "approved") && !mocDetData.mocForm.admin_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && (mocDetData.mocForm.submission_status === "resubmitted") && !mocDetData.mocForm.admin_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.mocForm.submission_status === "rejected" && !mocDetData.mocForm.admin_approval_status) {
                to_email.push(userData.admin_email);
            }

            else if (isEditMoc && (mocDetData.mocForm.submission_status === "rejected") && mocDetData.mocForm.admin_approval_status && !mocDetData.mocForm.quality_approval_status) {
                to_email.push(qualityHeadEmails);
            }

            else if (isEditMoc && (mocDetData.mocForm.submission_status === "approved") && mocDetData.mocForm.admin_approval_status && !mocDetData.trials_id && !mocDetData.implement_id) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && (mocDetData.mocForm.submission_status === "approved") && mocDetData.mocForm.admin_approval_status && mocDetData.mocForm.quality_approval_status && !mocDetData.mocForm.plant_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && (mocDetData.mocForm.submission_status === "resubmitted") && mocDetData.mocForm.admin_approval_status && !mocDetData.mocForm.quality_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && (mocDetData.mocForm.submission_status === "resubmitted") && mocDetData.mocForm.admin_approval_status && mocDetData.mocForm.quality_approval_status && !mocDetData.mocForm.plant_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && (mocDetData.mocForm.submission_status === "rejected") && mocDetData.mocForm.admin_approval_status && mocDetData.mocForm.quality_approval_status && !mocDetData.mocForm.plant_approval_status) {
                to_email.push(plantHeadEmails);
            }

            else if (isEditMoc && (mocDetData.mocForm.submission_status === "approved") && mocDetData.mocForm.admin_approval_status && mocDetData.mocForm.quality_approval_status && mocDetData.mocForm.plant_approval_status && !mocDetData.trials_id && !mocDetData.implement_id) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.trials_id && mocDetData.trials.trials_status === "rejected" && !mocDetData.trials.admin_approval_status) {
                to_email.push(userData.admin_email);
            }

            else if (isEditMoc && mocDetData.trials_id && mocDetData.trials.trials_status === "resubmitted" && !mocDetData.trials.admin_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.trials_id && mocDetData.trials.trials_status === "approved" && mocDetData.trials.admin_approval_status && !mocDetData.implement_id) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.trials_id && (mocDetData.trials.trials_status === "rejected") && mocDetData.trials.admin_approval_status && !mocDetData.trials.quality_approval_status) {
                to_email.push(qualityHeadEmails);
            }

            else if (isEditMoc && mocDetData.trials_id && mocDetData.trials.trials_status === "resubmitted" && mocDetData.trials.admin_approval_status && !mocDetData.trials.quality_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.trials_id && (mocDetData.trials.trials_status === "rejected") && mocDetData.trials.admin_approval_status && mocDetData.trials.quality_approval_status && !mocDetData.trials.plant_approval_status) {
                to_email.push(plantHeadEmails);
            }

            else if (isEditMoc && mocDetData.trials_id && mocDetData.trials.trials_status === "resubmitted" && mocDetData.trials.admin_approval_status && mocDetData.trials.quality_approval_status && !mocDetData.trials.plant_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.implement_id && (mocDetData.changeImplementation.implement_status === "rejected") && !mocDetData.changeImplementation.admin_approval_status) {
                to_email.push(userData.admin_email);
            }

            else if (isEditMoc && mocDetData.implement_id && (mocDetData.changeImplementation.implement_status === "pending") && !mocDetData.changeImplementation.admin_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.implement_id && (mocDetData.changeImplementation.implement_status === "resubmitted") && !mocDetData.changeImplementation.admin_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.implement_id && (mocDetData.changeImplementation.implement_status === "resubmitted") && !mocDetData.changeImplementation.admin_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.implement_id && (mocDetData.changeImplementation.implement_status === "approved") && mocDetData.changeImplementation.admin_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.implement_id && (mocDetData.changeImplementation.implement_status === "rejected") && mocDetData.changeImplementation.admin_approval_status && !mocDetData.changeImplementation.quality_approval_status) {
                to_email.push(qualityHeadEmails);
            }

            else if (isEditMoc && mocDetData.implement_id && (mocDetData.changeImplementation.implement_status === "resubmitted") && mocDetData.changeImplementation.admin_approval_status && !mocDetData.changeImplementation.quality_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.implement_id && (mocDetData.changeImplementation.implement_status === "rejected") && mocDetData.changeImplementation.admin_approval_status && mocDetData.changeImplementation.quality_approval_status && !mocDetData.changeImplementation.plant_approval_status) {
                to_email.push(plantHeadEmails);
            }

            else if (isEditMoc && mocDetData.implement_id && (mocDetData.changeImplementation.implement_status === "resubmitted") && mocDetData.changeImplementation.admin_approval_status && mocDetData.changeImplementation.quality_approval_status && !mocDetData.changeImplementation.plant_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }
        }
        else if (mocDetData.mocForm.conclusion === "Safety Head") {
            if (!isEditMoc) {
                to_email.push(userData.admin_email);
            }

            else if (isEditMoc && (mocDetData.mocForm.submission_status === "pending" || mocDetData.mocForm.submission_status === "approved") && !mocDetData.mocForm.admin_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && (mocDetData.mocForm.submission_status === "resubmitted") && !mocDetData.mocForm.admin_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.mocForm.submission_status === "rejected" && !mocDetData.mocForm.admin_approval_status) {
                to_email.push(userData.admin_email);
            }

            else if (isEditMoc && (mocDetData.mocForm.submission_status === "rejected") && mocDetData.mocForm.admin_approval_status && !mocDetData.mocForm.safety_approval_status) {
                to_email.push(safetyHeadEmails);
            }

            else if (isEditMoc && (mocDetData.mocForm.submission_status === "approved") && mocDetData.mocForm.admin_approval_status && !mocDetData.trials_id && !mocDetData.implement_id) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && (mocDetData.mocForm.submission_status === "approved") && mocDetData.mocForm.admin_approval_status && mocDetData.mocForm.safety_approval_status && !mocDetData.mocForm.plant_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && (mocDetData.mocForm.submission_status === "resubmitted") && mocDetData.mocForm.admin_approval_status && !mocDetData.mocForm.safety_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && (mocDetData.mocForm.submission_status === "resubmitted") && mocDetData.mocForm.admin_approval_status && mocDetData.mocForm.safety_approval_status && !mocDetData.mocForm.plant_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && (mocDetData.mocForm.submission_status === "rejected") && mocDetData.mocForm.admin_approval_status && mocDetData.mocForm.safety_approval_status && !mocDetData.mocForm.plant_approval_status) {
                to_email.push(plantHeadEmails);
            }

            else if (isEditMoc && (mocDetData.mocForm.submission_status === "approved") && mocDetData.mocForm.admin_approval_status && mocDetData.mocForm.safety_approval_status && mocDetData.mocForm.plant_approval_status && !mocDetData.trials_id && !mocDetData.implement_id) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.trials_id && mocDetData.trials.trials_status === "rejected" && !mocDetData.trials.admin_approval_status) {
                to_email.push(userData.admin_email);
            }

            else if (isEditMoc && mocDetData.trials_id && mocDetData.trials.trials_status === "resubmitted" && !mocDetData.trials.admin_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.trials_id && mocDetData.trials.trials_status === "approved" && mocDetData.trials.admin_approval_status && !mocDetData.implement_id) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.trials_id && (mocDetData.trials.trials_status === "rejected") && mocDetData.trials.admin_approval_status && !mocDetData.trials.safety_approval_status) {
                to_email.push(safetyHeadEmails);
            }

            else if (isEditMoc && mocDetData.trials_id && mocDetData.trials.trials_status === "resubmitted" && mocDetData.trials.admin_approval_status && !mocDetData.trials.safety_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.trials_id && (mocDetData.trials.trials_status === "rejected") && mocDetData.trials.admin_approval_status && mocDetData.trials.safety_approval_status && !mocDetData.trials.plant_approval_status) {
                to_email.push(plantHeadEmails);
            }

            else if (isEditMoc && mocDetData.trials_id && mocDetData.trials.trials_status === "resubmitted" && mocDetData.trials.admin_approval_status && mocDetData.trials.safety_approval_status && !mocDetData.trials.plant_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.implement_id && (mocDetData.changeImplementation.implement_status === "rejected") && !mocDetData.changeImplementation.admin_approval_status) {
                to_email.push(userData.admin_email);
            }

            else if (isEditMoc && mocDetData.implement_id && (mocDetData.changeImplementation.implement_status === "pending") && !mocDetData.changeImplementation.admin_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.implement_id && (mocDetData.changeImplementation.implement_status === "resubmitted") && !mocDetData.changeImplementation.admin_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.implement_id && (mocDetData.changeImplementation.implement_status === "resubmitted") && !mocDetData.changeImplementation.admin_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.implement_id && (mocDetData.changeImplementation.implement_status === "approved") && mocDetData.changeImplementation.admin_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.implement_id && (mocDetData.changeImplementation.implement_status === "rejected") && mocDetData.changeImplementation.admin_approval_status && !mocDetData.changeImplementation.safety_approval_status) {
                to_email.push(safetyHeadEmails);
            }

            else if (isEditMoc && mocDetData.implement_id && (mocDetData.changeImplementation.implement_status === "resubmitted") && mocDetData.changeImplementation.admin_approval_status && !mocDetData.changeImplementation.safety_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.implement_id && (mocDetData.changeImplementation.implement_status === "rejected") && mocDetData.changeImplementation.admin_approval_status && mocDetData.changeImplementation.safety_approval_status && !mocDetData.changeImplementation.plant_approval_status) {
                to_email.push(plantHeadEmails);
            }

            else if (isEditMoc && mocDetData.implement_id && (mocDetData.changeImplementation.implement_status === "resubmitted") && mocDetData.changeImplementation.admin_approval_status && mocDetData.changeImplementation.safety_approval_status && !mocDetData.changeImplementation.plant_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }
        }
        else if (mocDetData.mocForm.conclusion === "Non Critical") {
            if (!isEditMoc) {
                to_email.push(userData.admin_email);
            }

            else if (isEditMoc && (mocDetData.mocForm.submission_status === "pending" || mocDetData.mocForm.submission_status === "approved") && !mocDetData.mocForm.admin_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && (mocDetData.mocForm.submission_status === "resubmitted") && !mocDetData.mocForm.admin_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.mocForm.submission_status === "rejected" && !mocDetData.mocForm.admin_approval_status) {
                to_email.push(userData.admin_email);
            }

            else if (isEditMoc && (mocDetData.mocForm.submission_status === "approved") && mocDetData.mocForm.admin_approval_status && !mocDetData.trials_id && !mocDetData.implement_id) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.trials_id && mocDetData.trials.trials_status === "rejected" && !mocDetData.trials.admin_approval_status) {
                to_email.push(userData.admin_email);
            }

            else if (isEditMoc && mocDetData.trials_id && (mocDetData.trials.trials_status === "pending") && !mocDetData.trials.admin_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.trials_id && mocDetData.trials.trials_status === "resubmitted" && !mocDetData.trials.admin_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.trials_id && mocDetData.trials.trials_status === "approved" && mocDetData.trials.admin_approval_status && !mocDetData.implement_id) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.implement_id && (mocDetData.changeImplementation.implement_status === "rejected") && !mocDetData.changeImplementation.admin_approval_status) {
                to_email.push(userData.admin_email);
            }

            else if (isEditMoc && mocDetData.implement_id && (mocDetData.changeImplementation.implement_status === "pending") && !mocDetData.changeImplementation.admin_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.implement_id && (mocDetData.changeImplementation.implement_status === "resubmitted") && !mocDetData.changeImplementation.admin_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.implement_id && (mocDetData.changeImplementation.implement_status === "resubmitted") && !mocDetData.changeImplementation.admin_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.implement_id && (mocDetData.changeImplementation.implement_status === "approved") && mocDetData.changeImplementation.admin_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }
        }
        else if (mocDetData.mocForm.conclusion === "Safety & Quality") {
            if (!isEditMoc) {
                to_email.push(userData.admin_email);
            }

            else if (isEditMoc && (mocDetData.mocForm.submission_status === "pending" || mocDetData.mocForm.submission_status === "approved") && !mocDetData.mocForm.admin_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && (mocDetData.mocForm.submission_status === "resubmitted") && !mocDetData.mocForm.admin_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.mocForm.submission_status === "rejected" && !mocDetData.mocForm.admin_approval_status) {
                to_email.push(userData.admin_email);
            }

            else if (isEditMoc && (mocDetData.mocForm.submission_status === "rejected") && mocDetData.mocForm.admin_approval_status && !mocDetData.mocForm.quality_approval_status) {
                to_email.push(qualityHeadEmails);
            }

            else if (isEditMoc && (mocDetData.mocForm.submission_status === "approved") && mocDetData.mocForm.admin_approval_status && !mocDetData.trials_id && !mocDetData.implement_id) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && (mocDetData.mocForm.submission_status === "approved") && mocDetData.mocForm.admin_approval_status && mocDetData.mocForm.quality_approval_status && !mocDetData.mocForm.plant_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && (mocDetData.mocForm.submission_status === "resubmitted") && mocDetData.mocForm.admin_approval_status && !mocDetData.mocForm.quality_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && (mocDetData.mocForm.submission_status === "resubmitted") && mocDetData.mocForm.admin_approval_status && mocDetData.mocForm.quality_approval_status && !mocDetData.mocForm.plant_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && (mocDetData.mocForm.submission_status === "rejected") && mocDetData.mocForm.admin_approval_status && mocDetData.mocForm.quality_approval_status && !mocDetData.mocForm.safety_approval_status && !mocDetData.mocForm.plant_approval_status) {
                to_email.push(safetyHeadEmails);
            }

            else if (isEditMoc && (mocDetData.mocForm.submission_status === "rejected") && mocDetData.mocForm.admin_approval_status && mocDetData.mocForm.quality_approval_status && mocDetData.mocForm.safety_approval_status && !mocDetData.mocForm.plant_approval_status) {
                to_email.push(plantHeadEmails);
            }

            else if (isEditMoc && (mocDetData.mocForm.submission_status === "approved") && mocDetData.mocForm.admin_approval_status && mocDetData.mocForm.quality_approval_status && mocDetData.mocForm.safety_approval_status && mocDetData.mocForm.plant_approval_status && !mocDetData.trials_id && !mocDetData.implement_id) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            //2nd

            else if (isEditMoc && mocDetData.trials_id && mocDetData.trials.trials_status === "rejected" && !mocDetData.trials.admin_approval_status) {
                to_email.push(userData.admin_email);
            }

            else if (isEditMoc && mocDetData.trials_id && mocDetData.trials.trials_status === "resubmitted" && !mocDetData.trials.admin_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.trials_id && mocDetData.trials.trials_status === "approved" && mocDetData.trials.admin_approval_status && !mocDetData.implement_id) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.trials_id && (mocDetData.trials.trials_status === "rejected") && mocDetData.trials.admin_approval_status && !mocDetData.trials.quality_approval_status) {
                to_email.push(qualityHeadEmails);
            }

            else if (isEditMoc && mocDetData.trials_id && mocDetData.trials.trials_status === "resubmitted" && mocDetData.trials.admin_approval_status && !mocDetData.trials.quality_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.trials_id && (mocDetData.trials.trials_status === "rejected") && mocDetData.trials.admin_approval_status && mocDetData.trials.quality_approval_status && !mocDetData.trials.safety_approval_status && !mocDetData.trials.plant_approval_status) {
                to_email.push(safetyHeadEmails);
            }

            else if (isEditMoc && mocDetData.trials_id && (mocDetData.trials.trials_status === "rejected") && mocDetData.trials.admin_approval_status && mocDetData.trials.quality_approval_status && mocDetData.trials.safety_approval_status && !mocDetData.trials.plant_approval_status) {
                to_email.push(plantHeadEmails);
            }

            else if (isEditMoc && mocDetData.trials_id && mocDetData.trials.trials_status === "resubmitted" && mocDetData.trials.admin_approval_status && mocDetData.trials.quality_approval_status && !mocDetData.trials.plant_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.implement_id && (mocDetData.changeImplementation.implement_status === "rejected") && !mocDetData.changeImplementation.admin_approval_status) {
                to_email.push(userData.admin_email);
            }

            else if (isEditMoc && mocDetData.implement_id && (mocDetData.changeImplementation.implement_status === "pending") && !mocDetData.changeImplementation.admin_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.implement_id && (mocDetData.changeImplementation.implement_status === "resubmitted") && !mocDetData.changeImplementation.admin_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.implement_id && (mocDetData.changeImplementation.implement_status === "resubmitted") && !mocDetData.changeImplementation.admin_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.implement_id && (mocDetData.changeImplementation.implement_status === "approved") && mocDetData.changeImplementation.admin_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.implement_id && (mocDetData.changeImplementation.implement_status === "rejected") && mocDetData.changeImplementation.admin_approval_status && !mocDetData.changeImplementation.quality_approval_status) {
                to_email.push(qualityHeadEmails);
            }

            else if (isEditMoc && mocDetData.implement_id && (mocDetData.changeImplementation.implement_status === "resubmitted") && mocDetData.changeImplementation.admin_approval_status && !mocDetData.changeImplementation.quality_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }

            else if (isEditMoc && mocDetData.implement_id && (mocDetData.changeImplementation.implement_status === "rejected") && mocDetData.changeImplementation.admin_approval_status && mocDetData.changeImplementation.quality_approval_status && !mocDetData.changeImplementation.safety_approval_status && !mocDetData.changeImplementation.plant_approval_status) {
                to_email.push(safetyHeadEmails);
            }

            else if (isEditMoc && mocDetData.implement_id && (mocDetData.changeImplementation.implement_status === "rejected") && mocDetData.changeImplementation.admin_approval_status && mocDetData.changeImplementation.quality_approval_status && !mocDetData.changeImplementation.plant_approval_status) {
                to_email.push(plantHeadEmails);
            }

            else if (isEditMoc && mocDetData.implement_id && (mocDetData.changeImplementation.implement_status === "resubmitted") && mocDetData.changeImplementation.admin_approval_status && mocDetData.changeImplementation.quality_approval_status && !mocDetData.changeImplementation.plant_approval_status) {
                to_email.push(mocDetData?.mocForm.createdby_email);
            }
        }
    }
    else {
        //console.log('userData is empty');
    }

    const handleClick = (event) => {
        if (event?.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleMessageInput = (event) => {
        setMessage(event.target.value);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setError(null);
    };

    const handleCancel = () => {
        setAnchorEl(null);
        setError(null);
    };

    const onHandleClosed = () => {
        setShowDialog(false);
    }

    const insertReportStatus = async () => {
        const mocID = localStorage.getItem("mocID");
        try {
            const emailFieldsData = {
                from_email: from_email,
                to_email: to_email,
                message: 'hi',
                moc_id: mocID,
            };
            const response = await axios.post(API.INSERT_EMAIL_REPORT, emailFieldsData, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
            if (response.status === 200) {
                const sendMailID = response.data["identifiers"][0].id;
                localStorage.setItem("sendMailID", sendMailID);
                await sendEmail(from_email, to_email, message);
                handleClose();
            }
        } catch (error) {
            //console.log(error);
        }
    };


    const handleSend = async () => {
        if ((!from_email && !to_email) || !message) {
            setError('Email and message are required');
            return;
        }
        try {
            await insertReportStatus(from_email, to_email, message);
            const updateMocDet = localStorage.getItem("mocID");
            const storedUserData = JSON.parse(localStorage.getItem("userDatas"));

            const approverSGID = storedUserData.N1SGID;
            const userSGID = updateMocDet ? storedUserData.SGID : "";
            const mocApprovalID = localStorage.getItem("mocApprovalID");
            const userAppName = updateMocDet ? storedUserData.FName + " " + storedUserData.LName : "";
            const sendMailID = localStorage.getItem("sendMailID");

            const mocData = {
                id: mocApprovalID,
                moc_id: updateMocDet,
                approver_sgid: approverSGID,
                user_sgid: userSGID,
                user_approval_name: userAppName,
                send_mail_id: sendMailID,
            };
            const mocResponse = await axios.put(`${API.UPDATE_MOC_APPROVALS}?id=${mocApprovalID}`, mocData, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });

            if (mocResponse.status === 200) {
                // setSave(true);
                // setShowSuccessPopup(true);
            }
        } catch (error) {
            console.error("An error occurred in handleSend:", error);
            // Handle error state or display error message to the user
        }
    };

    const sendEmail = async (from_email, to_email, message) => {
        try {
            const emailParameters = {
                from_email: from_email,
                to_email: to_email,
                subject: 'MOC',
                message: message,
            }
            const response = await axios.post(API.SEND_MAIL_API, emailParameters, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
            setEmailStatus(response.data.message);
            handleClose();
            navigate("/ManageMoc");
        } catch (error) {
            setEmailStatus('Failed to send email');
            console.error(error);
        }
    };


    useEffect(() => {
        const event = { currentTarget: document.body }; // Create a synthetic event object
        handleClick(event); // Automatically open the popover when the component mounts
    }, []);

    const popoverContent = (
        <div>
            <SuccessDialog open={showDialog} onClose={onHandleClosed} msg={"Email sent successfully"} />
            {isLoading && (
                <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                    <CircularProgress />
                </div>
            )}
            {error && (
                <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                    <div>Error: {error}</div>
                </div>
            )}
            <div style={{ padding: 16, width: '100vw', boxSizing: 'border-box' }}>
                <div style={{ display: 'flex-start', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography style={{ fontSize: 18, fontWeight: 600 }}>MOC</Typography>
                </div>

                <div>
                    <TextField
                        style={{ marginTop: 20, textAlign: 'left', width: '470px' }}
                        id="outlined-read-only-input"
                        label="From E-Mail ID"
                        value={from_email}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
                <div>
                    <TextField
                        style={{ marginTop: 20, textAlign: 'left', width: '470px' }}
                        id="outlined-read-only-input"
                        label="To E-Mail ID"
                        value={to_email}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
                    <TextField
                        id="message"
                        label="Message"
                        variant="outlined"
                        size="normal"
                        style={{ width: '470px', flex: '0.5' }}
                        InputLabelProps={{
                            shrink: true,
                            style: { fontSize: '18px' },
                        }}
                        value={message}
                        // onChange={handleMessageInput}
                        onChange={(event) => setMessage(event.target.value)}
                        multiline={true}
                        rows={5}
                    ></TextField>
                </div>

                <div style={{ display: 'flex', width: '470px', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
                    <Button
                        variant="outlined"
                        style={{
                            borderRadius: '7px',
                            marginRight: 20,
                            flex: 1,
                            borderWidth: '3px',
                            border: '2px solid #00448B',
                            fontSize: 20,
                            color: '#00448B',
                            marginTop: '10px',
                            textTransform: 'none',
                            height: '45px',
                        }}
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>

                    <Button
                        variant="contained"
                        style={{
                            borderRadius: '7px',
                            flex: 1,
                            backgroundColor: '#00448B',
                            fontSize: 20,
                            color: 'white',
                            marginTop: '10px',
                            textTransform: 'none',
                            height: '45px',
                        }}
                        onClick={handleSend}
                    >
                        Send
                    </Button>
                </div>
            </div>
        </div>
    );

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={{ top: window.innerHeight / 2, left: window.innerWidth / 2 }} // Center of the screen
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
            >
                <Paper style={{ width: '500px' }}>{popoverContent}</Paper>
            </Popover>
        </div>
    );

};

export default EmailPopup;