import configParam from "../config";

const API = {
  //Login
  LOGIN: configParam.API_URL + "login",
  USER_DETAILS: configParam.API_URL + "users/:userID",
  // Request Form
  INSERT_REQUEST_FORM: configParam.API_URL + "insert_request_form",
  GET_REQUEST_FORM: configParam.API_URL + "get_request_form",
  UPDATE_REQUEST_FORM: configParam.API_URL + "update_request_form",
  GET_DEPARTMENT: configParam.API_URL + "get_department",
  // Questions
  GET_QUESTIONS: configParam.API_URL + "get_questions",
  // Assessment
  INSERT_ASSESSMENT: configParam.API_URL + "insert_assessment",
  UPDATE_ASSESSMENT: configParam.API_URL + "update_assessment",
  GET_ASSESSMENT: configParam.API_URL + "get_assessment",
  // EHSF
  UPDATE_EHSF: configParam.API_URL + "update_EHSF",
  // MOC
  INSERT_MOC_FORM: configParam.API_URL + "insert_moc_form",
  GET_ALL_MOCS: configParam.API_URL + "get_all_mocs",
  GET_MOC_ID: configParam.API_URL + "get_moc_id",
  GET_MOC_FORM: configParam.API_URL + "get_moc_form",
  UPDATE_MOC_FORM: configParam.API_URL + "update_moc_form",
  GET_LATEST_MOC: configParam.API_URL + "get_latest_moc",
  // Moc Approvals
  INSERT_MOC_APPROVALS: configParam.API_URL + "insert_moc_approvals",
  UPDATE_MOC_APPROVALS: configParam.API_URL + "update_moc_approvals",
  GET_ALL_MOC_FOR_APPROVALS: configParam.API_URL + "get_all_moc_for_approvals",
  EDIT_APPROVAL_MOC: configParam.API_URL + "edit_approval_moc",
  GET_APPROVAL_MOC_BY_ID: configParam.API_URL + "get_approvalData_byID",
  GET_APPROVAL_BYMOCID: configParam.API_URL + "get_approvalData_byMOCID",
  DELETE_MOC: configParam.API_URL + "delete_moc",
  GET_ALL_MOC_APPROVALS_NOTIFY: configParam.API_URL + "get_all_moc_approvals_notify",
  // Email
  SEND_MAIL_API: configParam.API_URL + "send_mail_api",
  INSERT_EMAIL_REPORT: configParam.API_URL + "insert_email_report",
  // Action Plan
  INSERT_ACTION_PLAN: configParam.API_URL + "insert_action_plan",
  UPDATE_ACTION_PLAN: configParam.API_URL + "update_action_plan",
  GET_ACTION_PLAN_BY_ID: configParam.API_URL + "get_action_Plan_byID",
  // Trials
  UPLOAD_IMAGES: configParam.API_URL + "upload_images",
  UPLOAD_DOCUMENTS: configParam.API_URL + "upload_documents",
  DOWNLOAD_DOCUMENTS: configParam.API_URL + "download_documents",
  DOWNLOAD_ALL_DOCUMENTS: configParam.API_URL + "download_all_documents",
  GET_IMAGES: configParam.API_URL + "get_images",
  INSERT_TRIALS: configParam.API_URL + "insert_trials",
  UPDATE_TRIALS: configParam.API_URL + "update_trials",
  GET_TRIALS: configParam.API_URL + "get_trials",
  EDIT_BEFORE_IMAGE: configParam.API_URL + "edit_before_images",
  // Documents
  INSERT_DOCUMENT: configParam.API_URL + "insert_document",
  UPDATE_DOCUMENT: configParam.API_URL + "update_document",
  GET_DOCUMENTS_BY_ID: configParam.API_URL + "get_documents_byID",
  UPLOAD_DOCUMENT: configParam.API_URL + "upload_document",
  // Change Implement
  INSERT_CHANGE_IMPLEMENT: configParam.API_URL + "insert_change_implement",
  UPDATE_CHANGE_IMPLEMENT: configParam.API_URL + "update_change_implement",
  GET_CHANGE_IMPLEMENT: configParam.API_URL + "get_change_implement",
  // User Management
  INSERT_USER_FORM: configParam.API_URL + "insert_user_form",
  GET_ALL_USERS: configParam.API_URL + "get_all_users",
  GET_USER_BYID: configParam.API_URL + "get_user_byID",
  UPDATE_USER: configParam.API_URL + "update_user",
  DELETE_USER: configParam.API_URL + "delete_user",
  SHOW_IMAGES: configParam.API_URL + "show_images",
  SHOW_PDF: configParam.API_URL + "get_completed_pdf",
  CHECK_API: configParam.API_URL + "check_api",

  CHECK_USER_SGID: configParam.API_URL + "check_user_sgid",

  // GET_USERS: configParam.API_URL + "users",
  // CHECK_USER: configParam.API_URL + "checkUser",
  // Trials Approval
  // INSERT_TRIAL_APPROVAL: configParam.API_URL + "insert_trial_approval",
  // UPDATE_TRIAL_APPROVAL: configParam.API_URL + "update_trial_approval",
  // GET_TRIAL_APPROVAL: configParam.API_URL + "get_trial_approval",


  // 4M - Change Management System
  // Change Request
  INSERT_CHANGE_REQUEST: configParam.API_URL + "insert_change_request",
  GET_CMS_REQUEST_FORM: configParam.API_URL + "get_cms_request_form",
  UPDATE_CHANGE_REQUEST: configParam.API_URL + "update_change_request",
  // Change Required
  INSERT_CHANGE_REQUIRED: configParam.API_URL + "insert_change_required",
  GET_CMS_CHANGE_REQUIRED: configParam.API_URL + "get_cms_change_required",
  UPDATE_CHANGE_REQUIRED: configParam.API_URL + "update_change_required",
  CHANGE_REQUIRED_UPLOAD_IMAGES: configParam.API_URL + "change_required_upload_images",
  UPLOAD_DOCUMENTS_CHANGE: configParam.API_URL + "upload_documents_change",
  DOWNLOAD_DOCUMENTS_CHANGE: configParam.API_URL + "download_documents_change",
  SHOW_IMAGES_CLICK: configParam.API_URL + "show_images_click",
  // Impact Change
  INSERT_IMPACT_CHANGE: configParam.API_URL + "insert_impact_change",
  GET_CMS_IMPACT_CHANGE: configParam.API_URL + "get_cms_impact_change",
  UPDATE_IMPACT_CHANGE: configParam.API_URL + "update_impact_change",
  UPLOAD_DOCUMENTS_IMPACT: configParam.API_URL + "upload_documents_impact",
  DOWNLOAD_DOCUMENTS_IMPACT: configParam.API_URL + "download_documents_impact",
  // Notification Header
  INSERT_NOTIFICATION_HEADER: configParam.API_URL + "insert_notification_header",
  UPDATE_NOTIFICATION_HEADER: configParam.API_URL + "update_notification_header",
  // Notification
  INSERT_NOTIFICATION: configParam.API_URL + "insert_notification",
  GET_NOTIFICATION: configParam.API_URL + "get_notification",
  UPDATE_NOTIFICATION: configParam.API_URL + "update_notification",
  GET_EMP_DEPT_DETAILS: configParam.API_URL + "get_emp_dept_details",
  // Alternation
  INSERT_ALTERNATION: configParam.API_URL + "insert_alternation",
  GET_CMS_ALTERNATION: configParam.API_URL + "get_cms_alternation",
  UPDATE_ALTERNATION: configParam.API_URL + "update_alternation",
  UPLOAD_DOCUMENTS_ALTER: configParam.API_URL + "upload_documents_alter",
  DOWNLOAD_DOCUMENTS_ALTER: configParam.API_URL + "download_documents_alter",
  // CMS
  INSERT_CMS_FORM: configParam.API_URL + "insert_cms_form",
  UPDATE_CMS_FORM: configParam.API_URL + "update_cms_form",
  GET_LATEST_CMS: configParam.API_URL + "get_latest_cms",
  // CMS Approvals
  INSERT_CMS_APPROVALS: configParam.API_URL + "insert_cms_approvals",
  UPDATE_CMS_APPROVALS: configParam.API_URL + "update_cms_approvals",
  GET_ALL_CMS_FOR_APPROVALS: configParam.API_URL + "get_all_cms_for_approvals",
  GET_APPROVAL_CMS_BY_ID: configParam.API_URL + "get_cms_approvalData_byID",
  EDIT_APPROVAL_CMS: configParam.API_URL + "edit_approval_cms",
  GET_APPROVAL_BYCMSID: configParam.API_URL + "get_approvalData_byCmsID",
  GET_ALL_CMS_APPROVALS_NOTIFY: configParam.API_URL + "get_all_cms_approvals_notify",
  DELETE_CMS: configParam.API_URL + "delete_cms",
  SHOW_REPORT_PDF: configParam.API_URL + "get_report_pdf",
  // Email
  SEND_MAIL_API_CMS: configParam.API_URL + "send_mail_api_cms",
  INSERT_EMAIL_REPORT_CMS: configParam.API_URL + "insert_email_report_cms",
  // CMS Const Questions
  GET_IMPACT_CMS_QUESTIONS: configParam.API_URL + "get_impact_cms_questions",
  GET_ALTERNATION_CMS_QUESTIONS: configParam.API_URL + "get_alternation_cms_questions",
  INSERT_IMPACT_CMS_QUESTIONS: configParam.API_URL + "insert_impact_cms_questions",
  INSERT_ALTER_CMS_QUESTIONS: configParam.API_URL + "insert_alter_cms_questions",
  // CMS Master Data
  GET_CMS_MASTER_DATA: configParam.API_URL + "get_cms_master_data",
  INSERT_CMS_MASTER_DATA: configParam.API_URL + "insert_cms_master_data",

};

export default API;