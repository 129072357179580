import { atom } from "recoil";

export const requestFormData = atom({
    key: "requestFormData",
    default: {
        moc_date: new Date().toISOString(),
        moc_no: "",
        requisition_by: "",
        department: "",
        department_id: "",
        change_type: "",
        brief_description: "",
        classification: "",
        particulars: "",
        current_status: "",
        change_required: "",
        reason_change_required: ""
    }
});

export const changeAssessmentData = atom({
    key: "changeAssessmentData",
    default: {
        change_affects: [],
        results: Array(1).fill("#B51849"),
        checkpoints_remarks: Array(1).fill(""),
        environment_results: Array(1).fill(""),
        health_results: Array(1).fill(""),
        safety_results: Array(1).fill(""),
        fire_results: Array(1).fill(""),
        environment_remarks: Array(1).fill(""),
        health_remarks: Array(1).fill(""),
        safety_remarks: Array(1).fill(""),
        fire_remarks: Array(1).fill(""),
    }
});

export const conclusionData = atom({
    key: "conclusionData",
    default: {
        conclusion: "",
        admin_remarks: "",
        submission_status: "",
        moc_no: "",
        quality_name: "",
        quality_remarks: "",
        plant_check: "",
        plant_name: "",
        plant_remarks: "",
        safety_name: "",
        safety_remarks: "",
        cft_team: ""
    }
});

export const getMocDataID = atom({
    key: "getMocDataID",
    default: {
        request_form_id: "",
        assessment_id: "",
        actionplan_id: "",
        trials_id: "",
        trialsapproval_id: ""
    }
});

export const editMocData = atom({
    key: "editMocData",
    default: false,
});

export const editehsfData = atom({
    key: "editehsfData",
    default: false,
});

export const editUserDatastatus = atom({
    key: "editUserDatastatus",
    default: false,
});

export const actionPlanData = atom({
    key: "actionPlanData",
    default: Array(12).fill(null).map(() => ({
        // descriptions: "",
        action_required: "",
        target_date: "",
        complete_date: "",
        responsibility: "",
        doc_reference: [],
    }))
});

export const trialsData = atom({
    key: "trialsData",
    default: {
        before_comments: "",
        after_comments: "",
        admin_remarks: "",
        quality_remarks: "",
        safety_remarks: "",
        plant_remarks: "",
        trials_status: "",
        plant_check: ""
    }
});

export const documentData = atom({
    key: "documentData",
    default: Array(10).fill(null).map(() => ({
        descriptions: "",
        applicability: "",
        target_date: "",
        complete_date: "",
        status: "",
        doc_reference: [],
    }))
});

export const userData = atom({
    key: "userData",
    default: {
        FName: "",
        LName: "",
        SGID: "",
        MailId: "",
        phone_number: "",
        plant: "",
        role: "",
        admin_sgid: "",
        admin_name: "",
        admin_email: "",
        name: ""
    }
});

export const trialsApprovalData = atom({
    key: "trialsApprovalData",
    default: {
        admin_remarks: "",
        quality_remarks: "",
        safety_remarks: "",
        trials_status: ""
    }
});

export const implementAppData = atom({
    key: "implementAppData",
    default: {
        implement_remarks: "",
        customer_approval: "",
        product_compliance_check: "",
        product_compliance: "",
        admin_remarks: "",
        quality_remarks: "",
        plant_remarks: "",
        safety_remarks: "",
        implement_status: "",
        plant_check: ""
    }
});

export const locationData = atom({
    key: "locationData",
    default: {
        locationName: ''
    }
});



// 4M - Change Management System
export const changeRequestData = atom({
    key: "changeRequestData",
    default: {
        date: "",
        request_initiator: "",
        department: "",
        cms_no: "",
        type_design_change: "",
        plant: "",
        change_category: "",
        design_change: "",
        process_change: "",
        design_int_customer: "",
        design_int_model: "",
        design_int_glass_reference: "",
        design_int_part_number_rev_no: "",
        design_int_part_number: "",
        design_int_part_name: "",
        design_int_drawing_no_rev_no: "",
        design_int_drawing_number: "",
        design_int_process_reference: "",
        design_ext_customer: "",
        design_ext_model: "",
        design_ext_glass_reference: "",
        design_ext_part_number_rev_no: "",
        design_ext_part_number: "",
        design_ext_part_name: "",
        design_ext_drawing_no_rev_no: "",
        design_ext_drawing_number: "",
        design_ext_process_reference: "",
        process_int_customer: "",
        process_int_model: "",
        process_int_glass_reference: "",
        process_int_part_number: "",
        process_int_part_number_rev_no: "",
        process_int_part_name: "",
        process_int_drawing_no_rev_no: "",
        process_int_drawing_number: "",
        process_int_process_reference: "",
        process_ext_customer: "",
        process_ext_model: "",
        process_ext_glass_reference: "",
        process_ext_part_number_rev_no: "",
        process_ext_part_number: "",
        process_ext_part_name: "",
        process_ext_drawing_no_rev_no: "",
        process_ext_drawing_number: "",
        process_ext_process_reference: "",
        des_change_required: "",
        other_comments: "",
        receiveing_customer_date: "",
        customer_req_imple_date: "",
        feas_con_customer_date: "",
        remarks: "",
    }
});

export const changeReqData = atom({
    key: "changeReqData",
    default: {
        change_required_remarks: "",
        change_request_remarks: ""
    }
});

export const impactChangeData = atom({
    key: "impactChangeData",
    default: {
        affects: "",
        actions: "",
        responsibility: "",
        date: "",
        remarks: "",
        document: []
    }
});

export const alternationData = atom({
    key: "alternationData",
    default: {
        affects: "",
        from: "",
        to: "",
        date: "",
        stock_disposal: "",
        remarks: "",
        document: [],
        alternation_date: "",
        implementation_date: "",
        implementation_status: "Approved",
        final_comments: ""
    }
});

export const editCMSData = atom({
    key: "editCMSData",
    default: false,
});

export const getCmsDataID = atom({
    key: "getCmsDataID",
    default: {
        change_request_id: "",
        change_required_id: "",
        impact_change_id: "",
        notification_id: "",
        alternation_note_id: ""
    }
});

export const notification = atom({
    key: "notification",
    default: {
        approver_mail_id: "",
        approver_sgid: "",
        approver_comments: "",
        approval_status: "",
        functional_mail_id: "",
        functional_sgid: "",
        functional_comments: "",
        functional_status: ""
    }
});

export const masterData = atom({
    key: "masterData",
    default: {
        department: "",
        customer: "",
        model: "",
        glass_reference: "",
        plant: "",
    },
});

export const questionsData = atom({
    key: "questionsData",
    default: {
        impact: "",
        alternation: "",
    },
});