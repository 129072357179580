import React from 'react';
import { useMediaQuery } from '@mui/material';
import ManageCmsDesktop from './ManageCmsDesktop';
import ManageCmsMobile from './ManageCmsMobile';

const ManageCms = () => {

    const isMobile = useMediaQuery('(max-width:1024px)');
    return (
        <>
            {isMobile ? (
                <ManageCmsMobile />
            ) : (
                <ManageCmsDesktop />
            )}
        </>
    );
}

export default ManageCms;