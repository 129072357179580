import React, { useState, useEffect } from "react";
import { Typography, InputLabel, Divider, TextField, MenuItem, Button, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Box, } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Header from "../app/TopAppBar";
import { useRecoilState } from "recoil";
import { requestFormData, editMocData } from "../recoil/atoms";
import axios from "axios";
import API from "../Services/API";
import "../index.css";
import configParam from "../config";
import ChangeAssessment from "./ChangeAssessment";

const changeType = configParam.CHANGE_TYPES;
const classification = configParam.CLASSIFICATION;
const RequestForm = ({ dataProps }) => {
  const [newDialogRequestOpen, setNewDialogRequestOpen] = useState('true');
  const [requestForm, setRequestForm] = useRecoilState(requestFormData);
  const [saveRequest, setSaveRequest] = useState('requestForm');
  const [isEditMoc] = useRecoilState(editMocData);
  const [departmentError, setDepartmentError] = useState("");
  const [errors, setErrors] = useState(false);
  const [stepError, setStepError] = useState(false);
  const [save, setSave] = useState(false);
  const [saveMessage, setSaveMessage] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [mocsData, setMocsData] = useState([]);
  const [initialLoad, setInitialLoad] = useState(false);
  const [functions, setFunctions] = useState([]);
  const [functionCode, setFunctionCode] = useState([]);
  const [closeDialog, setcloseDialog] = useState();
  const navigate = useNavigate();

  const access_token = JSON.parse(localStorage.getItem("accesstoken"));

  useEffect(() => {
    const handlePopup = (setter, delay) => {
      setter(true);
      const timer = setTimeout(() => {
        setter(false);
      }, delay);
      return () => clearTimeout(timer);
    };
    const handlePopups = (showPopup, setShowPopup) => {
      if (showPopup) {
        setInitialLoad(true);
        setTimeout(() => {
          setInitialLoad(false);
          handlePopup(setShowPopup, 1000);
        }, 2000);
      }
    };
    handlePopups(showSuccessPopup, setShowSuccessPopup);
    handlePopups(showUpdatePopup, setShowUpdatePopup);
  }, [showSuccessPopup, showUpdatePopup]);

  const handleRequestSaveClick = async () => {
    const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
    const userFName = storedUserData.FName + " " + storedUserData.LName;
    try {
      if (
        !requestForm.department ||
        !requestForm.change_type ||
        !requestForm.brief_description ||
        !requestForm.classification ||
        !requestForm.particulars ||
        !requestForm.current_status ||
        !requestForm.change_required ||
        !requestForm.reason_change_required
      ) {
        setErrors(true);
        setStepError(true);
        setSaveMessage(false);
        return;
      } else {
        setErrors(false);
        setStepError(false);
        setSaveMessage(false);
      }
      const requestData = {
        moc_date: requestForm.moc_date,
        requisition_by: userFName,
        department: requestForm.department,
        department_id: functionCode,
        change_type: requestForm.change_type,
        brief_description: requestForm.brief_description,
        classification: requestForm.classification,
        particulars: requestForm.particulars,
        current_status: requestForm.current_status,
        change_required: requestForm.change_required,
        reason_change_required: requestForm.reason_change_required,
      };
      const response = await axios.post(API.INSERT_REQUEST_FORM, requestData, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });
      if (response.status === 200) {
        const requestformID = response.data.identifiers[0].id;
        localStorage.setItem("requestformID", requestformID);
        localStorage.setItem("isRequestSave", true);
        await mocForm(requestformID);
        setSave(true);
        setShowSuccessPopup(true);
      }
    } catch (error) {
      console.error("An error occurred in update trials:", error);
    }
  };

  const mocForm = async (requestId) => {
    const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
    const mocID = localStorage.getItem("mocID");
    const userSGID = storedUserData.SGID;
    const userName = storedUserData.FName + " " + storedUserData.LName;
    try {
      const mocData = {
        user_id: userSGID,
        updated_at: new Date(),
        created_by: userName,
        doc_ref: "SGI-IMS-FOR-19",
        rev_no: "001",
        rev_date: new Date(),
        request_form_id: requestId,
        submission_status: requestId ? "pending" : "",
      };
      const mocResponse = await axios.post(API.INSERT_MOC_FORM, mocData, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });
      if (mocResponse.status === 200) {
        const mocID = mocResponse.data["identifiers"][0].id;
        localStorage.setItem("mocID", mocID);
        localStorage.setItem("mocData", JSON.stringify(mocData));
        await insertMocApprovals(mocID);
      }
    } catch (error) {
      console.error("An error occurred in insertIntoMocTable:", error);
    }
  };

  const insertMocApprovals = async () => {
    const updateMocDet = localStorage.getItem("mocID");
    const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
    const approverSGID = storedUserData.N1SGID;
    const userSGID = updateMocDet ? storedUserData.SGID : "";
    const userAppName = updateMocDet ? storedUserData.FName + " " + storedUserData.LName : "";
    try {
      const mocData = {
        moc_id: updateMocDet,
        approver_sgid: approverSGID,
        user_sgid: userSGID,
        user_approval_name: userAppName,
        status: "pending",
      };
      const mocResponse = await axios.post(API.INSERT_MOC_APPROVALS, mocData, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });
      if (mocResponse.status === 200) {
        const mocApprovalID = mocResponse.data["identifiers"][0].id;
        localStorage.setItem("mocApprovalID", mocApprovalID);
      }
    } catch (error) {
      console.error("An error occurred in insertIntoMocTable:", error);
    }
  };

  useEffect(() => {
    if (isEditMoc) {
      const getRequestForm = async () => {
        const storedMocDataString = localStorage.getItem("mocDataByID");
        const storedMocData = JSON.parse(storedMocDataString);
        const requestFormID = storedMocData.mocForm.request_form_id;
        try {
          const response = await axios.get(
            `${API.GET_REQUEST_FORM}?id=${requestFormID}`,
            {
              headers: {
                Authorization: `Bearer ${access_token}`
              }
            }
          );
          const requestFormDetails = await response.data;
          setRequestForm(requestFormDetails);
        } catch (error) {
          console.error("Error fetching MOC details:", error);
        }
      };
      getRequestForm();
    }
  }, [setRequestForm, isEditMoc]);

  useEffect(() => {
    const getFunction = async () => {
      try {
        const response = await axios.get(API.GET_DEPARTMENT, {
          headers: {
            Authorization: `Bearer ${access_token}`

          }
        });
        const requestFormDetails = await response.data;
        setFunctions(requestFormDetails);
        const departmentFunId = requestFormDetails.map((depart) => depart.id);
        setFunctionCode(departmentFunId);
      } catch (error) {
        console.error("Error fetching MOC details:", error);
      }
    };
    getFunction();
  }, []);

  const handleRequestUpdateClick = async () => {
    const storedMocDataString = localStorage.getItem("mocDataByID");
    const storedMocData = JSON.parse(storedMocDataString);
    const requestFormID = storedMocData?.mocForm?.request_form_id;
    const userRequisitionBy = storedMocData?.mocForm?.created_by;
    try {
      if (
        !requestForm.department ||
        !requestForm.change_type ||
        !requestForm.brief_description ||
        !requestForm.classification ||
        !requestForm.particulars ||
        !requestForm.current_status ||
        !requestForm.change_required ||
        !requestForm.reason_change_required
      ) {
        setErrors(true);
        setStepError(true);
        setSaveMessage(false);
        return;
      } else {
        setErrors(false);
        setStepError(false);
        setSaveMessage(false);
      }
      const updateRequestData = {
        moc_date: requestForm.moc_date,
        requisition_by: userRequisitionBy,
        department: requestForm.department,
        change_type: requestForm.change_type,
        brief_description: requestForm.brief_description,
        classification: requestForm.classification,
        particulars: requestForm.particulars,
        current_status: requestForm.current_status,
        change_required: requestForm.change_required,
        reason_change_required: requestForm.reason_change_required,
      };
      const response = await axios.put(
        `${API.UPDATE_REQUEST_FORM}?id=${requestFormID}`,
        updateRequestData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        }
      );
      if (response.status === 200) {
        setSave(true);
        setShowUpdatePopup(true);
      }
    } catch (error) {
      console.error("An error occurred in update trials:", error);
    }
  };

  const handleRequestNextClick = () => {
    try {
      const isRequestSave = JSON.parse(localStorage.getItem("isRequestSave"));
      if (
        !requestForm.department ||
        !requestForm.change_type ||
        !requestForm.brief_description ||
        !requestForm.classification ||
        !requestForm.particulars ||
        !requestForm.current_status ||
        !requestForm.change_required ||
        !requestForm.reason_change_required
      ) {
        setErrors(true);
        setStepError(true);
        setSaveMessage(false);
        return;
      } else {
        setErrors(false);
        setStepError(false);
        setSaveMessage(false);
      }
      if (save || isRequestSave || isEditMoc) {
        // navigate("/ChangeAssessment");
        dataProps(newDialogRequestOpen);
        setSaveRequest('ChangeAssessment');
        //console.log("newDialogRequestOpen", newDialogRequestOpen);
        //console.log("dataProps", dataProps);
      } else {
        setSaveMessage("Failed to save. Please try again.");
      }
    } catch (error) {
      console.error("An error occurred in update trials:", error);
    }
  };

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    let updatedForm = { ...requestForm };
    if (!updatedForm.moc_date) {
      updatedForm["moc_date"] = today;
      setRequestForm(updatedForm);
    }
  }, [requestForm, setRequestForm]);

  const handleDateChange = (event) => {
    const inputDate = event.target.value;
    const selectedDate = new Date(inputDate);
    const today = new Date().toISOString().split("T")[0];
    let updatedForm = { ...requestForm };
    if (!isNaN(selectedDate.getTime()) && inputDate <= today) {
      updatedForm["moc_date"] = inputDate;
      setRequestForm(updatedForm);
    } else {
      setRequestForm(updatedForm);
    }
  };

  const onHandleChange = (e, fieldName) => {
    setRequestForm((prevForm) => ({
      ...prevForm,
      [fieldName]: e.target.value,
    }));
  };

  const onHandleDepartmentChange = (event, field, selectedFunction = null) => {
    let updatedForm = { ...requestForm };
    const value = event.target.value;
    localStorage.setItem("isRequestSave", false);
    setErrors(false);
    if (field === "department") {
      updatedForm["department"] = selectedFunction.function;
      updatedForm["function_code"] = selectedFunction.function_code;
      localStorage.setItem("function_code", selectedFunction.function_code)
      // updatedForm[field] = value;
    } else {
      updatedForm[field] = value;
    }
    setRequestForm(updatedForm);
  };

  const loadMocVisits = async () => {
    try {
      const takenMocID = localStorage.getItem("takeMocID");
      const response = await axios.get(`${API.GET_ALL_MOC_FOR_APPROVALS}?id=${takenMocID}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        });
      if (response.status === 200) {
        if (response.data && response.data.length > 0) {
          setMocsData(response.data);
          const loadMocData = response.data;
          localStorage.setItem("loadMocData", JSON.stringify(loadMocData));
        }
      }
    } catch (error) {
      //console.log("ERROR", error);
    }
  };

  useEffect(() => {
    loadMocVisits();
  }, []);
  const mocDetData = JSON.parse(localStorage.getItem("mocDataByID"));
  const takeMocID = mocDetData?.moc_id;
  localStorage.setItem("takeMocID", takeMocID);
  const userRequisition = mocDetData?.mocForm?.created_by;
  const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
  const userFName = storedUserData.FName + " " + storedUserData.LName;
  const userSGID = storedUserData.SGID;
  let disableCondition;
  let updateDisable;
  if (isEditMoc) {
    disableCondition = userSGID === mocDetData.mocForm.user_id ? false : true;
    updateDisable = mocDetData.mocForm.submission_status === "approved" ? true : false;
  }
  return (
    <>
      <DialogContent>
        <Typography style={{ fontSize: 18, color: "#B51849", fontWeight: 600 }}>
          Change Request
        </Typography>
        <Box
          style={{
            // maxHeight: "100vh",
            marginTop: "0",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            style={{
              fontWeight: 600,
              fontSize: 16,
              marginTop: "10px",
              fontStyle: "normal",
              fontFamily: "Roboto, sans-serif",
              color: "#B51849",
              gap: "16px",
            }}
          >
            DETAILS
          </Typography>
          <Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography
                style={{
                  fontWeight: 600,
                  fontStyle: "normal",
                  fontFamily: "Roboto, sans-serif",
                  color: "#000",
                  fontSize: 14,
                  gap: 8,
                }}
              >
                Doc Reference:
              </Typography>
              <Typography
                style={{
                  fontWeight: 500,
                  fontStyle: "normal",
                  fontFamily: "Roboto, sans-serif",
                  color: "#7F7F7F",
                }}
              >
                SGI-IMS-FOR-19
              </Typography>
            </Box>
            {isEditMoc ? (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  style={{
                    fontWeight: 600,
                    fontStyle: "normal",
                    fontFamily: "Roboto, sans-serif",
                    color: "#000",
                    fontSize: 14,
                    gap: 8,
                  }}
                >
                  Moc No:
                </Typography>
                <Typography
                  style={{
                    fontWeight: 500,
                    fontStyle: "normal",
                    fontFamily: "Roboto, sans-serif",
                    color: "#7F7F7F",
                  }}
                >
                  {mocDetData.mocForm.moc_no}
                </Typography>
              </Box>
            ) : (
              ""
            )}
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography
                style={{
                  fontWeight: 600,
                  fontStyle: "normal",
                  fontFamily: "Roboto, sans-serif",
                  color: "#000",
                  fontSize: 14,
                  gap: "8px",
                }}
              >
                Revision Number:
              </Typography>
              <Typography
                style={{
                  fontWeight: 500,
                  fontStyle: "normal",
                  fontFamily: "Roboto, sans-serif",
                  color: "#7F7F7F",
                }}
              >
                001
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography
                style={{
                  fontWeight: 600,
                  fontStyle: "normal",
                  fontFamily: "Roboto, sans-serif",
                  color: "#000",
                  fontSize: 14,
                  gap: "8px",
                }}
              >
                Revision Date:
              </Typography>
              <Typography
                style={{
                  fontWeight: 500,
                  fontStyle: "normal",
                  fontFamily: "Roboto, sans-serif",
                  color: "#7F7F7F",
                }}
              >
                2018-04-17
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box style={{ marginTop: "10px" }}>
          <InputLabel
            style={{
              fontWeight: 600,
              fontStyle: "normal",
              fontFamily: "Roboto, sans-serif",
              color: "#000",
              fontSize: 14,
              alignSelf: "stretch",
              lineHeight: 2,
            }}
          >
            Date *
          </InputLabel>
          <TextField
            style={{ width: "100%", background: "#FFFFFF", borderRadius: 4 }}
            id="date"
            type="date"
            value={new Date(requestForm.moc_date).toLocaleDateString("en-CA")}
            onChange={(e) => handleDateChange(e, "moc_date")}
            variant="outlined"
            size="small"
            InputLabelProps={{ style: { fontWeight: 500, fontStyle: "normal" } }}
            inputProps={{ max: new Date().toISOString().split("T")[0] }}
            disabled={disableCondition}
          />
        </Box>
        <Box style={{ marginTop: "10px" }}>
          <InputLabel
            style={{
              fontWeight: 600,
              fontStyle: "normal",
              fontFamily: "Roboto, sans-serif",
              color: "#000",
              fontSize: 14,
              marginTop: 4,
            }}
          >
            Requisition By *
          </InputLabel>
          <TextField
            style={{
              width: "100%",
              marginTop: 10,
              background: "#FFFFFF",
              borderRadius: 8,
            }}
            id="name"
            size="small"
            variant="outlined"
            value={isEditMoc ? userRequisition : userFName}
            disabled={true}
          />
        </Box>
        <Box style={{ marginTop: "10px" }}>
          <InputLabel
            style={{
              fontWeight: 600,
              fontStyle: "normal",
              fontFamily: "Roboto, sans-serif",
              color: "#000",
              fontSize: 14,
            }}
          >
            Department/Function *
          </InputLabel>
          <TextField
            style={{ width: "100%", marginTop: 10, textAlign: "left" }}
            id="outlined-select-currency"
            select
            size="small"
            value={requestForm.department || ""}
            onChange={(e) => {
              const selectedFunction = functions.find(
                (func) => func.function === e.target.value
              );
              onHandleDepartmentChange(e, "department", selectedFunction);
            }}
            InputLabelProps={{
              shrink: true,
            }}
            error={stepError && !requestForm.department ? true : false}
            disabled={isEditMoc ? true : false}
          >
            {functions.map((func) => (
              <MenuItem key={func.id} value={func.function}>
                {func.function}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box style={{ marginTop: "10px" }}>
          <InputLabel
            style={{
              fontWeight: 600,
              fontStyle: "normal",
              fontFamily: "Roboto, sans-serif",
              color: "#000",
              fontSize: 14,
            }}
          >
            Product/Process Change *
          </InputLabel>
          <TextField
            style={{ width: "100%", marginTop: 10, textAlign: "left" }}
            value={requestForm.change_type|| ""}
            onChange={(e) => onHandleChange(e, "change_type")}
            id="outlined-select-currency"
            select
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            error={stepError && !requestForm.change_type ? true : false}
            disabled={disableCondition}
          >
            {changeType.map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box style={{ marginTop: "10px" }}>
          <InputLabel
            style={{
              fontWeight: 600,
              fontStyle: "normal",
              fontFamily: "Roboto, sans-serif",
              color: "#000",
              fontSize: 14,
            }}
          >
            Brief Description *
          </InputLabel>
          <TextField
            style={{
              width: "100%",
              marginTop: 10,
              background: "#FFFFFF",
              borderRadius: 4,
            }}
            id="outlined-multiline-static"
            multiline
            rows={4}
            value={requestForm.brief_description || ""}
            onChange={(e) => onHandleChange(e, "brief_description")}
            error={stepError && !requestForm.brief_description ? true : false}
            disabled={disableCondition}
          />
        </Box>
        <Box style={{ marginTop: "10px" }}>
          <InputLabel
            style={{
              fontWeight: 600,
              fontStyle: "normal",
              fontFamily: "Roboto, sans-serif",
              color: "#000",
              fontSize: 14,
            }}
          >
            Classification *
          </InputLabel>
          <TextField
            style={{ width: "100%", marginTop: 10, textAlign: "left" }}
            value={requestForm.classification || ""}
            onChange={(e) => onHandleChange(e, "classification")}
            id="outlined-select-currency"
            select
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            error={stepError && !requestForm.classification ? true : false}
            disabled={disableCondition}
          >
            {classification.map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box style={{ marginTop: "10px" }}>
          <Typography
            style={{
              fontWeight: 600,
              fontSize: 18,
              marginTop: "10px",
              fontStyle: "normal",
            }}
          >
            DETAILS OF CHANGE REQUIRED
          </Typography>
          <Box style={{ marginTop: "10px" }}>
            <InputLabel
              style={{
                fontWeight: 600,
                fontStyle: "normal",
                fontFamily: "Roboto, sans-serif",
                color: "#000",
                fontSize: 14,
              }}
            >
              Particulars *
            </InputLabel>
            <TextField
              style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
              id="outlined-multiline-static"
              multiline
              rows={4}
              value={requestForm.particulars || ""}
              onChange={(e) => onHandleChange(e, "particulars")}
              error={stepError && !requestForm.particulars ? true : false}
              disabled={disableCondition}
            />
          </Box>
          <Box style={{ marginTop: "10px" }}>
            <InputLabel
              style={{
                fontWeight: 600,
                fontStyle: "normal",
                fontFamily: "Roboto, sans-serif",
                color: "#000",
                fontSize: 14,
              }}
            >
              Current Status *
            </InputLabel>
            <TextField
              style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
              id="outlined-multiline-static"
              multiline
              rows={4}
              value={requestForm.current_status || ""}
              onChange={(e) => onHandleChange(e, "current_status")}
              error={stepError && !requestForm.current_status ? true : false}
              disabled={disableCondition}
            />
          </Box>
          <Box style={{ marginTop: "10px" }}>
            <InputLabel
              style={{
                fontWeight: 600,
                fontStyle: "normal",
                fontFamily: "Roboto, sans-serif",
                color: "#000",
                fontSize: 14,
              }}
            >
              Change Required *
            </InputLabel>
            <TextField
              style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
              id="outlined-multiline-static"
              multiline
              rows={4}
              value={requestForm.change_required || ""}
              onChange={(e) => onHandleChange(e, "change_required")}
              error={stepError && !requestForm.change_required ? true : false}
              disabled={disableCondition}
            />
          </Box>
          <Box style={{ marginTop: "10px" }}>
            <InputLabel
              style={{
                fontWeight: 600,
                fontStyle: "normal",
                fontFamily: "Roboto, sans-serif",
                color: "#000",
                fontSize: 14,
              }}
            >
              Reason For Change Required *
            </InputLabel>
            <TextField
              style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
              id="outlined-multiline-static"
              multiline
              rows={4}
              value={requestForm.reason_change_required || ""}
              onChange={(e) => onHandleChange(e, "reason_change_required")}
              error={
                stepError && !requestForm.reason_change_required ? true : false
              }
              disabled={disableCondition}
            />
          </Box>
        </Box>
        {errors ? (
          <Typography variant="subtitle2" color="red">
            {""}
            Please fill all the fields
          </Typography>
        ) : null}
        {saveMessage ? (
          <Typography variant="subtitle2" color="red">
            {""}
            Please Save before going to Next
          </Typography>
        ) : null}
        <Box
          style={{
            display: "flex",
            flexDirection: "row", // Keep it as row to align buttons horizontally
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          {initialLoad ? (
            <Box className="popup-overlay">
              <Box className="popup">
                <CircularProgress />
              </Box>
            </Box>
          ) : showSuccessPopup ? (
            <Box className="popup-overlay">
              <Box className="popup">
                <p>Data saved successfully!</p>
              </Box>
            </Box>
          ) : null}
          {initialLoad ? (
            <Box className="popup-overlay">
              <Box className="popup">
                <CircularProgress />
              </Box>
            </Box>
          ) : showUpdatePopup ? (
            <Box className="popup-overlay">
              <Box className="popup">
                <p>Data updated successfully!</p>
              </Box>
            </Box>
          ) : null}
          {isEditMoc && !(disableCondition) ? (
            <Button
              variant="contained"
              style={{
                backgroundColor: "#B1174B",
                fontSize: 16,
                color: "white",
                textTransform: "none",
                width: "100%",
                marginTop: 0,
                marginRight: 5,
                marginBottom: 10
              }}
              disabled={updateDisable}
              onClick={handleRequestUpdateClick}
            >
              Update
            </Button>
          ) :
            (!isEditMoc &&
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#fff",
                  fontSize: 16,
                  color: "#B1174B",
                  border: "1px solid #B1174B",
                  textTransform: "none",
                  width: "100%",
                  marginTop: 0,
                  marginRight: 5,
                  marginBottom: 10,
                  boxShadow: "none"
                }}
                onClick={handleRequestSaveClick}
              >
                Save
              </Button>
            )}
          <Button
            variant="contained"
            style={{
              backgroundColor: "#B1174B",
              fontSize: 16,
              color: "white",
              textTransform: "none",
              width: "100%",
              marginTop: 0,
              marginRight: 5,
              marginBottom: 10
            }}
            saveRequest={saveRequest}
            onClick={handleRequestNextClick}
          >
            Next
          </Button>
        </Box>
      </DialogContent>

    </>
  );
};
export default RequestForm;