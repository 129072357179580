import React, { useState, useEffect } from "react";
import { Tabs, Tab, Card, CardContent, Typography, Avatar, Divider, CircularProgress } from "@mui/material";
import API from "../../Services/API";
import axios from "axios";
import { formatDistanceToNow } from "date-fns";
import SearchIcon from "../../Asset/Icons/search_icon.svg";

const NotificationsList = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [notifyData, setNotifyData] = useState([]);
    const [approvalData, setApprovalData] = useState([]);
    const [initialLoad, setInitialLoad] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);
    const [searchText, setSearchText] = useState("");

    const access_token = JSON.parse(localStorage.getItem("accesstoken"));

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
    const storedAllUserData = JSON.parse(localStorage.getItem("allUsersData")) || [];
    const storedUserSgid = storedUserData.SGID;
    const userName = storedUserData.FName + " " + storedUserData.LName;
    const cmsDetData = JSON.parse(localStorage.getItem("cmsDataByID"));
    const mocCFT = cmsDetData?.cmsForm?.cft_team;

    const handleSearchChange = (event) => {
        const searchText = event.target.value;
        setSearchText(searchText);
        if (selectedTab === 0) {
            const filtNotify = filterNotifyData(notifyData, searchText);
            setNotifyData(filtNotify);
        } else {
            const filtApproval = filterApprovalData(approvalData, searchText);
            setApprovalData(filtApproval);
        }
        if (!searchText) {
            loadAllUser();
        }
    }

    const filterNotifyData = (data, search) => {
        const lowerCaseQuery = search.toLowerCase();
        const filtNotify = data.filter((cms) => {
            const userName = cms?.user_approval_name?.toLowerCase().includes(lowerCaseQuery);
            const status = cms?.status
                ? (cms?.status === "conditionallyapproved"
                    ? "conditionally approved"
                    : cms?.status === "submittedbackforqueries"
                        ? "submitted back for queries"
                        : cms?.status?.toLowerCase())
                : '';
            const statusMatch = status.includes(lowerCaseQuery);
            const cmsNo = cms?.cmsForm?.cms_no?.toLowerCase().includes(lowerCaseQuery);
            return userName || statusMatch || cmsNo;
        });
        return filtNotify;
    };

    const filterApprovalData = (data, search) => {
        const filtApproval = data.filter((cms) => {
            const lowerCaseQuery = search.toLowerCase();
            const name = cms?.cmsForm?.created_by.toLowerCase().includes(lowerCaseQuery);
            const status = cms?.status.toLowerCase().includes(lowerCaseQuery);
            const cmsNo = cms?.cmsForm?.cms_no.toLowerCase().includes(lowerCaseQuery);
            return name || status || cmsNo;
        });
        return filtApproval;
    };

    const loadAllUser = () => {
        axios
            .get(API.GET_ALL_CMS_APPROVALS_NOTIFY, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            .then(function (response) {
                if (response.status === 200) {
                    if (response.data && response.data.length > 0) {
                        const filteredData = response.data
                            .filter((item) => item?.cmsForm?.cms_no !== null && item?.cmsForm?.cms_no !== "" && item?.cmsForm?.cms_no !== undefined);
                        setNotifyData(filteredData);
                        // console.log(response, "response78")
                        // console.log(filteredData, "filteredData79")
                        const cmsDetData = JSON.parse(localStorage.getItem("cmsDataByID"));
                        const takeMocID = cmsDetData?.moc_id;
                        localStorage.setItem("takeMocID", takeMocID);
                    }
                }
            })
            .catch(function (error) {
                //console.log("ERROR", error);
            })
            .finally(() => {
                setInitialLoad(false);
            });
    };

    useEffect(
        () => {
            loadAllUser();
        },
        [
            //mocsData
        ]
    );

    // useEffect(() => {
    //     if (storedAllUserData) {
    //         const loggedInUserData = storedAllUserData.find(user => user.SGID === storedUserSgid);
    //         if ((loggedInUserData && (loggedInUserData.role === "Admin" || mocCFT)) || (cmsDetData?.cmsForm?.conclusion === "Quality Head" && loggedInUserData.role === "Quality Head") || (cmsDetData?.cmsForm?.conclusion === "Safety Head" && loggedInUserData.role === "Safety Head")
    //             //  || cmsDetData?.cmsForm?.plant_check === "Yes" && loggedInUserData.role === "Plant Head"
    //         ) {
    //             setIsAdmin(true);
    //         }
    //     }
    // }, [storedUserSgid, storedAllUserData, cmsDetData?.cmsForm?.conclusion]);

    return (
        <div style={{ justifyContent: "center", marginTop: 50, padding: 10 }}>
            {/* <Header pageTitle='Notification Center' /> */}
            <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                centered
                indicatorColor="primary"
                textColor="primary"
                sx={{
                    // maxWidth: "400px",
                    "& .MuiTab-textColorPrimary.Mui-selected": {
                        color: "#B51849",
                        borderBottom: "1px solid #B51849"
                    },
                    "& .MuiTabs-indicator": {
                        backgroundColor: "#B51849",
                        display: {
                            xs: "none",
                        },
                    },
                    "& .MuiTabs-flexContainer": {
                        display: {
                            xs: "block",
                        },
                        overflow: {
                            xs: "auto",
                        },
                    },
                }}>
                <Tab label="Notifications" sx={{ minWidth: "100px", width: "auto", textTransform: "capitalize", fontSize: "16px" }} />
                {/* <Tab label="Approvals" sx={{ minWidth: "100px", width: "auto", textTransform: "capitalize", fontSize: "16px" }} /> */}
            </Tabs>
            <div style={{ height: "16px", padding: "10px 6px" }}>
                <div
                    style={{
                        position: "relative",
                        display: "flex",
                        width: "100%",
                        marginTop: "10px",
                        marginBottom: "10px"
                    }}
                >
                    <img
                        src={SearchIcon}
                        alt="Search Icon"
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "10px",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                            marginLeft: 12,
                        }}
                    />
                    <input
                        type="text"
                        placeholder="Search..."
                        style={{
                            width: "65%",
                            padding: "10px",
                            paddingLeft: "40px",
                            fontSize: "14px",
                            borderRadius: "5px",
                            border: "1px solid #8F908F",
                            marginLeft: 12,
                        }}
                        value={searchText}
                        onChange={handleSearchChange}
                    />
                </div>
                {initialLoad ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <CircularProgress />
                    </div>
                ) : (
                    <React.Fragment>
                        {selectedTab === 0 && (
                            <div>
                                {notifyData.slice(0).map((cms, index) => {
                                    const createdAt = cms?.cmsForm?.created_at;
                                    const timeAgo = formatDistanceToNow(new Date(createdAt), { addSuffix: true });
                                    // console.log(cms, "cms203")
                                    return (
                                        <Card
                                            style={{
                                                width: "100%",
                                                borderRadius: "8px",
                                                marginBottom: 0,
                                                boxShadow: "none",
                                            }}
                                            key={index}
                                        >
                                            <CardContent
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "16px",
                                                    padding: "16px",
                                                }}
                                            >
                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', width: "100%", cursor: "pointer" }}>
                                                    <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
                                                        <Avatar
                                                            style={{
                                                                width: "30px",
                                                                height: "30px",
                                                                color: "#B51849",
                                                                padding: "4px",
                                                                fontSize: "14px",
                                                                backgroundColor: "#B518491A",
                                                            }}
                                                        >
                                                            {`${cms?.user_approval_name.split(" ")[1].charAt(0)}${cms?.user_approval_name.split(" ")[0].charAt(0)}`}
                                                        </Avatar>
                                                        <Typography
                                                            style={{
                                                                color: "#000",
                                                                fontFamily: "inherit",
                                                                fontSize: "14px",
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            {`${cms?.user_approval_name.split(" ")[0]} has ${cms?.status === "conditionallyapproved" ? "conditionally approved" : cms?.status === "submittedbackforqueries" ? "submitted back for queries" : cms?.status === "pending" ? "initiated" : cms?.status} CMS No: ${cms?.cmsForm?.cms_no}`}
                                                        </Typography>
                                                    </div>
                                                    <div style={{ position: "relative" }}>
                                                        <Typography
                                                            style={{
                                                                color: "#000",
                                                                fontFamily: "inherit",
                                                                fontSize: "14px",
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            {timeAgo}
                                                        </Typography>
                                                    </div>
                                                </div>
                                                <Divider style={{ width: "100%" }} />
                                            </CardContent>
                                        </Card>
                                    );
                                })}
                            </div>
                        )}
                        {notifyData.length === 0 && <p>No Records found.</p>}
                    </React.Fragment>
                )}
            </div>
        </div>
    );
}

export default NotificationsList;