import React, { useState, useEffect } from "react";
import Header from '../app/TopAppBar';
import { Tabs, Tab, Card, CardContent, Typography, Avatar, Divider, CircularProgress } from "@mui/material";
import API from "../Services/API";
import axios from "axios";
import { formatDistanceToNow } from "date-fns";
import SearchIcon from "../Asset/Icons/search_icon.svg";

const Notifications = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [notifyData, setNotifyData] = useState([]);
    const [approvalData, setApprovalData] = useState([]);
    const [initialLoad, setInitialLoad] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);
    const [searchText, setSearchText] = useState("");

    const access_token = JSON.parse(localStorage.getItem("accesstoken"));

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
    const storedAllUserData = JSON.parse(localStorage.getItem("allUsersData")) || [];
    const storedUserSgid = storedUserData.SGID;
    const userName = storedUserData.FName + " " + storedUserData.LName;
    const mocDetData = JSON.parse(localStorage.getItem("mocDataByID"));
    const mocCFT = mocDetData?.mocForm?.cft_team;

    const handleSearchChange = (event) => {
        const searchText = event.target.value;
        setSearchText(searchText);
        if (selectedTab === 0) {
            const filtNotify = filterNotifyData(notifyData, searchText);
            setNotifyData(filtNotify);
        } else {
            const filtApproval = filterApprovalData(approvalData, searchText);
            setApprovalData(filtApproval);
        }
        if (!searchText) {
            loadAllUser();
        }
    }

    const filterNotifyData = (data, search) => {
        const filtNotify = data.filter((moc) => {
            const lowerCaseQuery = search.toLowerCase();
            const name = moc.user_approval_name.toLowerCase().includes(lowerCaseQuery);
            const status = moc.status.toLowerCase().includes(lowerCaseQuery);
            const mocNo = moc.mocForm.moc_no.toLowerCase().includes(lowerCaseQuery);
            return name || status || mocNo;
        });
        return filtNotify;
    };

    const filterApprovalData = (data, search) => {
        const filtApproval = data.filter((moc) => {
            const lowerCaseQuery = search.toLowerCase();
            const name = moc.mocForm.created_by.toLowerCase().includes(lowerCaseQuery);
            const status = moc.status.toLowerCase().includes(lowerCaseQuery);
            const mocNo = moc.mocForm.moc_no.toLowerCase().includes(lowerCaseQuery);
            return name || status || mocNo;
        });
        return filtApproval;
    };

    const loadAllUser = () => {
        axios
            .get(API.GET_ALL_MOC_APPROVALS_NOTIFY, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            .then(function (response) {
                if (response.status === 200) {
                    if (response.data && response.data.length > 0) {
                        const filteredData = response.data.filter(item => {
                            const moc = item.mocForm;
                            if (moc.conclusion === "Quality Head") {
                                if ((item.status === "rejected" || item.status === "approved" || item.status === "pending" || item.status === "resubmitted") &&
                                    (storedUserSgid === moc.user_id || storedUserSgid === moc.admin || storedUserData.name === moc.quality_name || (moc.plant_check === "Yes" && storedUserData.name === moc.plant_name))) {
                                    return true;
                                }
                            } else if (moc.conclusion === "Safety Head") {
                                if ((item.status === "rejected" || item.status === "approved" || item.status === "pending" || item.status === "resubmitted") &&
                                    (storedUserSgid === moc.user_id || storedUserSgid === moc.admin || storedUserData.name === moc.safety_name || (moc.plant_check === "Yes" && storedUserData.name === moc.plant_name))) {
                                    return true;
                                }
                            } else if (moc.conclusion === "Safety & Quality") {
                                if ((item.status === "rejected" || item.status === "approved" || item.status === "pending" || item.status === "resubmitted") &&
                                    (storedUserSgid === moc.user_id || storedUserSgid === moc.admin || storedUserData.name === moc.quality_name || storedUserData.name === moc.safety_name || (moc.plant_check === "Yes" && storedUserData.name === moc.plant_name))) {
                                    return true;
                                }
                            } else if (moc.conclusion === "Non Critical") {
                                if ((item.status === "rejected" || item.status === "approved" || item.status === "pending" || item.status === "resubmitted") &&
                                    (storedUserSgid === moc.user_id || storedUserSgid === moc.admin)) {
                                    return true;
                                }
                            }
                            return false; // Add this line to handle the case when none of the conditions are met
                        });
                        setNotifyData(filteredData);
                        // const conclusionData = response.data.filter(
                        //     (item) => {
                        //         const moc = item.mocForm;
                        //         if (moc.conclusion === "Quality Head") {
                        //             if ((item.status === "pending" || item.status === "resubmitted") &&
                        //                 (storedUserSgid === moc.user_id || storedUserSgid === moc.admin || userName === moc.quality_name || (moc.plant_check === "Yes" && userName === moc.plant_name))) {
                        //                 return true;
                        //             }
                        //         } else if (moc.conclusion === "Safety Head") {
                        //             if ((item.status === "pending" || item.status === "resubmitted") &&
                        //                 (storedUserSgid === moc.user_id || storedUserSgid === moc.admin || userName === moc.safety_name || (moc.plant_check === "Yes" && userName === moc.plant_name))) {
                        //                 return true;
                        //             }
                        //         } else if (moc.conclusion === "Safety & Quality") {
                        //             if ((item.status === "pending" || item.status === "resubmitted") &&
                        //                 (storedUserSgid === moc.user_id || storedUserSgid === moc.admin || userName === moc.quality_name || userName === moc.safety_name || (moc.plant_check === "Yes" && userName === moc.plant_name))) {
                        //                 return true;
                        //             }
                        //         } else if (moc.conclusion === "Non Critical") {
                        //             if ((item.status === "pending" || item.status === "resubmitted") &&
                        //                 (storedUserSgid === moc.user_id || storedUserSgid === moc.admin)) {
                        //                 return true;
                        //             }
                        //         }
                        //         return false; // Add this line to handle the case when none of the conditions are met
                        //     });
                        // setApprovalData(conclusionData);
                        const mocDetData = JSON.parse(localStorage.getItem("mocDataByID"));
                        const takeMocID = mocDetData?.moc_id;
                        localStorage.setItem("takeMocID", takeMocID);
                        const filteredConclusionData = response.data.filter((item) => item.mocForm.conclusion === null);
                    }
                }
            })
            .catch(function (error) {
                //console.log("ERROR", error);
            })
            .finally(() => {
                setInitialLoad(false);
            });
    };

    useEffect(
        () => {
            loadAllUser();
        },
        [
            //mocsData
        ]
    );

    useEffect(() => {
        if (storedAllUserData) {
            const loggedInUserData = storedAllUserData.find(user => user.SGID === storedUserSgid);
            if ((loggedInUserData && (loggedInUserData?.role === "Admin" || mocCFT)) || (mocDetData?.mocForm.conclusion === "Quality Head" && loggedInUserData?.role === "Quality Head") || (mocDetData?.mocForm.conclusion === "Safety Head" && loggedInUserData?.role === "Safety Head")
                //  || mocDetData?.mocForm.plant_check === "Yes" && loggedInUserData?.role === "Plant Head"
            ) {
                setIsAdmin(true);
            }
        }
    }, [storedUserSgid, storedAllUserData, mocDetData?.mocForm?.conclusion]);

    return (
        <div style={{ justifyContent: "center", marginTop: 50, padding: 10 }}>
            {/* <Header pageTitle='Notification Center' /> */}
            <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                centered
                indicatorColor="primary"
                textColor="primary"
                sx={{
                    // maxWidth: "400px",
                    "& .MuiTab-textColorPrimary.Mui-selected": {
                        color: "#B51849",
                        borderBottom: "1px solid #B51849"
                    },
                    "& .MuiTabs-indicator": {
                        backgroundColor: "#B51849",
                        display: {
                            xs: "none",
                        },
                    },
                    "& .MuiTabs-flexContainer": {
                        display: {
                            xs: "block",
                        },
                        overflow: {
                            xs: "auto",
                        },
                    },
                }}>
                <Tab label="Notifications" sx={{ minWidth: "100px", width: "auto", textTransform: "capitalize", fontSize: "16px" }} />
                {/* <Tab label="Approvals" sx={{ minWidth: "100px", width: "auto", textTransform: "capitalize", fontSize: "16px" }} /> */}
            </Tabs>
            <div style={{ height: "16px", padding: "10px 6px" }}>
                <div
                    style={{
                        position: "relative",
                        display: "flex",
                        width: "100%",
                        marginTop: "10px",
                    }}
                >
                    <img
                        src={SearchIcon}
                        alt="Search Icon"
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "10px",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                            marginLeft: 12,
                        }}
                    />
                    <input
                        type="text"
                        placeholder="Search..."
                        style={{
                            width: "65%",
                            padding: "10px",
                            paddingLeft: "40px",
                            fontSize: "14px",
                            borderRadius: "5px",
                            border: "1px solid #8F908F",
                            marginLeft: 12,
                        }}
                        value={searchText}
                        onChange={handleSearchChange}
                    />
                </div>
                {initialLoad ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <CircularProgress />
                    </div>
                ) : (
                    <React.Fragment>
                        {selectedTab === 0 && (
                            <div>
                                {notifyData.slice(0).map((moc, index) => {
                                    const createdAt = moc.mocForm.updated_at;
                                    console.log("createdAt",createdAt)
                                    const timeAgo = formatDistanceToNow(new Date(createdAt), { addSuffix: true });

                                    return (
                                        <Card
                                            style={{
                                                width: "100%",
                                                borderRadius: "8px",
                                                marginBottom: 0,
                                                boxShadow: "none",
                                            }}
                                            key={index}
                                        >
                                            <CardContent
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "16px",
                                                    padding: "16px",
                                                }}
                                            >
                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', width: "100%", cursor: "pointer" }}>
                                                    <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
                                                        <Avatar
                                                            style={{
                                                                width: "30px",
                                                                height: "30px",
                                                                color: "#B51849",
                                                                padding: "4px",
                                                                fontSize: "14px",
                                                                backgroundColor: "#B518491A",
                                                            }}
                                                        >
                                                            {`${moc.user_approval_name.split(" ")[1].charAt(0)}${moc.user_approval_name.split(" ")[0].charAt(0)}`}
                                                        </Avatar>
                                                        <Typography
                                                            style={{
                                                                color: "#000",
                                                                fontFamily: "inherit",
                                                                fontSize: "14px",
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            {`${moc.user_approval_name.split(" ")[0]} has ${moc.status} MOC No: ${moc.mocForm.moc_no}`}
                                                        </Typography>
                                                    </div>
                                                    <div style={{ position: "relative" }}>
                                                        <Typography
                                                            style={{
                                                                color: "#000",
                                                                fontFamily: "inherit",
                                                                fontSize: "14px",
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            {timeAgo}
                                                        </Typography>
                                                    </div>
                                                </div>
                                                <Divider style={{ width: "100%" }} />
                                            </CardContent>
                                        </Card>
                                    );
                                })}
                            </div>
                        )}
                        {notifyData.length === 0 && <p>No Records found.</p>}
                    </React.Fragment>
                )}
                {/* <React.Fragment>
                    {selectedTab === 1 && (
                        <div>
                            {approvalData.slice(0).map((moc, index) => {
                                const createdAt = moc.mocForm.created_at;
                                const timeAgo = formatDistanceToNow(new Date(createdAt), { addSuffix: true });

                                return (
                                    <Card
                                        style={{
                                            width: "100%",
                                            borderRadius: "8px",
                                            marginBottom: 0,
                                            boxShadow: "none",
                                        }}
                                        key={index}
                                    >
                                        <CardContent
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "16px",
                                                padding: "16px",
                                            }}
                                        >
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', width: "100%", cursor: "pointer" }}>
                                                <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
                                                    <Avatar
                                                        style={{
                                                            width: "30px",
                                                            height: "30px",
                                                            color: "#B51849",
                                                            padding: "4px",
                                                            fontSize: "14px",
                                                            backgroundColor: "#B518491A",
                                                        }}
                                                    >
                                                        {`${moc.mocForm.created_by.split(" ").pop().charAt(0)}${moc.mocForm.created_by.charAt(0)}`}
                                                    </Avatar>
                                                    <Typography
                                                        style={{
                                                            color: "#000",
                                                            fontFamily: "inherit",
                                                            fontSize: "14px",
                                                            fontWeight: 600,
                                                        }}
                                                    >
                                                        {`${moc.mocForm.created_by.split(" ")[0]} has ${moc.status === "pending" ? "submitted" : moc.status} MOC No: ${moc.mocForm.moc_no}`}
                                                    </Typography>
                                                </div>
                                                <div style={{ position: "relative" }}>
                                                    <Typography
                                                        style={{
                                                            color: "#000",
                                                            fontFamily: "inherit",
                                                            fontSize: "14px",
                                                            fontWeight: 600,
                                                        }}
                                                    >
                                                        {timeAgo}
                                                    </Typography>
                                                </div>
                                            </div>
                                            <Divider style={{ width: "100%" }} />
                                        </CardContent>
                                    </Card>
                                );
                            })}
                            {approvalData.length === 0 && <p>No Records found.</p>}
                        </div>
                    )}
                </React.Fragment> */}
            </div>
        </div>
    );
}

export default Notifications;