import React, { useState, useEffect } from "react";
import Header from "../app/TopAppBar";
import { Typography, Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { useNavigate } from "react-router-dom";

const AccordionItem = ({ label, content, to, showIcon }) => {
  const navigate = useNavigate();

  const handleAccordionClick = () => {
    navigate(to);
  };

  return (
    <Accordion>
      <AccordionSummary
        style={{ cursor: "pointer" }}
        onClick={handleAccordionClick}
        expandIcon={<ArrowForwardIosIcon />}
        IconButtonProps={{ edge: "end", style: { padding: 0 } }}>
        {showIcon && <NotificationsActiveIcon style={{ marginRight: 8 }} />}
        <Typography variant="h7" style={{ color: "#000" }}>
          {label}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{content}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

const Settings = () => {
  // 4M Change Management System
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
  const plantSelection = storedUserData?.plant === "Sekurit";
  const storedAllUserData = JSON.parse(localStorage.getItem("allUsersData")) || [];
  const adminSGID = storedUserData.SGID;
  useEffect(() => {
    if (storedAllUserData) {
      const loggedInUserData = storedAllUserData.find(
        (user) => user.SGID === adminSGID
      );
      if (loggedInUserData && loggedInUserData.role === "SuperAdmin") {
        setIsSuperAdmin(true);
      }
    }
  }, [adminSGID, storedAllUserData]);

  return (
    <div style={{ background: "#F7F7F7", minHeight: "91vh" }}>
      {/* <Header pageTitle="Settings" isDashboard={true} /> */}
      <Typography
        variant="h6"
        style={{ color: "#B51849", marginTop: 60, padding: 20 }}
      >
        SETTINGS
      </Typography>
      {
        plantSelection ? (
          <>
            <AccordionItem
              label="User Management"
              content="Content for Accordion 2"
              to="/userList"
              showIcon={false}
            />
            {isSuperAdmin && (
              <AccordionItem
                label="Impact Change & Alternation Note"
                content="Content for Accordion 4"
                to="/questionsList"
                showIcon={false}
              />
            )
            }
            <AccordionItem
              label="Notification"
              content="Content for Accordion 4"
              to="/notificationslist"
              showIcon={true}
            />
          </>
        ) :
          (
            <>
              <AccordionItem
                label="User Management"
                content="Content for Accordion 2"
                to="/userList"
                showIcon={false}
              />
              <AccordionItem
                label="Notification"
                content="Content for Accordion 4"
                to="/notifications"
                showIcon={true}
              />
            </>
          )
      }
    </div >
  );
};

export default Settings;