import React, { useState, useEffect, useRef } from "react";
import { Typography, InputLabel, Divider, TextField, MenuItem, Button, CircularProgress, DialogTitle, DialogContent, FormControl, Select, OutlinedInput, Checkbox, ListItemText, Chip } from "@mui/material";
import { notification, editCMSData, userData } from "../recoil/atoms";
import { useRecoilState } from "recoil";
import { Close as CloseIcon } from '@mui/icons-material';
import Header from "../app/TopAppBar";
import axios from "axios";
import API from "../Services/API";
import { useNavigate } from "react-router-dom";
import configParam from "../config";
import { handleSend } from "../4m_Pages/EmailPopupCms";
import BackIcon from "../Asset/Icons/back_icon.svg";
// import CancelIcon from '@mui/icons-material/Cancel';

const appStatus = configParam.APPROVAL_STATUS;
const Notification = ({ dataNotified, onClose, onBack }) => {
    const [notificationData, setNotificationData] = useRecoilState(notification);
    const [newDialogNotifyOpen, setNewDialogNotifyOpen] = useState('true');
    const [isEditCms] = useRecoilState(editCMSData);
    const [save, setSave] = useState(false);
    const [errors, setErrors] = useState(false);
    const [emailErrors, setEmailErrors] = useState(false);
    const [approvalErrors, setApprovalErrors] = useState(false);
    const [saveMessage, setSaveMessage] = useState(false);
    const [stepError, setStepError] = useState(false);
    const [initialLoad, setInitialLoad] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [showUpdatePopup, setShowUpdatePopup] = useState(false);
    const [emailId, setEmailId] = useState([]);
    const [approverSGID, setApproverSGID] = useState([]);
    const [approverMailId, setApproverMailId] = useState('');
    const [emailSGIDMap, setEmailSGIDMap] = useState({});
    const [cftName, setCFTName] = React.useState([]);
    const [functionalName, setFunctionalName] = React.useState([]);
    const [cftEmailData, setCFTEmailData] = useState('');
    const [functionalEmailData, setFunctionalEmailData] = useState('');
    const [newDialogRequestsOpen, setNewDialogRequestsOpen] = useState(false);
    const [hasChangedCFT, setHasChangedCFT] = useState(false);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const access_token = JSON.parse(localStorage.getItem("accesstoken"));
    //console.log(isEditCms, "isEditCms32")

    // useEffect(() => {
    //     const handlePopup = (setter, delay) => {
    //         setter(true);
    //         const timer = setTimeout(() => {
    //             setter(false);
    //         }, delay);
    //         return () => clearTimeout(timer);
    //     };
    //     const handlePopups = (showPopup, setShowPopup) => {
    //         if (showPopup) {
    //             setInitialLoad(true);
    //             setTimeout(() => {
    //                 setInitialLoad(false);
    //                 handlePopup(setShowPopup, 1000);
    //             }, 2000);
    //         }
    //     };
    //     handlePopups(showSuccessPopup, setShowSuccessPopup);
    //     handlePopups(showUpdatePopup, setShowUpdatePopup);
    // }, [showSuccessPopup, showUpdatePopup]);

    useEffect(() => {
        const handlePopup = (setter, delay) => {
            setter(true);
            const timer = setTimeout(() => {
                setter(false);
            }, delay);
            return () => clearTimeout(timer);
        };

        const handlePopups = (showPopup, setShowPopup) => {
            if (showPopup) {
                setInitialLoad(true);
                setTimeout(() => {
                    setInitialLoad(false);
                    handlePopups(setShowPopup, 3000); // Increased to 3000 milliseconds (3 seconds)
                }, 5000); // Increased to 5000 milliseconds (5 seconds) before showing the popup again
            }
        };


        handlePopups(showSuccessPopup, setShowSuccessPopup);
        handlePopups(showUpdatePopup, setShowUpdatePopup);
    }, [showSuccessPopup, showUpdatePopup]);

    useEffect(() => {
        if (showSuccessPopup || showUpdatePopup) {
            onClose();
        }
    }, [showSuccessPopup, showUpdatePopup]);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    // PROD
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const response = await axios.get(`${API.GET_EMP_DEPT_DETAILS}`,
    //                 {
    //                     headers: {
    //                         Authorization: `Bearer ${access_token}`
    //                     }
    //                 });
    //             const empDeptDetails = response.data;
    //             //console.log(empDeptDetails, "empDeptDetails68");

    //             // Create a mapping of MailId to SGID
    //             const emailSGIDMap = {};
    //             const emails = empDeptDetails
    //                 .map(employee => {
    //                     const MailId = employee.emP_SG_MAILID;
    //                     const sgid = employee.emP_SGID;
    //                     if (MailId && MailId !== "") {
    //                         emailSGIDMap[MailId] = sgid; // Store SGID based on MailId
    //                         return MailId;
    //                     }
    //                     return null;
    //                 })
    //                 .filter(MailId => MailId !== null)
    //                 .sort();

    //             setEmailId(emails);
    //             setEmailSGIDMap(emailSGIDMap); // Store the map in state if needed
    //             //console.log(emails, "emails73");

    //             const approverSGID = empDeptDetails.map(employee => employee.emP_SGID);
    //             setApproverSGID(approverSGID);
    //             //console.log(approverSGID, "approverSGID79");
    //         } catch (error) {
    //             console.error('Error fetching employee department details:', error);
    //         }
    //     };

    //     fetchData();
    // }, []);


    const storedAllUserData = JSON.parse(localStorage.getItem("allUsersData"));
    //console.log(storedAllUserData, "storedAllUserData104")

    // const handleChangeCFT = (event) => {
    //     const selectedEmails = event.target.value;
    //     //console.log(selectedEmails, "selectedEmails108");
    //     setCFTName(selectedEmails);
    //        // Optionally, find the full user object if needed
    //        const user = storedAllUserData.find(user => user.MailId === selectedEmails);
    //        //console.log("userData", user);
    //        setCFTEmailData(user);
    // };

    const handleChangeFunctional = (event) => {
        const selectedEmails = event.target.value; // This might still be an array
        //console.log(selectedEmails, "selectedEmails108");
        // If using a multi-select input, ensure you handle it as an array
        const emailsArray = Array.isArray(selectedEmails) ? selectedEmails : [selectedEmails];
        setFunctionalName(emailsArray); // Update with the array of selected emails
        // Find all user objects corresponding to the selected emails
        const selectedUsers = emailsArray.map(MailId =>
            storedAllUserData.find(user => user.MailId === MailId)
        );
        //console.log("selectedUsers132", selectedUsers);
        setFunctionalEmailData(selectedUsers);
    };

    const handleChangeCFT = (event) => {
        const selectedEmails = event.target.value; // This might still be an array
        const emailsArray = Array.isArray(selectedEmails) ? selectedEmails : [selectedEmails];
        setCFTName(emailsArray);
        console.log("selectedEmails180", selectedEmails);
        console.log("emailsArray181", emailsArray);
        const selectedUsers = emailsArray.map(MailId =>
            storedAllUserData.find(user => user.MailId === MailId)
        ).filter(user => user);
        console.log("Selected Users:", selectedUsers);
        setCFTEmailData(selectedUsers);
        setHasChangedCFT(true);
        setOpen(false);
    };

    const handleDelete = (nameToDelete) => () => {
        //console.log(nameToDelete, "nameToDelete86")
        setCFTName((prev) => prev.filter(name => name !== nameToDelete));
        // Remove the email from the stored list of users for future selection
        // setCFTEmailData((prev) => prev.filter(user => user.MailId !== nameToDelete));
        // setOpen(false);
    };

    const handleDeleteFunctional = (nameToDelete) => () => {
        //console.log(nameToDelete, "nameToDelete86")
        setFunctionalName((prev) => prev.filter(name => name !== nameToDelete));
    };

    const handleDeleteClose = () => {
        setCFTName([]);
    }

    const onHandleChange = (event, field) => {
        let updatedForm = { ...notificationData };
        const value = event.target.value;
        updatedForm[field] = value;
        setNotificationData(updatedForm);
        setErrors(false);
        // setEmailErrors(false);
        // setApprovalErrors(false);
    };

    const handleNotifyHeaderSave = async () => {
        try {
            const notifyHeaderData = {
                cft_approver_type: Array.isArray(cftName) && cftName.length > 1 ? "multiplecftapprover" : "singlecftapprover",
                updated_at: new Date()
            }
            const response = await axios.post(API.INSERT_NOTIFICATION_HEADER, notifyHeaderData,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                });
            console.log(notifyHeaderData, "notifyHeaderData236")
            if (response.status === 200) {
                //console.log("response176", response);
                const notificationHeaderID = response.data.identifiers[0].id;
                localStorage.setItem("notificationHeaderID", notificationHeaderID);
                console.log(notificationHeaderID, "notificationHeaderID236");
                await Promise.all([
                    handleNotificationSave(notificationHeaderID),
                    cmsForm(notificationHeaderID),
                ]);
                setSave(true);
            } else {
                // console.error(`Failed to insert notification for ${selectedEmail?.MailId}`, response);
            }

        } catch (error) {
            console.error("An error occurred in update trials:", error);
        }
    };

    const cmsForm = async () => {
        const updateCmsDet = localStorage.getItem("cmsID");
        const updateTakeCmsDet = localStorage.getItem("takeCmsID");
        const storedCmsDataString = localStorage.getItem("cmsDataByID");
        const storedCmsData = JSON.parse(storedCmsDataString);
        // console.log(storedCmsData, "storedCmsData257")
        const localRequestId = localStorage.getItem("requestID");
        const requestID = isEditCms ? storedCmsData?.cmsForm?.change_request_id : localRequestId;
        const localChangeRequireId = localStorage.getItem("changeRequireID");
        const changeRequireID = isEditCms ? storedCmsData?.cmsForm?.change_required_id : localChangeRequireId;
        const localImpactId = localStorage.getItem("impactID");
        const impactID = isEditCms ? storedCmsData?.cmsForm?.impact_change_id : localImpactId;
        const localNotificationId = localStorage.getItem("notificationHeaderID");
        // console.log(localNotificationId, "localNotificationId304")
        const notifyHeaderID = isEditCms && storedCmsData?.cmsForm?.notification_header_id !== null ? storedCmsData?.cmsForm?.notification_header_id : localNotificationId;
        const userID = storedCmsData?.cmsForm?.user_id;
        const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
        const userSGID = storedUserData.SGID;
        //console.log(userSGID, "userSGID281")
        //console.log(userID, "userID282")
        const userName = isEditCms ? storedCmsData?.cmsForm?.created_by : storedUserData.FName + " " + storedUserData.LName;
        const cmsNumber = isEditCms && storedCmsData?.cmsForm?.notification_header_id !== null ? storedCmsData?.cmsForm?.cms_no : await generateCMSNumber();
        localStorage.setItem("cmsNumber", cmsNumber);
        // console.log(cmsNumber, "cmsNumber277")
        const isEditCmsNo = isEditCms && storedCmsData?.cmsForm?.notification_header_id !== null ? storedCmsData?.cmsForm?.cms_no : cmsNumber;
        const cmsDetID = isEditCms ? updateTakeCmsDet : updateCmsDet;
        const userMail = storedCmsData?.cmsForm?.user_mail;
        //console.log(storedCmsData?.cmsForm?.cms_no, "storedCmsData?.cmsForm?.cms_no")
        try {
            const cmsData = {
                id: cmsDetID,
                user_id: isEditCms ? storedCmsData?.cmsForm?.user_id : userSGID,
                updated_at: new Date(),
                created_by: userName,
                doc_ref: "F/CFT/SGSI/01",
                // rev_no: "03",
                // rev_date: new Date(),
                cms_no: isEditCmsNo,
                change_request_id: requestID,
                change_required_id: changeRequireID,
                impact_change_id: impactID,
                notification_header_id: notifyHeaderID,
                submission_status: getSubmissionStatus(),
                user_mail: userMail
            };
            function getSubmissionStatus() {
                let submissionStatus;
                const cmsDetData = JSON.parse(localStorage.getItem("cmsDataByID"));
                const submittedbackforqueries = cmsDetData?.cmsForm?.submission_status === "submittedbackforqueries";

                if (!isEditCms && JSON.parse(localStorage.getItem("cmsDataByID"))?.cmsForm?.notification_header_id !== null && userSGID === userID) {
                    submissionStatus = "pending";
                }
                else if (isEditCms && userSGID !== userID && cmsDetData?.cmsForm?.notificationHeader?.cmsForms.some((approver) =>
                    approver?.functional_sgid?.includes(userSGID))) {
                    submissionStatus = notificationData.functional_status;
                    //console.log(notificationData, "notificationData327")
                }
                else if (isEditCms && submittedbackforqueries && userSGID === userID) {
                    submissionStatus = "resubmitted";
                }
                else if (isEditCms && cmsDetData?.cmsForm?.submission_status === "approved" && userSGID === userID) {
                    submissionStatus = "resubmitted";
                }
                else if (isEditCms && cmsDetData?.cmsForm?.submission_status === "conditionallyapproved" && userSGID === userID) {
                    submissionStatus = "resubmitted";
                }
                else if (isEditCms && userSGID !== userID && cmsDetData?.cmsForm?.notificationHeader?.cmsForms.some((approver) =>
                    approver?.approver_sgid?.includes(userSGID))) {
                    submissionStatus = notificationData.approval_status;
                }
                return submissionStatus;
            }
            const cmsResponse = await axios.put(
                `${API.UPDATE_CMS_FORM}?id=${cmsDetID}`,
                cmsData,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                }
            );
            //console.log("updateCmsDet217", updateCmsDet);
            //console.log("cmsData217", cmsData);
            //console.log("cmsResponse221", cmsResponse);
            if (cmsResponse.status === 200) {
                //console.log("update conclusion", cmsResponse);
                //console.log("isEditCms222", isEditCms);
                isEditCms ? await insertCmsApprovals(updateCmsDet) : await updateCmsApprovals(updateCmsDet);
                setSave(true);
            }
        } catch (error) {
            console.error("An error occurred in insertIntoMocTable:", error);
        }
    };

    const updateCmsApprovals = async () => {
        const updateCmsDet = localStorage.getItem("cmsID");
        const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
        //console.log(storedUserData, "storedUserData79999");
        //console.log(updateCmsDet, "updateCmsDet");
        const userSGID = updateCmsDet ? storedUserData.SGID : "";
        const userAppName = updateCmsDet ? storedUserData.FName + " " + storedUserData.LName : "";
        const cmsApprovalID = localStorage.getItem("cmsApprovalID");
        //console.log(cmsApprovalID, "cmsApprovalID310")
        try {
            const cmsData = {
                id: cmsApprovalID,
                cms_id: updateCmsDet,
                user_sgid: userSGID,
                user_approval_name: userAppName,
                status: "pending"
            };
            const cmsResponse = await axios.put(`${API.UPDATE_CMS_APPROVALS}?id=${cmsApprovalID}`, cmsData,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                });
            //console.log(cmsData, "cmsData")
            if (cmsResponse.status === 200) {
                //console.log("Inserted into moc table:", cmsResponse);
                setSave(true);
            }
        } catch (error) {
            console.error("An error occurred in insertIntoMocTable:", error);
        }
    };

    const handleNotifyCancel = () => {
        onClose();
        // navigate("/managecms");
    }

    const handleNotifyHeaderUpdate = async () => {
        const storedCmsDataString = localStorage.getItem("cmsDataByID");
        const storedCmsData = JSON.parse(storedCmsDataString);
        const notificationHeaderID = storedCmsData?.cmsForm?.notification_header_id;
        try {
            const notifyHeaderData = {
                updated_at: new Date()
            }
            const response = await axios.put(
                `${API.UPDATE_NOTIFICATION_HEADER}?id=${notificationHeaderID}`,
                notifyHeaderData,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                }
            );
            if (response.status === 200) {
                //console.log("update response", response);
                await handleNotifyUpdate(notificationHeaderID);
            }
        } catch (error) {
            console.error("An error occurred in update trials:", error);
        }
    }

    const handleNotifyUpdate = async () => {
        const storedCmsDataString = localStorage.getItem("cmsDataByID");
        const storedCmsData = JSON.parse(storedCmsDataString);
        console.log(storedCmsData, "storedCmsData429")
        const notificationID = storedCmsData?.cmsForm?.notificationHeader?.id;
        console.log(notificationID, "notificationID430")
        const storeCmsID = storedCmsData?.cmsForm?.id;
        //console.log(storeCmsID, "storeCmsID275");
        const userID = storedCmsData?.cmsForm?.user_id;
        console.log(cftEmailData, "cftEmailData408")
        const functionalSGID = storedCmsData?.cmsForm?.notificationHeader?.cmsForms?.find(cmsForm =>
            cmsForm?.functional_sgid?.includes(userSGID)
        )?.functional_sgid;
        const approverSGID = storedCmsData?.cmsForm?.notificationHeader?.cmsForms?.find(cmsForm =>
            cmsForm?.approver_sgid?.includes(userSGID)
        )?.approver_sgid;
        try {
            // if (!notificationData.approval_status) {
            //     setApprovalErrors(true);
            //     setStepError(true);
            //     setSaveMessage(false);
            //     return;
            // } else {
            //     setApprovalErrors(false);
            //     setStepError(false);
            //     setSaveMessage(false);
            // }
            const selectedSGID = emailSGIDMap[cftName];
            const selectedFunctionalSGID = emailSGIDMap[functionalName];
            const updateData = {
                approver_comments: notificationData.approver_comments,
                approval_status: notificationData.approval_status,
                // cft_approval_status: notificationData.approval_status === "approved" || notificationData.approval_status === "conditionallyapproved" && userSGID !== userID,
                functional_comments: notificationData.functional_comments,
                functional_status: notificationData.functional_status,
                functional_sgid: functionalSGID,
                approver_sgid: approverSGID,
                // functional_approval_status: (notificationData.functional_status === "approved" || notificationData.functional_status === "conditionallyapproved") && userSGID !== userID
                ...((notificationData.functional_status === "approved" || notificationData.functional_status === "conditionallyapproved") && userSGID !== userID
                    ? { functional_approval_status: true } // Set to true explicitly
                    : {}),
                ...((notificationData.approval_status === "approved" || notificationData.approval_status === "conditionallyapproved") && userSGID !== userID
                    ? { cft_approval_status: true } // Set to true explicitly
                    : {}),
            };
            const response = await axios.put(
                `${API.UPDATE_NOTIFICATION}?id=${notificationID}`,
                updateData,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                }
            );
            console.log("updateData469", updateData);
            if (response.status === 200) {
                //console.log("update response", response);
                await cmsForm(storeCmsID);
                // navigate("/managecms");
                setSave(true);
                setShowUpdatePopup(true);
                const storedUserByData = JSON.parse(localStorage.getItem("userDatas"));
                let from_email = [storedUserByData?.MailId];
                console.log(storedCmsData?.cmsForm?.notificationHeader?.cmsForms, "storedCmsData471")
                // let to_email = process.env.REACT_APP_STAGE === "prod" ? functionalName : [storedCmsData?.cmsForm?.user_mail, storedCmsData?.cmsForm?.notification?.functional_mail_id, storedCmsData?.cmsForm?.notification?.approver_mail_id];
                // let to_email = [storedCmsData?.cmsForm?.user_mail, storedCmsData?.cmsForm?.notificationHeader?.cmsForms?.find(cmsForm =>
                //     cmsForm?.functional_mail_id), storedCmsData?.cmsForm?.notificationHeader?.cmsForms?.find(cmsForm =>
                //         cmsForm?.approver_mail_id)
                // ];
                let to_email = [
                    storedCmsData?.cmsForm?.user_mail,
                    ...new Set(storedCmsData?.cmsForm?.notificationHeader?.cmsForms?.map(cmsForm => cmsForm?.functional_mail_id).filter(Boolean)),
                    ...storedCmsData?.cmsForm?.notificationHeader?.cmsForms?.map(cmsForm => cmsForm?.approver_mail_id).filter(Boolean)
                ].filter(email => email);
                console.log(to_email, "to_email484");
                handleSend(from_email, to_email);
                setHasChangedCFT(false);
            }
        } catch (error) {
            console.error("An error occurred in update trials:", error);
        }
    }

    const insertCmsApprovals = async () => {
        const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
        //console.log(storedUserData, "storedUserData79999");
        const userSGID = storedUserData.SGID;
        const userAppName = storedUserData.FName + " " + storedUserData.LName;
        const cmsDetData = JSON.parse(localStorage.getItem("cmsDataByID"));
        //console.log(cmsDetData, "cmsDetData380");
        //console.log(userSGID === cmsDetData?.cmsForm?.user_id, "userSGID === cmsDetData?.cmsForm?.user_id381");
        const cmsIds = localStorage.getItem("takeCmsID");
        //console.log(cmsIds, "cmsIds398");
        const userID = cmsDetData?.cmsForm?.user_id;
        try {
            const cmsData = {
                cms_id: cmsIds,
                user_sgid: userSGID,
                user_approval_name: userAppName,
                status: getStatus()
                // userSGID === cmsDetData?.cmsForm?.user_id ? "resubmitted" : notificationData.approval_status,
            };
            function getStatus() {
                let status;
                const cmsDetData = JSON.parse(localStorage.getItem("cmsDataByID"));
                const submittedbackforqueries = cmsDetData?.cmsForm?.submission_status === "submittedbackforqueries";
                //console.log(submittedbackforqueries, "submittedbackforqueries312")
                //console.log(notificationData.approval_status, "notificationData.approval_status316")
                // if (cftEmailData[0]?.MailId) {
                if (!isEditCms && JSON.parse(localStorage.getItem("cmsDataByID"))?.cmsForm?.notification_header_id !== null && userSGID === userID) {
                    status = "pending";
                }
                else if (isEditCms && userSGID !== userID && cmsDetData?.cmsForm?.notificationHeader?.cmsForms.some((approver) =>
                    approver?.functional_sgid?.includes(userSGID))) {
                    status = notificationData.functional_status;
                }
                else if (isEditCms && submittedbackforqueries && userSGID === userID) {
                    status = "resubmitted";
                }
                else if (isEditCms && cmsDetData?.cmsForm?.submission_status === "approved" && userSGID === userID) {
                    status = "resubmitted";
                }
                else if (isEditCms && cmsDetData?.cmsForm?.submission_status === "conditionallyapproved" && userSGID === userID) {
                    status = "resubmitted";
                }
                else if (isEditCms && userSGID !== userID && cmsDetData?.cmsForm?.notificationHeader?.cmsForms.some((approver) =>
                    approver?.approver_sgid?.includes(userSGID))) {
                    status = notificationData.approval_status;
                }
                return status;
            }
            const mocResponse = await axios.post(API.INSERT_CMS_APPROVALS, cmsData,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                });
            //console.log(cmsData, "cmsData522")
            if (mocResponse.status === 200) {
                //console.log("Inserted into moc table:", mocResponse);
                const cmsApprovalID = mocResponse.data["identifiers"][0].id;
                //console.log("Inserted cmsApprovalID", cmsApprovalID);
                localStorage.setItem("cmsApprovalID", cmsApprovalID);
            }
        } catch (error) {
            console.error("An error occurred in insertIntoMocTable:", error);
        }
    };

    async function getLatestRecord() {
        try {
            const response = await axios.get(`${API.GET_LATEST_CMS}`,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                });
            //console.log(response, "response89")
            if (response.data && response.data.length > 0) {
                return response.data[0].cms_no; // Return the latest CMS number
            } else {
                //console.log("No data found in the response");
                return null;
            }
        } catch (error) {
            //console.log("Error:", error);
            return null;
        }
    }

    async function generateCMSNumber() {
        const latestRecord = await getLatestRecord();
        let lastTwoDigits = 1; // Default value if no records are found
        if (latestRecord) {
            const lastMocNumber = parseInt(latestRecord.split("-")[3]); // Extract last three digits
            lastTwoDigits = lastMocNumber + 1;
        }
        const date = new Date();
        const year = date.getFullYear().toString();
        const yearMocNumber = `CMS-SGS-${year}-${lastTwoDigits
            .toString()
            .padStart(3, "0")}`;
        return yearMocNumber;
    }
    generateCMSNumber()
        .then((serialNumber) => {
            //console.log("Generated Serial Number:", serialNumber);
        })
        .catch((error) => {
            console.error("Error occurred:", error);
        });

    // const notificationHeader = async () => {
    //     try {
    //         const storedCmsDataString = localStorage.getItem("cmsDataByID");
    //         const storedCmsData = JSON.parse(storedCmsDataString);
    //         const localNotificationId = localStorage.getItem("notificationID");
    //         const notificationID = isEditCms ? storedCmsData?.cmsForm?.notification_id : localNotificationId;
    //         const notifyHeaderData = {
    //             cft_approver_type: Array.isArray(cftName) && cftName.length > 1 ? "multiplecftapprover" : "singlecftapprover",
    //             notification_id: notificationID,
    //             updated_at: new Date()
    //         }
    //         const response = await axios.post(API.INSERT_NOTIFICATION_HEADER, notifyHeaderData,
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${access_token}`
    //                 }
    //             });
    //         if (response.status === 200) {
    //             //console.log("response176", response);
    //             const notificationHeaderID = response.data.identifiers[0].id;
    //             localStorage.setItem("notificationHeaderID", notificationHeaderID);
    //             console.log(notificationHeaderID, "notificationHeaderID");
    //         }
    //     } catch (error) {
    //         console.error("An error occurred in update trials:", error);
    //     }
    // };

    const handleNotificationSave = async () => {
        const notificationHeaderID = localStorage.getItem("notificationHeaderID");
        try {
            // if (!notificationData.approver_mail_id) {
            //     setEmailErrors(true);
            //     setStepError(true);
            //     setSaveMessage(false);
            //     return;
            // } else {
            //     setEmailErrors(false);
            //     setStepError(false);
            //     setSaveMessage(false);
            // }
            const selectedSGID = emailSGIDMap[cftName];
            //console.log(selectedSGID, "selectedSGID157")
            //console.log('APP stage169', process.env.REACT_APP_STAGE)
            const selectedFunctionalSGID = emailSGIDMap[functionalName];

            // Ensure cftEmailData contains the selected emails
            if (cftEmailData.length === 0) {
                console.log("No CFT emails selected");
                return;
            }

            // Loop through each selected CFT email and insert
            for (let i = 0; i < cftEmailData.length; i++) {
                const selectedEmail = cftEmailData[i];
                console.log(selectedEmail, "selectedEmail239")
                console.log(`Inserting notification for: ${selectedEmail?.MailId}`);
                const notifyData = {
                    // approver_mail_id: process.env.REACT_APP_STAGE === "prod" ? cftName : cftEmailData[0]?.MailId,
                    // approver_sgid: process.env.REACT_APP_STAGE === "prod" ? [selectedSGID] : [cftEmailData[0]?.SGID],
                    // functional_mail_id: process.env.REACT_APP_STAGE === "prod" ? functionalName : functionalEmailData[0]?.MailId,
                    // functional_sgid: process.env.REACT_APP_STAGE === "prod" ? [selectedFunctionalSGID] : [functionalEmailData[0]?.SGID],
                    approver_mail_id: selectedEmail?.MailId,
                    approver_sgid: [selectedEmail?.SGID],
                    functional_mail_id: functionalEmailData[0]?.MailId,
                    functional_sgid: [functionalEmailData[0]?.SGID],
                    notification_header_id: notificationHeaderID
                }
                const response = await axios.post(API.INSERT_NOTIFICATION, notifyData,
                    {
                        headers: {
                            Authorization: `Bearer ${access_token}`
                        }
                    });
                console.log(notifyData, "notifyData256")
                if (response.status === 200) {
                    //console.log("response176", response);
                    const notificationID = response.data.identifiers[0].id;
                    localStorage.setItem("notificationID", notificationID);
                    setSave(true);
                    setShowSuccessPopup(true);
                    const storedUserByData = JSON.parse(localStorage.getItem("userDatas"));
                    let from_email = [storedUserByData?.MailId];
                    // let to_email = process.env.REACT_APP_STAGE === "prod" ? cftName : cftEmailData[0]?.MailId;
                    // let to_email = process.env.REACT_APP_STAGE === "prod" ? functionalName : [functionalEmailData[0]?.MailId, cftEmailData[0]?.MailId];
                    let to_email = [functionalEmailData[0]?.MailId, cftEmailData.map(item => item.MailId)];
                    handleSend(from_email, to_email);
                }
            }
        } catch (error) {
            console.error("An error occurred in update trials:", error);
        }
    }

    useEffect(() => {
        if (isEditCms) {
            const getNotify = async () => {
                const storedCmsDataString = localStorage.getItem("cmsDataByID");
                const storedCmsData = JSON.parse(storedCmsDataString);
                const notifyIHeaderID = storedCmsData?.cmsForm?.notification_header_id;

                try {
                    const response = await axios.get(
                        `${API.GET_NOTIFICATION}?id=${notifyIHeaderID}`,
                        {
                            headers: {
                                Authorization: `Bearer ${access_token}`
                            }
                        }
                    );
                    const notifyDetails = response.data;
                    console.log(notifyDetails, "notifyDetails725")
                    // Handle the case where there is at least one notification in the data
                    if (notifyDetails.length > 0) {
                        const notification = notifyDetails[0];  // Assuming you're interested in the first notification

                        // Set functional_comments and functional_status from the notification data
                        setNotificationData(notifyDetails[0]);
                        setFunctionalName([notification.functional_mail_id]);  // Set functional email
                        setCFTName(Array.from(new Set(notifyDetails.map(notification => notification.approver_mail_id)))); // Set CFT approver emails
                    }
                } catch (error) {
                    console.error("Error fetching MOC details:", error);
                }
            };
            getNotify();
        }
    }, [setNotificationData, isEditCms, access_token]);

    const cmsNumberData = localStorage.getItem("cmsNumber");
    const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
    const userSGID = storedUserData.SGID;
    const cmsDetData = JSON.parse(localStorage.getItem("cmsDataByID"));
    console.log(cmsDetData, "cmsDetData733")
    const cmsNo = cmsDetData?.cmsForm?.cms_no === null;
    let disableCondition;
    let disableUserCondition;
    let disableBoth;
    let cftDisable = cmsDetData?.cmsForm?.notificationHeader?.cmsForms?.some(cmsForm =>
        cmsForm?.approver_sgid?.includes(userSGID)
    );
    console.log(cmsDetData?.notificationHeader?.cmsForms, "743")
    const functionalApprovalStatus = cmsDetData?.cmsForm?.notificationHeader?.cmsForms?.some(cmsForm => cmsForm?.functional_approval_status === true);
    let functionalDisable = userSGID === cmsDetData?.cmsForm?.notification?.functional_sgid[0];
    if (isEditCms) {
        disableCondition = userSGID !== cmsDetData?.cmsForm?.user_id;
        disableUserCondition = userSGID === cmsDetData?.cmsForm?.user_id;
        disableBoth = userSGID !== cmsDetData?.cmsForm?.user_id || userSGID === cmsDetData?.cmsForm?.user_id && !cmsNo;
    }

    return (
        <>
            <DialogTitle>Notification</DialogTitle>
            <img
                src={BackIcon}
                alt="menu icon"
                style={{ width: "28px", height: "24px", cursor: "pointer", marginLeft: "13px" }}
                onClick={onBack}
            />
            <DialogContent>

                {/* PROD */}
                {/* <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id="demo-multiple-checkbox-label">CFT Approval</InputLabel>
                    <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={cftName}
                        onChange={handleChangeCFT}
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected) => (
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {selected.map((value) => (
                                    <Chip
                                        key={value}
                                        label={value}
                                        onDelete={handleDelete(value)}
                                        deleteIcon={<CloseIcon />}
                                        style={{ margin: 2 }}
                                    />
                                ))}
                            </div>
                        )}
                        disabled={disableCondition}
                    >
                        {emailId.map((MailId) => (
                            <MenuItem key={MailId} value={MailId}>
                                {MailId}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl> */}

                <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id="functional-head-approval-label">Functional Head Approval</InputLabel>
                    <Select
                        labelId="functional-head-approval-label"
                        id="functional-head-approval"
                        value={functionalName}  // Make sure functionalName holds a single value (string or number)
                        onChange={handleChangeFunctional}
                        input={<OutlinedInput label="Functional Head Approval" />}
                        renderValue={(selected) => {
                            // Since it's single select, selected will be a single value, not an array
                            return selected ? selected : "Select a user";
                        }}
                        disabled={disableBoth}
                    >
                        {storedAllUserData.map((user) => (
                            <MenuItem key={user.id} value={user.MailId}>
                                {user.MailId}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {isEditCms && !(cmsNo) ?
                    <>
                        <div style={{ marginTop: "10px" }}>
                            <InputLabel
                                style={{
                                    fontWeight: 500,
                                    fontStyle: "normal",
                                    fontFamily: "Inter, sans-serif",
                                    color: "#000000",
                                }}
                            >
                                Functional Head Comments
                            </InputLabel>
                            <TextField
                                style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
                                value={notificationData.functional_comments}
                                onChange={(e) => onHandleChange(e, "functional_comments")}
                                id="outlined-multiline-static"
                                multiline
                                rows={4}
                                disabled={disableUserCondition || cftDisable}
                            />
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <InputLabel
                                style={{
                                    fontWeight: 500,
                                    fontStyle: "normal",
                                    fontFamily: "Inter, sans-serif",
                                    color: "#000000",
                                }}
                            >
                                Functional Head Approval Status *
                            </InputLabel>
                            <TextField
                                style={{ width: "100%", marginTop: 10, textAlign: "left" }}
                                value={notificationData.functional_status}
                                onChange={(e) => onHandleChange(e, "functional_status")}
                                id="outlined-select-currency"
                                select
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={stepError && !notificationData.functional_status}
                                disabled={disableUserCondition || cftDisable}
                            >
                                {appStatus.map((type) => (
                                    <MenuItem key={type.value} value={type.value}>
                                        {type.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </>
                    : <></>
                }
                {console.log(cftName, "cftName772")}
                <div style={{ marginTop: "10px" }}>
                    <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="demo-multiple-checkbox-label">CFT Approval</InputLabel>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={cftName}
                            onChange={handleChangeCFT}
                            input={<OutlinedInput label="CFT Approval" />}
                            renderValue={(selected) => {
                                const selectedArray = Array.isArray(selected) ? selected : [];
                                return (
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        {selectedArray.map((value) => (
                                            <Chip
                                                key={value}
                                                label={value}
                                                onDelete={handleDelete(value)}
                                                deleteIcon={<CloseIcon />}
                                                style={{ margin: 2 }}
                                            />
                                        ))}
                                    </div>
                                );
                            }}
                            disabled={disableBoth}
                            open={open}  // Control the open state of the dropdown
                            onOpen={() => setOpen(true)}  // Open the dropdown when clicked
                            onClose={() => setOpen(false)}  // Close the dropdown when clicked outside
                        >
                            {storedAllUserData.map((user) => (
                                <MenuItem key={user.id} value={user.MailId}>
                                    {user.MailId}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>

                {isEditCms && functionalApprovalStatus ?
                    <>
                        <div style={{ marginTop: "10px" }}>
                            <InputLabel
                                style={{
                                    fontWeight: 500,
                                    fontStyle: "normal",
                                    fontFamily: "Inter, sans-serif",
                                    color: "#000000",
                                }}
                            >
                                CFT Head Comments
                            </InputLabel>
                            <TextField
                                style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
                                value={notificationData.approver_comments}
                                onChange={(e) => onHandleChange(e, "approver_comments")}
                                id="outlined-multiline-static"
                                multiline
                                rows={4}
                                disabled={disableUserCondition || functionalDisable}
                            />
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <InputLabel
                                style={{
                                    fontWeight: 500,
                                    fontStyle: "normal",
                                    fontFamily: "Inter, sans-serif",
                                    color: "#000000",
                                }}
                            >
                                CFT Head Approval Status *
                            </InputLabel>
                            <TextField
                                style={{ width: "100%", marginTop: 10, textAlign: "left" }}
                                value={notificationData.approval_status}
                                onChange={(e) => onHandleChange(e, "approval_status")}
                                id="outlined-select-currency"
                                select
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={stepError && !notificationData.approval_status}
                                disabled={disableUserCondition || functionalDisable}
                            >
                                {appStatus.map((type) => (
                                    <MenuItem key={type.value} value={type.value}>
                                        {type.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </>
                    : <></>
                }

                {errors ? (
                    <Typography variant="subtitle2" color="red">
                        {""}
                        Please fill all the fields
                    </Typography>
                ) : null}
                {saveMessage ? (
                    <Typography variant="subtitle2" color="red">
                        {""}
                        Please Save before going to Next
                    </Typography>
                ) : null}
                {emailErrors ? (
                    <Typography variant="subtitle2" color="red">
                        {""}
                        Please fill the Email Id
                    </Typography>
                ) : null}
                {/* {approvalErrors ? (
                    <Typography variant="subtitle2" color="red">
                        {""}
                        Please fill the approval status
                    </Typography>
                ) : null} */}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px"
                    }}
                >
                    {initialLoad ? (
                        <div className="popup-overlay">
                            <div className="popup">
                                <CircularProgress />
                            </div>
                        </div>
                    ) : showSuccessPopup ? (
                        <div className="popup-overlay">
                            <div className="popup">
                                <p>Data saved successfully!</p>
                                <p>CMS No: {cmsNumberData} created successfully!</p>
                            </div>
                        </div>
                    ) : null}
                    {initialLoad ? (
                        <div className="popup-overlay">
                            <div className="popup">
                                <CircularProgress />
                            </div>
                        </div>
                    ) : showUpdatePopup ? (
                        <div className="popup-overlay">
                            <div className="popup">
                                <p>Data updated successfully!</p>
                            </div>
                        </div>
                    ) : null}
                </div>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "row", // Keep it as row to align buttons horizontally
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "20px",
                    }}
                >
                    {isEditCms && !(disableCondition) && !(cmsNo) &&
                        <Button
                            variant="contained"
                            style={{
                                backgroundColor: "#B1174B",
                                fontSize: 20,
                                color: "white",
                                textTransform: "none",
                                width: "75%",
                                marginTop: 20,
                                marginRight: 5,
                            }}
                            onClick={cmsDetData?.cmsForm?.submission_status === "pending" ? handleNotifyCancel : handleNotifyHeaderUpdate}
                            disabled={cmsDetData?.cmsForm?.submission_status === "resubmitted" || cmsDetData?.cmsForm?.submission_status === "approved" || cmsDetData?.cmsForm?.submission_status === "conditionallyapproved"}
                        >
                            {cmsDetData?.cmsForm?.submission_status === "pending" ? "Cancel" : "Resubmit"}
                        </Button>
                    }
                    {(!disableUserCondition || (JSON.parse(localStorage.getItem("cmsDataByID")))?.cmsForm?.notification_header_id === null) &&
                        <Button
                            variant="contained"
                            style={{
                                backgroundColor: "#fff",
                                fontSize: 20,
                                color: "#B1174B",
                                border: "1px solid #B1174B",
                                textTransform: "none",
                                width: "100%",
                                marginTop: 20,
                                marginRight: 5,
                                marginBottom: 10,
                                boxShadow: "none"
                            }}
                            onClick={() => {
                                if (isEditCms && JSON.parse(localStorage.getItem("cmsDataByID"))?.cmsForm?.notification_header_id !== null) {
                                    handleNotifyHeaderUpdate();
                                } else {
                                    handleNotifyHeaderSave();
                                }
                            }}
                            disabled={cmsDetData?.cmsForm?.submission_status === "rejected"
                                // || (cmsDetData?.cmsForm?.notificationHeader?.cmsForms.some((approver) =>
                                //     approver?.approver_sgid?.includes(userSGID)) && cmsDetData?.cmsForm?.submission_status === "submittedbackforqueries")
                            }
                        >
                            Save
                        </Button>
                    }
                </div>
            </DialogContent>
        </>
    );
}

export default Notification;