import React from "react";
import { Typography, Grid, Card } from "@mui/material";

const CardsCms = ({
    allCmsCount,
    inProgressCmsCount,
    pendingCmsCount,
    completedCmsCount,
    rejectedCmsCount,
}) => {
    const mocData = [
        { id: 1, name: "All CMS" },
        { id: 3, name: "In Progress CMS" },
        { id: 2, name: "Pending CMS" },
        { id: 4, name: "Completed CMS" },
        { id: 4, name: "Rejected CMS" },
    ];

    const valueMap = {
        "All CMS": allCmsCount,
        "In Progress CMS": inProgressCmsCount,
        "Pending CMS": pendingCmsCount,
        "Completed CMS": completedCmsCount,
        "Rejected CMS": rejectedCmsCount,
    };
    return (
        <Grid
            container
            spacing={2}
            style={{ display: "flex", marginBottom: "10px" }}
        >
            {mocData.map((e) => (
                <Grid item key={e.id} md sm={6} xs={12}>
                    <Card style={{ padding: "16px", background: "#B1174B" }}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography
                                style={{ fontSize: 14, fontWeight: 400, color: "#fff" }}
                            >
                                {e.name}
                            </Typography>
                        </div>
                        <div>
                            <div style={{ float: "left", paddingRight: 7 }}>
                                <Typography
                                    style={{ fontSize: 28, fontWeight: 400, color: "#fff" }}
                                >
                                    {valueMap[e.name] || e.value}
                                </Typography>
                            </div>
                        </div>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default CardsCms;