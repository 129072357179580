import React, { useState, useEffect } from "react";
import { Button, Typography, CircularProgress, Tabs, Card, CardContent, Box, Divider } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import axios from "axios";
import API from "../Services/API";
import ImpactQuestionsData from "./ImpactQuestionsData";
import AlterQuestionsData from "./AlterQuestionsData";

const QuestionsList = () => {
    const [initialLoad, setInitialLoad] = useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const [impactQuestionsData, setImpactQuestionsData] = useState([]);
    const [alterQuestionsData, setAlterQuestionsData] = useState([]);
    const [newDialogImpactQuestionOpen, setNewDialogImpactQuestionOpen] = useState(false);
    const [newDialogAlterQuestionOpen, setNewDialogAlterQuestionOpen] = useState(false);

    const access_token = JSON.parse(localStorage.getItem("accesstoken"));

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
    const storedAllUserData = JSON.parse(localStorage.getItem("allUsersData")) || [];

    const loadImpactQuestions = () => {
        axios
            .get(API.GET_IMPACT_CMS_QUESTIONS, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            .then(function (response) {
                if (response.status === 200) {
                    if (response.data && response.data.length > 0) {
                        const filteredData = response.data
                            .map(({ question_no, questions }) => ({ question_no, questions })) // Include questions
                            .sort((a, b) => Number(a.question_no) - Number(b.question_no)); // Sort by question_no

                        setImpactQuestionsData(filteredData);
                        //console.log(filteredData, "filteredData79");
                        //console.log(response.data, "response.data40");
                    }
                }
            })
            .catch(function (error) {
                //console.log("ERROR", error);
            })
            .finally(() => {
                setInitialLoad(false);
            });
    };

    const loadAlterQuestions = () => {
        axios
            .get(API.GET_ALTERNATION_CMS_QUESTIONS, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            .then(function (response) {
                if (response.status === 200) {
                    if (response.data && response.data.length > 0) {
                        const filteredData = response.data
                            .map(({ question_no, questions }) => ({ question_no, questions })) // Include questions
                            .sort((a, b) => Number(a.question_no) - Number(b.question_no)); // Sort by question_no

                        setAlterQuestionsData(filteredData);
                        //console.log(filteredData, "filteredData79");
                        //console.log(response.data, "response.data40");
                    }
                }
            })
            .catch(function (error) {
                //console.log("ERROR", error);
            })
            .finally(() => {
                setInitialLoad(false);
            });
    };


    useEffect(
        () => {
            loadImpactQuestions();
            loadAlterQuestions();
        },
        [
            //mocsData
        ]
    );

    const handleImpactQuestionsClick = () => {
        if (isSuperAdmin) {
            setNewDialogImpactQuestionOpen(true);
        }
    };

    const handleAlterQuestionsClick = () => {
        if (isSuperAdmin) {
            setNewDialogAlterQuestionOpen(true);
        }
    };

    const newDialogImpactQuestionsClose = () => {
        setNewDialogImpactQuestionOpen(false);
    }

    const newDialogAlterQuestionsClose = () => {
        setNewDialogAlterQuestionOpen(false);
    }

    const adminSGID = storedUserData.SGID;
    useEffect(() => {
        if (storedAllUserData) {
            const loggedInUserData = storedAllUserData.find(
                (user) => user.SGID === adminSGID
            );
            if (loggedInUserData && loggedInUserData.role === "SuperAdmin") {
                setIsSuperAdmin(true);
            }
        }
    }, [adminSGID, storedAllUserData]);

    return (
        <>
            <div style={{ justifyContent: "center", marginTop: 50, padding: 10 }}>
                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    centered
                    indicatorColor="primary"
                    textColor="primary"
                    sx={{
                        "& .MuiTab-textColorPrimary.Mui-selected": {
                            color: "#B51849",
                            borderBottom: "1px solid #B51849"
                        },
                        "& .MuiTabs-indicator": {
                            backgroundColor: "#B51849",
                            display: {
                                xs: "none",
                            },
                        },
                        "& .MuiTabs-flexContainer": {
                            display: {
                                xs: "block",
                            },
                            overflow: {
                                xs: "auto",
                            },
                        },
                    }}>
                </Tabs>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                    <Box sx={{ width: "100%" }}>
                        <Typography variant="h2" sx={{ color: '#333', fontSize: '21px', fontWeight: '600' }}>Questions List</Typography>
                        <Typography variant="h2" sx={{ color: '#333', fontSize: '18px', fontWeight: '400' }}>Impact Change to be Considered</Typography>
                    </Box>
                    {isSuperAdmin && (
                        <Box sx={{ position: 'relative' }}>
                            <Button
                                variant="contained"
                                onClick={handleImpactQuestionsClick}
                                sx={{
                                    background: '#B51849!important',
                                    width: '180px',
                                    border: 0,
                                    borderRadius: 1,
                                    color: 'white',
                                    height: '38px',
                                    fontSize: '14px',
                                    textTransform: 'capitalize',
                                    margin: '0',
                                    display: { xs: 'none', md: 'flex' },
                                    padding: '4px 0',
                                }}
                            >
                                <AddIcon />
                                New Impact Question
                            </Button>
                            <Button
                                variant="text"
                                onClick={handleImpactQuestionsClick}
                                sx={{
                                    height: '38px',
                                    color: "#000",
                                    display: { xs: 'flex', md: 'none' },
                                }}
                            >
                                <AddIcon sx={{ width: "35px", height: "35px" }} />
                            </Button>
                        </Box>
                    )}
                </Box>
                <div style={{ height: "16px", padding: "10px 6px" }}>
                    {initialLoad ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <CircularProgress />
                        </div>
                    ) : (
                        <React.Fragment>
                            {selectedTab === 0 && (
                                <>
                                    <div>
                                        {impactQuestionsData.length > 0 ? (
                                            <Card
                                                style={{
                                                    width: "100%",
                                                    borderRadius: "8px",
                                                    marginBottom: "16px",
                                                    boxShadow: "none",
                                                }}
                                            >
                                                <CardContent
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "16px",
                                                        padding: "16px",
                                                    }}
                                                >
                                                    {impactQuestionsData.map((item, index) => (
                                                        <div key={item.id}>
                                                            <Typography
                                                                style={{
                                                                    color: "#000",
                                                                    fontFamily: "inherit",
                                                                    fontSize: "14px",
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {item.question_no}. {item.questions}
                                                            </Typography>
                                                            <Divider style={{ width: "100%", margin: "8px 0" }} />
                                                        </div>
                                                    ))}
                                                </CardContent>
                                            </Card>
                                        ) : (
                                            <p>No Records found.</p>
                                        )}
                                    </div>
                                    <div>
                                        <Box sx={{ width: '100%', display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                                            <Box sx={{ width: "100%" }}>
                                                <Typography variant="h2" sx={{ color: '#333', fontSize: '18px', fontWeight: '400' }}>Alternation Note</Typography>
                                            </Box>
                                            {isSuperAdmin && (
                                                <Box sx={{ position: 'relative' }}>
                                                    <Button
                                                        variant="contained"
                                                        onClick={handleAlterQuestionsClick}
                                                        sx={{
                                                            background: '#B51849!important',
                                                            width: '210px',
                                                            border: 0,
                                                            borderRadius: 1,
                                                            color: 'white',
                                                            height: '38px',
                                                            fontSize: '14px',
                                                            textTransform: 'capitalize',
                                                            margin: '0',
                                                            display: { xs: 'none', md: 'flex', marginBottom: '15px' },
                                                            padding: '4px 0',
                                                        }}
                                                    >
                                                        <AddIcon />
                                                        New Alternation Question
                                                    </Button>
                                                    <Button
                                                        variant="text"
                                                        onClick={handleAlterQuestionsClick}
                                                        sx={{
                                                            height: '38px',
                                                            color: "#000",
                                                            display: { xs: 'flex', md: 'none' },
                                                        }}
                                                    >
                                                        <AddIcon sx={{ width: "35px", height: "35px" }} />
                                                    </Button>
                                                </Box>
                                            )}
                                        </Box>
                                        {alterQuestionsData.length > 0 ? (
                                            <Card
                                                style={{
                                                    width: "100%",
                                                    borderRadius: "8px",
                                                    marginBottom: "16px",
                                                    boxShadow: "none",
                                                }}
                                            >
                                                <CardContent
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "16px",
                                                        padding: "16px",
                                                    }}
                                                >
                                                    {alterQuestionsData.map((item, index) => (
                                                        <div key={item.id}>
                                                            <Typography
                                                                style={{
                                                                    color: "#000",
                                                                    fontFamily: "inherit",
                                                                    fontSize: "14px",
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {item.question_no}. {item.questions}
                                                            </Typography>
                                                            <Divider style={{ width: "100%", margin: "8px 0" }} />
                                                        </div>
                                                    ))}
                                                </CardContent>
                                            </Card>
                                        ) : (
                                            <p>No Records found.</p>
                                        )}
                                    </div>
                                </>
                            )}
                        </React.Fragment>

                    )}
                </div>
            </div>
            <ImpactQuestionsData isOpen={newDialogImpactQuestionOpen} onClose={newDialogImpactQuestionsClose} />
            <AlterQuestionsData isOpen={newDialogAlterQuestionOpen} onClose={newDialogAlterQuestionsClose} />
        </>
    );

};

export default QuestionsList;