import React, { useState, useEffect, useRef } from "react";
import Header from "../app/TopAppBar";
import Edit from "../Asset/Icons/edit.svg";
import Delete from "../Asset/Icons/delete.svg";
import { useRecoilState } from "recoil";
import { Typography, Card, CardContent, Divider, CircularProgress, Chip, Tooltip, IconButton, Box, Button } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import axios from "axios";
import API from "../Services/API";
import menu from "../Asset/Icons/menu_managemoc.svg";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { editMocData, userData, editCMSData } from "../recoil/atoms";
import DeleteDialog from "./DeleteDialog";
import SearchIcon from "../Asset/Icons/search_icon.svg";
import PlusIcon from "../Asset/Icons/addnew_icon.svg";
import NewUser from "./NewUser";
import NewMasterData from "./NewMasterData";

const UserList = ({ isNewUser }) => {
  const [dataUser, setDataUser] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [showMenu, setShowMenu] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [, setIsEditMoc] = useRecoilState(editMocData);
  const [, setIsEditCms] = useRecoilState(editCMSData);
  const [deleteUserId, setDeleteUserId] = useState(null); // State to store user ID for deletion
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const [newDialogUserOpen, setNewDialogUserOpen] = useState(false);
  const [newDialogMasterOpen, setNewDialogMasterOpen] = useState(false);
  const [, setUserList] = useRecoilState(userData);

  const access_token = JSON.parse(localStorage.getItem("accesstoken"));

  useEffect(() => {
    const handlePopup = (setter, delay) => {
      setter(true);
      const timer = setTimeout(() => {
        setter(false);
      }, delay);
      return () => clearTimeout(timer);
    };
    const handlePopups = (showPopup, setShowPopup) => {
      if (showPopup) {
        setInitialLoad(true);
        setTimeout(() => {
          setInitialLoad(false);
          handlePopup(setShowPopup, 1000);
        }, 2000);
      }
    };
    handlePopups(showDeletePopup, setShowDeletePopup);
  }, [showDeletePopup]);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };
    if (showMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showMenu]);

  //edit data
  const userDataByID = JSON.parse(localStorage.getItem("userDataByID"));
  const userDataID = userDataByID?.id;
  const editUserData = async () => {
    try {
      const response = await axios.put(`${API.UPDATE_USER}?id=${userDataID}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        }
      );
      const editUserData = await response.data;
      localStorage.setItem("editUserData", JSON.stringify(editUserData));
      navigate(`/newUser`);
    } catch (error) {
      console.error("Error fetching MOC details:", error);
    }
  };

  const deleteUserData = async () => {
    try {
      const response = await axios.delete(`${API.DELETE_USER}?id=${userDataID}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        }
      );
      loadAllUser();
      setShowDeletePopup(true);
    } catch (error) {
      console.error("Error fetching delete user details:", error);
    }
  }

  // Function to open delete dialog
  const openDeleteConfirmation = (userId) => {
    setDeleteUserId(userId);
    setOpenDeleteDialog(true);
  };

  // Function to close delete dialog
  const closeDeleteConfirmation = () => {
    setOpenDeleteDialog(false);
  };

  const handleSearchChange = (event) => {
    const searchText = event.target.value;
    setSearchText(searchText);
    const filtUser = filterUserData(dataUser, searchText);
    setDataUser(filtUser);
    if (!searchText) {
      loadAllUser();
    }
  }

  const filterUserData = (data, search) => {
    const lowerCaseQuery = search.toLowerCase();

    const filtUser = data.filter((user) => {
      const userFirstName = user.FName && user.FName.toLowerCase().includes(lowerCaseQuery);
      const userLastName = user.LName && user.LName.toLowerCase().includes(lowerCaseQuery);
      const userName = user.FName && user.LName &&
        (user.FName.toLowerCase() + " " + user.LName.toLowerCase()).includes(lowerCaseQuery);
      const userPlant = user.plant && user.plant.toLowerCase().includes(lowerCaseQuery);
      const userRole = user.role && user.role.toLowerCase().includes(lowerCaseQuery);
      const created_at = user.created_at
        ? moment(user.created_at).format("DD-MMM-YYYY").toLowerCase().includes(lowerCaseQuery)
        : false;
      return userFirstName || userLastName || userName || userPlant || userRole || created_at;
    });
    return filtUser;
  };

  const handleMenuClick = async (event, userId) => {
    try {
      const cardRect = event.currentTarget.getBoundingClientRect();
      const menuTop = cardRect.bottom + window.scrollY - 30;
      const menuLeft = cardRect.left + window.scrollX - 125;
      const response = await axios.get(`${API.GET_USER_BYID}?id=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        });
      const userDataByID = response.data;
      localStorage.setItem("userDataByID", JSON.stringify(userDataByID));
      setMenuPosition({ top: menuTop, left: menuLeft });
      setShowMenu(true);
    } catch (error) {
      console.error("Error fetching Moc data by ID:", error);
    }
  };

  const handleMenuItemClick = async (menuItem, userId) => {
    setIsEditMoc(true);
    setIsEditCms(true);
    switch (menuItem) {
      case "edit":
        // editUserData(userId);
        // navigate(`/newUser`);
        setNewDialogUserOpen(true);
        break;
      case "delete":
        openDeleteConfirmation(userId);
        break;
      default:
        break;
    }
    setShowMenu(false);
  };

  const loadAllUser = async () => {
    try {
      await axios.get(API.GET_ALL_USERS, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      }).then(function (response) {
        if (response.status === 200 && response.data.length > 0) {
          setDataUser(response.data);
          console.log("response.datta", response.data)
          const filteredResponse = response.data.filter(user => user.deleted !== true);
          localStorage.setItem("allUsersData", JSON.stringify(filteredResponse));
        }
      });

    } catch (error) {
      console.error("An error occurred:", error);
    }
    setInitialLoad(false);
  };
  useEffect(() => {
    loadAllUser();
  }, []);

  const handleAddClick = () => {
    setUserList([]);
    setIsEditMoc(false);
    // setIsEditCms(false);
    if (isAdmin || isSuperAdmin) {
      console.log(isAdmin, "isAdmin205")
      // navigate("/newUser");
      setNewDialogUserOpen(true);
    }
  };

  const newDialogUserClose = () => {
    setNewDialogUserOpen(false);
    setIsEditMoc(false);
    // setIsEditCms(false);
  }

  const handleMasterClick = () => {
    setUserList([]);
    setIsEditMoc(false);
    // setIsEditCms(false);
    if (isSuperAdmin) {
      setNewDialogMasterOpen(true);
    }
  };

  const newDialogMasterClose = () => {
    setNewDialogMasterOpen(false);
    setIsEditMoc(false);
    // setIsEditCms(false);
  }

  const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
  const storedAllUserData = JSON.parse(localStorage.getItem("allUsersData"));
  console.log(storedAllUserData, "storedAllUserData224")
  const adminSGID = storedUserData.SGID;
  console.log(storedUserData.plant, "storedUserData225")

  useEffect(() => {
    if (storedAllUserData) {
      const loggedInUserData = storedAllUserData.find(
        (user) => user.SGID === adminSGID
      );
      if (loggedInUserData && loggedInUserData.role === "Admin") {
        setIsAdmin(true);
      } else if (loggedInUserData && loggedInUserData.role === "SuperAdmin") {
        setIsSuperAdmin(true);
      }
    }
  }, [adminSGID, storedAllUserData]);

  return (
    <>
      <div style={{ background: "#F7F7F7", minHeight: "91vh", padding: 20 }}>
        <DeleteDialog
          open={openDeleteDialog}
          onClose={closeDeleteConfirmation}
          handleDelete={() => {
            deleteUserData(deleteUserId);
            closeDeleteConfirmation(); // Close dialog after deletion
          }}
        />
        <Box sx={{ width: '100%', display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
          {/* <Header pageTitle="User Management" isNewUser={adminSGID} /> */}
          <Box sx={{ width: "100%" }}>
            <Typography variant="h2" sx={{ color: '#333', fontSize: '21px', fontWeight: '600' }}>User's List</Typography>
            <Typography>List of users in your plant/business</Typography>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, position: 'relative', marginRight: '16px', width: { xs: '100%', md: '300px' } }}>
            <img src={SearchIcon} alt="Search Icon" style={{ position: "absolute", top: "50%", right: "10px", transform: "translateY(-50%)", cursor: "pointer" }} />
            <input type="text" placeholder="Search..." style={{ width: "100%", padding: "10px", paddingRight: "40px", fontSize: "14px", borderRadius: "5px", border: "1px solid #8F908F" }} value={searchText} onChange={handleSearchChange} />
          </Box>
          {console.log(isAdmin, "isAdmin264")}
          {(isAdmin || isSuperAdmin) && (
            <Box sx={{ position: 'relative' }}>
              <Button
                variant="contained"
                onClick={handleAddClick}
                sx={{
                  background: '#B51849!important',
                  width: '140px',
                  border: 0,
                  borderRadius: 1,
                  color: 'white',
                  height: '38px',
                  fontSize: '14px',
                  textTransform: 'capitalize',
                  margin: '0 16px 0 0',
                  display: { xs: 'none', md: 'flex' },
                  padding: '4px 0',
                }}
              >
                <AddIcon />
                New User
              </Button>
              <Button
                variant="text"
                onClick={handleAddClick}
                sx={{
                  height: '38px',
                  color: "#000",
                  display: { xs: 'flex', md: 'none' },
                }}
              >
                <AddIcon sx={{ width: "35px", height: "35px" }} />
              </Button>
            </Box>
          )}
          {storedUserData.plant === "Sekurit" && isSuperAdmin && (
            <Box sx={{ position: 'relative' }}>
              <Button
                variant="contained"
                onClick={handleMasterClick}
                sx={{
                  background: '#B51849!important',
                  width: '160px',
                  border: 0,
                  borderRadius: 1,
                  color: 'white',
                  height: '38px',
                  fontSize: '14px',
                  textTransform: 'capitalize',
                  margin: '0',
                  display: { xs: 'none', md: 'flex' },
                  padding: '4px 0',
                }}
              >
                <AddIcon />
                New Master Data
              </Button>
              <Button
                variant="text"
                onClick={handleMasterClick}
                sx={{
                  height: '38px',
                  color: "#000",
                  display: { xs: 'flex', md: 'none' },
                }}
              >
                <AddIcon sx={{ width: "35px", height: "35px" }} />
              </Button>
            </Box>
          )}
        </Box>
        <Box sx={{ display: { xs: 'flex', md: 'none' }, position: 'relative', marginTop: '16px', width: { xs: '100%', md: '300px' } }}>
          <img src={SearchIcon} alt="Search Icon" style={{ position: "absolute", top: "50%", right: "10px", transform: "translateY(-50%)", cursor: "pointer" }} />
          <input type="text" placeholder="Search..." style={{ width: "100%", padding: "10px", paddingRight: "40px", fontSize: "14px", borderRadius: "5px", border: "1px solid #8F908F" }} value={searchText} onChange={handleSearchChange} />
        </Box>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginTop: 10,
          }}
        >
          {initialLoad ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "25px"
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <React.Fragment>
              {dataUser.map((user) => {
                if (storedUserData.plant === "Sekurit" && user.plant === "Sekurit") {
                  return (
                    <Card
                      key={user.id}
                      style={{
                        width: "100%",
                        border: "1px solid #e2e2e2",
                        borderRadius: "8px",
                        marginBottom: 10,
                        boxShadow: "none",
                      }}
                    >
                      <CardContent
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          padding: "16px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          <div>
                            <Typography
                              style={{
                                color: "#000",
                                fontFamily: "inherit",
                                fontSize: "16px",
                                fontWeight: 600,
                              }}
                            >
                              {user.name}
                            </Typography>
                            <div>
                              {user.plant === "Glass" &&
                                <Chip
                                  label={user.role}
                                  style={{
                                    backgroundColor: "white",
                                    color: "#B51849",
                                    fontFamily: "inherit",
                                    fontSize: "12px",
                                    padding: "4px 8px",
                                    border: "1px solid #B51849",
                                    fontWeight: 600,
                                    width: "fit-content",
                                    position: "absolute",
                                    right: 85,
                                    cursor: "pointer"
                                  }}
                                />
                              }
                              {(isAdmin || isSuperAdmin) && (
                                <div>
                                  <img
                                    src={menu}
                                    alt="menu"
                                    style={{
                                      width: "28px",
                                      height: "20px",
                                      cursor: "pointer",
                                      position: "absolute",
                                      right: 50,
                                    }}
                                    onClick={(event) => handleMenuClick(event, user.id)}
                                  />

                                  {showMenu && (
                                    <div
                                      ref={menuRef}
                                      style={{
                                        position: "absolute",
                                        top: menuPosition.top,
                                        left: menuPosition.left,
                                        width: 150,
                                        backgroundColor: "white",
                                        boxShadow: "none",
                                        borderRadius: 4,
                                        border: "1px solid #B51849",
                                        zIndex: 10,
                                      }}
                                    >
                                      <div
                                        onClick={() => handleMenuItemClick("edit", user.id)}
                                        style={{
                                          padding: "10px",
                                          cursor: "pointer",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <img
                                          src={Edit}
                                          alt="Edit Icon"
                                          style={{
                                            verticalAlign: "middle",
                                            marginRight: "5px",
                                            marginBottom: "5px",
                                            cursor: "pointer",
                                          }}
                                        />
                                        Edit
                                      </div>
                                      <div
                                        onClick={() => handleMenuItemClick("delete", user.id)}
                                        style={{
                                          padding: "10px",
                                          cursor: "pointer",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <img
                                          src={Delete}
                                          alt="Delete Icon"
                                          style={{
                                            verticalAlign: "middle",
                                            marginRight: "5px",
                                            marginBottom: "5px",
                                            cursor: "pointer",
                                          }}
                                        />
                                        Delete
                                      </div>
                                    </div>
                                  )}

                                </div>
                              )}

                            </div>

                          </div>
                        </div>

                        <div>
                          <Typography
                            style={{
                              color: "#616161",
                              fontFamily: "inherit",
                              fontSize: "14px",
                              fontWeight: 600,
                              marginTop: "5px",
                            }}
                          >
                            {user.plant}
                          </Typography>
                        </div>
                        <Divider style={{ width: "100%" }} />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "left",
                            width: "100%",
                            cursor: "pointer",
                          }}
                        >
                          <Typography
                            style={{
                              color: "#616161",
                              fontFamily: "inherit",
                              fontSize: "14px",
                              fontWeight: 500,
                            }}
                          >
                            {moment(user.created_at).format("DD-MMM-YYYY")}
                          </Typography>
                        </div>
                      </CardContent>
                    </Card>
                  )
                }
                if (storedUserData.plant === "Glass" && user.plant === "Glass") {
                  return (
                    <Card
                      key={user.id}
                      style={{
                        width: "100%",
                        border: "1px solid #e2e2e2",
                        borderRadius: "8px",
                        marginBottom: 10,
                        boxShadow: "none",
                      }}
                    >
                      <CardContent
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          padding: "16px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          <div>
                            <Typography
                              style={{
                                color: "#000",
                                fontFamily: "inherit",
                                fontSize: "16px",
                                fontWeight: 600,
                              }}
                            >
                              {user.name}
                            </Typography>
                            <div>
                              {user.plant === "Glass" &&
                                <Chip
                                  label={user.role}
                                  style={{
                                    backgroundColor: "white",
                                    color: "#B51849",
                                    fontFamily: "inherit",
                                    fontSize: "12px",
                                    padding: "4px 8px",
                                    border: "1px solid #B51849",
                                    fontWeight: 600,
                                    width: "fit-content",
                                    position: "absolute",
                                    right: 85,
                                    cursor: "pointer"
                                  }}
                                />
                              }
                              {(isAdmin || isSuperAdmin) && (
                                <div>
                                  <img
                                    src={menu}
                                    alt="menu"
                                    style={{
                                      width: "28px",
                                      height: "20px",
                                      cursor: "pointer",
                                      position: "absolute",
                                      right: 50,
                                    }}
                                    onClick={(event) => handleMenuClick(event, user.id)}
                                  />

                                  {showMenu && (
                                    <div
                                      ref={menuRef}
                                      style={{
                                        position: "absolute",
                                        top: menuPosition.top,
                                        left: menuPosition.left,
                                        width: 150,
                                        backgroundColor: "white",
                                        boxShadow: "none",
                                        borderRadius: 4,
                                        border: "1px solid #B51849",
                                        zIndex: 10,
                                      }}
                                    >
                                      <div
                                        onClick={() => handleMenuItemClick("edit", user.id)}
                                        style={{
                                          padding: "10px",
                                          cursor: "pointer",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <img
                                          src={Edit}
                                          alt="Edit Icon"
                                          style={{
                                            verticalAlign: "middle",
                                            marginRight: "5px",
                                            marginBottom: "5px",
                                            cursor: "pointer",
                                          }}
                                        />
                                        Edit
                                      </div>
                                      <div
                                        onClick={() => handleMenuItemClick("delete", user.id)}
                                        style={{
                                          padding: "10px",
                                          cursor: "pointer",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <img
                                          src={Delete}
                                          alt="Delete Icon"
                                          style={{
                                            verticalAlign: "middle",
                                            marginRight: "5px",
                                            marginBottom: "5px",
                                            cursor: "pointer",
                                          }}
                                        />
                                        Delete
                                      </div>
                                    </div>
                                  )}

                                </div>
                              )}

                            </div>

                          </div>
                        </div>

                        <div>
                          <Typography
                            style={{
                              color: "#616161",
                              fontFamily: "inherit",
                              fontSize: "14px",
                              fontWeight: 600,
                              marginTop: "5px",
                            }}
                          >
                            {user.plant}
                          </Typography>
                        </div>
                        <Divider style={{ width: "100%" }} />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "left",
                            width: "100%",
                            cursor: "pointer",
                          }}
                        >
                          <Typography
                            style={{
                              color: "#616161",
                              fontFamily: "inherit",
                              fontSize: "14px",
                              fontWeight: 500,
                            }}
                          >
                            {moment(user.created_at).format("DD-MMM-YYYY")}
                          </Typography>
                        </div>
                      </CardContent>
                    </Card>
                  )
                }
              })}
              {dataUser.length === 0 && <p>No users found.</p>}
            </React.Fragment>
          )}

          {initialLoad ? (
            <div className="popup-overlay">
              <div className="popup">
                <CircularProgress />
              </div>
            </div>
          ) : showDeletePopup ? (
            <div className="popup-overlay">
              <div className="popup">
                <p>User deleted successfully!</p>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <NewUser isOpen={newDialogUserOpen} onClose={newDialogUserClose} />
      <NewMasterData isOpen={newDialogMasterOpen} onClose={newDialogMasterClose} />
    </>
  );
};

export default UserList;