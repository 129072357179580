import React from "react";
import { useState, useEffect ,useRef} from "react";
import { Typography, TextField, InputLabel, MenuItem, Box, Button, CircularProgress } from "@mui/material";
import { actionPlanData, editMocData } from "../recoil/atoms";
import { useRecoilState } from "recoil";
import axios from "axios";
import API from "../Services/API";
import Header from "../app/TopAppBar";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUpload from "../Asset/Icons/file_upload_icon.svg";

const ActionPlan = ({ currentPage, setCurrentPage, updateTabs }) => {
  const fileInputRef = useRef(null);
  const [actionPlan, setActionPlan] = useRecoilState(actionPlanData);
  const [errors, setErrors] = useState(false);
  const [stepError, setStepError] = useState(false);
  const [, setSave] = useState(false);
  const [saveMessage, setSaveMessage] = useState(false);
  const [responsibilityError, setResponsibilityError] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [questionAction, setQuestionAction] = useState([]);
  const [filledPages, setFilledPages] = useState(Array(8).fill(false));
  const [isEditMoc, setIsEditMoc] = useRecoilState(editMocData);
  const [initialLoad, setInitialLoad] = useState(true);

  const access_token = JSON.parse(localStorage.getItem("accesstoken"));

  useEffect(() => {
    const handlePopup = (setter, delay) => {
      setter(true);
      const timer = setTimeout(() => {
        setter(false);
      }, delay);
      return () => clearTimeout(timer);
    };
    const handlePopups = (showPopup, setShowPopup) => {
      if (showPopup) {
        setInitialLoad(true);
        setTimeout(() => {
          setInitialLoad(false);
          handlePopup(setShowPopup, 1000);
        }, 2000);
      }
    };
    handlePopups(showSuccessPopup, setShowSuccessPopup);
    handlePopups(showUpdatePopup, setShowUpdatePopup);
  }, [showSuccessPopup, showUpdatePopup]);

  const actionPlanDatas = {
    actionplan_description: [],
  };

  const questionsID = questionAction[currentPage]
    ? [questionAction[currentPage].id]
    : [];
  questionsID.forEach((questionId, index) => {
    actionPlanDatas.actionplan_description.push({
      action_required: actionPlan[currentPage]?.action_required,
      target_date: actionPlan[currentPage]?.target_date,
      complete_date: actionPlan[currentPage]?.complete_date,
      responsibility: actionPlan[currentPage]?.responsibility,
      doc_reference:actionPlan[currentPage]?.doc_reference,
      descriptions: questionId,
    });
  });

  const insertActionPlan = async () => {
    try {
      const response = await axios.post(
        API.INSERT_ACTION_PLAN,
        actionPlanDatas.actionplan_description[currentPage],
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        }
      );
      if (response.status === 200) {
        const actionPlanID = response.data.identifiers[0].id;
        localStorage.setItem("actionPlanID", actionPlanID);
        await updateMocApprovals(actionPlanID);
        setSave(true);
        setShowSuccessPopup(true);
      }
    } catch (error) {
      console.error("An error occurred while inserting action plan:", error);
    }
  };

  const handleActionUpdateClick = async () => {
    const actionID = localStorage.getItem("actionPlanID");
    try {
      if (
        actionPlan.length <= currentPage ||
        !actionPlan[currentPage] ||
        !actionPlan[currentPage]?.action_required ||
        !actionPlan[currentPage]?.responsibility ||
        (!actionPlan[currentPage]?.target_date && actionPlan[currentPage]?.action_required==="Yes") 
      ) {
        setErrors(true);
        setStepError(true);
        setSaveMessage(true);
        return;
      } else {
        setErrors(false);
        setStepError(false);
        setSaveMessage(false);
      }
      const updateData = questionAction.filter((question, index) => actionPlan[index] && actionPlan[index]?.action_required?.trim() !== ""
      ).map((question, index) => ({
        [`page ${index + 1}`]: {
          action_required: actionPlan[index]?.action_required,
          target_date: actionPlan[index]?.target_date,
          complete_date: actionPlan[index]?.complete_date,
          responsibility: actionPlan[index]?.responsibility,
          doc_reference:actionPlan[index]?.doc_reference,
          descriptions: question.id,
        },
      }));
      const response = await axios.put(
        `${API.UPDATE_ACTION_PLAN}?id=${actionID}`,
        updateData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        }
      );
      if (response.status === 200) {
        await updateMocApprovals(actionID);
        setSave(true);
        setShowSuccessPopup(true);
      }
    } catch (error) {
      console.error("An error occurred while updating action plan:", error);
    }
  };
  const handleDownloadDocument = async (file) => {
    const parts = file.split(/[\\/]/);
    try {
      const response = await axios.get(
        API.DOWNLOAD_DOCUMENTS + `/${parts[parts.length - 1]}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          },
          responseType: 'blob' // Ensure the response type is set to 'blob'
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', parts[parts.length - 1]); // Set the correct filename
      document.body.appendChild(link);
      link.click();
      link.remove();

      // Revoke the object URL after the file is downloaded to free up resources
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the file", error);
    }
  };

  const handleCaptureGallery = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
      fileInputRef.current.onchange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        if (selectedFiles.length > 0) {
          setUploadedFile({ target: { files: selectedFiles } });
        }
      };
      fileInputRef.current.value = null;
    }
  };

  const displayImageBefore = (files) => {
    const imageBeforeArray = [];
    Array.from(files).forEach(file => {
      const reader = new FileReader();
      reader.onload = (e) => {
        // Set the selected image URLs
        imageBeforeArray.push(e.target.result);
        if (imageBeforeArray.length === files.length) {
          // setBeforeImage((prevBeforeImage) => [...prevBeforeImage, ...imageBeforeArray])
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const setUploadedFile = async (event) => {
    const files = event.target.files;
    displayImageBefore(files);
    if (files.length > 0) {
      const form = new FormData();
      Array.from(files).forEach(file => {
        form.append('file', file, file.name);
      });

      try {
        const response = await axios.post(
          API.UPLOAD_DOCUMENTS,
          form,
          {
            headers: {
              Authorization: `Bearer ${access_token}`
            }
          }
        );
//console.log("response.data",response.data)
        const result = response.data.filePaths;
      //   const updatedDocument = actionPlan.map((item, index) => {
      //     if (index === currentPage) {
      //       return {
      //         ...item,
      //         doc_reference: [
      //           ...(item.doc_reference || []),
      //           ...result,
      //         ],
      //       };
      //     }
      //     return item;
      //   });
      //  //console.log("updatedDocument",updatedDocument)
      //   setActionPlan(updatedDocument);
        setActionPlan((prevPlan) => {
          const updatedPlan = { ...prevPlan }; // If actionPlan is an object
            updatedPlan[currentPage] = {
              ...updatedPlan[currentPage],
              ["doc_reference"]: [
                ...(updatedPlan[currentPage]?.doc_reference || []),
                ...result,
              ],
            };
          setErrors(false);
          return updatedPlan;
        });
      } catch (error) {
        // Handle errors
        console.error('Failed to upload images:', error);
      }
    }
  };

  const handleDelete = (index) => {
    setActionPlan((prevPlan) => {
      const updatedDocument = { ...prevPlan };
  
      // Ensure that currentPage exists and has a doc_reference array
      if (updatedDocument[currentPage] && updatedDocument[currentPage].doc_reference) {
        // Filter out the specific index from doc_reference
        const updatedDocReference = updatedDocument[currentPage].doc_reference.filter((_, i) => i !== index);
  
        updatedDocument[currentPage] = {
          ...updatedDocument[currentPage],
          doc_reference: updatedDocReference,
        };
      }
  
      return updatedDocument;
    });
  
    setErrors(false);
    // setBeforeImage((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };
  

  const updateMocApprovals = async () => {
    const updateMocDet = JSON.parse(localStorage.getItem("mocDataByID"));
    const updatedMocID = updateMocDet.moc_id;
    const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
    const approverSGID = storedUserData.N1SGID;
    const userSGID = storedUserData.SGID;
    const userAppName = storedUserData.FName + " " + storedUserData.LName;
    const mocApprovalID = localStorage.getItem("mocApprovalID");
    const actionID = localStorage.getItem("actionPlanID");
    try {
      const mocData = {
        id: mocApprovalID,
        moc_id: updatedMocID,
        approver_sgid: approverSGID,
        user_sgid: userSGID,
        user_approval_name: userAppName,
        actionplan_id: actionID,
        status: "pending",
      };
      //console.log(mocData, "mocData154")
      const mocResponse = await axios.put(
        `${API.UPDATE_MOC_APPROVALS}?id=${mocApprovalID}`,
        mocData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        }
      );
      if (mocResponse.status === 200) {
        setSave(true);
        setShowSuccessPopup(true);
      }
    } catch (error) {
      console.error("An error occurred in insertIntoMocTable:", error);
    }
  };

  const handleActionSaveClick = (currentPage, actionPlan) => {
    const storedMocDataString = localStorage.getItem("mocDataByID");
    const storedMocData = JSON.parse(storedMocDataString);
    const actionIds = storedMocData.actionplan_id;
    try {
      if (
        actionPlan.length <= currentPage ||
        !actionPlan[currentPage] ||
        !actionPlan[currentPage]?.action_required ||
        !actionPlan[currentPage]?.responsibility ||
        (!actionPlan[currentPage]?.target_date && actionPlan[currentPage]?.action_required==="Yes") 
      ) {
        setErrors(true);
        setStepError(true);
        setSaveMessage(false);
        return;
      } else {
        setErrors(false);
        setStepError(false);
        setSaveMessage(false);
      }
      // Set the corresponding page as filled
      setFilledPages((prevFilledPages) => {
        const updatedFilledPages = [...prevFilledPages];
        updatedFilledPages[currentPage] = true;
        return updatedFilledPages;
      });
      if (currentPage === 0 && (actionIds === null || actionIds === undefined || actionIds === "")) {
        insertActionPlan();
      } else {
        handleActionUpdateClick();
      }
    } catch (error) {
      console.error("An error occurred in handleActionSaveClick:", error);
    }
  };

  const handleActionNextClick = () => {
    try {
      if (
        // !Array.isArray(actionPlan) ||
        actionPlan.length <= currentPage ||
        !actionPlan[currentPage] ||
        !actionPlan[currentPage]?.action_required ||
        !actionPlan[currentPage]?.responsibility ||
        (!actionPlan[currentPage]?.target_date && actionPlan[currentPage]?.action_required==="Yes") 
      ) {
        setErrors(true);
        setStepError(true);
        setSaveMessage("Please fill in all required fields before proceeding.");
        return;
      } else {
        setErrors(false);
        setStepError(false);
        setSaveMessage(false);
      }
      // if (currentPage === 7 && save) {
      //   // navigate("/actionSheet");
      //   navigate("/trials");
      // }
      if (!filledPages[currentPage] && !isEditMoc) {
        setSaveMessage("Please fill in the current page before proceeding.");
        return;
      }
      // if (currentPage === 7) {
      //   // Check if all pages are filled
      //   const allPagesFilled = actionPlan.every(
      //     (page) =>
      //       page &&
      //       page.action_required &&
      //       page.responsibility &&
      //       page.target_date
      //   );
      //   if (allPagesFilled) {
      //     // Call the updateTabs function to switch to the "Trials" tab
      //     updateTabs();
      //     return;
      //   }
      // }
      if (currentPage === 11) {
        updateTabs(); // Call the function passed from the parent component
      } else {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, 11));
      }
    } catch (error) {
      console.error("An error occurred in handleActionNextClick:", error);
    }
  };

  useEffect(() => {
    const fetchActionQuestion = async () => {
      try {
        const response = await axios.get(`${API.GET_QUESTIONS}`, {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        });
        const data = response.data
          .filter((item) => item.tag === "actionPlan")
          .map(({ id, questions, tag }) => ({ id, questions, tag }));
        setQuestionAction(data);
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
      setInitialLoad(false);
    };
    fetchActionQuestion();
  }, [access_token]); // Include access_token in the dependency array if it might change

  useEffect(() => {
    const getActionPlan = async () => {
      const storedMocData = JSON.parse(localStorage.getItem("mocDataByID"));
      const actionIds = storedMocData.actionplan_id;
      if (actionIds === null || actionIds === undefined || actionIds === "") {
        setActionPlan([]);
        setIsEditMoc(false);
      } else {
        try {
          const response = await axios.get(
            `${API.GET_ACTION_PLAN_BY_ID}?id=${actionIds}`,
            {
              headers: {
                Authorization: `Bearer ${access_token}`
              }
            });
          const actionPlanDetails = await response.data;
          const data = actionPlanDetails.actionplan_description;
          const extractedData = data.map(page => {
            const key = Object.keys(page)[0]; // Get the key (e.g., "page 1", "page 2")
            const { target_date, complete_date, responsibility, action_required,doc_reference } = page[key]; // Destructure values
            return { target_date, complete_date, responsibility, action_required,doc_reference };
          });
          setActionPlan(extractedData);
          //console.log(extractedData, "extractedData306")
          setIsEditMoc(true);
        } catch (error) {
          console.error("Error fetching action plan details:", error);
        }
      }
    };
    getActionPlan();
  }, [setActionPlan, setIsEditMoc]);

  const renderActionQuestions = (page) => {
    const handleTargetDate = (currentPage, newTargetDate) => {
      setActionPlan((prevPlan) => {
        const updatedPlan = { ...prevPlan }; // If actionPlan is an object
        updatedPlan[currentPage] = {
          ...updatedPlan[currentPage],
          target_date: newTargetDate,
        };
        setErrors(false);
        return updatedPlan;
      });
    };

    const handleCompleteDate = (currentPage, newCompleteDate) => {
      setActionPlan((prevPlan) => {
        const updatedPlan = { ...prevPlan }; // If actionPlan is an object
        updatedPlan[currentPage] = {
          ...updatedPlan[currentPage],
          complete_date: newCompleteDate,
        };
        setErrors(false);
        return updatedPlan;
      });
    };

    const handleInputChange = (index, field, value) => {
      setActionPlan((prevPlan) => {
        const updatedPlan = { ...prevPlan }; // If actionPlan is an object
        const containsNonAlphabets = /[^A-Za-z\s]/.test(value);
        let specificError = containsNonAlphabets
          ? "Only alphabets are allowed"
          : "";
        if (field === "responsibility") {
          const alphabeticValue = value.replace(/[^A-Za-z\s]/g, "");
          updatedPlan[currentPage] = {
            ...updatedPlan[currentPage],
            [field]: alphabeticValue,
          };
          setResponsibilityError(specificError);
        } else {
          updatedPlan[currentPage] = {
            ...updatedPlan[currentPage],
            [field]: value,
          };
        }
        setErrors(false);
        return updatedPlan;
      });
    };

    const totalActionPlanSteps = 12;
    const handleActionPlanStep = (index) => {
      try {
        if (
          actionPlan.length <= currentPage ||
          !actionPlan[currentPage] ||
          !actionPlan[currentPage]?.action_required ||
          !actionPlan[currentPage]?.responsibility ||
          !actionPlan[currentPage]?.target_date
        ) {
          setErrors(true);
          setStepError(true);
          setSaveMessage(false);
          return;
        } else {
          setErrors(false);
          setStepError(false);
          setSaveMessage(false);
        }
        // Check if the clicked page is filled
        // if (filledPages[index]) {
        setCurrentPage(index);
        // }
      } catch (error) {
        console.error("An error occurred in handleActionSaveClick:", error);
      }
    };

    const mocDetData = JSON.parse(localStorage.getItem("mocDataByID"));
    const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
    const userSGID = storedUserData.SGID;
    let disableCondition;
    if (isEditMoc) {
      disableCondition = userSGID === mocDetData.mocForm.user_id ? false : true;
    }
    //console.log(actionPlan, "actionPlan401")
    //console.log(currentPage, "currentPage402")
    //console.log("actionPlanData",actionPlanData)
    //console.log(actionPlan[currentPage], "actionPlan[currentPage]")
    return (
      <div>
        <Box display="flex" flexDirection="column" alignItems="center">
          {/* <Header pageTitle="Trials Approval" /> */}
          <Box display="flex" alignItems="center">
            <div style={{ marginRight: "10px", fontWeight: "bold" }}>
              {currentPage + 1} of {totalActionPlanSteps} steps
            </div>
          </Box>
          <Box
            display="flex"
            width="80%"
            justifyContent="space-between"
            style={{ padding: 10 }}
          >
            {[...Array(totalActionPlanSteps)].map((_, index) => (
              <div
                key={index}
                style={{
                  width: "30px",
                  height: "3px",
                  borderRadius: "5px",
                  background:
                    index === currentPage ||
                      (index < currentPage && actionPlan[index]?.action_required)
                      ? "#B51849"
                      : "gray",
                  cursor: "pointer"
                }}
                onClick={() => handleActionPlanStep(index)}
              />
            ))}
          </Box>
        </Box>
        {initialLoad ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "25px"
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <Typography
            style={{ fontSize: 20, fontFamily: "sans-serif", padding: 15 }}
            gutterBottom
          >
            {questionAction.length > 0 && questionAction[page].questions}
          </Typography>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginTop: "10px",
              width: "90%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <InputLabel
              style={{
                fontWeight: 600,
                fontStyle: "normal",
                fontFamily: "Inter, sans-serif",
                color: "#000",
                fontSize: 14,
              }}
            >
              Action Required *
            </InputLabel>
            <TextField
              style={{
                width: "100%",
                marginTop: 10,
                textAlign: "left",
                boxSizing: "border-box",
              }}
              // value={actionPlan[currentPage].action_required}
              value={actionPlan[currentPage] ? actionPlan[currentPage].action_required : ""}
              onChange={(e) =>
                handleInputChange(
                  currentPage,
                  "action_required",
                  e.target.value
                )
              }
              disabled={disableCondition}
              error={
                stepError && !actionPlan[currentPage]?.action_required
                  ? true
                  : false
              }
              id="outlined-select-currency"
              select
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </TextField>
          </div>
          {actionPlan[currentPage]?.action_required==="Yes"&&(
            <>
  <div
  style={{
    marginTop: "10px",
    width: "90%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  }}
>
  <InputLabel
    style={{
      fontWeight: 600,
      fontStyle: "normal",
      fontFamily: "Inter, sans-serif",
      color: "#000",
      fontSize: 14,
      marginBottom: 5,
    }}
    htmlFor="date"
  >
    Target Date *
  </InputLabel>
  <TextField
    style={{ width: "100%", background: "#FFFFFF", borderRadius: 4 }}
    id="date"
    type="date"
    variant="outlined"
    InputLabelProps={{
      style: { fontWeight: 500, fontStyle: "normal" },
    }}
    // value={actionPlan[currentPage].target_date}
    value={actionPlan[currentPage] ? actionPlan[currentPage].target_date : ""}
    onChange={(e) => handleTargetDate(currentPage, e.target.value)}
    disabled={disableCondition}
    inputProps={{ max: new Date().toISOString().split("T")[0] }}
    error={
      stepError && !actionPlan[currentPage]?.target_date ? true : false
    }
  />
</div>
<div
  style={{
    marginTop: "10px",
    width: "90%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  }}
>
  <InputLabel
    style={{
      fontWeight: 600,
      fontStyle: "normal",
      fontFamily: "Inter, sans-serif",
      color: "#000",
      fontSize: 14,
      marginBottom: 5,
    }}
    htmlFor="date"
  >
    Completed Date
  </InputLabel>
  <TextField
    style={{ width: "100%", background: "#FFFFFF", borderRadius: 4 }}
    id="date"
    type="date"
    variant="outlined"
    InputLabelProps={{
      style: { fontWeight: 500, fontStyle: "normal" },
    }}
    // value={actionPlan[currentPage].complete_date}
    value={actionPlan[currentPage] ? actionPlan[currentPage].complete_date : ""}
    onChange={(e) => handleCompleteDate(currentPage, e.target.value)}
    disabled={disableCondition}
    inputProps={{ max: new Date().toISOString().split("T")[0] }}
  />
</div>
</>
          )}
        
          <div
            style={{
              marginTop: "10px",
              width: "90%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <InputLabel
              style={{
                fontWeight: 600,
                fontStyle: "normal",
                fontFamily: "Inter, sans-serif",
                color: "#000",
                fontSize: 14,
              }}
            >
              Responsibility *
            </InputLabel>
            <TextField
              style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
              id="outlined-multiline-static"
              // value={actionPlan[currentPage].responsibility}
              value={actionPlan[currentPage] ? actionPlan[currentPage].responsibility : ""}
              onChange={(e) =>
                handleInputChange(currentPage, "responsibility", e.target.value)
              }
              disabled={disableCondition}
              error={
                Boolean(responsibilityError) ||
                  (stepError && !actionPlan[currentPage]?.responsibility)
                  ? true
                  : false
              }
              helperText={
                <span style={{ color: "#FF0000" }}>
                  {responsibilityError || " "}
                </span>
              }
            />
          </div>
          <div
              style={{
                marginTop: "10px",
                width: "90%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <input
                id="upload-image"
                multiple
                type="file"
                style={{ display: "none" }}
                // onChange={setUploadedFile}
                ref={fileInputRef}
              />
              <InputLabel
                style={{
                  fontWeight: 600,
                  fontStyle: "normal",
                  fontFamily: "Inter, sans-serif",
                  color: "#000",
                  fontSize: 16,
                }}
              >
                Doc Reference
              </InputLabel>
              <div
                style={{
                  border: "2px dashed #00448B",
                  padding: "20px",
                  flex: 1,
                  display: "flex",
                  width: "100%",
                  height: 300,
                  marginTop: 10,
                  backgroundColor: "#00448a1a",
                  marginLeft: "-15px",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "4px",
                }}
              >
                <Button
                  // onClick={handleCaptureGallery}
                  style={{
                    width: 30,
                    height: 30,
                    marginLeft: 10,
                    marginBottom: 10,
                    cursor: "pointer",
                    padding: 0, // Remove padding to ensure image is not covered
                    border: "none", // Remove border to ensure image is not covered
                    background: "none", // Remove background to ensure image is not covered
                  }}
                  aria-label="Upload File"
                >
                  <div style={{ marginTop: "30px" }}>
                    <img
                      src={CloudUpload}
                      alt="Uploaded File"
                      style={{ width: "100%", height: "auto", cursor: "pointer" }} // Adjust styling as needed
                      onClick={handleCaptureGallery}
                    />
                  </div>
                </Button>
                <Typography
                  onClick={handleCaptureGallery}
                  style={{
                    textAlign: "center",
                    marginLeft: 15,
                    color: "black",
                    fontWeight: 600,
                    width: 170,
                    fontSize: 16,
                  }}
                >
                  Click to Upload
                </Typography>

                {/* <div style={{ marginTop: "30px" }}>
                  {documentUpload.map((file, index) => (
                    <div key={index}>
                      <img
                        src={file.imagePath}
                        alt={`Uploaded File ${index + 1}`}
                        style={{ width: "100%", height: "auto" }} // Adjust styling as needed
                      />
                    </div>
                  ))}
                </div> */}

                {actionPlan[currentPage] && Array.isArray(actionPlan[currentPage].doc_reference) && actionPlan[currentPage].doc_reference.length > 0 && (
                  <div style={{ marginTop: '10px' }}>
                    {actionPlan[currentPage].doc_reference.map((file, index) => (
                      <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                        <CloudDownloadIcon
                          style={{ width: '50px', height: 'auto', marginRight: '10px', cursor: "pointer" }} // Adjust styling as needed
                          onClick={() => handleDownloadDocument(file)}
                        />
                        <span>{file}</span>
                        <button
                          onClick={() => handleDelete(index)}
                          style={{
                            marginLeft: '10px',
                            background: 'none',
                            border: 'none',
                            color: 'red',
                            fontSize: '20px',
                            cursor: 'pointer'
                          }}
                        >
                          &times;
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          {initialLoad ? (
            <div className="popup-overlay">
              <div className="popup">
                <CircularProgress />
              </div>
            </div>
          ) : showSuccessPopup ? (
            <div className="popup-overlay">
              <div className="popup">
                <p>Data saved successfully!</p>
              </div>
            </div>
          ) : null}
          {initialLoad ? (
            <div className="popup-overlay">
              <div className="popup">
                <CircularProgress />
              </div>
            </div>
          ) : showUpdatePopup ? (
            <div className="popup-overlay">
              <div className="popup">
                <p>Data updated successfully!</p>
              </div>
            </div>
          ) : null}
          {errors ? (
            <Typography variant="subtitle2" color="red">
              {""}
              Please fill all the fields
            </Typography>
          ) : null}
          {saveMessage ? (
            <Typography variant="subtitle2" color="red">
              {""}
              Please Save before going to Next
            </Typography>
          ) : null}
        </div>
      </div>
    );
  };

  const mocDetData = JSON.parse(localStorage.getItem("mocDataByID"));
  const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
  const userSGID = storedUserData.SGID;
  let disableCondition;
  if (isEditMoc) {
    disableCondition = userSGID === mocDetData.mocForm.user_id ? false : true;
  }
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {renderActionQuestions(currentPage)}
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "space-between",
          maxWidth: "90%",
        }}
      >
        {currentPage < 12 &&
          (isEditMoc && !(disableCondition) ? (
            <Button
              variant="contained"
              style={{
                backgroundColor: "#B1174B",
                fontSize: 20,
                color: "white",
                textTransform: "none",
                width: "100%",
                marginTop: 20,
                marginRight: 5,
                marginBottom: 10,
              }}
              onClick={() => handleActionUpdateClick(currentPage, actionPlan)}
            >
              Update
            </Button>
          ) : (!isEditMoc &&
            <Button
              variant="contained"
              style={{
                backgroundColor: "#B1174B",
                fontSize: 20,
                color: "white",
                textTransform: "none",
                width: "100%",
                marginTop: 20,
                marginRight: 5,
                marginBottom: 10,
              }}
              onClick={() => handleActionSaveClick(currentPage, actionPlan)}
            >
              Save
            </Button>
          ))
        }
        {currentPage < 12 && (
          <Button
            variant="contained"
            style={{
              backgroundColor: "#B1174B",
              fontSize: 20,
              color: "white",
              textTransform: "none",
              width: "100%",
              marginTop: 20,
              marginRight: 5,
              marginBottom: 10,
            }}
            onClick={handleActionNextClick}
          >
            Next
          </Button>
        )}
      </div>
    </div>
  );
};

export default ActionPlan;