import React, { Suspense, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LinearProgress } from "@mui/material";
import "./App.css";
import LoginLaunchPage from './Login/LaunchScreen';
import { mainRoutes } from '../src/Route';
import { AuthContext } from "./Pages/AuthContext";
import { RecoilRoot } from "recoil";
import Header from "./app/TopAppBar";
import { styled } from '@mui/material/styles';
import LeftDrawer from "./Pages/LeftDrawer";

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: `${drawerWidth}px`,
    }),
  }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

function App() {
  const currentUser = localStorage.getItem("userData") || '{}';
  const [userId, setUserId] = useState(currentUser);
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <AuthContext.Provider value={{ userId, setAuthTokens: setUserId }}>
      <RecoilRoot>
        <BrowserRouter>
          <Suspense fallback={<LinearProgress />}>
            <Routes>
              <Route path="/" element={<LoginLaunchPage />} />
              <Route path="/login" element={<LoginLaunchPage />} />
              {mainRoutes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <>
                      <Header
                        open={open}
                        handleDrawerOpen={handleDrawerOpen}
                        pageTitle={route.pageTitle}
                        isDashboard={route.isDashboard}
                        isManageMOCPage={route.isManageMOCPage}
                        isNewUser={route.isNewUser}
                        goBack={route.goBack}
                      />
                      <LeftDrawer open={open} handleDrawerClose={handleDrawerClose} />
                      <Main open={open} style={{ padding: '0' }}>
                        <DrawerHeader />
                        {route.component ? <route.component /> : null}
                      </Main>
                    </>
                  }
                />
              ))}
            </Routes>
          </Suspense>
        </BrowserRouter>
      </RecoilRoot>
    </AuthContext.Provider>
  );
}

export default App;
