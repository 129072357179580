import React, { useState } from "react";
import LaunchLogo from "../Asset/Icons/LaunchLogo.svg";
import { Button, Typography, Grid, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import configParam from "../config";
import axios from "axios";
import debug from 'debug';
import API from "../Services/API";
import { userData } from "../recoil/atoms";

const log = debug('login');

const LoginLaunchPage = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  // const access_token = JSON.parse(localStorage.getItem("accesstoken"));

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f4f4f4", // Change this to your preferred background color
  };

  const onHandleSSO = () => {
    const url = configParam.SSO_URL;
    window.location.replace(url);
  };

  // const userDetailsData = async () => {
  //   try {
  //     const queryParameters = new URLSearchParams(window.location.search);
  //     const userDetails = queryParameters.get("UserData");
  //     const err = queryParameters.get("err");
  //     if (userDetails) {
  //       localStorage.setItem("userData", JSON.stringify(userDetails));
  //     } else {
  //       setError(err || "");
  //       setLoading(false);
  //     }
  //   } catch (err) {
  //     localStorage.setItem("userDetails", "");
  //     setError(err ? err : "Access denied");
  //     setError("Error processing user details");
  //     setLoading(false);
  //     console.error(err);
  //   }
  // };

  // const apiUserDetails = async () => {
  //   try {
  //     const queryParameters = new URLSearchParams(window.location.search);
  //     const userDetails = queryParameters.get("UserData");
  //     if (userDetails) {
  //       const cleanedUserDetails = userDetails.replace(/"/g, "");
  //       const userDetailsServiceURL = `${configParam.API_URL}users/${cleanedUserDetails}`;
  //       const response = await axios.get(userDetailsServiceURL);
  //       const apiUserData = response.data.userDetails;
  //       localStorage.setItem("userDatas", JSON.stringify(apiUserData));
  //       window.location.href = "/Dashboard";
  //     } else {
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     setError("Error processing user details");
  //     setLoading(false);
  //     console.error(error);
  //   }
  // };
  const url = window.location.href;
  const Response = decodeURIComponent(url);
  const myArray = Response.split("?");
  if (myArray.length > 1) {
    const queryParameters = new URLSearchParams(window.location.search)
    const UserDetails = queryParameters.get("UserData");
  }
  const userDetails = async () => {
    const url = window.location.href;
    //console.log(url, "url82")
    const Response = decodeURIComponent(url);
    const myArray = Response.split("?");
    log(Response)
    if (myArray.length > 1) {
      const queryParameters = new URLSearchParams(window.location.search)
      //console.log(queryParameters, "queryParameters87")
      const UserDetails = queryParameters.get("UserData");
      //console.log(UserDetails, "UserDetails88")
      const access_token = queryParameters.get("access_token");
      const reponse = await axios.get(
        `${API.CHECK_USER_SGID}?id=${UserDetails}`);
      //console.log(reponse, "reponse94")
      // const secret = queryParameters.get("secret_key");
      // const err = queryParameters.get("err");
      // const currentUserDetails = JSON.parse(UserDetails)
      // const currentUserDetails = response.data.userDetails;
      if (reponse.data) {
        localStorage.setItem("userDatas", JSON.stringify(reponse.data[0]));
        localStorage.setItem("accesstoken", access_token);
        // localStorage.setItem("secret", secret);
        const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
        const plantSelection = storedUserData.plant === "Sekurit";
        window.location.href = plantSelection ? "/dashboardcms" : "/Dashboard";
      } else {
        localStorage.setItem("userDetails", "");
        // setError(err ?? "Access denied");
        setLoading(false);
      }
    }
  }

  const loadAllUser = async (access_token) => {
    try {
      await axios.get(API.GET_ALL_USERS, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      }).then(function (response) {
        if (response.status === 200 && response.data.length > 0) {
          const filteredResponse = response.data.filter(user => user.deleted !== true);
          localStorage.setItem("allUsersData", JSON.stringify(filteredResponse));
        }
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const fetchUserDetails = async (code) => {
    try {
      const response1 = await axios.get(`${API.LOGIN}`, {
        params: {
          code: code
        }
      });
      //console.log("responseeeee", response1)
      if (response1.data.success) {
        const reponse = await axios.get(
          `${API.CHECK_USER_SGID}?id=${response1.data.UserData}`);
        //console.log(reponse, "reponse94")
        if (reponse.data) {
          localStorage.setItem("userDatas", JSON.stringify(reponse.data[0]));
          localStorage.setItem("accesstoken", JSON.stringify(response1.data.access_token));
          await loadAllUser(response1.data.access_token);
          const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
          const plantSelection = storedUserData.plant === "Sekurit";
          window.location.href = plantSelection ? "/dashboardcms" : "Dashboard";
          // window.location.href = "/Dashboard";
        } else {
          localStorage.setItem("userDetails", "");
          // setError(err ?? "Access denied");
          setLoading(false);
        }
        //  localStorage.setItem("accesstoken", access_token);
        const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
        const plantSelection = storedUserData.plant === "Sekurit";
        window.location.href = plantSelection ? "/dashboardcms" : "Dashboard";
        // window.location.href = "/Dashboard";
      } else {
        // setError(err ?? "Access denied");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error during login:", error);
      setError("An error occurred during login");
      setLoading(false);
    }
  };

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const code = queryParameters.get("code");

    //console.log(code, "code168",)
    const currentcode = localStorage.getItem("code")
    // loadAllUser();
    if (currentcode === null && code) {
      localStorage.setItem("code", code)
      fetchUserDetails(code);
    }
    else if (currentcode !== null && currentcode !== code && code) {
      fetchUserDetails(code);
      localStorage.setItem("code", code)
    } else {
      setLoading(false);
    }
  }, []);

  // useEffect(() => {
  //   const fetchUserDetails = async () => {
  //     try {
  //       const queryParameters = new URLSearchParams(window.location.search);
  //       const userData = queryParameters.get("UserData");
  //       const err = queryParameters.get("err");
  //       await loadAllUser();
  //       if (userData) {
  //         // await userDetailsData(); // Wait for userDetails to complete
  //         // await apiUserDetails(); // Now call apiUserDetails
  //         await userDetails();
  //       } else if (userData === null) {
  //         setLoading(false);
  //         setError(err);
  //       }
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };
  //   fetchUserDetails();
  // }, []);

  return (
    <Grid
      container
      style={{ ...containerStyle, height: "100vh" }}
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        item
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <img src={LaunchLogo} alt="Logo" className="icon" />
        </Grid>
        <Grid item container direction="column" alignItems="center">
          {!loading ? (
            <Button
              variant="contained"
              style={{
                backgroundColor: "#B1174B",
                fontSize: 20,
                color: "white",
                textTransform: "none",
                width: "335px",
                marginTop: 20,
              }}
              onClick={onHandleSSO}
            >
              Login
            </Button>
          ) : (
            <CircularProgress />
          )}
          <Typography
            variant="subtitle1"
            style={{ color: "red", marginTop: "15px" }}
          >
            {error}&nbsp;
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="body1"
            style={{
              marginTop: 30,
              fontSize: 14,
              textAlign: "center",
              marginBottom: "15px",
            }}
          >
            © 2024 INDEC4.0 Saint-Gobain
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoginLaunchPage;