import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormControl, Radio, RadioGroup, FormControlLabel, InputLabel, Tab, Tabs, TextField, Typography, Button, CircularProgress, Dialog, DialogContent } from "@mui/material";
import Header from "../app/TopAppBar";
import { useRecoilState } from "recoil";
import { changeAssessmentData, editMocData } from "../recoil/atoms";
import axios from "axios";
import API from "../Services/API";
import configParam from "../config";

const additionalQuestions = configParam.ADDITIONAL_QUESTIONS;

export const getQuestionsForAffect = (affect) => {
  return additionalQuestions[affect] || [];
};

const EHSF = ({ open, onClose }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const changeAssessment = location?.state?.changeAssessment || [];
  const assessmentData = location?.state?.assessmentData || [];
  const [selectedTab, setSelectedTab] = useState(0);
  // const [results, setResults] = useState(Array(changeAssessment.length).fill(""));
  const [ehsfResults, setEHSFResults] = useRecoilState(changeAssessmentData);
  const [isEditMoc] = useRecoilState(editMocData);
  // const [checkpoints_remarks, setCheckpointsRemarks] = useState(Array(changeAssessment.length).fill(""));
  const [save, setSave] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [errors, setErrors] = useState(false);
  const [saveMessage, setSaveMessage] = useState(false);
  const [stepError, setStepError] = useState(false);
  const [initialLoad, setInitialLoad] = useState(false);

  const access_token = JSON.parse(localStorage.getItem("accesstoken"));

  useEffect(() => {
    const handlePopup = (setter, delay) => {
      setter(true);
      const timer = setTimeout(() => {
        setter(false);
      }, delay);
      return () => clearTimeout(timer);
    };
    const handlePopups = (showPopup, setShowPopup) => {
      if (showPopup) {
        setInitialLoad(true);
        setTimeout(() => {
          setInitialLoad(false);
          handlePopup(setShowPopup, 1000);
        }, 2000);
      }
    };
    handlePopups(showSuccessPopup, setShowSuccessPopup);
    handlePopups(showUpdatePopup, setShowUpdatePopup);
  }, [showSuccessPopup, showUpdatePopup]);

  useEffect(() => {
    if (!isEditMoc) {
      const filterAdditionalQuestions = (changeAffects, questions) => {
        return changeAffects.reduce((filtered, affect) => {
          if (questions[affect]) {
            filtered[affect] = questions[affect];
          }
          return filtered;
        }, {});
      };

      const updateEhsfResults = (filteredQuestions) => {
        return Object.keys(filteredQuestions).reduce((updatedResults, key) => {
          const questionsLength = filteredQuestions[key].length;
          updatedResults[`${key.toLowerCase()}_results`] = Array(questionsLength).fill("No");
          updatedResults[`${key.toLowerCase()}_remarks`] = Array(questionsLength).fill("");
          return updatedResults;
        }, {});
      };

      // Filter the additionalQuestion object
      const filteredQuestions = filterAdditionalQuestions(changeAssessment, additionalQuestions);

      // Get the updated results and remarks
      const newEhsfResults = updateEhsfResults(filteredQuestions);

      // Update the state
      setEHSFResults((prev) => ({
        ...prev,
        ...newEhsfResults,
      }));
    }
  }, []); // Empty dependency array ensures this effect runs only once

  const handleTabChange = (_, newValue) => {
    setSelectedTab(newValue);
  };

  const tabs = changeAssessment.map((value, index) => (
    <Tab key={index} label={value} />
  ));

  const handleRadioChange = (index, value) => {
    setEHSFResults((prev) => {
      const updatedResults = { ...prev };
      localStorage.setItem("isEHSFSave", false);

      switch (changeAssessment[selectedTab]) {
        case 'Environment':
          updatedResults.environment_results = [...updatedResults.environment_results];
          updatedResults.environment_results[index] = value;
          break;
        case 'Health':
          updatedResults.health_results = [...updatedResults.health_results];
          updatedResults.health_results[index] = value;
          break;
        case 'Safety':
          updatedResults.safety_results = [...updatedResults.safety_results];
          updatedResults.safety_results[index] = value;
          break;
        case 'Fire':
          updatedResults.fire_results = [...updatedResults.fire_results];
          updatedResults.fire_results[index] = value;
          break;
        default:
          break;
      }
      return updatedResults;
    });
  };



  const showRemedialActions = (index) => {
    switch (changeAssessment[selectedTab]) {
      case 'Environment':
        return ehsfResults.environment_results[index] !== "No";
      case 'Health':
        return ehsfResults.health_results[index] !== "No";
      case 'Safety':
        return ehsfResults.safety_results[index] !== "No";
      case 'Fire':
        return ehsfResults.fire_results[index] !== "No";
      default:
        return true;
    }
  };

  const handleRemarksChange = (index, event) => {
    setEHSFResults((prevResults) => {
      const newResults = { ...prevResults };
      let updatedRemarks = event.target.value;
      localStorage.setItem("isEHSFSave", false);
      if (updatedRemarks.length > 200) {
        updatedRemarks = updatedRemarks.slice(0, 200);
      }
      switch (changeAssessment[selectedTab]) {
        case 'Environment':
          newResults.environment_remarks = [...prevResults.environment_remarks];
          newResults.environment_remarks[index] = updatedRemarks;
          break;
        case 'Health':
          newResults.health_remarks = [...prevResults.health_remarks];
          newResults.health_remarks[index] = updatedRemarks;
          break;
        case 'Safety':
          newResults.safety_remarks = [...prevResults.safety_remarks];
          newResults.safety_remarks[index] = updatedRemarks;
          break;
        case 'Fire':
          newResults.fire_remarks = [...prevResults.fire_remarks];
          newResults.fire_remarks[index] = updatedRemarks;
          break;
        default:
          break;
      }
      setErrors(false);
      return newResults;
    });
  };

  const assessmentID = localStorage.getItem("assessmentID");
  const handleEHSFSaveClick = async () => {
    const hasEmptyResultsOrRemarks = (results, remarks) => {
      return results.some((result, index) => {
        if (result === 'No' && remarks[index] !== "") {
          return true;
        }
        if (result === 'Yes' && (remarks[index] === "" || remarks[index] === null)) {
          return true;
        }
        if (result === '') {
          return true;
        }
        return false;
      });
    };
    if (
      (ehsfResults.change_affects.includes("Environment") && hasEmptyResultsOrRemarks(ehsfResults.environment_results, ehsfResults.environment_remarks)) ||
      (ehsfResults.change_affects.includes("Health") && hasEmptyResultsOrRemarks(ehsfResults.health_results, ehsfResults.health_remarks)) ||
      (ehsfResults.change_affects.includes("Safety") && hasEmptyResultsOrRemarks(ehsfResults.safety_results, ehsfResults.safety_remarks)) ||
      (ehsfResults.change_affects.includes("Fire") && hasEmptyResultsOrRemarks(ehsfResults.fire_results, ehsfResults.fire_remarks))
    ) {
      setErrors(true);
      setStepError(true);
      setSaveMessage(false);
      return;
    } else {
      setErrors(false);
      setStepError(false);
      setSaveMessage(false);
    }
    const additionalQuestionsData = {
      id: assessmentID,
      assessment_data: assessmentData,
      change_affects_json: changeAssessment.map((changeAffect, tabIndex) => {
        let tabResults;
        let tabRemarks;
        switch (changeAssessment[tabIndex]) {
          case 'Environment':
            tabResults = ehsfResults.environment_results
              ? ehsfResults.environment_results
              : "";
            tabRemarks = ehsfResults.environment_remarks
              ? ehsfResults.environment_remarks
              : "";
            break;
          case 'Health':
            tabResults = ehsfResults.health_results
              ? ehsfResults.health_results
              : "";
            tabRemarks = ehsfResults.health_remarks
              ? ehsfResults.health_remarks
              : "";
            break;
          case 'Safety':
            tabResults = ehsfResults.safety_results
              ? ehsfResults.safety_results
              : "";
            tabRemarks = ehsfResults.safety_remarks
              ? ehsfResults.safety_remarks
              : "";
            break;
          case 'Fire':
            tabResults = ehsfResults.fire_results ? ehsfResults.fire_results : "";
            tabRemarks = ehsfResults.fire_remarks ? ehsfResults.fire_remarks : "";
            break;
          default:
            tabResults = [];
            tabRemarks = [];
        }
        return {
          [changeAffect]: additionalQuestions[changeAffect].map(
            (question, index) => ({
              results: tabResults[index],
              checkpoint: question,
              checkpoints_remarks: tabRemarks[index],
            })
          ),
        };
      }),
    };
    try {
      const response = await axios.put(
        API.UPDATE_EHSF,
        additionalQuestionsData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        }
      );
      if (response.status === 200) {
        await mocForm(assessmentID);
        localStorage.setItem("isEHSFSave", true);
        setSave(true);
        setErrors(false);
        setShowSuccessPopup(true);
        if (isEditMoc) {
          setShowUpdatePopup(true);
        }
      }
    } catch (error) {
      console.error("An error occurred in update trials:", error);
    }
  };
//console.log("ehsfresultsss",ehsfResults)
  const mocForm = async () => {
    const updateMocDet = localStorage.getItem("mocID");
    const requestformID = localStorage.getItem("requestformID");
    const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
    const userSGID = updateMocDet ? storedUserData.SGID : "";
    const userName = storedUserData.FName + " " + storedUserData.LName;
    try {
      const mocData = {
        id: updateMocDet,
        user_id: userSGID,
        updated_at: new Date(),
        created_by: userName,
        doc_ref: "SGI-IMS-FOR-19",
        rev_date: new Date(),
        request_form_id: requestformID,
        assessment_id: assessmentID,
        submission_status: requestformID && assessmentID ? "pending" : "",
      };
      const mocResponse = await axios.put(
        `${API.UPDATE_MOC_FORM}?id=${updateMocDet}`,
        mocData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        }
      );
      if (mocResponse.status === 200) {
        await updateMocApprovals(updateMocDet);
        setSave(true);
        setShowSuccessPopup(true);
      }
    } catch (error) {
      console.error("An error occurred in insertIntoMocTable:", error);
    }
  };

  const updateMocApprovals = async () => {
    const updateMocDet = localStorage.getItem("mocID");
    const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
    const approverSGID = storedUserData.N1SGID;
    const userSGID = updateMocDet ? storedUserData.SGID : "";
    const mocApprovalID = localStorage.getItem("mocApprovalID");
    const userAppName = updateMocDet ? storedUserData.FName + " " + storedUserData.LName : "";
    try {
      const mocData = {
        id: mocApprovalID,
        moc_id: updateMocDet,
        approver_sgid: approverSGID,
        user_sgid: userSGID,
        user_approval_name: userAppName,
        status: "pending",
      };
      const mocResponse = await axios.put(
        `${API.UPDATE_MOC_APPROVALS}?id=${mocApprovalID}`,
        mocData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        }
      );
      if (mocResponse.status === 200) {
        setSave(true);
        setShowSuccessPopup(true);
      }
    } catch (error) {
      console.error("An error occurred in insertIntoMocTable:", error);
    }
  };

  const handleEHSFNextClick = () => {
    const isEHSFSave = JSON.parse(localStorage.getItem("isEHSFSave"));

    const hasEmptyResultsOrRemarks = (results, remarks) => {
      return results.some((result, index) => {
        if (result === 'No' && remarks[index] !== "") {
          return true;
        }
        if (result === 'Yes' && (remarks[index] === "" || remarks[index] === null)) {
          return true;
        }
        if (result === '') {
          return true;
        }
        return false;
      });
    };
    if (
      (ehsfResults.change_affects.includes("Environment") && hasEmptyResultsOrRemarks(ehsfResults.environment_results, ehsfResults.environment_remarks)) ||
      (ehsfResults.change_affects.includes("Health") && hasEmptyResultsOrRemarks(ehsfResults.health_results, ehsfResults.health_remarks)) ||
      (ehsfResults.change_affects.includes("Safety") && hasEmptyResultsOrRemarks(ehsfResults.safety_results, ehsfResults.safety_remarks)) ||
      (ehsfResults.change_affects.includes("Fire") && hasEmptyResultsOrRemarks(ehsfResults.fire_results, ehsfResults.fire_remarks))
    ) {
      setErrors(true);
      setStepError(true);
      setSaveMessage(false);
      return;
    } else {
      setErrors(false);
      setStepError(false);
      setSaveMessage(false);
    }
    // Clear state for the current tab
    setEHSFResults((prevResults) => {
      const newResults = { ...prevResults };
      return newResults;
    });
    if (save || isEHSFSave || isEditMoc) {
      navigate("/Conclusion");
    } else {
      setSaveMessage("Failed to save. Please try again.");
    }

    // const nextTab = getNextAvailableTab();

    // Navigate to the next tab
    // if (nextTab < changeAssessment.length) {
    //   // Navigate to the next available tab
    //   setSelectedTab(nextTab);
    // } else {
    //   // If there are no more tabs, navigate to the Conclusion page
    //   navigate("/Conclusion");
    // }
  };

  // const getNextAvailableTab = () => {
  //   let nextTab = selectedTab;
  //   while (nextTab < changeAssessment.length) {
  //     if (additionalQuestions[changeAssessment[nextTab]]) {
  //       return nextTab;
  //     }
  //     nextTab++;
  //   }
  //   return changeAssessment.length; // Return the length to indicate the last tab
  // };

  useEffect(() => {
    if (isEditMoc) {
      const getEHSF = async () => {
        const storedMocDataString = localStorage.getItem("mocDataByID");
        const storedMocData = JSON.parse(storedMocDataString);
        const assessmentID = storedMocData?.mocForm.assessment_id;
        try {
          const response = await axios.get(
            `${API.GET_ASSESSMENT}?id=${assessmentID}`,
            {
              headers: {
                Authorization: `Bearer ${access_token}`
              }
            });
          const assessData = await response.data;
          let checkpoint = [];
          let environmentResult = [];
          let environmentRemark = [];
          let healthResult = [];
          let healthRemark = [];
          let safetyResult = [];
          let safetyRemark = [];
          let fireResult = [];
          let fireRemark = [];
          assessData.checkpoints.map((data, tabIndex) => {
            if (data.change_affects_json) {
              data.change_affects_json.forEach((changeAffect) => {
                const key = Object.keys(changeAffect)[0];
                const tabResults = changeAffect[key].map(
                  (entry) => entry.results || ""
                );
                const tabRemarks = changeAffect[key].map(
                  (entry) => entry.checkpoints_remarks || ""
                );
                switch (key) {
                  case "Environment":
                    environmentResult = environmentResult.concat(tabResults);
                    environmentRemark = environmentRemark.concat(tabRemarks);
                    break;
                  case "Health":
                    healthResult = healthResult.concat(tabResults);
                    healthRemark = healthRemark.concat(tabRemarks);
                    break;
                  case "Safety":
                    safetyResult = safetyResult.concat(tabResults);
                    safetyRemark = safetyRemark.concat(tabRemarks);
                    break;
                  case "Fire":
                    fireResult = fireResult.concat(tabResults);
                    fireRemark = fireRemark.concat(tabRemarks);
                    break;
                  default:
                    break;
                }
              });
            }
            return null;
          });
          setEHSFResults((prev) => ({
            ...prev,
            checkpoint: checkpoint,
            environment_results: environmentResult,
            environment_remarks: environmentRemark,
            health_results: healthResult,
            health_remarks: healthRemark,
            safety_results: safetyResult,
            safety_remarks: safetyRemark,
            fire_results: fireResult,
            fire_remarks: fireRemark,
          }));
        } catch (error) {
          console.error("Error fetching MOC details:", error);
        }
      };
      getEHSF();
    }
  }, [setEHSFResults, isEditMoc]);

  const CPQuestionsStyle = {
    fontWeight: 550,
    fontSize: 16,
    fontStyle: "normal",
    fontFamily: "Inter, sans-serif",
    color: "black",
    lineHeight: 1.5,
    textTransform: "capitalize",
    marginTop: "10px",
  };
  const mocDetData = JSON.parse(localStorage.getItem("mocDataByID"));
  const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
  const userSGID = storedUserData.SGID;
  let disableCondition;
  let updateDisable;
  if (isEditMoc) {
    disableCondition = userSGID === mocDetData.mocForm.user_id ? false : true;
    updateDisable = mocDetData.mocForm.submission_status === "approved" ? true : false;
  }

  return (
    <DialogContent>
      <div>
        <div style={{ justifyContent: "center" }}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            sx={{
              maxWidth: "400px",
              "& .MuiTab-textColorPrimary.Mui-selected": {
                color: "#B51849",
              },
              "& .MuiTabs-indicator": {
                backgroundColor: "#B51849",
              },
            }}
          >
            {tabs}
          </Tabs>
        </div>
        <div style={{ marginTop: "20px" }}>
          {additionalQuestions[changeAssessment[selectedTab]] && (
            <div>
              {additionalQuestions[changeAssessment[selectedTab]].map(
                (question, index) => (
                  <div key={index} style={{ marginBottom: "20px" }}>
                    <div style={{ display: "flex", marginBottom: "10px" }}>
                      <span style={{ fontWeight: 500, marginRight: "5px" }}>
                        {index + 1}.{" "}
                      </span>
                      <div style={{ ...CPQuestionsStyle, margin: 0 }}>
                        {question}
                      </div>
                    </div>
                    <div style={{ marginTop: "10px", marginRight: "5px" }}>
                      <div style={{ marginLeft: "20px" }}>
                        <FormControl component="fieldset">
                          <RadioGroup
                            value={
                              changeAssessment[selectedTab] === 'Environment'
                                ? (ehsfResults.environment_results && ehsfResults.environment_results[index]) || null
                                : changeAssessment[selectedTab] === 'Health'
                                  ? (ehsfResults.health_results && ehsfResults.health_results[index]) || null
                                  : changeAssessment[selectedTab] === 'Safety'
                                    ? (ehsfResults.safety_results && ehsfResults.safety_results[index]) || null
                                    : changeAssessment[selectedTab] === 'Fire'
                                      ? (ehsfResults.fire_results && ehsfResults.fire_results[index]) || null
                                      : null
                            }
                            onChange={(event) => handleRadioChange(index, event.target.value)}
                            row
                          >
                            <FormControlLabel
                              value="Yes"
                              control={<Radio />}
                              label="Yes"
                              disabled={disableCondition}
                            />
                            <FormControlLabel
                              value="No"
                              control={<Radio />}
                              label="No"
                              disabled={disableCondition}
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                      {showRemedialActions(index) &&
                        <div style={{ marginTop: "10px" }}>
                          <InputLabel
                            style={{
                              fontWeight: 600,
                              fontStyle: "normal",
                              fontFamily: "Inter, sans-serif",
                              fontSize: 14,
                              color: "#000000",
                              marginLeft: "15px",
                            }}
                          >
                            Recommended remedial actions *
                          </InputLabel>
                          <div style={{ display: "flex" }}>
                            <TextField
                              style={{
                                width: "100%",
                                marginTop: 10,
                                background: "#FFFFFF",
                                borderRadius: 4,
                                marginLeft: "15px",
                              }}
                              variant="outlined"
                              multiline
                              rows={3}
                              // value={checkpoints_remarks[index]}
                              value={
                                changeAssessment[selectedTab] === 'Environment'
                                  ? ehsfResults.environment_remarks[index] || ""
                                  : changeAssessment[selectedTab] === 'Health'
                                    ? ehsfResults.health_remarks[index] || ""
                                    : changeAssessment[selectedTab] === 'Safety'
                                      ? ehsfResults.safety_remarks[index] || ""
                                      : changeAssessment[selectedTab] === 'Fire'
                                        ? ehsfResults.fire_remarks[index] || ""
                                        : ""
                              }
                              onChange={(event) =>
                                handleRemarksChange(index, event)
                              }
                              error={
                                stepError &&
                                (changeAssessment[selectedTab] === 'Environment'
                                  ? !ehsfResults.environment_remarks[index] ||
                                  ehsfResults.environment_remarks[index] === ""
                                  : changeAssessment[selectedTab] === 'Health'
                                    ? !ehsfResults.health_remarks[index] ||
                                    ehsfResults.health_remarks[index] === ""
                                    : changeAssessment[selectedTab] === 'Safety'
                                      ? !ehsfResults.safety_remarks[index] ||
                                      ehsfResults.safety_remarks[index] === ""
                                      : changeAssessment[selectedTab] === 'Fire'
                                        ? !ehsfResults.fire_remarks[index] ||
                                        ehsfResults.fire_remarks[index] === ""
                                        : false)
                              }
                              disabled={disableCondition}
                            />
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                )
              )}
              {initialLoad ? (
                <div className="popup-overlay">
                  <div className="popup">
                    <CircularProgress />
                  </div>
                </div>
              ) : showSuccessPopup ? (
                <div className="popup-overlay">
                  <div className="popup">
                    <p>Data saved successfully!</p>
                  </div>
                </div>
              ) : null}
              {initialLoad ? (
                <div className="popup-overlay">
                  <div className="popup">
                    <CircularProgress />
                  </div>
                </div>
              ) : showUpdatePopup ? (
                <div className="popup-overlay">
                  <div className="popup">
                    <p>Data updated successfully!</p>
                  </div>
                </div>
              ) : null}
              {errors && (
                <div
                  style={{ color: "red", marginTop: "10px", textAlign: "center" }}
                >
                  Please answer all the questions
                </div>
              )}
              {saveMessage ? (
                <Typography variant="subtitle2" color="red">
                  {""}
                  Please Save before going to Next
                </Typography>
              ) : null}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                  //   backgroundColor: "#ECECEC",
                }}
              >
                {isEditMoc && !disableCondition ? (
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#B1174B",
                      fontSize: 20,
                      color: "white",
                      textTransform: "none",
                      width: "75%",
                      marginTop: 20,
                      marginRight: 5,
                      marginBottom: 10,
                    }}
                    disabled={updateDisable}
                    onClick={handleEHSFSaveClick}
                  >
                    Update
                  </Button>
                ) : (
                  !isEditMoc && (
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#fff",
                        fontSize: 20,
                        color: "#B1174B",
                        border: "1px solid #B1174B",
                        textTransform: "none",
                        width: "75%",
                        marginTop: 20,
                        marginRight: 5,
                        marginBottom: 10,
                        boxShadow: "none",
                      }}
                      onClick={handleEHSFSaveClick}
                    >
                      Save
                    </Button>
                  )
                )}
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#B1174B",
                    fontSize: 20,
                    color: "white",
                    textTransform: "none",
                    width: "75%",
                    marginTop: 20,
                    marginRight: 5,
                    marginBottom: 10,
                  }}
                  onClick={handleEHSFNextClick}
                >
                  Next
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </DialogContent>
  );
};

export default EHSF;