import React from "react";
import { Typography, Grid, Card } from "@mui/material";

const Cards = ({
    allMocCount,
    pendingMocCount,
    resubmittedMocCount,
    completedMocCount,
    rejectedMocCount,
}) => {
    const mocData = [
        { id: 1, name: "All MOC" },
        { id: 2, name: "Pending MOC" },
        { id: 3, name: "Resubmitted MOC" },
        { id: 4, name: "Completed MOC" },
        { id: 5, name: "Rejected MOC" },
    ];

    const valueMap = {
        "All MOC": allMocCount,
        "Pending MOC": pendingMocCount,
        "Resubmitted MOC": resubmittedMocCount,
        "Completed MOC": completedMocCount,
        "Rejected MOC": rejectedMocCount,
    };
    return (
        <Grid
            container
            spacing={2}
            style={{ display: "flex", marginBottom: "10px" }}
        >
            {mocData.map((e) => (
                <Grid item key={e.id} md sm={6} xs={12}>
                    <Card style={{ padding: "16px", background: "#B1174B" }}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography
                                style={{ fontSize: 14, fontWeight: 400, color: "#fff" }}
                            >
                                {e.name}
                            </Typography>
                        </div>
                        <div>
                            <div style={{ float: "left", paddingRight: 7 }}>
                                <Typography
                                    style={{ fontSize: 28, fontWeight: 400, color: "#fff" }}
                                >
                                    {valueMap[e.name] || e.value}
                                </Typography>
                            </div>
                        </div>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default Cards;