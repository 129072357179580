import API from "../Services/API";
import axios from "axios";

export const handleSend = async (from_email, to_email) => {
    const access_token = JSON.parse(localStorage.getItem("accesstoken"));
    // Static CFT team emails
    const cft_team = [
        "navina.m@saint-gobain.com",
        "sharan.balaji@saint-gobain.com",
        "ramanujan.j@saint-gobain.com",
        "arvind.b@saint-gobain.com"
    ];
    try {
        await insertReportStatus(from_email, to_email, cft_team);
        const updateMocDet = localStorage.getItem("mocID");
        const storedUserData = JSON.parse(localStorage.getItem("userDatas"));

        const approverSGID = storedUserData.N1SGID;
        const userSGID = updateMocDet ? storedUserData.SGID : "";
        const mocApprovalID = localStorage.getItem("mocApprovalID");
        const userAppName = updateMocDet ? storedUserData.FName + " " + storedUserData.LName : "";
        const sendMailID = localStorage.getItem("sendMailID");

        const mocData = {
            id: mocApprovalID,
            moc_id: updateMocDet,
            approver_sgid: approverSGID,
            // user_sgid: userSGID,
            // user_approval_name: userAppName,
            // send_mail_id: sendMailID,
        };

        const mocResponse = await axios.put(`${API.UPDATE_MOC_APPROVALS}?id=${mocApprovalID}`, mocData, {
            headers: {
                Authorization: `Bearer ${access_token}`
            }
        }
        );

        if (mocResponse.status === 200) {
            // setSave(true);
            // setShowSuccessPopup(true);
            return mocResponse.status;
        }
        
       
    } catch (error) {
        console.error("An error occurred in handleSend:", error);
        return error.response?.status || 500;
        // Handle error state or display error message to the user
    }
};

const insertReportStatus = async (from_email, to_email, cft_team) => {

    const mocID = localStorage.getItem("mocID");
    const access_token = JSON.parse(localStorage.getItem("accesstoken"));
    const userData = JSON.parse(localStorage.getItem("userDatas"));
    const mocData = JSON.parse(localStorage.getItem("mocData"));
    const mocNumberData = localStorage.getItem("mocNumber");
    let messageHint = '';
    //console.log(userData, "userData50");
    //console.log(mocData, "mocData51", mocNumberData, "mocNumberData51");
    const mocDetData = JSON.parse(localStorage.getItem("mocDataByID"));
    //console.log(mocDetData, "mocDetData534");
    //console.log(userData.SGID, "userData.SGID55");
    //console.log(mocData?.user_id, "mocData?.user_id56");
    //console.log(mocDetData?.mocForm?.user_id, "mocDetData?.mocForm?.user_sgid58");
    const userId = mocData?.user_id ? mocData?.user_id : mocDetData?.mocForm?.user_id;
    //console.log(userId, "userId60");
    const created_by = mocData?.created_by;
    if (userData.SGID === userId) {
        messageHint = 'Kindly review and update the MOC details with all evidences.';
    }
    if (userData.SGID !== userId) {
        messageHint = 'Kindly review the MOC details with all evidences and approve the same.';
    }
    //console.log(messageHint, "messageHint57")
    const messageText = `Dear ${userData.FName + " " + userData.LName},

    Greetings,
    
    MOC request no. ${mocNumberData ? mocNumberData : mocDetData?.mocForm?.moc_no} needs your kind attention.
    
    ${messageHint}
    
    MOC created by: ${created_by ? created_by : mocDetData?.mocForm?.created_by}
    
    MOC Link: https://dev.sgmoc.saint-gobain.com/login`;


    try {
        const emailFieldsData = {
            from_email: from_email,
            to_email: to_email,
            cft_team: cft_team,
            moc_id: mocID,
            message: messageText,
        };
        const response = await axios.post(API.INSERT_EMAIL_REPORT, emailFieldsData, {
            headers: {
                Authorization: `Bearer ${access_token}`
            }
        });
        if (response.status === 200) {
            const sendMailID = response.data["identifiers"][0].id;
            localStorage.setItem("sendMailID", sendMailID);
            await sendEmail(from_email, to_email, cft_team, messageText);
            // handleClose();
        }
    } catch (error) {
        //console.log(error);
    }
};

const sendEmail = async (from_email, to_email, cft_team, message) => {
    const access_token = JSON.parse(localStorage.getItem("accesstoken"));
    const userData = JSON.parse(localStorage.getItem("userDatas"));
    const mocDetData = JSON.parse(localStorage.getItem("mocDataByID"));
    const mocNumberData = localStorage.getItem("mocNumber");
    try {
        const emailParameters = {
            from_email: from_email,
            to_email: to_email,
            cft_team: cft_team,
            subject: mocNumberData ? mocNumberData : mocDetData?.mocForm?.moc_no,
            message: message,
            // name: userData.name,
            // created_by: mocDetData.mocForm.created_by,
            // moc_no: mocDetData.mocForm.moc_no,
        }
        const response = await axios.post(API.SEND_MAIL_API, emailParameters, {
            headers: {
                Authorization: `Bearer ${access_token}`
            }
        });
        // setEmailStatus(response.data.message);
        // handleClose();
        // navigate("/ManageMoc");
    } catch (error) {
        // setEmailStatus('Failed to send email');
        console.error(error);
    }
};