import React from "react";
import Header from "../app/TopAppBar";
import { InputLabel, TextField } from "@mui/material";
import saveLarge from "../Asset/Icons/save_large.svg";

const Function = () => {
  return (
    <div style={{ background: "#F7F7F7", minHeight: "90vh" }}>
      {/* <Header pageTitle="Function" /> */}
      <div style={{ marginTop: "60px", padding: 10 }}>
        <InputLabel
          style={{
            fontWeight: 500,
            fontStyle: "normal",
            fontFamily: "Inter, sans-serif",
            marginBottom: 5,
            marginTop: "10px",
            color: "#000000",
          }}
        >
          Function Name
        </InputLabel>
        <TextField
          style={{ width: "100%", background: "#FFFFFF" }}
          //   value={conclu.designation}
          //   onChange={(e) => onHandleChange(e, "designation")}
          id="name"
          variant="outlined"
          InputLabelProps={{ style: { fontWeight: 500, fontStyle: "normal" } }}
          //   error={stepError && !conclu.designation ? true : false}
        />
      </div>
      <div style={{ textAlign: "center", marginTop: 15 }}>
        <img
          src={saveLarge}
          style={{
            cursor: "pointer",
            maxWidth: "95%",
            marginBottom: "15px",
          }}
          alt="Save and Next"
        />
      </div>
    </div>
  );
};

export default Function;