import { useState, useEffect, useRef } from "react";
import { Box, Typography, InputLabel, TextField, MenuItem, Button, CircularProgress } from "@mui/material";
import { documentData, editMocData } from "../recoil/atoms";
import { useRecoilState } from "recoil";
import axios from "axios";
import API from "../Services/API";
import { useNavigate } from "react-router-dom";
import CloudUpload from "../Asset/Icons/file_upload_icon.svg";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

const imageStyle = {
  width: "100%",
  height: "auto",
  display: "block",
};

const Documents = ({ currentPage, setCurrentPage }) => {
  const fileInputRef = useRef(null);
  const [questionDocument, setQuestionDocument] = useState([]);
  const [documents, setDocument] = useRecoilState(documentData);
  const [errors, setErrors] = useState(false);
  const [stepError, setStepError] = useState(false);
  const [, setSave] = useState(false);
  const [saveMessage, setSaveMessage] = useState(false);
  const [statusError, setStatusError] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSubmitPopup, setShowSubmitPopup] = useState(false);
  const [filledPages, setFilledPages] = useState(Array(10).fill(false));
  const [captureActive, setCaptureActive] = useState(false);
  const [cloudActive, setCloudActive] = useState(false);
  const [imagePath, setImagePath] = useState("");
  const [beforeImage, setBeforeImage] = useState([])
  const [documentUpload, setDocumentUpload] = useState(Array(10).fill(null));
  const [isEditMoc, setIsEditMoc] = useRecoilState(editMocData);
  const [initialLoad, setInitialLoad] = useState(true);
  const navigate = useNavigate();

  const access_token = JSON.parse(localStorage.getItem("accesstoken"));

  useEffect(() => {
    const handlePopup = (setter, delay) => {
      setter(true);
      const timer = setTimeout(() => {
        setter(false);
      }, delay);
      return () => clearTimeout(timer);
    };
    const handlePopups = (showPopup, setShowPopup) => {
      if (showPopup) {
        setInitialLoad(true);
        setTimeout(() => {
          setInitialLoad(false);
          handlePopup(setShowPopup, 1000);
        }, 2000);
      }
    };
    handlePopups(showSuccessPopup, setShowSuccessPopup);
    handlePopups(showSubmitPopup, setShowSubmitPopup);
  }, [showSuccessPopup, showSubmitPopup]);

  const documentDatas = {
    doc_descriptions: [],
  };

  const questionsID = questionDocument[currentPage]
    ? [questionDocument[currentPage]?.id]
    : [];
  questionsID.forEach((questionId, index) => {
    documentDatas.doc_descriptions.push({
      applicability: documents[currentPage]?.applicability,
      target_date: documents[currentPage]?.target_date,
      status: documents[currentPage]?.status,
      descriptions: questionId,
      doc_reference: documents[currentPage]?.doc_reference,
    });
  });

  // const insertDocument = async () => {
  //   try {
  //     const response = await axios.post(
  //       API.INSERT_DOCUMENT,
  //       documentDatas.doc_descriptions[currentPage],
  //       {
  //         headers: {
  //           Authorization: `Bearer ${access_token}`
  //         }
  //       }
  //     );
  //     if (response.status === 200) {
  //       const documentsID = response.data.identifiers[0].id;
  //       localStorage.setItem("documentsID", documentsID);
  //       await updateMocApprovals(documentsID);
  //       setSave(true);
  //       setShowSuccessPopup(true);
  //     }
  //   } catch (error) {
  //     console.error("An error occurred while inserting action plan:", error);
  //   }
  // };

  const insertDocument = async () => {
    try {
      const response = await axios.post(
        API.INSERT_DOCUMENT,
        {
          ...documentDatas.doc_descriptions[currentPage],
        },
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        }
      );
      if (response.status === 200) {
        const documentsID = response.data.identifiers[0].id;
        localStorage.setItem("documentsID", documentsID);
        await updateMocApprovals(documentsID);
        setSave(true);
        setShowSuccessPopup(true);
      }
    } catch (error) {
      console.error("An error occurred while inserting action plan:", error);
    }
  };

  const handleDocumentUpdateClick = async () => {
    var documentsID = localStorage.getItem("documentsID");
    try {
      if (
        documents.length <= currentPage ||
        !documents[currentPage] ||
        !documents[currentPage]?.applicability ||
        !documents[currentPage]?.target_date ||
        !documents[currentPage]?.status
        //  ||
        // !documents[currentPage]?.doc_reference
      ) {
        setErrors(true);
        setStepError(true);
        setSaveMessage(false);
        return;
      } else {
        setErrors(false);
        setStepError(false);
        setSaveMessage(false);
      }
      const updateData = questionDocument
        .filter(
          (question, index) =>
            documents[index] && documents[index]?.applicability?.trim() !== ""
        )
        .map((question, index) => ({
          [`page ${index + 1}`]: {
            applicability: documents[index].applicability,
            target_date: documents[index].target_date,
            status: documents[index].status,
            descriptions: question.id,
            doc_reference: documents[index].doc_reference,
          },
        }));
      const response = await axios.put(
        `${API.UPDATE_DOCUMENT}?id=${documentsID}`,
        updateData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        }
      );
      if (response.status === 200) {
        await updateMocApprovals(documentsID);
        setSave(true);
        setShowSuccessPopup(true);
      }
    } catch (error) {
      console.error("An error occurred while updating documents:", error);
    }
  };

  const updateMocApprovals = async () => {
    const updateMocDet = JSON.parse(localStorage.getItem("mocDataByID"));
    const updatedMocID = updateMocDet.moc_id;
    const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
    const approverSGID = storedUserData.N1SGID;
    const userSGID = storedUserData.SGID;
    const mocApprovalID = localStorage.getItem("mocApprovalID");
    const storedUsersList = JSON.parse(localStorage.getItem("allUsersData"));
    const actionID = localStorage.getItem("actionPlanID");
    const documentsID = localStorage.getItem("documentsID");
    try {
      const mocData = {
        id: mocApprovalID,
        moc_id: updatedMocID,
        approver_sgid: approverSGID,
        user_sgid: userSGID,
        actionplan_id: actionID,
        documents_id: documentsID,
        status: "pending",
      };
      const mocResponse = await axios.put(
        `${API.UPDATE_MOC_APPROVALS}?id=${mocApprovalID}`,
        mocData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        }
      );
      if (mocResponse.status === 200) {
        setSave(true);
        setShowSuccessPopup(true);
      }
    } catch (error) {
      console.error("An error occurred in insertIntoMocTable:", error);
    }
  };

  const handleDocumentSaveClick = async (currentPage, documents) => {
    const storedMocDataString = localStorage.getItem("mocDataByID");
    const storedMocData = JSON.parse(storedMocDataString);
    const documentsIds = storedMocData.documents_id;
    try {
      if (
        documents.length <= currentPage ||
        !documents[currentPage] ||
        !documents[currentPage]?.applicability ||
        !documents[currentPage]?.target_date ||
        !documents[currentPage]?.status
        // ||
        // !documents[currentPage]?.doc_reference
      ) {
        setErrors(true);
        setStepError(true);
        setSaveMessage(false);
        return;
      } else {
        setErrors(false);
        setStepError(false);
        setSaveMessage(false);
      }
      // Set the corresponding page as filled
      setFilledPages((prevFilledPages) => {
        const updatedFilledPages = [...prevFilledPages];
        updatedFilledPages[currentPage] = true;
        return updatedFilledPages;
      });
      if (
        (currentPage === 0 && documentsIds === null) ||
        documentsIds === undefined ||
        documentsIds === ""
      ) {
        insertDocument();
      } else {
        handleDocumentUpdateClick();
      }
    } catch (error) {
      console.error("An error occurred in handleDocumentSaveClick:", error);
    }
  };

  const handleCaptureGallery = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
      fileInputRef.current.onchange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        if (selectedFiles.length > 0) {
          setUploadedFile({ target: { files: selectedFiles } });
        }
      };
      setCaptureActive(false);
      setCloudActive(false);
      fileInputRef.current.value = null;
    }
  };

  const uploadBlobImagesAPI = async () => {
    const documentsSet = documentUpload;
    try {
      const response = await axios.post(
        API.UPLOAD_DOCUMENT,
        { documentsSet },
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        }
      );
      const uploadResponse = response.data[0].name;
      // const insertResponse = await axios.post(API.INSERT_DOCUMENT, { images: [{ name: uploadResponse }] });
      return uploadResponse;
    } catch (error) {
      console.error("Failed to save area:", error);
      throw error; // Rethrow the error to handle it in the calling function
    }
  };

  const generateImagesArray = async (file, index) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const imageType = file.type.split("/")[1];
      const imageBase64 = reader.result.split(",")[1];
      const imageName = `image_${Date.now()}.${imageType}`;
      setDocumentUpload((prevUploads) => {
        const updatedUploads = prevUploads.map((upload, idx) => {
          if (idx === index) {
            return {
              imageBase64,
              imageName,
            };
          }
          return upload; // Keep other uploads unchanged
        });
        return updatedUploads;
      });
    };
    reader.readAsDataURL(file);
  };

  const displayImageBefore = (files) => {
    const imageBeforeArray = [];
    Array.from(files).forEach(file => {
      const reader = new FileReader();
      reader.onload = (e) => {
        // Set the selected image URLs
        imageBeforeArray.push(e.target.result);
        if (imageBeforeArray.length === files.length) {
          // setBeforeImage((prevBeforeImage) => [...prevBeforeImage, ...imageBeforeArray])
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const setUploadedFile = async (event) => {
    const files = event.target.files;
    displayImageBefore(files);
    if (files.length > 0) {
      const form = new FormData();
      Array.from(files).forEach(file => {
        form.append('file', file, file.name);
      });

      try {
        const response = await axios.post(
          API.UPLOAD_DOCUMENTS,
          form,
          {
            headers: {
              Authorization: `Bearer ${access_token}`
            }
          }
        );

        const result = response.data.filePaths;
        const updatedDocument = documents.map((item, index) => {
          if (index === currentPage) {
            return {
              ...item,
              doc_reference: [
                ...(item.doc_reference || []),
                ...result,
              ],
            };
          }
          return item;
        });

        setDocument(updatedDocument);
        setErrors(false);
        // setBeforeImage((UploadFileBefore) => [...UploadFileBefore, ...result]);
      } catch (error) {
        // Handle errors
        console.error('Failed to upload images:', error);
      }
    }
  };

  const handleDelete = (index) => {
    const updatedDocument = [...documents];

    // Filter out the specific index from doc_reference
    const updatedDocReference = updatedDocument[currentPage].doc_reference.filter((_, i) => i !== index);

    updatedDocument[currentPage] = {
      ...updatedDocument[currentPage],
      doc_reference: updatedDocReference,
    };

    setDocument(updatedDocument);
    setErrors(false);
    // setBeforeImage((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };
  const handleDownloadDocument = async (file) => {
    const parts = file.split(/[\\/]/);
    try {
      const response = await axios.get(
        API.DOWNLOAD_DOCUMENTS + `/${parts[parts.length - 1]}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          },
          responseType: 'blob' // Ensure the response type is set to 'blob'
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', parts[parts.length - 1]); // Set the correct filename
      document.body.appendChild(link);
      link.click();
      link.remove();

      // Revoke the object URL after the file is downloaded to free up resources
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the file", error);
    }
  };

  // const setUploadedFile = async (event) => {
  //   const fileBefore = event.target.files[0];
  //   if (fileBefore) {
  //     try {
  //       const imageData = await generateImagesArray(fileBefore); // Generate image data
  //       // Update state with uploaded image data
  //       setImagePath(URL.createObjectURL(fileBefore));
  //       setDocumentUpload(imageData);
  //     } catch (error) {
  //       console.error("Error uploading file:", error);
  //     }
  //   } else {
  //     setImagePath("");
  //   }
  // };

  const handleDocumentNextClick = () => {
    try {
      if (
        // !Array.isArray(documents) ||
        documents.length <= currentPage ||
        !documents[currentPage] ||
        !documents[currentPage]?.applicability ||
        !documents[currentPage]?.target_date ||
        !documents[currentPage]?.status
        // ||
        // !documents[currentPage]?.doc_reference
      ) {
        setErrors(true);
        setStepError(true);
        setSaveMessage(false);
        return;
      } else {
        setErrors(false);
        setStepError(false);
        setSaveMessage(false);
      }
      if (!filledPages[currentPage] && !isEditMoc) {
        setSaveMessage("Please fill in the current page before proceeding.");
        return;
      }
      if (currentPage === 9) {
        navigate("/ManageMoc");
      } else {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, 9));
      }
      // Reset the imagePath state to clear the previous image
      setImagePath("");
    } catch (error) {
      console.error("An error occurred in handleDocumentNextClick:", error);
    }
  };

  const handleDocumentSubmitClick = () => {
    setShowSubmitPopup(true);
    setTimeout(() => {
      navigate("/ManageMoc");
    }, 4000);
  };

  useEffect(() => {
    const fetchDocumentQuestion = async () => {
      try {
        const response = await axios.get(`${API.GET_QUESTIONS}`, {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        });
        const data = response.data
          .filter((item) => item.tag === "documents")
          .map(({ id, questions, tag }) => ({ id, questions, tag }));
        setQuestionDocument(data);
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
      setInitialLoad(false);
    };
    fetchDocumentQuestion();
  }, []);

  useEffect(() => {
    const getDocuments = async () => {
      const storedMocDataString = localStorage.getItem("mocDataByID");
      const storedMocData = JSON.parse(storedMocDataString);
      const documentsIds = storedMocData.documents_id;
      if (
        documentsIds === null ||
        documentsIds === undefined ||
        documentsIds === ""
      ) {
        setDocument([]);
        setIsEditMoc(false);
      } else {
        try {
          const response = await axios.get(
            `${API.GET_DOCUMENTS_BY_ID}?id=${documentsIds}`, {
            headers: {
              Authorization: `Bearer ${access_token}`
            }
          }
          );
          const documentDetails = await response.data;
          const data = documentDetails.doc_descriptions;
          const extractedData = data.map((page) => {
            const key = Object.keys(page)[0]; // Get the key (e.g., "page 1", "page 2")
            const { applicability, target_date, status, doc_reference } = page[key]; // Destructure values
            return { applicability, target_date, status, doc_reference };
          });
          setDocument(extractedData);
          setIsEditMoc(true);
        } catch (error) {
          console.error("Error fetching documents details:", error);
        }
      }
    };
    getDocuments();
  }, [setDocument, setIsEditMoc]);

  // const getDocuments = async () => {
  //   const storedMocDataString = localStorage.getItem("mocDataByID");
  //   const storedMocData = JSON.parse(storedMocDataString);
  //   const documentsIds = storedMocData.documents_id;
  //   if (
  //     documentsIds === null ||
  //     documentsIds === undefined ||
  //     documentsIds === ""
  //   ) {
  //     setDocument([]);
  //     setIsEditMoc(false);
  //   } else {
  //     try {
  //       const response = await axios.get(
  //   `${API.GET_DOCUMENTS_BY_ID}?id=${documentsIds}`,
  //   {
  //     headers: {
  //       Authorization: `Bearer ${access_token}`
  //     }
  //   }
  // );
  //       const documentDetails = await response.data;
  //       const data = documentDetails.doc_descriptions;
  //       const extractedData = data.map((page) => {
  //         const key = Object.keys(page)[0]; // Get the key (e.g., "page 1", "page 2")
  //         const { applicability, target_date, status } = page[key]; // Destructure values
  //         return { applicability, target_date, status };
  //       });
  //       setDocument(extractedData);
  //       setIsEditMoc(true);
  //     } catch (error) {
  //       console.error("Error fetching documents details:", error);
  //     }
  //   }
  // };

  const renderDocumentQuestions = (page) => {
    // const handleTargetDate = (index, date) => {
    //   setTargetDate((prevDates) => {
    //     const newTargetDate = [...prevDates];
    //     newTargetDate[index] = date;
    //     return newTargetDate;
    //   });
    // };
    // const handleDateChange = (e) => {
    //   const newDate = e.target.value;
    //   setSelectedDate(newDate);
    // };

    const handleTargetDate = (currentPage, newTargetDate) => {
      const updateTargetDate = [...documents];
      updateTargetDate[currentPage] = {
        ...updateTargetDate[currentPage],
        target_date: newTargetDate,
      };
      setDocument(updateTargetDate);
      setErrors(false);
    };

    const handleInputChange = (index, field, value) => {
      setDocument((prevPlan) => {
        const updatedPlan = [...prevPlan];
        const containsNonAlphabets = /[^A-Za-z\s]/.test(value);
        let specificError = containsNonAlphabets
          ? "Only alphabets are allowed"
          : "";
        if (field === "status") {
          const alphabeticValue = value.replace(/[^A-Za-z\s]/g, "");
          updatedPlan[index] = {
            ...updatedPlan[index],
            [field]: alphabeticValue,
          };
          setStatusError(specificError);
        } else {
          updatedPlan[index] = {
            ...updatedPlan[index],
            [field]: value,
          };
        }
        setErrors(false);
        return updatedPlan;
      });
    };

    const totalDocumentSteps = 10;
    const handleDocumentStep = (index) => {
      // Check if the clicked page is filled
      if (filledPages[index]) {
        setCurrentPage(index);
      }
    };

    return (
      <div>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          style={{ width: "300px" }}
        >
          <Box display="flex" alignItems="center">
            <div
              style={{ marginRight: "10px", fontWeight: "bold" }}
            >
              {currentPage + 1} of {totalDocumentSteps} steps
            </div>
            {/* Display the '2 of 8' above the stepper */}
          </Box>
          <Box
            display="flex"
            width="100%"
            justifyContent="space-between"
            style={{ padding: 10 }}
          >
            {[...Array(totalDocumentSteps)].map((_, index) => (
              <div
                key={index}
                style={{
                  width: "25px",
                  height: "3px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  background: (index === currentPage || (index < currentPage && documents[index]?.applicability)) ? "#B51849" : "gray"
                }}
                onClick={() => handleDocumentStep(index)}
              />
            ))}
          </Box>
        </Box>
        <div>
          {initialLoad ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "25px"
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <Typography
              style={{ fontSize: 20, fontFamily: "sans-serif" }}
              gutterBottom
            >
              {questionDocument.length > 0 && questionDocument[page].questions}
            </Typography>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginTop: "10px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <InputLabel
                style={{
                  fontWeight: 600,
                  fontStyle: "normal",
                  fontFamily: "Inter, sans-serif",
                  color: "#000",
                  fontSize: 14,
                }}
              >
                Applicability *
              </InputLabel>
              <TextField
                style={{
                  width: "100%",
                  marginTop: 10,
                  textAlign: "left",
                  boxSizing: "border-box",
                }}
                value={documents[currentPage]?.applicability || ""}
                onChange={(e) =>
                  handleInputChange(
                    currentPage,
                    "applicability",
                    e.target.value
                  )
                }
                id="outlined-select-currency"
                select
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                error={
                  stepError && !documents[currentPage]?.applicability
                    ? true
                    : false
                }
                disabled={disableCondition}
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </TextField>
            </div>
            <div
              style={{
                marginTop: "10px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <InputLabel
                style={{
                  fontWeight: 600,
                  fontStyle: "normal",
                  fontFamily: "Inter, sans-serif",
                  color: "#000",
                  fontSize: 14,
                  marginBottom: 5,
                }}
                htmlFor="date"
              >
                Target Date *
              </InputLabel>
              <TextField
                style={{
                  width: "100%",
                  background: "#FFFFFF",
                  borderRadius: 4,
                }}
                id="date"
                type="date"
                variant="outlined"
                InputLabelProps={{
                  style: { fontWeight: 500, fontStyle: "normal" },
                }}
                value={documents[currentPage]?.target_date || ""}
                onChange={(e) => handleTargetDate(currentPage, e.target.value)}
                inputProps={{ max: new Date().toISOString().split("T")[0] }}
                error={
                  stepError && !documents[currentPage]?.target_date ? true : false
                }
                disabled={disableCondition}
              />
            </div>
            <div
              style={{
                marginTop: "10px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <InputLabel
                style={{
                  fontWeight: 600,
                  fontStyle: "normal",
                  fontFamily: "Inter, sans-serif",
                  color: "#000",
                  fontSize: 14,
                }}
              >
                Status *
              </InputLabel>
              <TextField
                style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
                id="outlined-multiline-static"
                value={documents[currentPage]?.status || ""}
                onChange={(e) =>
                  handleInputChange(currentPage, "status", e.target.value)
                }
                error={
                  Boolean(statusError) ||
                    (stepError && !documents[currentPage]?.status)
                    ? true
                    : false
                }
                helperText={
                  <span style={{ color: "#FF0000" }}>{statusError || " "}</span>
                }
                disabled={disableCondition}
              />
            </div>
            <div
              style={{
                marginTop: "10px",
                width: "90%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <input
                id="upload-image"
                multiple
                type="file"
                style={{ display: "none" }}
                // onChange={setUploadedFile}
                ref={fileInputRef}
              />
              <InputLabel
                style={{
                  fontWeight: 600,
                  fontStyle: "normal",
                  fontFamily: "Inter, sans-serif",
                  color: "#000",
                  fontSize: 16,
                }}
              >
                Doc Reference
              </InputLabel>
              <div
                style={{
                  border: "2px dashed #00448B",
                  padding: "20px",
                  flex: 1,
                  display: "flex",
                  width: "100%",
                  height: 300,
                  marginTop: 10,
                  backgroundColor: "#00448a1a",
                  marginLeft: "-15px",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "4px",
                }}
              >
                <Button
                  // onClick={handleCaptureGallery}
                  style={{
                    width: 30,
                    height: 30,
                    marginLeft: 10,
                    marginBottom: 10,
                    cursor: "pointer",
                    padding: 0, // Remove padding to ensure image is not covered
                    border: "none", // Remove border to ensure image is not covered
                    background: "none", // Remove background to ensure image is not covered
                  }}
                  aria-label="Upload File"
                >
                  <div style={{ marginTop: "30px" }}>
                    <img
                      src={CloudUpload}
                      alt="Uploaded File"
                      style={{ width: "100%", height: "auto", cursor: "pointer" }} // Adjust styling as needed
                      onClick={handleCaptureGallery}
                    />
                  </div>
                </Button>
                <Typography
                  onClick={handleCaptureGallery}
                  style={{
                    textAlign: "center",
                    marginLeft: 15,
                    color: "black",
                    fontWeight: 600,
                    width: 170,
                    fontSize: 16,
                  }}
                >
                  Click to Upload
                </Typography>

                {/* <div style={{ marginTop: "30px" }}>
                  {documentUpload.map((file, index) => (
                    <div key={index}>
                      <img
                        src={file.imagePath}
                        alt={`Uploaded File ${index + 1}`}
                        style={{ width: "100%", height: "auto" }} // Adjust styling as needed
                      />
                    </div>
                  ))}
                </div> */}

                {[currentPage] && Array.isArray(documents[currentPage].doc_reference) && documents[currentPage].doc_reference.length > 0 && (
                  <div style={{ marginTop: '10px' }}>
                    {documents[currentPage].doc_reference.map((file, index) => (
                      <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                        <CloudDownloadIcon
                          style={{ width: '50px', height: 'auto', marginRight: '10px', cursor: "pointer" }} // Adjust styling as needed
                          onClick={() => handleDownloadDocument(file)}
                        />
                        <span>{file}</span>
                        <button
                          onClick={() => handleDelete(index)}
                          style={{
                            marginLeft: '10px',
                            background: 'none',
                            border: 'none',
                            color: 'red',
                            fontSize: '20px',
                            cursor: 'pointer'
                          }}
                        >
                          &times;
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            {initialLoad ? (
              <div className="popup-overlay">
                <div className="popup">
                  <CircularProgress />
                </div>
              </div>
            ) : showSuccessPopup ? (
              <div className="popup-overlay">
                <div className="popup">
                  <p>Data saved successfully!</p>
                </div>
              </div>
            ) : null}
            {initialLoad ? (
              <div className="popup-overlay">
                <div className="popup">
                  <CircularProgress />
                </div>
              </div>
            ) : showSubmitPopup ? (
              <div className="popup-overlay">
                <div className="popup">
                  <p>Document submitted successfully!</p>
                </div>
              </div>
            ) : null}
            {errors ? (
              <Typography variant="subtitle2" color="red">
                {""}
                Please fill all the fields
              </Typography>
            ) : null}
            {saveMessage ? (
              <Typography variant="subtitle2" color="red">
                {""}
                Please Save before going to Next
              </Typography>
            ) : null}
          </div>
        </div>
      </div>
    );
  };
  const mocDetData = JSON.parse(localStorage.getItem("mocDataByID"));
  const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
  const userSGID = storedUserData.SGID;
  let disableCondition;
  if (isEditMoc) {
    disableCondition = userSGID === mocDetData.mocForm.user_id ? false : true;
  }

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {renderDocumentQuestions(currentPage)}
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "space-between",
          maxWidth: "90%",
        }}
      >
        {
          currentPage < 10 && (
            (isEditMoc && !(disableCondition) ? (
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#B1174B",
                  fontSize: 20,
                  color: "white",
                  textTransform: "none",
                  width: "75%",
                  marginTop: 20,
                  marginRight: 5,
                  marginBottom: 10,
                }}
                onClick={() => handleDocumentUpdateClick(currentPage, documents)}
              >
                Update
              </Button>
            ) :
              (!isEditMoc &&
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#B1174B",
                    fontSize: 20,
                    color: "white",
                    textTransform: "none",
                    width: "75%",
                    marginTop: 20,
                    marginRight: 5,
                    marginBottom: 10,
                  }}
                  onClick={() => handleDocumentSaveClick(currentPage, documents)}
                >
                  Save
                </Button>
              )
            )
          )
        }
        {currentPage === 9 ? (
          <Button
            variant="contained"
            style={{
              backgroundColor: "#B1174B",
              fontSize: 20,
              color: "white",
              textTransform: "none",
              width: "75%",
              marginTop: 20,
              marginRight: 5,
              marginBottom: 10,
            }}
            onClick={handleDocumentSubmitClick}
          >
            Submit
          </Button>
        ) : (
          <Button
            variant="contained"
            style={{
              backgroundColor: "#B1174B",
              fontSize: 20,
              color: "white",
              textTransform: "none",
              width: "75%",
              marginTop: 20,
              marginRight: 5,
              marginBottom: 10,
            }}
            onClick={handleDocumentNextClick}
          >
            Next
          </Button>
        )}
      </div>
    </div>
  );
};

export default Documents;