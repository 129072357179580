import React from "react";
import { Grid, Typography, Card, TableContainer, Table, TableBody, TableRow, TableCell, Paper } from "@mui/material";
import SaintGobain from "../Asset/Icons/Saint-Gobain_logo.svg";
import CloudUpload from "../Asset/Icons/file_upload_icon.svg";

const ReportCmsPdf = ({ data }) => {
    console.log(data, "report data5")
    return (
        <Grid >
            <Grid container style={{ marginBottom: 10 }}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",

                    }}
                >
                    <div>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "center",
                                fontWeight: "bold",
                                maxWidth: "700px",
                                fontSize: "28px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            CMS REPORT
                        </Typography>

                        {/* <img
                            src={SaintGobain}
                            alt="SaintGobain"
                            style={{
                                fontSize: 14,
                                textAlign: "center",
                                color: "#B51849",
                                position: "absolute",
                                bottom: 0,
                                left: "37px",
                                right: 0,
                                padding: "30px",
                            }}
                        /> */}
                    </div>
                </div>

                <div>
                    <Typography
                        variant="h1"
                        style={{
                            textTransform: "uppercase",
                            textAlign: "center",
                            fontWeight: "bold",
                            maxWidth: "700px",
                            fontSize: "28px",
                            color: 'FFFFFF',
                            padding: '5px'
                        }}
                    > {data.cms_no}
                    </Typography>

                </div>
            </Grid>
            <h3
                style={{
                    width: "100%",
                    fontWeight: 600,
                    color: "#4D4D4D",
                    padding: "7px 15px",
                    fontSize: 18,
                }}
            >
                Change Request Form
            </h3>
            <Card style={{ marginBottom: "10px", width: 'calc(100% - 2px)', border: "1px solid #ccc", boxShadow: "none" }}>
                <Grid container style={{
                    marginBottom: 10, display: "flex",
                    // alignItems: "center",
                    flexDirection: "column",
                    width: "100%",
                }} >
                    <TableContainer component={Paper} style={{ marginBottom: 10, boxShadow: "none" }}>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                        Date
                                    </TableCell>
                                    <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                        {data?.change_request[0]?.date}
                                    </TableCell>

                                    <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                        Request Initiator
                                    </TableCell>
                                    <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                        {data?.change_request[0]?.request_initiator}
                                    </TableCell>

                                    <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                        Department
                                    </TableCell>
                                    <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                        {data?.change_request[0]?.department}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                        Type of Design Change
                                    </TableCell>
                                    <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                        {data?.change_request[0]?.type_design_change}
                                    </TableCell>

                                    <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                        Plant
                                    </TableCell>
                                    <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                        {data?.change_request[0]?.plant}
                                    </TableCell>

                                    <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                        Change Category
                                    </TableCell>
                                    <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                        {data?.change_request[0]?.change_category}
                                    </TableCell>
                                </TableRow>
                                {data?.change_request[0]?.change_category === "Design Change" ? (
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                            Design Change
                                        </TableCell>
                                        <TableCell colSpan={5} style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                            {data?.change_request[0]?.design_change}
                                        </TableCell>
                                    </TableRow>
                                ) : data?.change_request[0]?.change_category === "Process Change" ? (
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                            Process Change
                                        </TableCell>
                                        <TableCell colSpan={5} style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="left">
                                            {data?.change_request[0]?.process_change}
                                        </TableCell>
                                    </TableRow>
                                ) : null}
                                {data?.change_request[0]?.change_category === "Design Change" && data?.change_request[0]?.design_change === "Internal" ? (
                                    <>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                                Model
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                                {data?.change_request[0]?.design_int_model}
                                            </TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                                Glass Reference
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                                {data?.change_request[0]?.design_int_glass_reference}
                                            </TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                                Part Number
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                                {data?.change_request[0]?.design_int_part_number}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                                Rev No
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                                {data?.change_request[0]?.design_int_part_number_rev_no}
                                            </TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                                Part Name
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                                {data?.change_request[0]?.design_int_part_name}
                                            </TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                                Drawing Number
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                                {data?.change_request[0]?.design_int_drawing_number}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                                Rev No
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                                {data?.change_request[0]?.design_int_drawing_no_rev_no}
                                            </TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                                Process Reference
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                                {data?.change_request[0]?.design_int_process_reference}
                                            </TableCell>
                                        </TableRow>
                                    </>
                                ) : data?.change_request[0]?.change_category === "Design Change" && data?.change_request[0]?.design_change === "External" ? (
                                    <>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                                Customer
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                                {data?.change_request[0]?.design_ext_customer}
                                            </TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                                Model
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                                {data?.change_request[0]?.design_ext_model}
                                            </TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                                Glass Reference
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                                {data?.change_request[0]?.design_ext_glass_reference}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                                Part Number
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                                {data?.change_request[0]?.design_ext_part_number}
                                            </TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                                Rev No
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                                {data?.change_request[0]?.design_ext_part_number_rev_no}
                                            </TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                                Part Name
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                                {data?.change_request[0]?.design_ext_part_name}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                                Drawing Number
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                                {data?.change_request[0]?.design_ext_drawing_number}
                                            </TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                                Rev No
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                                {data?.change_request[0]?.design_ext_drawing_no_rev_no}
                                            </TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                                Process Reference
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                                {data?.change_request[0]?.design_ext_process_reference}
                                            </TableCell>
                                        </TableRow>
                                    </>
                                ) : null}
                                {data?.change_request[0]?.change_category === "Process Change" && data?.change_request[0]?.process_change === "Internal" ? (
                                    <>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                                Customer
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                                {data?.change_request[0]?.process_int_customer}
                                            </TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                                Model
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                                {data?.change_request[0]?.process_int_model}
                                            </TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                                Glass Reference
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                                {data?.change_request[0]?.process_int_glass_reference}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                                Part Number
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                                {data?.change_request[0]?.process_int_part_number}
                                            </TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                                Rev No
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                                {data?.change_request[0]?.process_int_part_number_rev_no}
                                            </TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                                Part Name
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                                {data?.change_request[0]?.process_int_part_name}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                                Drawing Number
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                                {data?.change_request[0]?.process_int_drawing_number}
                                            </TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                                Rev No
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                                {data?.change_request[0]?.process_int_drawing_no_rev_no}
                                            </TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                                Process Reference
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                                {data?.change_request[0]?.process_int_process_reference}
                                            </TableCell>
                                        </TableRow>
                                    </>
                                ) : data?.change_request[0]?.change_category === "Process Change" && data?.change_request[0]?.process_change === "External" ? (
                                    <>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                                Customer
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                                {data?.change_request[0]?.process_ext_customer}
                                            </TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                                Model
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                                {data?.change_request[0]?.process_ext_model}
                                            </TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                                Glass Reference
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                                {data?.change_request[0]?.process_ext_glass_reference}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                                Part Number
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                                {data?.change_request[0]?.process_ext_part_number}
                                            </TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                                Rev No
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                                {data?.change_request[0]?.process_ext_part_number_rev_no}
                                            </TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                                Part Name
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                                {data?.change_request[0]?.process_ext_part_name}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                                Drawing Number
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                                {data?.change_request[0]?.process_ext_drawing_number}
                                            </TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                                Rev No
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                                {data?.change_request[0]?.process_ext_drawing_no_rev_no}
                                            </TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                                Process Reference
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                                {data?.change_request[0]?.process_ext_process_reference}
                                            </TableCell>
                                        </TableRow>
                                    </>
                                ) : null}
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                        Date of Receiveing Customer Requirement
                                    </TableCell>
                                    <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                        {data?.change_request[0]?.receiveing_customer_date}
                                    </TableCell>

                                    <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                        Date of Customer Requirement For Implement
                                    </TableCell>
                                    <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                        {data?.change_request[0]?.customer_req_imple_date}
                                    </TableCell>

                                    <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                        Feasibility Confirmation To Customer
                                    </TableCell>
                                    <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                        {data?.change_request[0]?.feas_con_customer_date}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                        Description Of Change Required
                                    </TableCell>
                                    <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                        {data?.change_request[0]?.des_change_required}
                                    </TableCell>

                                    <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                        Others
                                    </TableCell>
                                    <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                        {data?.change_request[0]?.other_comments}
                                    </TableCell>

                                    <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                        Remarks
                                    </TableCell>
                                    <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                        {data?.change_request[0]?.remarks}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/* <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                fontWeight: "bold",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            Date
                        </Typography>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            {data?.change_request[0]?.date}
                        </Typography>
                    </div>
                    <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                fontWeight: "bold",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            Request Initiator
                        </Typography>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            {data?.change_request[0]?.request_initiator}
                        </Typography>
                    </div>
                    <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                fontWeight: "bold",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            Department
                        </Typography>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            {data?.change_request[0]?.department}
                        </Typography>
                    </div>
                    <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                fontWeight: "bold",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            Type of Design Change
                        </Typography>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            {data?.change_request[0]?.type_design_change}
                        </Typography>
                    </div>
                    <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                fontWeight: "bold",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            Plant
                        </Typography>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            {data?.change_request[0]?.plant}
                        </Typography>
                    </div>
                    <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                fontWeight: "bold",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            Change Category
                        </Typography>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            {data?.change_request[0]?.change_category}
                        </Typography>
                    </div>
                    {
                        data?.change_request[0]?.change_category === "Design Change" ?
                            <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                <Typography
                                    variant="h1"
                                    style={{
                                        textTransform: "uppercase",
                                        textAlign: "left",
                                        fontWeight: "bold",
                                        width: "300px",
                                        fontSize: "18px",
                                        color: 'FFFFFF',
                                        padding: '5px'
                                    }}
                                >
                                    Design Change
                                </Typography>
                                <Typography
                                    variant="h1"
                                    style={{
                                        textTransform: "uppercase",
                                        textAlign: "left",
                                        width: "300px",
                                        fontSize: "18px",
                                        color: 'FFFFFF',
                                        padding: '5px'
                                    }}
                                >
                                    {data?.change_request[0]?.design_change}
                                </Typography>
                            </div> :
                            data?.change_request[0]?.change_category === "Process Change" ?
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Process Change
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.process_change}
                                    </Typography>
                                </div> : <></>
                    }
                    {
                        data?.change_request[0]?.change_category === "Design Change" && data?.change_request[0]?.design_change === "Internal" && (
                            <>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Customer
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.design_int_customer}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Model
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.design_int_model}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Glass Reference
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.design_int_glass_reference}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Part Number
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.design_int_part_number}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Rev No
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.design_int_part_number_rev_no}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Part Name
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.design_int_part_name}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Drawing Number
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.design_int_drawing_number}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Rev No
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.design_int_drawing_no_rev_no}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Process Reference
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.design_int_process_reference}
                                    </Typography>
                                </div>
                            </>
                        )
                    }

                    {
                        data?.change_request[0]?.change_category === "Design Change" && data?.change_request[0]?.design_change === "External" && (
                            <>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Customer
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.design_ext_customer}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Model
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.design_ext_model}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Glass Reference
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.design_ext_glass_reference}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Part Number
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.design_ext_part_number}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Rev No
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.design_ext_part_number_rev_no}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Part Name
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.design_ext_part_name}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Drawing Number
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.design_ext_drawing_number}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Rev No
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.design_ext_drawing_no_rev_no}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Process Reference
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.design_ext_process_reference}
                                    </Typography>
                                </div>
                            </>
                        )
                    }

                    {
                        data?.change_request[0]?.change_category === "Process Change" && data?.change_request[0]?.process_change === "Internal" && (
                            <>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Customer
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.process_int_customer}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Model
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.process_int_model}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Glass Reference
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.process_int_glass_reference}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Part Number
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.process_int_part_number}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Rev No
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.process_int_part_number_rev_no}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Part Name
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.process_int_part_name}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Drawing Number
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.process_int_drawing_number}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Rev No
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.process_int_drawing_no_rev_no}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Process Reference
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.process_int_process_reference}
                                    </Typography>
                                </div>
                            </>
                        )
                    }

                    {
                        data?.change_request[0]?.change_category === "Process Change" && data?.change_request[0]?.process_change === "External" && (
                            <>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Customer
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.process_ext_customer}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Model
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.process_ext_model}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Glass Reference
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.process_ext_glass_reference}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Part Number
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.process_ext_part_number}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Rev No
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.process_ext_part_number_rev_no}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Part Name
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.process_ext_part_name}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Drawing Number
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.process_ext_drawing_number}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Rev No
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.process_ext_drawing_no_rev_no}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Process Reference
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.process_ext_process_reference}
                                    </Typography>
                                </div>
                            </>
                        )
                    } */}

                    {/* <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                fontWeight: "bold",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            Description Of Change Required
                        </Typography>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            {data?.change_request[0]?.des_change_required}
                        </Typography>
                    </div>

                    <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                fontWeight: "bold",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            Others
                        </Typography>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            {data?.change_request[0]?.other_comments}
                        </Typography>
                    </div>

                    <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                fontWeight: "bold",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            Date of Receiveing Customer Requirement
                        </Typography>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            {data?.change_request[0]?.receiveing_customer_date}
                        </Typography>
                    </div>

                    <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                fontWeight: "bold",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            Date of Customer Requirement For Implement
                        </Typography>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            {data?.change_request[0]?.customer_req_imple_date}
                        </Typography>
                    </div>

                    <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                fontWeight: "bold",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            Feasibility Confirmation To Customer
                        </Typography>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            {data?.change_request[0]?.feas_con_customer_date}
                        </Typography>
                    </div>

                    <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                fontWeight: "bold",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            Remarks
                        </Typography>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            {data?.change_request[0]?.remarks}
                        </Typography>
                    </div> */}
                </Grid>
            </Card>

            <h3
                style={{
                    width: "100%",
                    fontWeight: 600,
                    color: "#4D4D4D",
                    padding: "7px 15px",
                    fontSize: 18,
                }}
            >
                Details of the Change
            </h3>
            <Card style={{ marginBottom: "10px", width: 'calc(100% - 2px)', border: "1px solid #ccc", boxShadow: "none" }}>
                <TableContainer component={Paper} style={{ marginBottom: 10, boxShadow: "none" }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                    Details Of Change Required
                                </TableCell>
                                <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                    {data?.change_required_remarks}
                                </TableCell>
                            </TableRow>
                            {
                                data?.change_required_document &&
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                        Details Of Change Required Document
                                    </TableCell>
                                    <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                        {data?.change_required_document && data.change_required_document.length > 0 &&
                                            <img
                                                src={CloudUpload}
                                                alt="Upload File"
                                                style={{ width: "35px", height: "35px", cursor: "pointer" }}
                                            />
                                        }
                                    </TableCell>
                                </TableRow>
                            }
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                    Reason For Change Request
                                </TableCell>
                                <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                    {data?.change_request_remarks}
                                </TableCell>
                            </TableRow>
                            {
                                data?.change_request_document &&
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                        Reason For Change Request Document
                                    </TableCell>
                                    <TableCell style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                        {data?.change_request_document && data.change_request_document.length > 0 &&
                                            <img
                                                src={CloudUpload}
                                                alt="Upload File"
                                                style={{ width: "35px", height: "35px", cursor: "pointer" }}
                                            />
                                        }
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                fontWeight: "bold",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            Details Of Change Required
                        </Typography>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            {data?.change_required_remarks}
                        </Typography>
                    </div>

                    <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                fontWeight: "bold",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            Reason For Change Request
                        </Typography>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            {data?.change_request_remarks}
                        </Typography>
                    </div> */}
            </Card>

            <h3
                style={{
                    width: "100%",
                    fontWeight: 600,
                    color: "#4D4D4D",
                    padding: "7px 15px",
                    fontSize: 18,
                }}
            >
                The Impact of Change
            </h3>
            <Card style={{ marginBottom: "10px", width: 'calc(100% - 2px)', border: "1px solid #ccc", boxShadow: "none" }}>
                <TableContainer component={Paper} style={{ marginBottom: 10, boxShadow: "none" }}>
                    <Table>
                        <TableBody>
                            {data?.impact_change.map((impactData, index) => (
                                <TableRow key={index}>
                                    <TableCell
                                        style={{ fontWeight: 'bold', fontSize: '16px', borderRight: '1px solid #e0e0e0' }}>
                                        {impactData.impactchanges.question_no ? `${impactData.impactchanges.question_no}. ` : ""}
                                        {impactData.impactchanges.questions || "No questions available"}
                                    </TableCell>
                                    {impactData.affects === "No" ? (
                                        <TableCell colSpan={6} style={{ fontSize: '14px', borderRight: '1px solid #e0e0e0' }} align="center">
                                            <strong>Results:</strong> {impactData.affects}
                                        </TableCell>
                                    ) : (
                                        <>
                                            <TableCell style={{ fontSize: '14px', borderRight: '1px solid #e0e0e0' }} align="center">
                                                <strong>Results:</strong> {impactData.affects}
                                            </TableCell>
                                            <TableCell style={{ padding: '8px', fontSize: '14px', borderRight: '1px solid #e0e0e0' }}>
                                                <strong>Actions:</strong> {impactData.actions}
                                            </TableCell>
                                            <TableCell style={{ padding: '8px', fontSize: '14px', borderRight: '1px solid #e0e0e0' }}>
                                                <strong>Responsibility:</strong> {impactData.responsibility}
                                            </TableCell>
                                            <TableCell style={{ padding: '8px', fontSize: '14px', borderRight: '1px solid #e0e0e0' }}>
                                                <strong>Date:</strong> {impactData.date}
                                            </TableCell>
                                            <TableCell style={{ padding: '8px', fontSize: '14px', borderRight: '1px solid #e0e0e0' }}>
                                                <strong>Remarks:</strong> {impactData.remarks}
                                            </TableCell>
                                            <TableCell style={{ padding: '8px', fontSize: '14px' }}>
                                                <strong>Document:</strong>
                                                {impactData?.document && impactData.document.length > 0 &&
                                                    <img
                                                        src={CloudUpload}
                                                        alt="Upload File"
                                                        style={{ width: "35px", height: "35px", cursor: "pointer" }}
                                                    />
                                                }
                                            </TableCell>
                                        </>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>

            <h3
                style={{
                    width: "100%",
                    fontWeight: 600,
                    color: "#4D4D4D",
                    padding: "7px 15px",
                    fontSize: 18,
                }}
            >
                Alternation Note
            </h3>

            <Card style={{ marginBottom: "10px", width: 'calc(100% - 2px)', border: "1px solid #ccc", boxShadow: "none" }}>
                <TableContainer component={Paper} style={{ marginBottom: 10, boxShadow: "none" }}>
                    <Table>
                        <TableBody>
                            {data?.alter_note.alter_change.map((alterChange, index) => (
                                <TableRow key={index}>
                                    <TableCell
                                        style={{ fontWeight: 'bold', fontSize: '16px', borderRight: '1px solid #e0e0e0' }}>
                                        {alterChange.alterchanges.question_no ? `${alterChange.alterchanges.question_no}. ` : ""}
                                        {alterChange.alterchanges.questions || "No questions available"}
                                    </TableCell>
                                    {alterChange.affects === "No" ? (
                                        <TableCell colSpan={6} style={{ fontSize: '14px', borderRight: '1px solid #e0e0e0' }} align="center">
                                            <strong>Results:</strong> {alterChange.affects}
                                        </TableCell>
                                    ) : (
                                        <>
                                            <TableCell style={{ fontSize: '14px', borderRight: '1px solid #e0e0e0' }} align="center">
                                                <strong>From:</strong> {alterChange.from}
                                            </TableCell>
                                            <TableCell style={{ padding: '8px', fontSize: '14px', borderRight: '1px solid #e0e0e0' }}>
                                                <strong>To:</strong> {alterChange.to}
                                            </TableCell>
                                            <TableCell style={{ padding: '8px', fontSize: '14px', borderRight: '1px solid #e0e0e0' }}>
                                                <strong>Date:</strong> {alterChange.date}
                                            </TableCell>
                                            <TableCell style={{ padding: '8px', fontSize: '14px', borderRight: '1px solid #e0e0e0' }}>
                                                <strong>Stock Disposal:</strong> {alterChange.stock_disposal}
                                            </TableCell>
                                            <TableCell style={{ padding: '8px', fontSize: '14px', borderRight: '1px solid #e0e0e0' }}>
                                                <strong>Remarks:</strong> {alterChange.remarks}
                                            </TableCell>
                                            <TableCell style={{ padding: '8px', fontSize: '14px' }}>
                                                <strong>Document:</strong>
                                                {alterChange?.document && alterChange.document.length > 0 &&
                                                    <img
                                                        src={CloudUpload}
                                                        alt="Upload File"
                                                        style={{ width: "35px", height: "35px", cursor: "pointer" }}
                                                    />
                                                }
                                            </TableCell>
                                        </>
                                    )}
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                    This alternation to take effect from :
                                </TableCell>
                                <TableCell colSpan={6} style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                    {data?.alternation_date}
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                    Date of implementation of change :
                                </TableCell>
                                <TableCell colSpan={6} style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                    {data?.implementation_date}
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                    Implementation status :
                                </TableCell>
                                <TableCell colSpan={6} style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0', backgroundColor: '#66BB6A', color: 'white' }} align="center">
                                    {data?.implementation_status}
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold', fontSize: '18px', borderRight: '1px solid #e0e0e0' }}>
                                    Final comments if any :
                                </TableCell>
                                <TableCell colSpan={6} style={{ fontSize: '18px', borderRight: '1px solid #e0e0e0' }} align="center">
                                    {data?.final_comments}
                                </TableCell>
                            </TableRow>

                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>


        </Grid>
    )
}
export default ReportCmsPdf;