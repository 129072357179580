import React, { useState, useEffect } from "react";
import { IconButton, Toolbar, Typography, Tooltip, Avatar, Box } from "@mui/material";
import MenuIcon from "../Asset/Icons/drawer_icon.svg";
import BackIcon from "../Asset/Icons/back_icon.svg";
import PlusIcon from "../Asset/Icons/addnew_icon.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import { useRecoilState } from "recoil";
import { editMocData, requestFormData, changeAssessmentData, conclusionData, userData, editCMSData, changeRequestData, changeReqData, impactChangeData, notification } from "../recoil/atoms";
import Logout from '@mui/icons-material/Logout';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
export default function Header({
  pageTitle,
  isDashboard,
  isManageMOCPage,
  isNewUser,
  goBack,
  open, handleDrawerOpen
}) {
  const [, setRequestForm] = useRecoilState(requestFormData);
  const [, setChangeAssessment] = useRecoilState(changeAssessmentData);
  const [, setConclu] = useRecoilState(conclusionData);
  const [, setIsEditMoc] = useRecoilState(editMocData);
  const [, setUserList] = useRecoilState(userData);
  const [isAdmin, setIsAdmin] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [apiData, setApiData] = useState("");

  // 4M Change Management System
  const [, setIsEditCms] = useRecoilState(editCMSData);
  const [, setChangeRequest] = useRecoilState(changeRequestData);
  const [, setChangeRequireData] = useRecoilState(changeReqData);
  const [, setImpactData] = useRecoilState(impactChangeData);
  const [, setNotificationData] = useRecoilState(notification);

  // const handleOpenLeftDrawerClick = () => {
  //   setDrawerOpen(true);
  // };

  // const handleCloseLeftDrawer = () => {
  //   setDrawerOpen(false);
  // };

  useEffect(() => {
    const storedUserData = localStorage.getItem("userDatas");
    const userData = storedUserData ? JSON.parse(storedUserData) : null;
    setApiData(userData);
  }, []);

  const handleGoBack = () => {
    if (location.pathname === '/userList') {
      navigate('/settings');
    } else {
      window.history.back();
    }
  };

  const handleAddClick = () => {
    setRequestForm([]);
    if (setChangeAssessment) {
      setChangeAssessment({
        change_affects: [],
        results: Array(1).fill("#B51849"),
        checkpoints_remarks: Array(1).fill(""),
        environment_results: Array(1).fill(""),
        health_results: Array(1).fill(""),
        safety_results: Array(1).fill(""),
        fire_results: Array(1).fill(""),
        environment_remarks: Array(1).fill(""),
        health_remarks: Array(1).fill(""),
        safety_remarks: Array(1).fill(""),
        fire_remarks: Array(1).fill(""),
      });
    }
    setConclu([]);
    setUserList([]);
    setIsEditMoc(false);

    // 4M Change Management System
    setChangeRequest({
      date: '',
      request_initiator: '',
      department: '',
      cms_no: '',
      type_design_change: '',
      plant: '',
      change_category: '',
      ecn_type: '',
      process_type: '',
      ecn_int_customer: '',
      ecn_int_model: '',
      ecn_int_glass_reference: '',
      ecn_int_part_number_rev_no: '',
      ecn_int_part_number: '',
      ecn_int_part_name: '',
      ecn_int_drawing_no_rev_no: '',
      ecn_int_drawing_number: '',
      ecn_int_process_reference: '',
      ecn_ext_customer: '',
      ecn_ext_model: '',
      ecn_ext_glass_reference: '',
      ecn_ext_part_number_rev_no: '',
      ecn_ext_part_number: '',
      ecn_ext_part_name: '',
      ecn_ext_drawing_no_rev_no: '',
      ecn_ext_drawing_number: '',
      ecn_ext_process_reference: '',
      pro_int_customer: '',
      pro_int_model: '',
      pro_int_glass_reference: '',
      pro_int_part_number: '',
      pro_int_part_number_rev_no: '',
      pro_int_part_name: '',
      pro_int_drawing_no_rev_no: '',
      pro_int_drawing_number: '',
      pro_int_process_reference: '',
      pro_ext_customer: '',
      pro_ext_model: '',
      pro_ext_glass_reference: '',
      pro_ext_part_number_rev_no: '',
      pro_ext_part_number: '',
      pro_ext_part_name: '',
      pro_ext_drawing_no_rev_no: '',
      pro_ext_drawing_number: '',
      pro_ext_process_reference: '',
      des_change_required: '',
      other_comments: '',
      receiveing_customer_date: '',
      customer_req_imple_date: '',
      feas_con_customer_date: '',
      remarks: '',
    });
    setChangeRequireData({
      change_required_remarks: "",
      change_request_remarks: ""
    });
    setImpactData({
      affects: "",
      actions: "",
      responsibility: "",
      date: "",
      remarks: "",
      document: []
    });
    setNotificationData({
      approver_mail_id: "",
      approval_status: "",
    });
    // setIsEditCms(false);
    if (isNewUser) {
      navigate("/newUser");
    } else {
      navigate("/changerequest");
      localStorage.removeItem("isRequestSave");
      localStorage.removeItem("isAssessmentSave");
      localStorage.removeItem("isEHSFSave");

      // 4M Change Management System
      localStorage.removeItem("isChangeRequestSave");
      localStorage.removeItem("isChangeRequireSave");
      localStorage.removeItem("isImpactChangeSave");
    }
  };
  const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
  const storedAllUserData = JSON.parse(localStorage.getItem("allUsersData"));
  const adminSGID = storedUserData.SGID;

  useEffect(() => {
    if (storedAllUserData) {
      const loggedInUserData = storedAllUserData.find(user => user.SGID === adminSGID);
      if (loggedInUserData && loggedInUserData.role === "Admin") {
        setIsAdmin(true);
      }
    }
  }, [adminSGID, storedAllUserData]);
  const handleNavigation = (route) => {
    navigate(route);
  };
  const handleNextButtonClick = () => handleNavigation("/login");

  const plantSelection = storedUserData.plant === "Sekurit";

  return (
    <>
      <AppBar position="fixed" open={open} sx={{ background: '#fff', boxShadow: 'none', borderBottom: '1px solid #e6e6e6' }}>
        <Toolbar>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <img
              src={MenuIcon}
              alt="menu icon"
              style={{ width: "28px", height: "24px" }}
            />
          </IconButton>

          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              textAlign: "center",
              color: "#000",
              fontSize: 16,
              fontWeight: 600,
              fontStyle: "normal",
              fontFamily: "Inter, sans-serif",
            }}
          >
            {pageTitle}
          </Typography>

          {(isNewUser && isAdmin) || isManageMOCPage ? (
            <Tooltip title={isManageMOCPage && !plantSelection ? "New MOC" : isManageMOCPage && plantSelection ? "New CMS" : "New User"} arrow>
              <IconButton
                edge="end"
                color="inherit"
                aria-label="add"
                onClick={handleAddClick}
              >
                <img
                  src={PlusIcon}
                  alt="add icon"
                  style={{ width: "28px", height: "24px" }}
                />
              </IconButton>
            </Tooltip>
          ) : <></>}



          <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
            {/* <IconButton>
              <NotificationsNoneIcon fontSize="24px" />
            </IconButton> */}

            <Tooltip title={apiData ? apiData.FName + " " + apiData.LName : " "}>
              <IconButton
                size="small"
                sx={{ mr: 2 }}
              >
                <Avatar sx={{ fontSize: '16px' }}>{apiData ? `${apiData.LName.charAt(0).toUpperCase()}${apiData.FName.charAt(0).toUpperCase()}` : " "}</Avatar>
              </IconButton>
            </Tooltip>
            <Tooltip title="Logout">
              <IconButton
                onClick={handleNextButtonClick}
              >
                <Logout fontSize="24px" />
              </IconButton>
            </Tooltip>
          </Box>


        </Toolbar>
      </AppBar>
    </>
  );
}