import React, { useState, useEffect } from "react";
import Header from '../app/TopAppBar';
import { Typography, Grid, Skeleton, Card } from "@mui/material";
import DashboardCards from "./Cards";
import ChartComponent from "./Chart";
import API from "../Services/API";
import axios from "axios";

const Dashboard = () => {
    const [graphData, setGraphData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
    const access_token = JSON.parse(localStorage.getItem("accesstoken"));
    const profileName = storedUserData.FName + " " + storedUserData.LName;
    const user = storedUserData.SGID;
    const userName = storedUserData.name;

    const loadMocData = () => {
        try {
          axios
            .get(API.GET_ALL_MOC_FOR_APPROVALS, {
              headers: {
                Authorization: `Bearer ${access_token}`
              }
            })
            .then(function (response) {
              if (response.status === 200) {
                if (response.data && response.data.length > 0) {
    
                  const conclusionData = response.data;
                
    
                  const filteredConclusionData = response.data.filter((item) => item.mocForm.conclusion === null && item.mocForm.deleted !== true);
    
                  const filteredApprovalData = response.data.filter((item) => {
                    //console.log(item, "item523")
                    const moc = item.mocForm;
                    if (
                      moc.conclusion === "Non Critical" &&
                      (
                        (moc.submission_status === "pending" || moc.submission_status === "resubmitted") ||
                        (item?.changeImplementation?.implement_status === "pending" || item?.changeImplementation?.implement_status === "resubmitted")
                      )
                    ) {
                      return true;
                    }
                    else if (
                      (
                        (moc.submission_status === "pending" && moc.conclusion !== null) ||
                        moc.submission_status === "resubmitted" ||
                        (item?.changeImplementation?.implement_status === "pending" && moc.conclusion !== null) ||
                        item?.changeImplementation?.implement_status === "resubmitted" ||
                        (
                          // (moc.submission_status === "pending" || moc.submission_status === "approved") && (item?.trials === null || item?.trials?.trials_status === "pending" || item?.trials?.trials_status === "resubmitted" || item?.trials?.trials_status === "approved") && (item?.changeImplementation === null || item?.changeImplementation?.implement_status === "pending" || item?.changeImplementation?.implement_status === "resubmitted" || item?.changeImplementation?.admin_approval_status === true && item?.changeImplementation?.quality_approval_status === false)
                          (moc.conclusion === "Quality Head") &&
                          ((moc.submission_status === "pending" || moc.submission_status === "approved") && moc.admin_approval_status === true)
                          && ((item?.changeImplementation === null || (item?.changeImplementation?.implement_status === "pending" || item?.changeImplementation?.implement_status === "resubmitted" || item?.changeImplementation?.implement_status === "approved" && item?.changeImplementation?.admin_approval_status === true && ((item?.changeImplementation?.quality_approval_status === true && (item?.changeImplementation?.plant_check === "Yes" && item?.changeImplementation?.plant_approval_status === false)) || item?.changeImplementation?.quality_approval_status === false)))
                          )
                        ) && user === item.user_sgid
    
                        // || (moc.conclusion === "Quality Head" && moc?.trials?.trials_status === "pending" || moc?.trials?.trials_status === "resubmitted" && moc?.trials?.admin_approval_status === false) && user === moc.user_sgid
                        ||
                        (
                          (moc.conclusion === "Safety Head") &&
                          ((moc.submission_status === "pending" || moc.submission_status === "approved") && moc.admin_approval_status === true) && ((item?.changeImplementation === null || (item?.changeImplementation?.implement_status === "pending" || item?.changeImplementation?.implement_status === "resubmitted" || item?.changeImplementation?.implement_status === "approved" && item?.changeImplementation?.admin_approval_status === true && ((item?.changeImplementation?.quality_approval_status === true && (item?.changeImplementation?.plant_check === "Yes" && item?.changeImplementation?.plant_approval_status === false)) || item?.changeImplementation?.safety_approval_status === false) && item?.changeImplementation?.plant_approval_status === false))
                          )
                        ) && user === item.user_sgid
                        ||
                        (
                          (moc.conclusion === "Safety & Quality")
                          &&
                          (moc.submission_status === "approved" && moc.admin_approval_status === true && moc.quality_approval_status === true && moc.safety_approval_status === true && moc.plant_approval_status === true && moc.plant_check === "Yes") && (item?.trials === null || item?.trials?.admin_approval_status === true && item?.trials?.quality_approval_status === true && item?.trials?.safety_approval_status === true && item?.trials?.plant_approval_status === true)
                          && ((item?.changeImplementation === null || item?.changeImplementation?.admin_approval_status === true && item?.changeImplementation?.quality_approval_status === true && item?.changeImplementation?.plant_approval_status === true && item?.changeImplementation?.safety_approval_status === false)
                          ) &&
                          user === item.user_sgid
                          ||
                          (moc.submission_status === "approved" && moc.admin_approval_status === true && moc.quality_approval_status === true && moc.safety_approval_status === true && moc.plant_approval_status === true && moc.plant_check === "Yes")
                          && ((item?.changeImplementation === null || item?.changeImplementation?.admin_approval_status === false && item?.changeImplementation?.quality_approval_status === false && item?.changeImplementation?.safety_approval_status === false && item?.changeImplementation?.plant_approval_status === false && item?.changeImplementation?.safety_approval_status === false)
                          ) &&
                          user === item.user_sgid
                          ||
                          (moc.submission_status === "approved" && moc.admin_approval_status === true && moc.quality_approval_status === true && moc.safety_approval_status === true && moc.plant_approval_status === false && moc.plant_check === "No") &&
                          user === item.user_sgid
                          // && ((item?.changeImplementation === null || item?.changeImplementation?.admin_approval_status === true && item?.changeImplementation?.quality_approval_status === true && item?.changeImplementation?.plant_approval_status === false && item?.changeImplementation?.safety_approval_status === false)
                          // )
                        ) &&
                        user === item.user_sgid
                        ||
                        (
                          (moc.conclusion === "Quality Head") &&
                          (moc.submission_status === "pending" || moc.submission_status === "approved") && (item?.changeImplementation === null || item?.changeImplementation?.implement_status === "pending" || item?.changeImplementation?.implement_status === "resubmitted")
                        ) && user === item.approver_sgid
                        ||
                        ((moc.conclusion === "Safety Head") &&
                          (moc.submission_status === "pending" || moc.submission_status === "approved") && (item?.changeImplementation === null || item?.changeImplementation?.implement_status === "pending" || item?.changeImplementation?.implement_status === "resubmitted")
                        ) && user === item.approver_sgid
                        ||
                        (
                          (moc.conclusion === "Safety & Quality")
                          &&
                          (moc.submission_status === "approved" && moc.admin_approval_status === true && moc.quality_approval_status === true && moc.safety_approval_status === true && moc.plant_approval_status === true && moc.plant_check === "Yes") && (item?.trials === null || item?.trials?.admin_approval_status === true && item?.trials?.quality_approval_status === true && item?.trials?.safety_approval_status === true && item?.trials?.plant_approval_status === true)
                          && ((item?.changeImplementation === null || item?.changeImplementation?.admin_approval_status === true && item?.changeImplementation?.quality_approval_status === true && item?.changeImplementation?.plant_approval_status === true && item?.changeImplementation?.safety_approval_status === false)
                          )
                          ||
                          (moc.submission_status === "approved" && moc.admin_approval_status === true && moc.quality_approval_status === true && moc.safety_approval_status === true && moc.plant_approval_status === true && moc.plant_check === "Yes") && (item?.trials?.trials_status === "pending" && item?.trials?.admin_approval_status === false && item?.trials?.quality_approval_status === false && item?.trials?.safety_approval_status === false && item?.trials?.plant_approval_status === false)
                          ||
                          (moc.submission_status === "approved" && moc.admin_approval_status === true && moc.quality_approval_status === true && moc.safety_approval_status === true && moc.plant_approval_status === true && moc.plant_check === "Yes") && (item?.trials === null || item?.trials?.admin_approval_status === true && item?.trials?.quality_approval_status === true && item?.trials?.safety_approval_status === true && item?.trials?.plant_approval_status === true)
                          && ((item?.changeImplementation?.admin_approval_status === false && item?.changeImplementation?.quality_approval_status === false && item?.changeImplementation?.plant_approval_status === false && item?.changeImplementation?.safety_approval_status === false)
                          )
                          ||
                          (moc.submission_status === "approved" && moc.admin_approval_status === true && moc.quality_approval_status === true && moc.safety_approval_status === true && moc.plant_approval_status === false && moc.plant_check === "No") && (item?.trials === null || item?.trials?.admin_approval_status === true && item?.trials?.quality_approval_status === true && item?.trials?.safety_approval_status === true && item?.trials?.plant_approval_status === false)
                          && ((item?.changeImplementation === null || item?.changeImplementation?.admin_approval_status === false && item?.changeImplementation?.quality_approval_status === false && item?.changeImplementation?.plant_approval_status === false && item?.changeImplementation?.safety_approval_status === false)
                          )
                        ) &&
                        user === item.approver_sgid
                        ||
                        (
                          (
                            (moc.conclusion === "Quality Head") &&
                            (moc.submission_status === "approved" && moc.admin_approval_status === true)
                            && ((item?.changeImplementation === null || (item?.changeImplementation?.implement_status === "pending" || item?.changeImplementation?.implement_status === "resubmitted" || item?.changeImplementation?.implement_status === "approved"))
                            )
                          ) && userName === moc.quality_name
                        )
    
                        ||
                        (
                          (moc.conclusion === "Safety & Quality") &&
                          (moc.submission_status === "approved" && moc.admin_approval_status === true) && (item?.trials === null || item?.trials?.admin_approval_status === true && (item?.trials?.quality_approval_status === false || item?.trials?.quality_approval_status === true || item?.trials?.quality_approval_status === undefined))
                          && ((item?.changeImplementation === null || item?.changeImplementation?.admin_approval_status === true)
                          )
                        ) && userName === moc.quality_name
                        ||
                        (
                          (moc.conclusion === "Safety Head") &&
                          (moc.submission_status === "approved" && moc.admin_approval_status === true)
                          && ((item?.changeImplementation === null || (item?.changeImplementation?.implement_status === "pending" || item?.changeImplementation?.implement_status === "resubmitted" || item?.changeImplementation?.implement_status === "approved"))
                          )
                        ) && userName === moc.safety_name
                        ||
                        (
                          (moc.conclusion === "Safety & Quality") &&
                          (moc.submission_status === "approved" && moc.admin_approval_status === true) && (item?.trials === null || item?.trials?.admin_approval_status === true && (item?.trials?.safety_approval_status === false || item?.trials?.safety_approval_status === true))
                          && ((item?.changeImplementation === null || item?.changeImplementation?.admin_approval_status === true)
                          )
                        ) && userName === moc.safety_name
                        ||
                        (
                          (moc.conclusion === "Quality Head") &&
                          (moc.submission_status === "approved" && moc.admin_approval_status === true && moc.quality_approval_status === true && (moc.plant_check === "Yes" || item?.changeImplementation?.plant_check === "Yes"))
                          && (item?.changeImplementation === null || (item?.changeImplementation?.implement_status === "pending" || item?.changeImplementation?.implement_status === "resubmitted" || item?.changeImplementation?.implement_status === "approved") && item?.changeImplementation?.admin_approval_status === true && item?.changeImplementation?.quality_approval_status === true && item?.changeImplementation?.plant_approval_status === false)
                        ) &&
                        userName === moc.plant_name
                        ||
                        (
                          (moc.conclusion === "Safety Head") &&
                          (moc.submission_status === "approved" && moc.admin_approval_status === true && moc.safety_approval_status === true && (moc.plant_check === "Yes" || item?.changeImplementation?.plant_check === "Yes")) && (item?.changeImplementation === null || item?.changeImplementation?.implement_status === "pending" || item?.changeImplementation?.implement_status === "resubmitted" || item?.changeImplementation?.implement_status === "approved" && item?.changeImplementation?.admin_approval_status === true && item?.changeImplementation?.safety_approval_status === true && item?.changeImplementation?.plant_approval_status === false)
                        ) && userName === moc.plant_name
                        ||
                        (
                          (moc.conclusion === "Safety & Quality")
                          &&
                          (moc.submission_status === "approved" && moc.admin_approval_status === true && ((moc.quality_approval_status === true && moc.safety_approval_status === true) || (moc.quality_approval_status === true || moc.safety_approval_status === true)) && moc.plant_check === "Yes") && (item?.trials === null || item?.trials?.trials_status === "approved" && item?.trials?.admin_approval_status === true && (item?.trials?.quality_approval_status === true && item?.trials?.safety_approval_status === true) || (item?.trials?.quality_approval_status === true || item?.trials?.safety_approval_status === true)) && (item?.changeImplementation === null || item?.changeImplementation?.implement_status === "approved" && item?.changeImplementation?.admin_approval_status === true && (item?.changeImplementation?.quality_approval_status === true && item?.changeImplementation?.safety_approval_status === true || (item?.changeImplementation?.quality_approval_status === true || item?.changeImplementation?.safety_approval_status === true)) && item?.changeImplementation?.plant_approval_status === false)
                        )
                        && userName === moc.plant_name
                      )
                    ) {
                      return true;
                    }
                    return false;
                  });
                  //console.log("filteredApprovalData",filteredApprovalData)
                  const filteredRejectData = response.data.filter((item) => {
                    const moc = item.mocForm;
                    // Define isRejected variable based on item properties
                    const isRejected = item.mocForm.submission_status === "rejected" ||
                      item?.changeImplementation?.implement_status === "rejected";
                    // Check if the item matches the desired conditions
                    if (
                      (moc.conclusion === "Quality Head" ||
                        moc.conclusion === "Safety Head" ||
                        moc.conclusion === "Non Critical") &&
                      isRejected
                    ) {
                      return true;
                    }
                    if (
                      moc.conclusion === "Safety & Quality" &&
                      item.mocForm.submission_status === "rejected" || item?.changeImplementation?.implement_status === "rejected" && user === item.user_sgid
                      || moc?.admin_approval_status === false && user === item.approver_sgid
                      || moc?.admin_approval_status === true && moc?.quality_approval_status === false && userName === moc.quality_name
                      || moc?.admin_approval_status === true && moc?.safety_approval_status === false && userName === moc.safety_name
                      || moc?.admin_approval_status === true && moc?.quality_approval_status === true && moc?.safety_approval_status === true && moc?.plant_approval_status === false && userName === moc.plant_name
                      ||
                      item?.changeImplementation?.implement_status === "rejected" && item?.changeImplementation?.admin_approval_status === false && user === item.approver_sgid
                      || item?.changeImplementation?.implement_status === "rejected" && item?.changeImplementation?.admin_approval_status === true && item?.changeImplementation?.quality_approval_status === false && userName === moc.quality_name
                      || item?.changeImplementation?.implement_status === "rejected" && item?.changeImplementation?.admin_approval_status === true && item?.changeImplementation?.safety_approval_status === false && userName === moc.safety_name
                      || item?.changeImplementation?.implement_status === "rejected" && item?.changeImplementation?.admin_approval_status === true && item?.changeImplementation?.quality_approval_status === true && item?.changeImplementation?.safety_approval_status === true && item?.changeImplementation?.plant_approval_status === false && userName === moc.plant_name
                    ) {
                      return true;
                    }
                    return false;
                  });
                  //console.log(filteredRejectData, "filteredRejectData645")
                  //console.log(rejectedData, "rejectedData646")
    
                  const filteredCompletedData = response.data.filter((item) => {
                    const moc = item.mocForm;
                    //console.log(moc, "moc648")
                    if (
                      moc.conclusion === "Non Critical" &&
                      (
                        item?.changeImplementation?.implement_status === "approved" &&
                        item?.changeImplementation?.admin_approval_status === true &&
                        (user === item.user_sgid || user === item.approver_sgid)
                      )
                    ) {
                      return true;
                    } else if (
                      moc.conclusion === "Quality Head" &&
                      (
                        item?.changeImplementation?.implement_status === "approved" &&
                        ((item?.changeImplementation?.admin_approval_status === true && item?.changeImplementation?.product_compliance_check === "No") || (item?.changeImplementation?.admin_approval_status === true &&
                          item?.changeImplementation?.quality_approval_status === true &&
                          (
                            (item?.changeImplementation?.plant_check === "No") ||
                            (item?.changeImplementation?.plant_check === "Yes" && item?.changeImplementation?.plant_approval_status === true)
                          )))
    
                        &&
                        (user === item.user_sgid || user === item.approver_sgid || userName === moc.quality_name || userName === moc.plant_name)
                      )
                    ) {
                      return true;
                    }
                    else if (
                      moc.conclusion === "Safety Head" &&
                      (
                        item?.changeImplementation?.implement_status === "approved" &&
                        ((item?.changeImplementation?.admin_approval_status === true && item?.changeImplementation?.product_compliance_check === "No") || (item?.changeImplementation?.admin_approval_status === true &&
                          item?.changeImplementation?.safety_approval_status === true &&
                          (
                            (item?.changeImplementation?.plant_check === "No") ||
                            (item?.changeImplementation?.plant_check === "Yes" && item?.changeImplementation?.plant_approval_status === true)
                          )))
                        &&
                        (user === item.user_sgid || user === item.approver_sgid || userName === moc.safety_name || userName === moc.plant_name)
                      )
                    ) {
                      return true;
                    }
                    else if (
                      moc.conclusion === "Safety & Quality" &&
                      (
                        item?.changeImplementation?.implement_status === "approved" &&
                        item?.changeImplementation?.admin_approval_status === true &&
                        item?.changeImplementation?.quality_approval_status === true &&
                        item?.changeImplementation?.safety_approval_status === true &&
                        (
                          (moc?.plant_check === "No") ||
                          (moc?.plant_check === "Yes" && item?.changeImplementation?.plant_approval_status === true)
                        ) &&
                        (user === item.user_sgid || user === item.approver_sgid || userName === moc.quality_name || userName === moc.safety_name || userName === moc.plant_name)
                      )
                    ) {
                      return true;
                    } else {
                      return false;
                    }
                  });
    
                  const filteredProgressData = response.data.filter((item) => {
                    const moc = item.mocForm;
                    if (
                      moc.conclusion === "Non Critical" &&
                      (
                        moc.admin_approval_status === true
                      )
                    ) {
                      return true;
                    } else if (
                      moc.conclusion === "Quality Head" &&
                      (
                        (moc.admin_approval_status === true && moc.quality_approval_status === true && user === item.user_sgid) ||
                        (moc.admin_approval_status === true && user === item.approver_sgid) ||
                        (moc.admin_approval_status === true && moc.quality_approval_status === true && userName === moc.quality_name) ||
                        (moc.admin_approval_status === true && moc.quality_approval_status === true && userName === moc.plant_name)
                      )
                    ) {
                      return true;
                    } else if (
                      moc.conclusion === "Safety Head" &&
                      (
                        (moc.admin_approval_status === true && moc.safety_approval_status === true && user === item.user_sgid) ||
                        (moc.admin_approval_status === true && user === item.approver_sgid) ||
                        (moc.admin_approval_status === true && moc.safety_approval_status === true && userName === moc.safety_name) ||
                        (moc.admin_approval_status === true && moc.safety_approval_status === true && userName === moc.plant_name)
                      )
                    ) {
                      return true;
                    } else if (
                      moc.conclusion === "Safety & Quality" &&
                      (
                        (moc.admin_approval_status === true &&
                          moc.quality_approval_status === true && moc.safety_approval_status === true && user === item.user_sgid) ||
                        (item?.trials?.admin_approval_status === true &&
                          item?.trials?.quality_approval_status === true && item?.trials?.safety_approval_status === true && user === item.user_sgid) ||
                        (moc.admin_approval_status === true && (item?.trials_id === null || item?.trials?.admin_approval_status === true) && (item?.changeImplementation === null || item?.changeImplementation?.admin_approval_status === true) && user === item.approver_sgid) ||
                        (moc.admin_approval_status === true &&
                          moc.quality_approval_status === true && userName === moc.quality_name) ||
                        (moc.admin_approval_status === true && moc.safety_approval_status === true && userName === moc.safety_name) ||
                        (moc.admin_approval_status === true && moc.safety_approval_status === true && moc.quality_approval_status === true && userName === moc.plant_name)
                      )
                    ) {
                      return true;
                    }
                    return false;
                  });
                  //console.log("filteredProgressData",filteredProgressData)
                  const completedMoc = filteredCompletedData.length;
                  localStorage.setItem("completedMoc", completedMoc);
                  const pendingMoc = filteredApprovalData.length;
                  localStorage.setItem("pendingMoc", pendingMoc);
                  const resubmittedMoc = filteredProgressData.length;
                  localStorage.setItem("resubmittedMoc", resubmittedMoc);
                  const rejectedMoc = filteredRejectData.length;
                  localStorage.setItem("rejectedMoc", rejectedMoc);
                  const allMoc = conclusionData.length;
                  localStorage.setItem("allMoc", allMoc);
                       // Assuming you have the data stored in localStorage as described
        const completedMocCount = localStorage.getItem("completedMoc") || 0;
        const pendingMocCount = localStorage.getItem("pendingMoc") || 0;
        const resubmittedMocCount = localStorage.getItem("resubmittedMoc") || 0;
        const rejectedMocCount = localStorage.getItem("rejectedMoc") || 0;

        const seriesData = {
            "All Moc": {
                "Pending Moc": parseInt(pendingMocCount),
                "Resubmitted Moc": parseInt(resubmittedMocCount),
                "Completed Moc": parseInt(completedMocCount),
                "Rejected Moc": parseInt(rejectedMocCount),
            },
        };

        setGraphData(seriesData);
        
                }
              }
            })
            .catch(function (error) {
              //console.log("ERROR", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
        } catch (error) {
          console.error("An error occurred:", error);
        }
      };
    
      useEffect(
        () => {
          loadMocData();
        },[]);

    const allMocCount = localStorage.getItem("allMoc") || 0;
    const completedMocCount = localStorage.getItem("completedMoc") || 0;
    const pendingMocCount = localStorage.getItem("pendingMoc") || 0;
    const resubmittedMocCount = localStorage.getItem("resubmittedMoc") || 0;
    const rejectedMocCount = localStorage.getItem("rejectedMoc") || 0;

    return (
        <div style={{ justifyContent: 'center', padding: '16px' }}>
            {/* <Header pageTitle="Dashboard" isDashboard={true} /> */}
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography
                        id="user_name"
                        style={{ fontSize: "20px", fontWeight: 600 }}
                    >
                        Welcome {profileName}
                    </Typography>
                </Grid>
            </Grid>
            {isLoading &&
                <div className="skeleton-dashboard" style={{ width: "100%" }}>
                    <Grid container style={{ justifyContent: "space-between", marginTop: "30px" }}>
                        <Skeleton style={{ width: "19%", height: 110, transform: "none" }} />
                        <Skeleton style={{ width: "19%", height: 110, transform: "none" }} />
                        <Skeleton style={{ width: "19%", height: 110, transform: "none" }} />
                        <Skeleton style={{ width: "19%", height: 110, transform: "none" }} />
                        <Skeleton style={{ width: "19%", height: 110, transform: "none" }} />
                    </Grid>
                    <Grid container style={{ justifyContent: "space-between", marginTop: "20px" }}>
                        <Skeleton style={{ width: "85%", height: 400, transform: "none" }} />
                    </Grid>
                </div>}

            <Grid item xs={12}>
                <Grid>
                    <DashboardCards
                        allMocCount={allMocCount}
                        completedMocCount={completedMocCount}
                        pendingMocCount={pendingMocCount}
                        resubmittedMocCount={resubmittedMocCount}
                        rejectedMocCount={rejectedMocCount}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid
                        item
                        xl={12}
                        md={12}
                        xs={12}
                        className="pl-md"
                        style={{ marginTop: "24px" }}
                    >
                        <Card
                            style={{
                                marginRight: "0px",
                                boxShadow: "none",
                                backgroundColor: "#FCFCFC",
                                border: "1px solid #E8E8E8",
                                padding: 10,
                            }}
                        >
                            {Object.keys(graphData).length > 0 ? (
                                <ChartComponent series={graphData} />
                            ) : (
                                <div>No data available</div>
                            )}
                        </Card>
                    </Grid>
                </Grid>
            </Grid>

        </div>
    );
}

export default Dashboard;