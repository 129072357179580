import RequestForm from '../src/Pages/RequestForm';
import LoginLaunchPage from '../src/Login/LaunchScreen';
import ChangeAssessment from './Pages/ChangeAssessment';
import Dashboard from './Pages/Dashboard';
import HelpSupport from './Pages/HelpSupport';
import ManageMoc from './Pages/ManageMoc';
import EHSF from './Pages/EHSF';
import ConclusionMoc from './Pages/ConclusionMoc';
import ActionSheet from './actionSheet/ActionSheet';
import Trials from './actionSheet/TrialsMoc';
import Document from './actionSheet/Documents';
import Settings from './settings/Settings';
import UserList from './settings/UserList';
import NewUser from './settings/NewUser';
import Function from './settings/Function';
import Notifications from './settings/Notifications';
import ImplementMOC from './Implementation/implementMOC';
import Profile from './settings/Profile';

// 4M Change Management System
import ChangeRequest from './4m_Pages/ChangeRequest';
import ChangeRequired from './4m_Pages/ChangeRequired';
import ImpactChange from './4m_Pages/ImpactChange';
import Alternation from './4m_Pages/Alternation';
import ManageCms from './4m_Pages/ManageCms';
import DashboardCms from './4m_Pages/DashboardCms';
import Notification from './4m_Pages/Notification';
import NotificationsList from './4m_Pages/4m_settings/NotificationsList';
import NewMasterData from './settings/NewMasterData';
import QuestionsList from './settings/QuestionsList';
import ImpactQuestionsData from './settings/ImpactQuestionsData';
import AlterQuestionsData from './settings/AlterQuestionsData';

export const mainRoutes = [
  {
    path: '/Conclusion',
    name: 'Conclusion',
    component: ConclusionMoc
  },
  {
    path: '/ehsf',
    name: 'EHFS',
    component: EHSF
  },
  {
    path: '/RequestForm',
    name: 'Request Form',
    component: RequestForm
  },
  {
    path: '/ChangeAssessment',
    name: '6M change Assessment',
    component: ChangeAssessment
  },
  {
    path: '/Dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/Support',
    name: 'Help & Support',
    component: HelpSupport
  },
  {
    path: '/ManageMoc',
    name: 'Manage Moc',
    component: ManageMoc
  },
  {
    path: '/actionSheet',
    name: 'Action Sheet',
    component: ActionSheet
  },
  {
    path: '/trials',
    name: 'Trials',
    component: Trials
  },
  {
    path: '/documents',
    name: 'Document',
    component: Document
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings
  },
  {
    path: '/userList',
    name: 'User List',
    component: UserList
  },
  {
    path: '/newUser',
    name: 'New User',
    component: NewUser
  },
  {
    path: '/function',
    name: 'Function',
    component: Function
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications
  },
  {
    path: '/implementmoc',
    name: 'Implement MOC',
    component: ImplementMOC
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },

  // 4M Change Management System
  {
    path: '/changerequest',
    name: 'Change Request',
    component: ChangeRequest
  },
  {
    path: '/changerequired',
    name: 'Change Required',
    component: ChangeRequired
  },
  {
    path: '/impactchange',
    name: 'Impact Change',
    component: ImpactChange
  },
  {
    path: '/alternation',
    name: 'Alternation',
    component: Alternation
  },
  {
    path: '/managecms',
    name: 'Manage Cms',
    component: ManageCms
  },
  {
    path: '/dashboardcms',
    name: 'Dashboard Cms',
    component: DashboardCms
  },
  {
    path: '/notification',
    name: 'Notification',
    component: Notification
  },
  {
    path: '/notificationslist',
    name: 'Notifications List',
    component: NotificationsList
  },
  {
    path: '/newMasterData',
    name: 'New Master Data',
    component: NewMasterData
  },
  {
    path: '/questionsList',
    name: 'Questions List',
    component: QuestionsList
  },
  {
    path: '/impactquestionsData',
    name: 'Impact Questions Data',
    component: ImpactQuestionsData
  },
  {
    path: '/alterquestionsData',
    name: 'Alter Questions Data',
    component: AlterQuestionsData
  }

]

export default mainRoutes;