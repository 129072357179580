// import { jwtDecode } from "jwt-decode";

const _get_api_url = function () {
  switch (process.env.REACT_APP_STAGE) {
    case "dev":
      return "https://dev.sgmoc.api.saint-gobain.com/";
    case "local":
      return "http://localhost:8000/";
    default:
      return "https://sgmoc.saint-gobain.com/sgmoc-api";
  }
};

const _get_sso_url = () => {
  switch (process.env.REACT_APP_STAGE) {
    case "dev":
      return "https://uat.cloudgateway.saint-gobain.com/openam/oauth2/realms/root/realms/AccessManagement/authorize?response_type=code&client_id=POC_MOC&scope=stGoSGI&redirect_uri=https://dev.sgmoc.saint-gobain.com/login";
    case "local":
      return "https://uat.cloudgateway.saint-gobain.com/openam/oauth2/realms/root/realms/AccessManagement/authorize?response_type=code&client_id=POC_MOC&scope=stGoSGI&redirect_uri=http://localhost:3000/login";
    default:
      return "https://cloudsso.saint-gobain.com/openam/oauth2/realms/root/realms/AccessManagement/authorize?response_type=code&client_id=Management_Change&scope=stGoSGI&redirect_uri=https://sgmoc.saint-gobain.com/login";
  }
};

const _get_app_url = function () {
  switch (process.env.REACT_APP_STAGE) {
    case "dev":
      return "https://dev.sgmoc.saint-gobain.com/";
    case "local":
      return "http://localhost:3000/";
    default:
      return "https://sgmoc.saint-gobain.com/";
  }
};

// const _check_and_get_token = async (existingTokens) => {
//   try {
//     let decodedToken = jwtDecode(existingTokens);
//     let dateNow = new Date();
//     if (
//       decodedToken.exp < parseInt(dateNow.getTime() / 1000) ||
//       decodedToken.exp === parseInt(dateNow.getTime() / 1000)
//     ) {
//       (async () => {
//         let myHeaders = new Headers();
//         myHeaders.append("Content-Type", "application/json");
//         let url = configParam.AUTH_URL + "refresh";
//         await fetch(url, {
//           method: "POST",
//           headers: myHeaders,
//           body: JSON.stringify({ refresh_token: existingTokens }),
//         })
//           .then((response) => response.json())
//           .then(async (result) => {
//             if (Object.keys(result).length > 0) {
//               localStorage.setItem("SPGCAccessToken", result["access_token"]);
//               return result["access_token"];
//             } else {
//               console.log("no response from refresh token hit");
//             }
//           })
//           .catch((error) => console.log("error", error));
//       })();
//     } else {
//       return existingTokens;
//     }
//   } catch (e) {
//     return "";
//   }
// };

// const refreshAccessToken = async (query, variables, retries) => {
//   return _run_rest_api("refresh", { "refresh_token": localStorage.getItem("SPGCRefreshToken") }, true)
//     .then((data) => {
//       if (data && data.access_token) {
//         localStorage.setItem("", data.access_token);
//         return _run_gql_api(query, variables, retries - 1);
//       }
//     })
// }

// const _run_gql_api = async (query, variables, retries = 3) => {
//   let controller = new AbortController();
//   let signal = controller.signal;
//   var myHeaders = new Headers();
//   myHeaders.append("Content-Type", "application/json");
//   myHeaders.append(
//     "Authorization",
//     "Bearer "
//     + localStorage.getItem("SPGCAccessToken").replace(/['"]+/g, "")
//   );
//   setTimeout(() => controller.abort(), 20000);
//   return fetch(_get_graphql_url(), {
//     method: "POST",
//     headers: myHeaders,
//     body: JSON.stringify({ query: query, variables: variables }),
//     signal: signal,
//   })
//     .then(response => response.json())
//     .then(result => {
//       if (result.errors && result.errors.length && result.errors[0].extensions && result.errors[0].extensions.code === "invalid-jwt") {
//         redirectToLoginPage();
//         return refreshAccessToken(query, variables, retries);
//       } else {
//         return result["data"];
//       }
//     })
//     .catch((e) => {
//       if (retries > 0) return _run_gql_api(query, variables, retries - 1);
//       else {
//         promiseSetRecoil(apiTimeout, true);
//         return e;
//       }
//     });
// };

const changeType = [
  { value: "Process", label: "Process" },
  { value: "Product", label: "Product" },
  { value: "Safety", label: "Safety" }
];

const classification = [
  { value: "Man", label: "Man" },
  { value: "Machine", label: "Machine" },
  { value: "Material", label: "Material" },
  { value: "Method", label: "Method" },
  { value: "Others", label: "Others" }
];

const conclusionType = [
  { value: "Quality Head", label: "Critical to Quality" },
  { value: "Safety Head", label: "Critical to Safety" },
  // { value: "Safety & Quality", label: "Critical to Safety & Quality" },
  { value: "Non Critical", label: "Non Critical" }
];

const roleList = [
  { value: "Quality Head", label: "Quality Head" },
  { value: "Safety Head", label: "Safety Head" },
  { value: "Plant Head", label: "Plant Head" },
  { value: "Admin", label: "Admin" },
  { value: "Product Compliance", label: "Product Compliance" }
];

const additionalQuestions = {
  Environment: [
    "Will there be a change in plant Production output (Kgs or # units) as result of project?",
    "Will there be any new chemicals, changes to chemicals currently being used, or storage methods (drums, containers, etc.)?",
    "Are any structures (walls, floors, pipelines, building, etc.) going to be modified, demolished or renovated?",
    "Will there be a change in present air emissions (increase or decrease) or will there be a new air emission?",
    "Will new process equipment be installed or will existing process equipment be replaced or modified?",
    "Does the project involve maintenance, modification, reconstruction, of an existing solvent / chemical application system or a combustion system (i.e. boiler) or a new installation of this type?",
    "Will new equipment be needed to control or monitor emissions (capture, control, flow meters, gauges, etc.) or will it involve altering an existing capture or control system?",
    "Is Secondary containments considered for the chemical storages?",
    "Will the volume of process wastewater change (addition of processes, changes to existing, new pollutants, etc.)?",
    "Will the project impact the stormwater drainage system (modification, changes in volume, new source pollutant)?",
    "Will there be an increase in the amount of wastes or new wastes produced?",
    "Will new waste disposal or recycling services have to be explored?",
    "Will the amount of hazardous waste increase (used oil, solvents, etc.)?",
    "Will more storage space become necessary for the addition of new waste?",
    "Will the Aspect/Impact list require review prior to the start up of the project?",
    "Any other change that is not mentioned above (Please specify)",
  ],
  Health: [
    "Will this process introduce any new potential chemical hazards or add to the current personal exposure levels?",
    "Will this process introduce a potential for ionizing or non-ionizing radiation?",
    "Will this process introduce a new potential noise source or add to the current personal noise exposure levels?",
    "Will this process or equipment produce temperature extremes?",
    "Will the project have any impact on the overall ergonomic conditions of the activity?",
    "Any other change that is not mentioned above (Please specify)",

    "Does the equipment or any component of the project contain asbestos / ACMR",
    "Does the chemical being introduced have been approved by EHS?",
    "Does the chemical being introduced have a potential to increase current exposures at the work post?",
    "Has the one page MSDS been prepared and issued by EHS?",
    "Will the addition of this chemical require aspiration or modifications to the current aspiration system?",
    "Has the proper make-up air been determined where addition or modification to an aspiration system are expected?",
    "Will there be special handling or storage needs for this chemical?",
    "Will an eye wash and/or safety shower be require where this chemical is used?",
    "Will the employees have to be trained in the safe use of this chemical prior to its use? (As per local regulations)",
    "Has the EHS been notified that this chemical is being introduced and determined the need to schedule air sampling?",
    "Has the proper respiratory protection and/or additional personal protective equipment been determined and the employees placed in a respiratory protection program? Note: If this is a new project or modification to an existing process, engineering controls should be in place prior to start up. If an existing process is modified respiratory protection can be used until the engineering controls are in place.",
    "Does the equipment or process produce noise greater then applicable local regulations (hearing conservation/protection) ?",
    "Will placing the equipment or process in its intended position increase the noise greater then applicable local regulations (hearing conservation/protection) ?",
    "Are engineering controls (sound enclosures, etc.) required to bring the noise levels below applicable local regulations (hearing conservation/protection) ?",
    "Have all the proper labels and signage been installed if required?",
    "Does the equipment or process produce extreme temperatures?",
    "Are engineering controls required to bring temperature levels into an acceptable range? (spot cooling/heating, cool/heated room)",
    "Will employees be required to take breaks in temperature controlled areas?",
    "Have all the proper labels and signage been installed if required?"
  ],
  Safety: [
    "Does the project involve a completely new m/c or process?",
    "Will the project increase the site traffic external to the buildings?",
    "Will the project increase the FLT (Forklift Traffic) movements inside the workshops?",
    "Will the project result in a change to existing FLT or pedestrian access routes?",
    "Will the project result in a change to an existing SOP or introduce a new SOP?",
    "Does the LOTO procedure need to be revised due to this project?",
    "Does the Arc Flash risk need to be calculated and area to be labeled?",
    "Will any new fall potential be created due to this project?",
    "Any new safety devices, machine guards are included in this project to protect employee exposures to a safety hazard?",
    "Does any new warning signages / posters are to be displayed?",
    "Will any plant equipment be removed or placed on stand-by status?",
    "Does a Risk Assessment / KPS needed to be written or revised?",
    "Have proper working clearances for Electrical Cabinets been established? (Arc Flash)",
    "Does Lux level meet the recommended Standard? Any additional lighting needs to be added.",
    "Has the PPE assesment been revisited/revised?",
    "Any other change that is not mentioned above (Please specify)",
  ],
  Fire: [
    "Does the change requires validation from XL Catlin?",
    "Is the use/occupancy of any buildings or workshops changing?",
    "Will the total floor area (interior space only) of existing workshop change?",
    "Does the project involve the construction of a new building or workshop?",
    "Are any existing travel distances to fire exits going to change?",
    "Will there be any changes in quantities or types of flammable liquids to be used?",
    "Will there be a change to material/product storage or packing materials, which may increase / decrease fire load?",
    "Will additional fire detection, protection equipment be required? If critical panel than equipped with Fire Protection system ?",
    "Will any walls or doors be changed (location, size, breached,)",
    "Will any products be stored in racks?",
    "Are any LEV (Local Exhaust Ventilation) systems going to be modified or installed?",
    "Is there any potential for static charges to be created as a result of this project?",
    "Any other change that is not mentioned above (Please specify)",

    "a) Will the emergency alarms still be audible?",
    "b) Will the emergency lighting be adequate for evacuation?",
    "a) Explain changes due to the proposed project",
    "a) If a new building, is the physical separation > 20m from the nearest existing building (state the distance)?",
    "b) Will fire risks be increased from equipment design or product use?",
    "c) Will new equipment increase fire load to exceed than capability of existing fire protection systems?",
    "d) Will new equipment impede performance of existing fire protection systems?",
    "e) Will new equipment, such as conveyors that will block overhead sprinkler action, be designed with sprinkler protection underneath?",
    "a) What is the maximum travel distance for an employee to reach an Emergency Exit",
    "a) Are flammable liquids located near a heat source",
    "b) Are quantities of flammable liquids changing or application",
    "a) Will there be any explosive atmosphere potential?",
    "b) Give storage materials and arrangements",
    "a) What is the existing sprinkler density",
    "b) What is the required sprinkler density (IRP standards)",
    "c) What is the planned sprinkler density",
    "d) Have the hydraulic calculations been completed to prove that the system is adequate?",
    "e) Specify the number and type of fire extinguishers required (IRP standards) Include cost in project costs",
    "a) What provisions have been made for any breaches to existing solid walls to prevent the spread of fire and smoke",
    "b) If blocking or moving fire exits,  has EHS been consulted and has permission been given?",
    "c) Is access restricted to any of the following: Evacuation routes, Fire extinguishers, hose reels, pull boxes, and sprinkler protection systems.",
    "d) Will access ways and emergency exits be impeded?",
    "e) Will additional emergency lighting, signage or exits be required? ",
    "a) What is the material and height of storage?",
    "b) What levels are required to have sprinklers?",
    "a) If installing or modifying Local Exhaust Ventilation Systems (LEV's) will all sections be correctly grounded and bonded?",
    "a) If a static charge may be generated, have appropriate measures been taken? - specify in comments."
  ],
};


// 4M Change Management System
const desChangeRequired = [
  { value: "Process Change Including Process Flow Change", label: "Process Change Including Process Flow Change" },
  { value: "Use Of Optional Construction Method Or Material", label: "Use Of Optional Construction Method Or Material" },
  { value: "Refurbishment Of Equipment", label: "Refurbishment Of Equipment" },
  { value: "Tooling & Equipment Transferred to a Different Plant Location", label: "Tooling & Equipment Transferred to a Different Plant Location" },
  { value: "Change Of Sub-Contractor For Parts that Affect Fit, Form, Function, Durability Or Performance", label: "Change Of Sub-Contractor For Parts that Affect Fit, Form, Function, Durability Or Performance" },
  { value: "Operating / Setting Method", label: "Operating / Setting Method" }
];

const approvalSatus = [
  { value: "approved", label: "Approved" },
  { value: "conditionallyapproved", label: "Conditionally Approved" },
  { value: "rejected", label: "Rejected" },
  { value: "submittedbackforqueries", label: "Submitted back for queries" },
];

const rolesList = [
  { value: "Admin", label: "Admin" },
  { value: "User", label: "User" },
  { value: "SuperAdmin", label: "SuperAdmin" },
];

export const configParam = {
  primaryColor: "#FF6D04",
  lightBackground: "#FC8C200C",
  grey: "#D8D8D8",
  // CHECK_TOKEN: _check_and_get_token,
  API_URL: _get_api_url(),
  SSO_URL: _get_sso_url(),
  APP_URL: _get_app_url(),

  CHANGE_TYPES: changeType,
  CLASSIFICATION: classification,
  CONCLUSION_TYPES: conclusionType,
  ROLE_LIST: roleList,
  ADDITIONAL_QUESTIONS: additionalQuestions,

  // 4M Change Management System
  DES_CHANGE_REQUIRED: desChangeRequired,
  APPROVAL_STATUS: approvalSatus,
  ROLES_LIST: rolesList,
};

export default configParam;