import React, { useState } from "react";
import {
    Stepper,
    Step,
    StepButton,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    useTheme, useMediaQuery
} from "@mui/material";
import RequestForm from './RequestForm';
import ChangeAssessment from './ChangeAssessment';
import EHSF from "./EHSF";

const NewRequest = ({ isOpen, onClose }) => {
    const [dataSend, setDataSend] = useState(false);
    const [dataAss, setDataAss] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    // Callback function to update state
    const handleRequest = (data) => {
        setDataSend(data);
        //console.log(data, 'data')
    };

    const handleAssessment = (data) => {
        setDataAss(data);
        //console.log(data, 'data')
    };

    const handleGoBackAssessment = () => {
        setDataSend(false); // Reset to show RequestForm
        setDataAss(false);  // Ensure dataAss is also reset
    };

    //console.log("dataSend", dataSend)
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            fullScreen={fullScreen}
            fullWidth
            maxWidth="sm"
        >
            {!dataSend && <RequestForm dataProps={handleRequest} />}

            {dataSend && !dataAss && <ChangeAssessment dataAssessment={handleAssessment} onGoBackAssessment={handleGoBackAssessment} />}

        </Dialog>
    );
}
export default NewRequest;