import React, { useState, useEffect } from "react";
import Header from "../app/TopAppBar";
import { Typography, Card, CardContent, CircularProgress, Avatar } from "@mui/material";
import axios from "axios";
import API from "../Services/API";

const Profile = () => {
    const [apiData, setApiData] = useState("");
    const [initialLoad, setInitialLoad] = useState(true);

    const access_token = JSON.parse(localStorage.getItem("accesstoken"));

    useEffect(() => {
        const storedUserData = localStorage.getItem("userDatas");
        const userData = storedUserData ? JSON.parse(storedUserData) : null;
        setApiData(userData);
    }, []);

    const loadAllUser = async () => {
        try {
            await axios.get(API.GET_ALL_USERS, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            }).then(function (response) {
                if (response.status === 200 && response.data.length > 0) {
                    // setUserData(response.data);
                    const filteredResponse = response.data.filter(user => user.deleted !== true);
                }
            });
        } catch (error) {
            console.error("An error occurred:", error);
        }
        setInitialLoad(false);
    };

    useEffect(() => {
        loadAllUser();
    }, []);

    const allMocCount = localStorage.getItem("allMoc");
    const completedMocCount = localStorage.getItem("completedMoc");
    const pendingMocCount = localStorage.getItem("pendingMoc");
    const resubmittedMocCount = localStorage.getItem("resubmittedMoc");
    const rejectedMocCount = localStorage.getItem("rejectedMoc");

    return (
        <div style={{ background: "#F7F7F7", padding: 20, marginTop: "60px" }}>
            {/* <Header pageTitle="My Profile" /> */}
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    marginTop: 10,
                }}
            >
                {initialLoad ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "25px"
                        }}
                    >
                        < CircularProgress />
                    </div>
                ) : (
                    <React.Fragment>
                        <Card
                            style={{
                                width: "95%",
                                border: "1px solid #e2e2e2",
                                borderRadius: "8px",
                                marginBottom: 10,
                                boxShadow: "none",
                                textAlign: "center"
                            }}>
                            <CardContent
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    gap: "10px",
                                    padding: "16px",
                                    backgroundColor: "#B1174B",
                                    height: "35vh"
                                }}
                            >
                                <Avatar style={{ width: "80px", height: "80px" }}>{apiData ? `${apiData.LName.charAt(0).toUpperCase()}${apiData.FName.charAt(0).toUpperCase()}` : " "}</Avatar>
                                <Typography
                                    variant="subtitle1"
                                    style={{
                                        fontSize: 16,
                                        fontWeight: 600,
                                        fontFamily: "Inter, sans-serif",
                                        color: "#fff",
                                    }}
                                >
                                    {apiData ? apiData[0].FName + " " + apiData[0].LName : " "}
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card
                            // key={user.id}
                            style={{
                                width: "95%",
                                border: "1px solid #e2e2e2",
                                borderRadius: "8px",
                                marginBottom: 10,
                                boxShadow: "none",
                            }}
                        >
                            <CardContent
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "10px",
                                    padding: "16px",
                                }}
                            >
                                <div
                                    style={{
                                        alignItems: "center",
                                        gap: "8px",
                                    }}
                                >
                                    <Typography
                                        style={{
                                            color: "#000",
                                            fontFamily: "inherit",
                                            fontSize: "16px",
                                            fontWeight: 600,
                                        }}
                                    >
                                        Total MOC Raised
                                    </Typography>
                                    <Typography
                                        style={{
                                            color: "#000",
                                            fontFamily: "inherit",
                                            fontSize: "16px",
                                            fontWeight: 600,
                                        }}
                                    >
                                        {allMocCount}
                                    </Typography>
                                </div>

                            </CardContent>
                        </Card>
                        <Card
                            // key={user.id}
                            style={{
                                width: "95%",
                                border: "1px solid #e2e2e2",
                                borderRadius: "8px",
                                marginBottom: 10,
                                boxShadow: "none",
                            }}
                        >
                            <CardContent
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "10px",
                                    padding: "16px",
                                }}
                            >
                                <div
                                    style={{
                                        alignItems: "center",
                                        gap: "8px",
                                    }}
                                >
                                    <Typography
                                        style={{
                                            color: "#000",
                                            fontFamily: "inherit",
                                            fontSize: "16px",
                                            fontWeight: 600,
                                        }}
                                    >
                                        Total MOC Completed
                                    </Typography>
                                    <Typography
                                        style={{
                                            color: "#000",
                                            fontFamily: "inherit",
                                            fontSize: "16px",
                                            fontWeight: 600,
                                        }}
                                    >
                                        {completedMocCount}
                                    </Typography>
                                </div>

                            </CardContent>
                        </Card>
                        <Card
                            // key={user.id}
                            style={{
                                width: "95%",
                                border: "1px solid #e2e2e2",
                                borderRadius: "8px",
                                marginBottom: 10,
                                boxShadow: "none",
                            }}
                        >
                            <CardContent
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "10px",
                                    padding: "16px",
                                }}
                            >
                                <div
                                    style={{
                                        alignItems: "center",
                                        gap: "8px",
                                    }}
                                >
                                    <Typography
                                        style={{
                                            color: "#000",
                                            fontFamily: "inherit",
                                            fontSize: "16px",
                                            fontWeight: 600,
                                        }}
                                    >
                                        Total MOC Pending
                                    </Typography>
                                    <Typography
                                        style={{
                                            color: "#000",
                                            fontFamily: "inherit",
                                            fontSize: "16px",
                                            fontWeight: 600,
                                        }}
                                    >
                                        {pendingMocCount}
                                    </Typography>
                                </div>

                            </CardContent>
                        </Card>

                        <Card
                            // key={user.id}
                            style={{
                                width: "95%",
                                border: "1px solid #e2e2e2",
                                borderRadius: "8px",
                                marginBottom: 10,
                                boxShadow: "none",
                            }}
                        >
                            <CardContent
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "10px",
                                    padding: "16px",
                                }}
                            >
                                <div
                                    style={{
                                        alignItems: "center",
                                        gap: "8px",
                                    }}
                                >
                                    <Typography
                                        style={{
                                            color: "#000",
                                            fontFamily: "inherit",
                                            fontSize: "16px",
                                            fontWeight: 600,
                                        }}
                                    >
                                        Total MOC Resubmitted
                                    </Typography>
                                    <Typography
                                        style={{
                                            color: "#000",
                                            fontFamily: "inherit",
                                            fontSize: "16px",
                                            fontWeight: 600,
                                        }}
                                    >
                                        {resubmittedMocCount}
                                    </Typography>
                                </div>

                            </CardContent>
                        </Card>

                        <Card
                            // key={user.id}
                            style={{
                                width: "95%",
                                border: "1px solid #e2e2e2",
                                borderRadius: "8px",
                                marginBottom: 10,
                                boxShadow: "none",
                            }}
                        >
                            <CardContent
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "10px",
                                    padding: "16px",
                                }}
                            >
                                <div
                                    style={{
                                        alignItems: "center",
                                        gap: "8px",
                                    }}
                                >
                                    <Typography
                                        style={{
                                            color: "#000",
                                            fontFamily: "inherit",
                                            fontSize: "16px",
                                            fontWeight: 600,
                                        }}
                                    >
                                        Total MOC Rejected
                                    </Typography>
                                    <Typography
                                        style={{
                                            color: "#000",
                                            fontFamily: "inherit",
                                            fontSize: "16px",
                                            fontWeight: 600,
                                        }}
                                    >
                                        {rejectedMocCount}
                                    </Typography>
                                </div>

                            </CardContent>
                        </Card>
                    </React.Fragment>
                )}
            </div>
        </div>
    );
}
export default Profile;