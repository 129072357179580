import React, { useEffect, useState } from "react";
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Dashboard from "../Asset/Icons/dashboard_icon.svg";
import ManageMoc from "../Asset/Icons/manage_moc.svg";
import Settings from "../Asset/Icons/settings_icon.svg";
import Support from "../Asset/Icons/helpnSupport.svg";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';
import { styled, useTheme } from '@mui/material/styles';
import Favicon from "../Asset/Icons/favicon.ico";
import SaintGobain from "../Asset/Icons/Saint-Gobain_logo.svg";

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const CustomListItem = styled(ListItem)(({ theme, selected }) => ({
  cursor: 'pointer',
  backgroundColor: selected ? '#f0f0f0' : 'transparent',
  '&:hover': {
    backgroundColor: '#e0e0e0',
  },
}));

const LeftDrawer = ({ open, handleDrawerClose }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [apiData, setApiData] = useState("");
  const [selectedItem, setSelectedItem] = useState("");

  useEffect(() => {
    const storedUserData = localStorage.getItem("userDatas");
    const userData = storedUserData ? JSON.parse(storedUserData) : null;
    setApiData(userData);
  }, []);

  const textStyles = {
    fontWeight: 500,
    fontSize: 16,
    fontStyle: "normal",
    fontFamily: "Inter, sans-serif",
    color: "black",
    lineHeight: 1,
    textTransform: "capitalize",
    marginTop: "10px",
  };

  const handleNavigation = (route, item) => {
    navigate(route);
    setSelectedItem(item);
  };
  // Usage:
  // const handleDashboardClick = () => handleNavigation("/Dashboard");
  const handleNextButtonClick = () => handleNavigation("/login");
  // const handleManageMocClick = () => handleNavigation("/ManageMoc");
  const handleHelpNSupportClick = () => handleNavigation("/Support");
  const handleSettingsClick = () => handleNavigation("/settings");

  // 4M Change Management System
  const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
  const plantSelection = storedUserData.plant === "Sekurit";
  const handleDashboardClick = () => handleNavigation(plantSelection ? "/dashboardcms" : "/Dashboard");
  const handleManageMocClick = () => handleNavigation(plantSelection ? "/managecms" : "/ManageMoc");
  return (
    <Drawer sx={{
      width: drawerWidth,
      flexShrink: 0,
      '& .MuiDrawer-paper': {
        width: drawerWidth,
        boxSizing: 'border-box',
      },
    }}
      variant="persistent"
      anchor="left"
      open={open}>
      <DrawerHeader style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box style={{ display: 'flex', alignItems: 'center', marginLeft: "10px" }}>
          <img
            src={Favicon}
            alt="Favicon"
            style={{ width: "24px", height: "24px", marginRight: "8px" }} // Optional margin between icon and text
          />
          <Typography>{plantSelection ? "CMS" : "MOC"}</Typography>
        </Box>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader>

      <div style={{ background: "#ffffff" }}>
        <List>
          <Divider />
          <CustomListItem onClick={handleDashboardClick}>
            <ListItemIcon>
              <img
                src={Dashboard}
                alt="Dashboard"
                style={{ width: "24px", height: "24px" }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Dashboard"
              primaryTypographyProps={{ style: textStyles }}
            />
          </CustomListItem>
          {
            plantSelection ?
              <CustomListItem onClick={handleManageMocClick}>
                <ListItemIcon>
                  <img
                    src={ManageMoc}
                    alt="manageMOC"
                    style={{ width: "24px", height: "24px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Manage 4M"
                  primaryTypographyProps={{ style: textStyles }}
                />
              </CustomListItem>
              :
              <CustomListItem onClick={handleManageMocClick}>
                <ListItemIcon>
                  <img
                    src={ManageMoc}
                    alt="Manage MOC"
                    style={{ width: "24px", height: "24px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Manage MOC"
                  primaryTypographyProps={{ style: textStyles }}
                />
              </CustomListItem>
          }
          <CustomListItem onClick={handleSettingsClick}>
            <ListItemIcon>
              <img
                src={Settings}
                alt="Settings"
                style={{ width: "24px", height: "24px" }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Settings"
              primaryTypographyProps={{ style: textStyles }}
            />
          </CustomListItem>
          <CustomListItem onClick={handleHelpNSupportClick}>
            <ListItemIcon>
              <img
                src={Support}
                alt="Help & Support"
                style={{ width: "24px", height: "24px" }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Help & Support"
              primaryTypographyProps={{ style: textStyles }}
            />
          </CustomListItem>
        </List>
        <div>
          <img
            src={SaintGobain}
            alt="SaintGobain"
            style={{
              fontSize: 14,
              textAlign: "center",
              color: "#B51849",
              position: "absolute",
              bottom: 0,
              left: "37px",
              right: 0,
              padding: "30px",
            }}
          />
          <Typography
            variant="body1"
            style={{
              fontSize: 14,
              textAlign: "center",
              color: "#B51849",
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              padding: "10px",
            }}
          >
            Copyright @ 2024 INDEC4.0
          </Typography>
        </div>
      </div>
    </Drawer>
  );
};

export default LeftDrawer;