import React from 'react';
import { useMediaQuery } from '@mui/material';
import ManageMocDesktop from './ManageMocDeskTop'
import ManageMocMobile from './ManageMocMobile'

const ManageMoc = () => {

    const isMobile = useMediaQuery('(max-width:1024px)');
    return (
        <>
            {isMobile ? (
                <ManageMocMobile />
            ) : (
                <ManageMocDesktop />
            )}
        </>
    );
}

export default ManageMoc;