import React, { Component, useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  Grid,
  Card,
  TableCell,
  Table,
  TableRow,
  Box,
  TableContainer,
  TableBody,
  Typography
} from "@mui/material";



const PreviewPdf = ({ data }) => {
  return (
    <Grid>
      <Grid container style={{ marginBottom: 10 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",

          }}
        >
          <div>
            <Typography
              variant="h1"
              style={{
                textTransform: "uppercase",
                textAlign: "center",
                fontWeight: "bold",
                maxWidth: "700px",
                fontSize: "28px",
                color: 'FFFFFF',
                padding: '5px'
              }}
            >
              MOC REPORT
            </Typography>

          </div>

        </div>
      </Grid>
      <h3
        style={{
          width: "100%",
          fontWeight: 600,
          color: "#4D4D4D",
          padding: "7px 15px",
          fontSize: 18,
        }}
      >
        Request Form
      </h3>
      <Card style={{ marginBottom: "10px", width: "99%", border: "1px solid #ccc" }}>
        <Grid container style={{
          marginBottom: 10, display: "flex",
          // alignItems: "center",
          flexDirection: "column",
          width: "100%",
        }} >
          <div style={{
            display: "flex",
            //   alignItems: "center",
            // justifyContent: "space-between",
            width: "100%",
            marginBottom: "10px" // Add margin to separate the sections
          }}>
            <Typography
              variant="h1"
              style={{
                textTransform: "uppercase",
                textAlign: "left",
                fontWeight: "bold",
                width: "300px",
                fontSize: "18px",
                color: 'FFFFFF',
                padding: '5px'
              }}
            >
              Date
            </Typography>
            <Typography
              variant="h1"
              style={{
                textTransform: "uppercase",
                textAlign: "left",
                width: "300px",
                fontSize: "18px",
                color: 'FFFFFF',
                padding: '5px'
              }}
            >
              {data?.moc_date}
            </Typography>
          </div>
          <div style={{
            display: "flex",
            //   alignItems: "center",
            // justifyContent: "space-between",
            width: "100%",
            marginBottom: "10px" // Add margin to separate the sections
          }}>
            <Typography
              variant="h1"
              style={{
                textTransform: "uppercase",
                textAlign: "left",
                fontWeight: "bold",
                width: "300px",
                fontSize: "18px",
                color: 'FFFFFF',
                padding: '5px'
              }}
            >
              Requisition By
            </Typography>
            <Typography
              variant="h1"
              style={{
                textTransform: "uppercase",
                textAlign: "left",

                width: "300px",
                fontSize: "18px",
                color: 'FFFFFF',
                padding: '5px'
              }}
            >
              {data?.requisition_by}
            </Typography>
          </div>
          <div style={{
            display: "flex",
            //   alignItems: "center",

            width: "100%",
            marginBottom: "10px" // Add margin to separate the sections
          }}>
            <Typography
              variant="h1"
              style={{
                textTransform: "uppercase",
                textAlign: "left",
                fontWeight: "bold",
                width: "300px",
                fontSize: "18px",
                color: 'FFFFFF',
                padding: '5px'
              }}
            >
              Department
            </Typography>
            <Typography
              variant="h1"
              style={{
                textTransform: "uppercase",
                textAlign: "left",

                width: "300px",
                fontSize: "18px",
                color: 'FFFFFF',
                padding: '5px'
              }}
            >
              {data?.department}
            </Typography>
          </div>
          <div style={{
            display: "flex",
            //   alignItems: "center",

            width: "100%",
            marginBottom: "10px" // Add margin to separate the sections
          }}>
            <Typography
              variant="h1"
              style={{
                textTransform: "uppercase",
                textAlign: "left",
                fontWeight: "bold",
                width: "300px",
                fontSize: "18px",
                color: 'FFFFFF',
                padding: '5px'
              }}
            >
              Product/Process Change
            </Typography>
            <Typography
              variant="h1"
              style={{
                textTransform: "uppercase",
                textAlign: "left",
                width: "300px",
                fontSize: "18px",
                color: 'FFFFFF',
                padding: '5px'
              }}
            >
              {data?.change_type}
            </Typography>
          </div>
          <div style={{
            display: "flex",
            //   alignItems: "center",

            width: "100%",
            marginBottom: "10px" // Add margin to separate the sections
          }}>
            <Typography
              variant="h1"
              style={{
                textTransform: "uppercase",
                textAlign: "left",
                fontWeight: "bold",
                width: "300px",
                fontSize: "18px",
                color: 'FFFFFF',
                padding: '5px'
              }}
            >
              Brief Description
            </Typography>
            <Typography
              variant="h1"
              style={{
                textTransform: "uppercase",
                textAlign: "left",

                width: "300px",
                fontSize: "18px",
                color: 'FFFFFF',
                padding: '5px'
              }}
            >
              {data?.brief_description}
            </Typography>
          </div>
          <div style={{
            display: "flex",
            //   alignItems: "center",
            width: "100%",
            marginBottom: "10px" // Add margin to separate the sections
          }}>
            <Typography
              variant="h1"
              style={{
                textTransform: "uppercase",
                textAlign: "left",
                fontWeight: "bold",
                width: "300px",
                fontSize: "18px",
                color: 'FFFFFF',
                padding: '5px'
              }}
            >
              Classification
            </Typography>
            <Typography
              variant="h1"
              style={{
                textTransform: "uppercase",
                textAlign: "left",
                width: "300px",
                fontSize: "18px",
                color: 'FFFFFF',
                padding: '5px'
              }}
            >
              {data?.classification}
            </Typography>
          </div>
          <div style={{
            display: "flex",
            //   alignItems: "center",

            width: "100%",
            marginBottom: "10px" // Add margin to separate the sections
          }}>
            <Typography
              variant="h1"
              style={{
                textTransform: "uppercase",
                textAlign: "left",
                fontWeight: "bold",
                width: "300px",
                fontSize: "18px",
                color: 'FFFFFF',
                padding: '5px'
              }}
            >
              Particulars
            </Typography>
            <Typography
              variant="h1"
              style={{
                textTransform: "uppercase",
                textAlign: "left",

                width: "300px",
                fontSize: "18px",
                color: 'FFFFFF',
                padding: '5px'
              }}
            >
              {data?.particulars}
            </Typography>
          </div>
          <div style={{
            display: "flex",
            //   alignItems: "center",

            width: "100%",
            marginBottom: "10px" // Add margin to separate the sections
          }}>
            <Typography
              variant="h1"
              style={{
                textTransform: "uppercase",
                textAlign: "left",
                fontWeight: "bold",
                width: "300px",
                fontSize: "18px",
                color: 'FFFFFF',
                padding: '5px'
              }}
            >
              Current Status
            </Typography>
            <Typography
              variant="h1"
              style={{
                textTransform: "uppercase",
                textAlign: "left",

                width: "300px",
                fontSize: "18px",
                color: 'FFFFFF',
                padding: '5px'
              }}
            >
              {data?.current_status}
            </Typography>
          </div>
          <div style={{
            display: "flex",
            //   alignItems: "center",

            width: "100%",
            marginBottom: "10px" // Add margin to separate the sections
          }}>
            <Typography
              variant="h1"
              style={{
                textTransform: "uppercase",
                textAlign: "left",
                fontWeight: "bold",
                width: "300px",
                fontSize: "18px",
                color: 'FFFFFF',
                padding: '5px'
              }}
            >
              Change Required
            </Typography>
            <Typography
              variant="h1"
              style={{
                textTransform: "uppercase",
                textAlign: "left",
                width: "300px",
                fontSize: "18px",
                color: 'FFFFFF',
                padding: '5px'
              }}
            >
              {data?.change_required}
            </Typography>
          </div>
          <div style={{
            display: "flex",
            //   alignItems: "center",

            width: "100%",
            marginBottom: "10px" // Add margin to separate the sections
          }}>
            <Typography
              variant="h1"
              style={{
                textTransform: "uppercase",
                textAlign: "left",
                fontWeight: "bold",
                width: "300px",
                fontSize: "18px",
                color: 'FFFFFF',
                padding: '5px'
              }}
            >
              Reason for change required
            </Typography>
            <Typography
              variant="h1"
              style={{
                textTransform: "uppercase",
                textAlign: "left",

                width: "300px",
                fontSize: "18px",
                color: 'FFFFFF',
                padding: '5px'
              }}
            >
              {data?.reason_change_required}
            </Typography>
          </div>




        </Grid>
      </Card>
      <h3
        style={{
          width: "100%",
          fontWeight: 600,
          color: "#4D4D4D",
          padding: "7px 15px",
          fontSize: 18,
        }}
      >
        6M Check Points
      </h3>
      <Card style={{ marginBottom: "10px", width: "99%", border: "1px solid #ccc" }}>
        <Grid container direction="column" spacing={2}>
          {data?.checkpoints.slice(0, 13).map((checkpointData, index) => (
            <Grid item key={index}>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <Typography
                    variant="h6"
                    style={{
                      textTransform: "uppercase",
                      textAlign: "left",
                      fontWeight: "bold",
                      fontSize: "16px",
                      color: '#000000', // Changed to black for readability
                      padding: '5px'
                    }}
                  >
                    {checkpointData.checkpoint.question_no ? `${checkpointData.checkpoint.question_no}. ` : ""}
                    {checkpointData.checkpoint.questions}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body1"
                    style={{
                      textTransform: "uppercase",
                      textAlign: "left",
                      // fontWeight: "bold",
                      fontSize: "14px",
                      color: '#000000', // Changed to black for readability
                      padding: '5px'
                    }}
                  >
                    Results: {checkpointData.results}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body1"
                    style={{
                      textTransform: "uppercase",
                      textAlign: "left",
                      // fontWeight: "bold",
                      fontSize: "14px",
                      color: '#000000', // Changed to black for readability
                      padding: '5px'
                    }}
                  >
                    Remarks: {checkpointData.checkpoints_remarks}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Card>
      <Card style={{ marginBottom: "10px", width: "99%", border: "1px solid #ccc" }}>
        <Grid container direction="column" spacing={2}>
          {data?.checkpoints[12].change_affects_json.map((affectData, index) => (
            <Grid item key={index}>
              {Object.keys(affectData).map((key) => (
                <div key={key}>
                  <Typography
                    variant="h6"
                    style={{
                      textTransform: "uppercase",
                      textAlign: "left",
                      fontWeight: "bold",
                      fontSize: "16px",
                      color: '#000000', // Changed to black for readability
                      padding: '5px'
                    }}
                  >
                    {key}
                  </Typography>
                  {affectData[key].map((checkpoint, idx) => (
                    <Grid container direction="column" spacing={1} key={idx}>
                      <Grid item>
                        <Typography
                          variant="body1"
                          style={{
                            textTransform: "uppercase",
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: '#000000', // Changed to black for readability
                            padding: '5px'
                          }}
                        >
                          {checkpoint.checkpoint}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="body2"
                          style={{
                            textTransform: "uppercase",
                            textAlign: "left",
                            // fontWeight: "bold",
                            fontSize: "12px",
                            color: '#000000', // Changed to black for readability
                            padding: '5px'
                          }}
                        >
                          Results: {checkpoint.results}
                        </Typography>
                      </Grid>
                      {checkpoint.results === 'Yes' && (
                        <Grid item>
                          <Typography
                            variant="body2"
                            style={{
                              textTransform: "uppercase",
                              textAlign: "left",
                              // fontWeight: "bold",
                              fontSize: "12px",
                              color: '#000000', // Changed to black for readability
                              padding: '5px'
                            }}
                          >
                            Remarks: {checkpoint.checkpoints_remarks}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  ))}
                </div>
              ))}
            </Grid>
          ))}
        </Grid>
      </Card>

      <h3
        style={{
          width: "100%",
          fontWeight: 600,
          color: "#4D4D4D",
          padding: "7px 15px",
          fontSize: 18,
        }}
      >
        Action Plan
      </h3>
      <Card style={{ marginBottom: "10px", width: "99%", border: "1px solid #ccc" }}>
        <Grid container direction="column" spacing={2}>
          {data.actionplan_description.map((pageData, index) => (
            <Grid item key={index}>
              {Object.keys(pageData).map((pageKey) => (
                <div key={pageKey}>
                  <Typography
                    variant="h6"
                    style={{
                      textTransform: "uppercase",
                      textAlign: "left",
                      fontWeight: "bold",
                      fontSize: "16px",
                      color: '#000000', // Changed to black for readability
                      padding: '5px'
                    }}
                  >
                    {pageKey}
                  </Typography>
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <Typography
                        variant="body1"
                        style={{
                          textTransform: "uppercase",
                          textAlign: "left",
                          // fontWeight: "bold",
                          fontSize: "14px",
                          color: '#000000', // Changed to black for readability
                          padding: '5px'
                        }}
                      >
                        Target Date: {pageData[pageKey].target_date}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        style={{
                          textTransform: "uppercase",
                          textAlign: "left",
                          // fontWeight: "bold",
                          fontSize: "14px",
                          color: '#000000', // Changed to black for readability
                          padding: '5px'
                        }}
                      >
                        Descriptions: {pageData[pageKey].descriptions}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        style={{
                          textTransform: "uppercase",
                          textAlign: "left",
                          // fontWeight: "bold",
                          fontSize: "14px",
                          color: '#000000', // Changed to black for readability
                          padding: '5px'
                        }}
                      >
                        Responsibility: {pageData[pageKey].responsibility}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        style={{
                          textTransform: "uppercase",
                          textAlign: "left",
                          // fontWeight: "bold",
                          fontSize: "14px",
                          color: '#000000', // Changed to black for readability
                          padding: '5px'
                        }}
                      >
                        Action Required: {pageData[pageKey].action_required}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              ))}
            </Grid>
          ))}
        </Grid>
      </Card>
      {/* <h3
        style={{
          width: "100%",
          fontWeight: 600,
          color: "#4D4D4D",
          padding: "7px 15px",
          fontSize: 18,
        }}
      >
        Trials
      </h3>
      <Card style={{ marginBottom: "10px", width: "99%", border: "1px solid #ccc" }}>
        <Grid container direction="column" spacing={2}>
          {Object.keys(data.trials).map((key, index) => {
            if (!['before', 'after', 'id'].includes(key)) {
              return (
                <Grid item key={index}>
                  <Typography
                    variant="body1"
                    style={{
                      textTransform: "uppercase",
                      textAlign: "left",
                      // fontWeight: "bold",
                      fontSize: "14px",
                      color: '#000000', // Changed to black for readability
                      padding: '5px'
                    }}
                  >
                    {`${key.replace(/_/g, ' ')}: ${data.trials[key]}`}
                  </Typography>
                </Grid>
              );
            }
            return null;
          })}
        </Grid>
      </Card> */}
      {/* <h3
        style={{
          width: "100%",
          fontWeight: 600,
          color: "#4D4D4D",
          padding: "7px 15px",
          fontSize: 18,
        }}
      >
        Document
      </h3>
      <Card style={{ marginBottom: "10px", width: "99%", border: "1px solid #ccc" }}>
        <Grid container direction="column" spacing={2}>
          {data.doc_descriptions.map((pageData, index) => (
            <Grid item key={index}>
              {Object.keys(pageData).map((pageKey) => (
                <div key={pageKey}>
                  <Typography
                    variant="h6"
                    style={{
                      textTransform: "uppercase",
                      textAlign: "left",
                      fontWeight: "bold",
                      fontSize: "16px",
                      color: '#000000', // Changed to black for readability
                      padding: '5px'
                    }}
                  >
                    {pageKey}
                  </Typography>
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <Typography
                        variant="body1"
                        style={{
                          textTransform: "uppercase",
                          textAlign: "left",
                          // fontWeight: "bold",
                          fontSize: "14px",
                          color: '#000000', // Changed to black for readability
                          padding: '5px'
                        }}
                      >
                        Status: {pageData[pageKey].status}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        style={{
                          textTransform: "uppercase",
                          textAlign: "left",
                          // fontWeight: "bold",
                          fontSize: "14px",
                          color: '#000000', // Changed to black for readability
                          padding: '5px'
                        }}
                      >
                        Target Date: {pageData[pageKey].target_date}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        style={{
                          textTransform: "uppercase",
                          textAlign: "left",
                          // fontWeight: "bold",
                          fontSize: "14px",
                          color: '#000000', // Changed to black for readability
                          padding: '5px'
                        }}
                      >
                        Descriptions: {pageData[pageKey].descriptions}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        style={{
                          textTransform: "uppercase",
                          textAlign: "left",
                          // fontWeight: "bold",
                          fontSize: "14px",
                          color: '#000000', // Changed to black for readability
                          padding: '5px'
                        }}
                      >
                        Applicability: {pageData[pageKey].applicability}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              ))}
            </Grid>
          ))}
        </Grid>
      </Card> */}
      <h3
        style={{
          width: "100%",
          fontWeight: 600,
          color: "#4D4D4D",
          padding: "7px 15px",
          fontSize: 18,
        }}
      >
        Implement MOC
      </h3>
      <Card style={{ marginBottom: "10px", width: "99%", border: "1px solid #ccc" }}>
        <Grid container style={{
          marginBottom: 10, display: "flex",
          // alignItems: "center",
          flexDirection: "column",
          width: "100%",
        }} >
          <div style={{
            display: "flex",
            //   alignItems: "center",

            width: "100%",
            marginBottom: "10px" // Add margin to separate the sections
          }}>
            <Typography
              variant="h1"
              style={{
                textTransform: "uppercase",
                textAlign: "left",
                fontWeight: "bold",
                width: "300px",
                fontSize: "18px",
                color: 'FFFFFF',
                padding: '5px'
              }}
            >
              Remarks
            </Typography>
            <Typography
              variant="h1"
              style={{
                textTransform: "uppercase",
                textAlign: "left",

                width: "300px",
                fontSize: "18px",
                color: 'FFFFFF',
                padding: '5px'
              }}
            >
              {data?.admin_remarks}
            </Typography>
          </div>
          <div style={{
            display: "flex",
            //   alignItems: "center",

            width: "100%",
            marginBottom: "10px" // Add margin to separate the sections
          }}>
            <Typography
              variant="h1"
              style={{
                textTransform: "uppercase",
                textAlign: "left",
                fontWeight: "bold",
                width: "300px",
                fontSize: "18px",
                color: 'FFFFFF',
                padding: '5px'
              }}
            >
              Customer Approval
            </Typography>
            <Typography
              variant="h1"
              style={{
                textTransform: "uppercase",
                textAlign: "left",

                width: "300px",
                fontSize: "18px",
                color: 'FFFFFF',
                padding: '5px'
              }}
            >
              {data?.customer_approval}
            </Typography>
          </div>
          <div style={{
            display: "flex",
            //   alignItems: "center",

            width: "100%",
            marginBottom: "10px" // Add margin to separate the sections
          }}>
            <Typography
              variant="h1"
              style={{
                textTransform: "uppercase",
                textAlign: "left",
                fontWeight: "bold",
                width: "300px",
                fontSize: "18px",
                color: 'FFFFFF',
                padding: '5px'
              }}
            >
              Whether this change affects product compliance requirements
            </Typography>
            <Typography
              variant="h1"
              style={{
                textTransform: "uppercase",
                textAlign: "left",

                width: "300px",
                fontSize: "18px",
                color: 'FFFFFF',
                padding: '5px'
              }}
            >
              {data?.product_compliance_check}
            </Typography>
          </div>
       {data?.product_compliance_check==="Yes" && (
          <div style={{
            display: "flex",
            //   alignItems: "center",

            width: "100%",
            marginBottom: "10px" // Add margin to separate the sections
          }}>
            <Typography
              variant="h1"
              style={{
                textTransform: "uppercase",
                textAlign: "left",
                fontWeight: "bold",
                width: "300px",
                fontSize: "18px",
                color: 'FFFFFF',
                padding: '5px'
              }}
            >
              Product Complaince Name
            </Typography>
            <Typography
              variant="h1"
              style={{
                textTransform: "uppercase",
                textAlign: "left",

                width: "300px",
                fontSize: "18px",
                color: 'FFFFFF',
                padding: '5px'
              }}
            >
              {data?.product_compliance}
            </Typography>
          </div>)}
        </Grid>
      </Card>
    </Grid>
  );
};
export default PreviewPdf;