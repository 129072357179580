import React, { useState } from "react";
import Header from "../app/TopAppBar";
import { Tab, Tabs } from "@mui/material";
import TrialsMoc from "./TrialsMoc";
import Document from "./Documents";
import ActionPlan from "./ActionPlan";

const ActionSheet = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const initialPageData = Array(8).fill([]).map(() => Array(1).fill(""));
  const [pageData, setPageData] = useState(initialPageData);

  const handleTabChange = (_, newValue) => {
    // setSelectedTab(newValue);
  };

  const updateTab = () => {
    setSelectedTab(1); // Switch to the "Trials" tab
    setCurrentPage(1);
  };

  const handleDocumentTab = () => {
    setSelectedTab(2); // Switch to the "Documents" tab
    setCurrentPage(0); // Optionally, set the current page to the Documents page
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      {/* <Header pageTitle="MOC Action Sheet" /> */}
      <div style={{ width: "100%", marginTop: "0px" }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          centered
          indicatorColor="primary"
          sx={{
            maxWidth: "400px",
            "& .MuiTab-textColorPrimary.Mui-selected": {
              color: "#B51849",
            },
            "& .MuiTabs-indicator": {
              backgroundColor: "#B51849",
            },
          }}
        >
          <Tab label="Action Plan" />
          <Tab label="Trials" />
        </Tabs>
        {selectedTab === 0 && (
          <ActionPlan
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageData={pageData}
            setPageData={setPageData}
            updateTabs={updateTab} // Update the prop name to updateTabs
          />
        )}
        {selectedTab === 1 && (
          <TrialsMoc
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageData={pageData}
            setPageData={setPageData}
            handleDocumentTab={handleDocumentTab}
          />
        )}
      </div>
    </div>
  );
};

export default ActionSheet;